import * as React from "react";
import PublicProfileServiceCard from "./PublicProfileServiceCard";
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext";
import Loader from "../ui/loader";

const PublicProfileServices: React.FC = () => {
  const { loadingServices, services, getServices } = React.useContext(
    CoachPublicProfileContext,
  );

  return (
    <>
      <h1 className="font-bold text-xl">Services & Programs</h1>
      <div className="w-full md:max-w-[1200px] flex items-center flex-wrap justify-start gap-[20px]">
        {loadingServices ? <Loader /> : (
          <>
            {services &&
              services.map((service: any, index: number) => (
                <PublicProfileServiceCard
                  key={index}
                  service={service}
                  getServices={getServices}
                />
              ))}
            {services.length === 0 && (
              <div className="flex flex-col items-center gap-[12px]">
                <div className="text-graySlate">No services</div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PublicProfileServices;
