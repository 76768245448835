"use client"

import React from "react"
import { Button } from "../../ui/button"
import image1 from "../../../assets/images/hero/card-1.png"
import image2 from "../../../assets/images/hero/card-2.png"
import image3 from "../../../assets/images/hero/card-3.png"
import image4 from "../../../assets/images/hero/card-4.png"
import image5 from "../../../assets/images/hero/card-6.png"
import whiteLogo from "../../../assets/images/White-Logo-1.png"
import { motion, useScroll, useTransform } from "framer-motion"
import CalendlyScheduler from "../../../pages/manage-business/CalendlyScheduler"
import { useAuthModal } from "../../auth/use-auth-modal"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog"
import { useAutoAnimate } from "@formkit/auto-animate/react"

export const Hero = () => {
  const strings = ["starting", "growing", "managing", "enjoying"]

  const [index, setIndex] = React.useState(0)

  const { scrollYProgress } = useScroll()
  const { showRegister } = useAuthModal()
  const [parent] = useAutoAnimate()
  let y = useTransform(scrollYProgress, [0, 1], ["0%", "-300%"])

  React.useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= strings.length - 1) return 0
        return state + 1
      })
    }, 2500)
    return () => clearInterval(id)
  }, [])

  return (
    <div
      className="pt-[225px] md:pt-[350px] flex flex-col items-center justify-center overflow-hidden -mt-16 bg-gradient-to-tl from-blueVibrant/20 to-white"
      style={{
        fontFamily: "BeausiteClassic",
      }}
    >
      <h1
        className="text-5xl leading-snug font-bold mb-10 text-center -mt-16 tracking-normal max-w-4xl text-balance"
        ref={parent}
      >
        Zoee handles the details of
        <span key={strings[index]} className="inline-block w-64 text-blurple">
          {strings[index]}
        </span>
        your coaching business
      </h1>
      <div className="flex flex-row items-center flex-wrap gap-8 justify-center">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline" size="lg">
              Schedule a Demo
            </Button>
          </DialogTrigger>
          <DialogContent className="flex flex-col items-center">
            <DialogHeader>
              <DialogTitle className="text-center">
                Schedule Your 30 Minute Demo
              </DialogTitle>
            </DialogHeader>

            <CalendlyScheduler name="group-demo" />
          </DialogContent>
        </Dialog>

        <Button
          onClick={() => showRegister({ accountType: "coach" })}
          size="lg"
        >
          Start Your Free Trial
        </Button>
      </div>
      <p className="mt-5 mb-20 text-base text-muted-foreground">
        No credit card required.
      </p>

      <motion.div
        style={{ y }}
        transition={{
          type: "spring",
          damping: 0,
          stiffness: 1000,
          duration: 0.075,
        }}
        className="h-[700px] w-full flex flex-row -mb-[400px] ml-auto 2xl:justify-center"
      >
        <img
          alt="client contact card with a link to view their profile"
          src={image1}
          className="h-72 -mt-8 2xl:ml-24"
        />
        <img
          alt="meeting interface with a 'Join' button"
          src={image2}
          className="h-80"
        />
        <img
          alt="coach card displaying the coach's title with a link to view their profile"
          src={image3}
          className="h-80 mt-8 ml-6"
        />
        <img
          alt="chat conversations between a coach and a client"
          src={image4}
          className="h-96 -ml-1"
        />
        <img
          alt="service offered by a coach"
          src={image5}
          className="h-96 -ml-2 -mt-8"
        />
      </motion.div>
      <div className="bg-gradient-to-t from-white to-transparent w-full flex justify-center">
        <img
          src={whiteLogo}
          alt="Zoee logo"
          className="min-w-[1000px] -mb-4 lg:-mb-16"
        />
      </div>
    </div>
  )
}
