import React from "react";
import PrivateProfileBlog from "./PrivateProfileBlog";
import PrivateProfileBlogPost from "./PrivateProfileBlogPost";
import PortraitVideoDeck from "./PortraitVideoDeck";

interface Props {}

const PrivateProfileFeatureTab: React.FC<Props> = ({}) => {
  return (
    <>
      <h1 className="text-xl font-bold">Video</h1>
      <PortraitVideoDeck />
      <h1 className="text-xl font-bold">Blog</h1>
      <PrivateProfileBlog />
    </>
  );
};
export default PrivateProfileFeatureTab;
