import React from "react";
import { NotificationCtx } from "../../context/notificationContext";
import { UserCtx } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { SvgClose, SvgNotifications } from "../icons/";
import { Button } from "../ui/button";

interface Props {
  notification: any;
}

const NotificationAlert: React.FC<Props> = ({ notification }) => {
  const { hideNotificationAlert } = React.useContext(NotificationCtx);
  const { user } = React.useContext(UserCtx);
  const navigate = useNavigate();

  const setLink = () => {
    let activeProfile = user.activeProfile;
    let link = "/";
    if (notification.link === "/contacts") {
      link = `${activeProfile}/contacts`;
    } else if (notification.link === "/scheduling") {
      link = `${activeProfile}/scheduling`;
    } else if (notification.link === "/sessions") {
      link = `${activeProfile}/sessions`;
    } else if (notification.link === "/inbox") {
      link = `${activeProfile}/inbox`;
    } else {
      link = notification.link;
    }

    return link;
  };

  const handleNotificationClick = () => {
    const link = setLink();
    navigate(link);
    hideNotificationAlert();
  };

  return (
    <div 
    className="fixed right-0 top-28 lg:top-24 z-50 flex overflow-hidden rounded-xl bg-white shadow-lg sm:left-auto sm:w-full sm:max-w-[380px]"
    >
      <button
        onClick={handleNotificationClick}
        className="flex items-center p-3"
      >
        <SvgNotifications className="text-blurple h-8 w-8 shrink-0" />
        <div className="mr-10 flex flex-col items-center">
          <span className="font-semibold">{notification.message}</span>
          <span className="text-base font-normal">click here</span>
        </div>
      </button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          hideNotificationAlert();
        }}
        size="icon"
        variant="ghost"
        className="absolute right-3 top-3 rounded-full"
      >
        <SvgClose />
      </Button>
    </div>
  );
};

export default NotificationAlert;
