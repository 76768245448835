import { useState, useEffect, FC, ReactNode } from "react";
import { faqs } from "../../../data/faqData";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { SvgChevronDown } from "../../icons";

const FAQ: FC = () => {
    return (
        <div className="max-w-[1232px] mx-auto md:px-[48px]">
            {faqs.map((faq) => {
                return <FAQItem key={faq.id} title={faq.title} content={faq.content} />;
            })}
        </div>
    );
};

export default FAQ;

type FAQItemProps = {
    title: string;
    content: ReactNode;
};

const FAQItem: FC<FAQItemProps> = ({ title, content }) => {
    const [showContent, setShowContent] = useState<boolean>(false);

    const controls = useAnimation();

    const variants = {
        expanded: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 },
    };

    useEffect(() => {
        if (showContent) {
            controls.start("expanded");
        } else {
            controls.start("collapsed");
        }
    }, [showContent]);

    return (
        <motion.div
            onClick={() => setShowContent(() => !showContent)}
            className="py-[54px] mx-[24px] cursor-pointer border-b border-gray last:border-0"
        >
            <div className="flex justify-between">
                <h3 className="font-semibold md:text-[32px] md:leading-[36px] text-[26px] leading-[32px]">
                    {title}
                </h3>
                <SvgChevronDown
                    className={`transition-all duration-300 ${showContent ? "rotate-180" : ""}`}
                />
            </div>
            <AnimatePresence>
                {showContent && (
                    <>
                        <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: 36 }}
                            exit={{ height: 0 }}
                            transition={{ duration: 0.3 }}
                        ></motion.div>
                        <motion.div
                            className="text-[18px] overflow-hidden"
                            initial="collapsed"
                            animate={controls}
                            transition={{ duration: 0.3 }}
                            variants={variants}
                            exit={variants.collapsed}
                        >
                            {content}
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </motion.div>
    );
};
