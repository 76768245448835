import React, { FC } from "react";
import { features } from "../../../data/featureData";
import { Button } from "../../ui/button";
import { SvgChevronLeft, SvgChevronRight } from "../../icons/";

type Props = {
    activeFeature: number;
    setActiveFeature: React.Dispatch<React.SetStateAction<number>>;
};

const FeaturesCarouselControls: FC<Props> = ({
    setActiveFeature,
    activeFeature,
}) => {
    return (
        <div className="flex flex-col w-full">
            <div className="flex items-center justify-between py-[20px] px-[32px]">
                <Button
                    className="z-30"
                    size="icon"
                    variant="ghost"
                    onClick={() =>
                        setActiveFeature((prev) => {
                            if (prev === 1) {
                                return features.length;
                            } else {
                                return prev - 1;
                            }
                        })
                    }
                >
                    <SvgChevronLeft />
                </Button>
                <Button
                    className="z-30"
                    size="icon"
                    variant="ghost"
                    onClick={() =>
                        setActiveFeature((prev) => {
                            if (prev === features.length) {
                                return 1;
                            } else {
                                return prev + 1;
                            }
                        })
                    }
                >
                    <SvgChevronRight />
                </Button>
            </div>
            <div className="flex items-center gap-[10px] mx-auto">
                {features.map((feature, index) => {
                    return (
                        <div
                            key={index}
                            className={`${
                                feature.id === activeFeature ? "bg-grayCharcoal" : "bg-grayCharcoal/20"
                            } rounded-full w-[12px] h-[12px]`}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default FeaturesCarouselControls;
