import React from "react"
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { Button } from "../../ui/button"
import { SvgEdit } from "../../icons/"

interface Props {
  post: any
}

const PrivateProfileBlogPost: React.FC<Props> = ({ post }) => {
  const { setEditBlogPost, coachPublicProfileData } = React.useContext(CoachPrivateProfileContext)

  const coachColor = coachPublicProfileData?.profile_information?.profileColor

  const readTime = () => {
    const textItems = post?.content
    const allTextStrings: any = []
    textItems?.forEach((item: any) => {
      const children = item.children
      children?.forEach((child: any) => {
        allTextStrings?.push(child.text)
      })
    })
    const fullString = allTextStrings.join(" ")
    const wpm = 225
    const words = fullString?.trim().split(/\s+/).length
    const time = Math.ceil(words / wpm)
    return time
  }

  const getPostStatus = () => {
    if (post?.status === "approved") {
      return "Approved Post"
    } else if (post?.status === "pending") {
      return "Pending Approval"
    } else if (post?.status === "denied") {
      return "Post Denied"
    }
  }

  const getStatusColor = () => {
    if (post?.status === "approved") {
      return "bg-green text-white"
    } else if (post?.status === "pending") {
      return "bg-yellow text-black"
    } else if (post?.status === "denied") {
      return "bg-vividRed text-white"
    }
  }

  return (
    <div
      className="flex min-w-[300px] min-h-[300px] md:min-w-[350px] md:min-h-[350px]
            rounded-[16px] relative main-shadow group shadow-custom last:mb-[80px] md:last:mb-0"
      style={{
        backgroundColor: `${coachColor}`,
      }}
    >
      <Button
        onClick={() => setEditBlogPost({ show: true, post: post })}
        variant="utility"
        size="lg"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-[4px] px-[20px] bg-white border z-20 border-[1px] shadow-md opacity-100 hidden group-hover:flex"
      >
        <SvgEdit />
        Edit
      </Button>
      <button
        onClick={() => setEditBlogPost({ show: true, post: post })}
        className="sm:hidden flex absolute w-[40px] h-[40px] rounded-[16px] z-[10] top-[24px] right-[24px]
                bg-white/[0.84] items-center justify-center"
      >
        <SvgEdit />
      </button>
      {post?.has_image ? (
        <img
          key={post?.id}
          src={`https://blog-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${post?.id}`}
          alt="blog post"
          className="w-full h-full absolute top-0 left-0 rounded-[16px] object-cover"
        />
      ) : null}
      <div className="flex flex-col items-start absolute top-[24px] left-[24px] gap-[8px]">
        <div
          className={`rounded-[100px] ${getStatusColor()}
                    bg-blur-md text-black py-[4px] px-[12px] font-bold text-base`}
        >
          {getPostStatus()}
        </div>
        {post?.status === "approved" && post?.published ? (
          <div className="rounded-[100px] bg-primaryBlue bg-blur-md text-white py-[4px] px-[12px] font-bold text-base">
            Published
          </div>
        ) : (
          <div className="rounded-[100px] bg-primaryBlue bg-blur-md text-white py-[4px] px-[12px] font-bold text-base">
            Draft
          </div>
        )}
      </div>
      <div className="absolute bottom-[10px] left-[10px] flex flex-col gap-[12px] text-left bg-white/[0.85] p-[12px] rounded-[15px] min-w-[236px]">
        <h2 className="font-bold text-[16px] max-w-[208px] text-black line-clamp-2">
          {post?.title}
        </h2>
        <div className="flex items-center gap-[8px] font-bold text-base">
          <span className="text-black">{readTime()} min read</span>
        </div>
      </div>
    </div>
  )
}

export default PrivateProfileBlogPost
