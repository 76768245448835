import React from "react";
import BackToButton from "../../BackToButton";
import BillingPreviewElement from "./BillingPreviewElement";

type Props = {
    contact: any;
    setShowBilling: React.Dispatch<React.SetStateAction<boolean>>;
};

const BillingMobileView: React.FC<Props> = ({ contact, setShowBilling }) => {
    return (
        <div className="px-[16px] pt-[12px] md:pt-[106px] pb-[80px]">
            <BackToButton onClick={() => setShowBilling(false)} prev={contact.contact_name} current={`${contact.contact_name} - Billing`} />
            <BillingPreviewElement contact={contact} />
        </div>
    );
};

export default BillingMobileView;
