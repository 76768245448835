import { FC } from "react";

type Props = {
    text: string;
};

const ValidationWarning: FC<Props> = ({ text }) => {
    return <div className="mx-auto px-[24px] py-[16px] rounded-lg bg-white border border-orange shadow-lg w-[50%] text-center font-semibold text-base">{text}</div>;
};

export default ValidationWarning;
