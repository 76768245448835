import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as z from "zod"

import { UserExists } from "../../../api/auth.service"
import PasswordRequirements from "../../account-settings/PasswordRequirements"
import { SvgChevronRight } from "../../icons"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Loader from "../../ui/loader"
import { SwapAuthAction } from "../swap-auth-action"
import { RegisterFormContext } from "./context"
import { validateFirstAndLastName } from "./name-form"
import Recaptcha from "./Recaptcha"
import { is } from "date-fns/locale"

const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        email,
      },
    })

    return { found: data.user_found }
  } catch (error) {
    console.error(error)
  }
}

export const RegistrationForm: React.FC = () => {
  const {
    email,
    setEmail,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    createAccount,
  } = React.useContext(RegisterFormContext)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)

  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  )
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  )
  const [showPaswReqs, setShowPassReq] = useState<boolean>(false)
  const { password, setPassword, accountType } = useContext(RegisterFormContext)
  const [isEntreeValid, setIsEntryValid] = useState<boolean>(false)
  const [confUserPassword, setConfUserPassword] = useState<string>("")
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)

  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  useEffect(() => {
    const validateForm = () => {
      const emailIsValid = z.string().email().safeParse(email).success
      const firstNameIsValid =
        firstName.trim() !== "" && validateFirstAndLastName(firstName).success
      const lastNameIsValid =
        lastName.trim() !== "" && validateFirstAndLastName(lastName).success
      let passwordIsValid = true
      if (accountType === "coach") {
        const passwordsMatch = password === confUserPassword
        passwordIsValid = password.trim() !== "" && passwordsMatch
      }
      setIsFormValid(
        emailIsValid &&
          firstNameIsValid &&
          lastNameIsValid &&
          (accountType !== "coach" || passwordIsValid) &&
          isNotRobot
      )
    }

    validateForm()
  }, [
    email,
    firstName,
    lastName,
    password,
    confUserPassword,
    isNotRobot,
    accountType,
  ])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const emailSchema = z.string().email().safeParse(email)

    if (!emailSchema.success) {
      setError(emailSchema.error.issues[0].message)
      setLoading(false)
      return
    }
    const emailExists = await doesEmailExist(email)
    if (emailExists?.found) {
      setError("Email is already in use")
      setLoading(false)
      return
    }
    try {
      await createAccount()
      setLoading(false)
    } catch (error) {
      console.error("Error creating account:", error)
      setError("Failed to create account. Please try again.")
      setLoading(false)
    }
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const inputValue = e.target.value
    const inputSuccess = validateFirstAndLastName(inputValue).success
    const errorMsg = "Invalid Input"

    if (inputName === "email") {
      setError(undefined)
      setEmail(inputValue)
    }

    if (inputName === "first") {
      setFirstNameError(undefined)
      setFirstName(inputValue)
      if (!inputSuccess) {
        setFirstNameError(errorMsg)
      }
    }

    if (inputName === "last") {
      setLastNameError(undefined)
      setLastName(inputValue)
      if (!inputSuccess) {
        setLastNameError(errorMsg)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2">
      <Input
        label="Email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={error}
        disabled={loading}
        autoFocus
      />
      <Input
        label="First Name"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => handleChange(e, "first")}
        error={firstNameError}
        disabled={loading}
      />
      <Input
        label="Last Name"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => handleChange(e, "last")}
        error={lastNameError}
        disabled={loading}
      />
      {accountType === "coach" && (
        <>
          {showPaswReqs && (
            <PasswordRequirements
              validEntree={password}
              setIsEntryValid={setIsEntryValid}
            />
          )}
          <Input
            label="Password"
            placeholder="New password"
            value={password}
            onFocus={() => setShowPassReq(true)}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            autoComplete="off"
            disabled={loading}
          />
          <Input
            label="Confirm your password"
            placeholder="Confirm new password"
            value={confUserPassword}
            onChange={(e) => setConfUserPassword(e.target.value)}
            type="password"
            onFocus={() => setShowPassReq(false)}
            autoComplete="off"
            disabled={loading}
          />
        </>
      )}

      <Recaptcha setIsNotRobot={setIsNotRobot} />

      <Button className="w-full" disabled={loading || !isFormValid}>
        {loading ? <Loader /> : "Create Account"}
      </Button>

      <p className="text-muted-foreground text-xs text-pretty w-52 text-center mx-auto">
        By clicking continue, I agree to Zoee{" "}
        <Link
          to="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          Terms
        </Link>{" "}
        &{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          Privacy Policy
        </Link>
      </p>
    </form>
  )
}
