import React, { useRef } from "react";
import moment from "moment";
import { ProfileClientPaymentsItem } from "../../../types/clientPaymentTypes";
import _ from "lodash";
import { UserCtx } from "../../../context/userContext";
import { SvgDownload, SvgEye } from "../../icons";
// import useOutsideClick from "../../../hooks/useOutsideClick";

interface Props {
    invoice: ProfileClientPaymentsItem;
}

const BillingPreviewItem: React.FC<Props> = ({ invoice }) => {
    const { user } = React.useContext(UserCtx);
    const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
    const stripeAmount = invoice.stripe_amount / 100;
    const formattedStripeAmount = stripeAmount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
    });
    // TODO implement outside click with a unique ref for each item
    // const ref = useRef(null);

    // useOutsideClick(ref, () => {
    //     setShowDropdown(false);
    // });
    const formatStripeStatus = (stripeStatus: string) => {
        if (stripeStatus === "succeeded") {
            return "Paid";
        } else {
            return _.capitalize(stripeStatus);
        }
    };

    return (
        <div className="w-full h-[76px] border border-grayMist rounded-[10px] px-[24px] flex items-center justify-between">
            <div className="flex items-center gap-[24px]">
                <div className="flex items-center gap-[4px]">
                    <div className="flex items-center flex-col">
                        <span className="text-gray font-bold text-base">
                            {moment(invoice.transaction_date).format("MMM")}
                        </span>
                        <span className="text-gray font-bold text-[18px]">
                            {moment(invoice.transaction_date).format("DD")}
                        </span>
                    </div>
                    {/* <span className="text-gray font-bold text-[30px]">
                        '{moment(invoice.transaction_date).format("YY")}
                    </span> */}
                </div>
                
                <div className="w-[1px] h-[40px] bg-grayMist"></div>
                <div className="flex flex-col">
                    <h6 className="font-bold text-[16px]">{invoice?.service_name}</h6>
                    <span className="text-gray text-base">
                        {formatStripeStatus(invoice.stripe_status)}
                    </span>
                </div>
            </div>
            <div className="relative flex items-center gap-[24px]">
                <h5 className="font-bold text-[16px]">${formattedStripeAmount}</h5>
                
                {user.activeProfile === "member" && 
                <button
                    onClick={() => setShowDropdown(() => !showDropdown)}
                    className="flex items-center gap-[3px] h-[20px]"
                >
                    <div className="w-[3px] h-[3px] bg-gray rounded-full"></div>
                    <div className="w-[3px] h-[3px] bg-gray rounded-full"></div>
                    <div className="w-[3px] h-[3px] bg-gray rounded-full"></div>
                </button>
                }

                {showDropdown && (
                    <div className="absolute top-[32px] right-0 z-[600]">
                        <BillingPreviewItemDropdown
                            viewInvoiceUrl={invoice.invoice_url}
                            downloadInvoiceUrl={invoice.invoice_pdf}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BillingPreviewItem;

interface DropdownProps {
    viewInvoiceUrl: string;
    downloadInvoiceUrl: string;
}

const BillingPreviewItemDropdown: React.FC<DropdownProps> = ({
    viewInvoiceUrl,
    downloadInvoiceUrl,
}) => {
    return (
        <div className="flex flex-col bg-white px-[16px] py-[12px] rounded-[12px] card-hover-shadow">
            <a
                href={viewInvoiceUrl}
                target="_blank"
                className="text-base w-[216px] px-[16px] py-[12px] flex items-center gap-[8px] rounded-[12px] hover:bg-hover"
                rel="noreferrer"
            >
                <SvgEye />
                View Receipt
            </a>
            <a
                href={downloadInvoiceUrl}
                target="_blank"
                className="text-base w-[216px] px-[16px] py-[12px] flex items-center gap-[8px] rounded-[12px] hover:bg-hover"
                rel="noreferrer"
            >
                <SvgDownload />
                Download Invoice PDF
            </a>
        </div>
    );
};
