import React, { useEffect, type Dispatch, type SetStateAction } from "react";
import { CommonFunctionCtx } from "./commonFunctionContext";
import { UserCtx } from "./userContext";
import { SubscriptionRequests } from "../api/app.service";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";

interface SubscriptionInterface {
  activeSubscription: any
  activeSubscriptionType: string
  setUserActiveSubscription: () => void
  annualAmount: number
  monthAmount: number
  twoYearAmount : number
  lifetimeAmount: number
  monthPromoAmountString: string
  monthPromo: string
  lifetimePromo: string
  twoYear: string
  subscriptionPlans: []
  setZoeeSubscriptionPlans: () => Promise<void>;
  showSubscriptionCheckout: boolean
  setShowSubscriptionCheckout: Dispatch<SetStateAction<boolean>>
  showSubscribeBanner: boolean
  setShowSubscribeBanner: Dispatch<SetStateAction<boolean>>
}

export const SubscriptionCtx = React.createContext<SubscriptionInterface>({} as SubscriptionInterface);

interface Props {
  children: React.ReactNode;
}

export const SubscriptionProvider: React.FC<Props> = ({ children }) => {
  const { user } = React.useContext(UserCtx)
  const isAuthed = useAuthenticatedUser(user);
  const { renderError, hideAlert } = React.useContext(CommonFunctionCtx)
  const [showSubscriptionCheckout, setShowSubscriptionCheckout] = React.useState<boolean>(false)
  const [showSubscribeBanner, setShowSubscribeBanner] = React.useState<boolean>(false)
  const [activeSubscription, setActiveSubscription] = React.useState<any>({})
  const [activeSubscriptionType, setActiveSubscriptionType] = React.useState<string>("")
  const [subscriptionPlans, setSubscriptionPlans] = React.useState<any>([])
  const monthAmount = 49
  const annualAmount = 490
  const twoYearAmount = 500
  const lifetimeAmount = 500
  const monthPromoAmountString = `1.00`
  const monthPromo = 'FALL2023'
  const lifetimePromo = '500FOREVER'
  const twoYear = '500TWOYEAR'

  useEffect(() => {
    setUserActiveSubscription()
    setZoeeSubscriptionPlans()
  }, [])

  const activeUserSubscriptionType = (active_subscription:any) => {
    let subscriptionType = `unknown`
    if (Object.keys(active_subscription).length === 0) {
      subscriptionType = `trial`
    } else if (active_subscription.recurring_interval === 'month' && active_subscription.subscription_promo_code === 'FALL2023') {
      subscriptionType = `promoMonth`
    } else if (Object.keys(active_subscription).length !== 0 && active_subscription.subscription_promo_code !== '500FOREVER') {
      subscriptionType = `paid`
    } else if (active_subscription.is_promotional && active_subscription.subscription_promo_code === '500FOREVER') {
      subscriptionType = `lifetime`
    } else if (active_subscription.recurring_interval === 'month' && !active_subscription.is_promotional) {
      subscriptionType = `month`
    } else if (active_subscription.recurring_interval === 'annual' && !active_subscription.is_promotional) {
      subscriptionType = `annual`
    }
    setActiveSubscriptionType(subscriptionType)
    setShowSubscribeBanner(subscriptionType !== 'lifetime')
  }

  const setZoeeSubscriptionPlans = async () => {
    if (isAuthed) {
      await SubscriptionRequests.getSubscriptionPlans({
        user_id: parseInt(user.user_id),
      })
      .then((data) => {
        setSubscriptionPlans(data?.plans)
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setTimeout(() => hideAlert(), 5000);
      })
    }  
  };

  const setUserActiveSubscription = async () => {
    if (isAuthed) {
      await SubscriptionRequests.getActiveSubscription({
        user_id: parseInt(user.user_id)
      })
      .then((data: any) => {
        setActiveSubscription(data.active_subscription)
        activeUserSubscriptionType(data.active_subscription)
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
      })
    }
  }
  
  return (
    <SubscriptionCtx.Provider value={{ 
      activeSubscription,
      activeSubscriptionType,
      annualAmount,
      monthAmount,
      twoYearAmount,
      lifetimeAmount,
      monthPromoAmountString,
      monthPromo,
      lifetimePromo,
      twoYear,
      setUserActiveSubscription,
      subscriptionPlans,
      setZoeeSubscriptionPlans,
      showSubscriptionCheckout,
      setShowSubscriptionCheckout,
      showSubscribeBanner,
      setShowSubscribeBanner
    }}>
      {children}
    </SubscriptionCtx.Provider>
  );
};
