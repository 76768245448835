"use client";

import * as React from "react";
import { Link } from "react-router-dom";
import ResourceSlider from "./ResourceSlider";
import { motion } from "framer-motion";
import { posts } from "../../../data/postsData/postIndex";
import { Button } from "../../ui/button";

export const FeaturedArticles: React.FC = () => {
  const displayedPosts = [posts[1], posts[17], posts[8]];

  return (
    <div className="container min-h-screen flex flex-col justify-center items-center">
      <div className="flex items-center justify-between w-full flex-wrap">
        <h2 className="md:text-[42px] text-[30px] text-black font-bold">
          Featured Articles
        </h2>
        <Button variant="link" size="lg" asChild>
          <Link to="/blog">See All Posts</Link>
        </Button>
      </div>

      <div className="hidden lg:grid grid-cols-3 pt-4 gap-8">
        {displayedPosts.map((post, index) => (
          <motion.div
            key={index}
            className="rounded-xl shadow-lg transition-opacity duration-500 overflow-hidden flex flex-col"
            whileHover={{ scale: 1.05 }}
          >
            <img
              src={post.metadata.previewImg}
              alt={`post ${index + 1}`}
              className="w-full object-cover aspect-video"
            />
            <div className="flex flex-col gap-4 p-4 items-start flex-1">
              <h3 className="font-bold text-xl">{post.metadata.title}</h3>
              <p className="text-sm flex-1">{post.metadata.description}</p>
              <Button variant="link" asChild>
                <Link to={`/blog/${post.metadata.urlSlug}`}>Read More</Link>
              </Button>
            </div>
          </motion.div>
        ))}
      </div>

      <ResourceSlider posts={posts} />
    </div>
  );
};
