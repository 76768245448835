import * as React from "react";
import { Post as PostType } from "../../../../types/postTypes";
import { useParams } from "react-router-dom";
import { posts } from "../../../../data/postsData/postIndex";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import { Button } from "../../../../components/ui/button";
import { useAuthModal } from "../../../../components/auth/use-auth-modal";

const PostPage: React.FC = () => {
  const { showRegister } = useAuthModal();

  useScrollToTop();

  const { slug } = useParams();
  const post: PostType | undefined = posts.find(
    (post) => post.metadata.urlSlug === slug,
  );

  const [isHover, setHover] = React.useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(`https://zoee.com/blog/${slug}`);
  };

  const openCreateAccountModal = () => {
    showRegister({
      accountType: localStorage.getItem("signupType") === "member"
        ? "member"
        : "coach",
    });
  };

  const renderContent = () => {
    const nodes: Array<React.ReactNode> = [];

    post?.content.forEach((node) => {
      switch (node.type) {
        case "paragraph":
          nodes.push(<p className="mb-[24px]">{node.text}</p>);
          break;
        case "header1":
          nodes.push(
            <h3 className="mb-[24px] mt-[16px] text-[22px] font-bold">
              {node.text}
            </h3>,
          );
          break;
        case "header2":
          nodes.push(
            <h4 className="mb-[24px] mt-[16px] text-[18px] font-bold">
              {node.text}
            </h4>,
          );
          break;
        case "pullquote":
          nodes.push(
            <div className="bg-blueVibrant mb-[24px]">{node.text}</div>,
          );
          break;
        case "ordered-list":
          nodes.push(
            <ol className="mb-[24px] list-outside list-decimal pl-[24px]">
              {node.items.map((text) => {
                return <li className="mb-[12px]">{text}</li>;
              })}
            </ol>,
          );
          break;
        case "unordered-list":
          nodes.push(
            <ul className="mb-[24px] list-outside list-disc pl-[24px]">
              {node.items.map((text) => {
                return <li className="mb-[12px]">{text}</li>;
              })}
            </ul>,
          );
          break;
        case "rich-text":
          const richTextNode: Array<React.ReactNode> = [];
          node?.items.forEach((item, idx) => {
            switch (item.type) {
              case "normal":
                richTextNode.push(<>{item.text}</>);
                break;
              case "italic":
                richTextNode.push(
                  <span className="italic">
                    {idx === 0 ? "" : " "}
                    {item.text}
                    {idx === node.items.length ? "" : " "}
                  </span>,
                );
                break;
              case "bold":
                richTextNode.push(
                  <span className="font-bold">
                    {idx === 0 ? "" : " "}
                    {item.text}
                    {idx === node.items.length ? "" : " "}
                  </span>,
                );
                break;
              case "boldItalic":
                richTextNode.push(
                  <span className="font-bold italic">
                    {idx === 0 ? "" : " "}
                    {item.text}
                    {idx === node.items.length ? "" : " "}
                  </span>,
                );
                break;
              case "link":
                richTextNode.push(
                  <a
                    href={item.href ? item.href : "#"}
                    className="text-blueVibrant cursor-pointer font-semibold"
                  >
                    {idx === 0 ? "" : " "}
                    <span className="hover:underline">{item.text}</span>
                    {idx === node.items.length ? "" : " "}
                  </a>,
                );
                break;
              default:
                break;
            }
          });
          nodes.push(<p className="mb-[24px]">{richTextNode.map((n) => n)}</p>);
          break;
        default:
          nodes.push(<></>);
          break;
      }
    });

    return <>{nodes.map((n) => n)}</>;
  };

  if (!post) {
    return (
      <div className="my-48 flex flex-col items-center justify-center">
        <h3 className="mb-[32px] text-2xl font-bold">Page not found.</h3>
        <Button asChild variant="link">
          <Link to="/blog">Return to Blog</Link>
        </Button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Zoee Blog - {post.metadata.title}</title>
        <meta name="description" content={post.metadata.description} />
        <meta property="og:title" content={post.metadata.title} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={post.metadata.previewImg} />
        <meta property="og:description" content={post.metadata.description} />
        <meta property="og:site_name" content="Zoee" />
      </Helmet>
      <div className="text-grayCharcoal relative mt-[120px] overflow-hidden">
        <div className="mx-auto mb-[36px] flex w-full max-w-[868px] flex-col items-center justify-center gap-[6px] px-[28px] text-center">
          <h6 className="text-base font-semibold">
            {post.metadata.tags[0].toUpperCase()}
          </h6>
          <h2 className="text-[48px] font-bold leading-[58px]">
            {post.metadata.title}
          </h2>
          {post.metadata.subtitle && <h3>{post.metadata.subtitle}</h3>}
          <h5 className="font-semibold">{post.metadata.date}</h5>
        </div>
        <div className="px-[28px]">
          <img
            src={post.metadata.previewImg}
            alt={post.metadata.title}
            className="mx-auto mb-[50px] max-h-[496px] w-[812px] overflow-hidden rounded-[24px] object-cover md:mb-[80px]"
          />
        </div>
        <div className="mx-auto max-w-[768px] px-[28px]">
          {renderContent()}
          <div className="mx-auto mt-[48px] flex justify-center">
            <Button
              onClick={openCreateAccountModal}
              className=" min-w-fit rounded-md px-[32px] py-[14px] text-base font-semibold text-white"
            >
              Start Now
            </Button>
            {" "}
          </div>
        </div>
        <div className="mx-auto max-w-[768px] px-[28px]">
          <div className="bg-gray mt-[64px] h-[1px]"></div>
          <div className="mb-[48px] mt-[16px] flex flex-col gap-[12px] md:flex-row md:justify-between">
            <div className="flex flex-wrap items-center justify-center gap-[12px] md:justify-start">
              {post.metadata.tags.map((tag, idx) => {
                return (
                  <div
                    key={idx}
                    className="text-graySlate border-graySlate rounded-[8px] border px-[12px] py-[8px] text-base font-semibold"
                  >
                    {tag}
                  </div>
                );
              })}
            </div>

            <div className="flex items-center justify-center md:justify-end">
              <motion.button
                animate={{
                  color: isHover ? "#000" : "#7D878F",
                  borderColor: isHover ? "#000" : "#7D878F",
                }}
                transition={{ duration: 0.3 }}
                onHoverStart={() => setHover(true)}
                onHoverEnd={() => setHover(false)}
                whileTap={{ scale: 0.8, transition: { duration: 0.2 } }}
                onClick={copyLink}
                className="text-graySlate border-graySlate rounded-[8px] border px-[12px] py-[8px] text-base font-semibold"
              >
                Copy Link
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostPage;
