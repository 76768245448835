import React, { FC } from "react";
import InviteToService from "../../../components/services/InviteToService";
import EnrollInService from "../../../components/services/EnrollInService";
import ConfirmServiceInvite from "../../../components/services/ConfirmServiceInvite";
import InviteSent from "../../InviteSent";
import { useLocation, useNavigate } from "react-router-dom";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { ContactRequests } from "../../../api/app.service";
import useScrollToTop from "../../../hooks/useScrollToTop";
import useLoading from "../../../hooks/useLoading";

type Props = {
    contact: any;
};

const ContactServicesFlow: FC<Props> = ({ contact }) => {
    const [confirmServiceInvite, setConfirmServiceInvite] = React.useState<any>(null);
    const [inviteToService, setInviteToService] = React.useState<boolean>(false);
    const [enrollInService, setEnrollInService] = React.useState<boolean>(false);
    const [inviteSent, setInviteSent] = React.useState<boolean>(false);
    const [activeContact, setActiveContact] = React.useState<any>(null);
    const { user } = React.useContext(UserCtx);
    const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();
    const location: any = useLocation();
    const navigate = useNavigate();

    // Logic for enroll in service click originating from ServicesPreviewElement.tsx
    let service: any;
    if (location && location.state) {
        service = location.state.service;
    }
    React.useEffect(() => {
        if (service && activeContact) {
            navigate(`/${user.activeProfile}/contacts/${activeContact?.profile_id}/services/${service.service_id}`, {
                state: { service: service, contact: activeContact },
            });
        }
    }, [service, activeContact]);

    const getServiceOwnerContact = (profileIdToMatch: number) => {
        if (user?.coachProfile || user?.memberProfile) {
            startLoading();
            ContactRequests.getContactList({
                profile_id: parseInt(user.activeProfileId),
                profile_type: user.activeProfile,
            })
                .then((data) => {
                    const foundContact = data.contact_list.find(
                        (contact: any) => contact.profile_id === profileIdToMatch
                    );
                    setActiveContact(foundContact);
                    stopLoading();
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    stopLoading();
                    setTimeout(() => hideAlert(), 5000);
                });
        }
    };

    // Logic for setting InviteToService or EnrollInService based on active profile
    React.useEffect(() => {
        user.activeProfile === "coach" ? setInviteToService(true) : setEnrollInService(true);

        // Logic for handling hard refresh or direct link to .../profile_id/services
        // Fetches contact info from db only if it was not passed from DynamicContactProfile
        if (!contact) {
            const profileId = parseInt(location.pathname.split("/")[3]);
            getServiceOwnerContact(profileId);
        } else {
            setActiveContact(contact);
        }
    }, []);

    useScrollToTop();

    return (
        <div className="">
            {inviteSent && <InviteSent setInviteSent={setInviteSent} />}
            {activeContact && confirmServiceInvite && (
                <ConfirmServiceInvite
                    confirmServiceInvite={confirmServiceInvite}
                    setConfirmServiceInvite={setConfirmServiceInvite}
                    selectedContact={activeContact}
                    setInviteToService={setInviteToService}
                />
            )}
            {activeContact && inviteToService && (
                <InviteToService
                    contact={activeContact}
                    setInviteToService={setInviteToService}
                    setConfirmServiceInvite={setConfirmServiceInvite}
                />
            )}
            {activeContact && enrollInService && (
                <EnrollInService
                    contact={activeContact}
                />
            )}
        </div>
    );
};

export default ContactServicesFlow;
