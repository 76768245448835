import React from "react";
import { useNavigate } from "react-router-dom";
import { ServiceRequests } from "../../../api/app.service";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import ServiceCardBannerOverlay from "../../services/ServiceCardBannerOverlay";
import { Button } from "../../ui/button";
import useLoading from "../../../hooks/useLoading";
import { getLightTextColor } from "../../../utils/getLightTextColor";
import { getTextColor } from "../../../utils/getTextColor";
import { SvgCheckCircle, SvgEdit } from "../../icons/";
import { Badge } from "../../ui/badge";
import moment from "moment";
interface Props {
  service?: any;
  getServices?: any;
}

const PrivateProfileServiceCard: React.FC<Props> = ({
  service,
  getServices,
}) => {
  const serviceDetails = service?.service_details;
  const navigate = useNavigate();
  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  );
  const coachColor = coachPublicProfileData?.profile_information?.profileColor;
  const textColor = getLightTextColor(coachColor);
  const { user } = React.useContext(UserCtx);
  const { startLoading, stopLoading } = useLoading();

  const { service_details, is_group_service } = service;

  const getPrice = () => {
    const amount = parseInt(serviceDetails?.billing?.amount);
    let result = amount === 0 ? "Free" : `$${amount.toFixed(2)}`;
    return result;
  };

  const getProfileColorClass = () => {
    return `text-[#${coachPublicProfileData?.profile_information?.profileColor}]`;
  };

  const _ = require("lodash");
  _.mixin(require("lodash-inflection"));

  const getUnits = () => {
    const unit = serviceDetails.billing.unit;
    if (parseInt(serviceDetails?.billing?.amount) === 0) {
      return "";
    } else if (unit === "bulk" || unit === "installment") {
      return " / for program";
    } else if (["month", "monthly"].includes(unit)) {
      return " / month";
    } else if (["year", "yearly"].includes(unit)) {
      return " / year";
    } else if (["week", "weekly"].includes(unit)) {
      return " / week";
    } else if (["consultation", "individual"].includes(serviceDetails?.type)) {
      return " / per session";
    } else {
      return "";
    }
  };

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(serviceDetails?.type)) {
      return `${serviceDetails?.session.count} session`;
    } else if (["bundle"].includes(serviceDetails?.type)) {
      return `${serviceDetails?.session?.count} ${_("session").pluralize(
        serviceDetails?.session.count
      )}`;
    } else if (["recurring"].includes(serviceDetails?.type)) {
      return (
        <div>
          {`${serviceDetails?.session?.count} 
                        ${_("session").pluralize(
                          serviceDetails?.session?.count
                        )}`}
          <span> / {`${serviceDetails?.billing?.unit}`}</span>
        </div>
      );
    } else {
      return "Not Found";
    }
  };

  const getSessionDuration = () => {
    return (
      <div>{parseInt(serviceDetails?.session?.duration) / 60} minutes</div>
    );
  };

  const goToEditService = () => {
    startLoading();
    navigate(`/coach/services`, {
      state: { service: service },
    });
  };

  const getStartDate = (): {
    startMessage: string;
  } => {
    const meetingDates = service_details?.group?.meetingDates || [];
    const upcomingDates = meetingDates
      .map((date: string) => new Date(date))
      .filter((date: Date) => date >= new Date())
      .sort((a: Date, b: Date) => a.getTime() - b.getTime());
  
    if (upcomingDates.length > 0) {
      const startDate = upcomingDates[0];
      const startTime = moment(startDate).format("h:mm A");
      return {
        startMessage: `Next Session at ${moment(startDate).format(
          "MMM DD"
        )} at ${startTime}`,
      };
    } else {
      return { startMessage: "" };
    }
  };
  
  return (
    <div className="w-full md:w-[385px] bg-white rounded-[16px] main-shadow min-h-[410px] relative shadow-custom last:mb-[80px] md:last:mb-0 flex flex-col">
      <div
        className={`w-full h-[110px] border border-white border-[12px] rounded-[20px] relative 
                ${serviceDetails?.image?.color}`}
      >
        <ServiceCardBannerOverlay service={service} />
        {serviceDetails.image.exists && (
          <img
            src={`https://clientservice-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${service.stripe_product_id}?t=${new Date().getTime()}`}
            alt="service"
            className="w-full h-full object-cover rounded-[8px]"
          />
        )}
      </div>
      <div className="w-full flex flex-col px-[16px] py-[12px] flex-1">
        <div className="w-full flex flex-col items-left justify-between">
          <h4 className="font-bold text-[18px] max-w-[260px]">
            {serviceDetails?.title}
          </h4>
          <h3 className={`font-bold text-[16px] ${getProfileColorClass()}`}>
            {getPrice()} 
            <span className="text-graySlate text-[12px] text-base font-normal">
              {getUnits()}
            </span>
            {is_group_service && getStartDate().startMessage && (
              <>
                &nbsp; &nbsp;
                <Badge variant="success">{getStartDate().startMessage}</Badge>
              </>
            )}
          </h3>
        </div>
        <div className="w-full flex items-center justify-start">
          <div className="flex gap-[16px] items-center mt-2">
            <div
              className="py-[4px] px-[8px] rounded-[8px]
                            flex items-center justify-center font-bold text-base text-black"
              style={{ background: textColor }}
            >
              {getSessionCount()}
            </div>
            <div
              className="py-[4px] px-[8px] rounded-[8px]
                            flex items-center justify-center font-bold text-base text-black"
              style={{ background: textColor }}
            >
              {getSessionDuration()}
            </div>
          </div>
        </div>
        <div className="text-sm mt-4 flex-1">
          <div className="flex items-center">
            {serviceDetails?.details?.features?.feature1 ? (
              <>
                <span>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                </span>
                <p className="ml-3 line-clamp-1 mt-1">
                  {serviceDetails.details.features.feature1}
                </p>
              </>
            ) : null}
          </div>
          <div className="flex items-center">
            {serviceDetails?.details?.features?.feature2 ? (
              <>
                <span>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                </span>
                <p className="ml-3 line-clamp-1 mt-1">
                  {serviceDetails.details.features.feature2}
                </p>
              </>
            ) : null}
          </div>
          <div className="flex items-center">
            {serviceDetails?.details?.features?.feature3 ? (
              <>
                <span>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                </span>
                <p className="ml-3 line-clamp-1 mt-1">
                  {serviceDetails.details.features.feature3}
                </p>
              </>
            ) : null}
          </div>
        </div>
        <Button
          className={`bg-${coachColor} text-${getTextColor(coachColor)} w-full`}
          style={{
            backgroundColor: coachColor,
            color: getTextColor(coachColor),
          }}
          onClick={goToEditService}
        >
          <SvgEdit className="mr-2" />
          Edit
        </Button>
      </div>
    </div>
  );
};

export default PrivateProfileServiceCard;
