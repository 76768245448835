import React, { useRef, useState } from "react"
import { Button } from "../ui/button"
import multiple from "../../assets/images/multiple.svg"
import { Switch } from "../ui/switch"
import { Label } from "../ui/label"
import moment from "moment"
import {
  SvgBundle,
  SvgCheckCircle,
  SvgConsultation,
  SvgEdit,
  SvgPiechartOneQuarter,
  SvgRecurring,
  SvgStarBox,
  SvgUserCheck,
} from "../icons"
import { Badge } from "../ui/badge"
interface Props {
  //   getOrdinalSuffix?: any;
  setShowPreview: any
  serviceValues: any
  setServiceValues: any
  saveType: string
  callback: any
  groupCallback: any
  //   getFirstInstallmentAmount?: any;
  previewImgUrl: string
  serviceType: string
  isGroupService: boolean
  //   getSubsequentInstallmentAmount?:any
}

const ServicePreview: React.FC<Props> = ({
  //   getFirstInstallmentAmount,
  setShowPreview,
  serviceValues,
  setServiceValues,
  saveType,
  callback,
  previewImgUrl,
  serviceType,
  isGroupService,
  groupCallback,
  //   getOrdinalSuffix,
  //   getSubsequentInstallmentAmount
}) => {
  const ref = useRef(null)
  const [service, setService] = useState<any>(null)
  const { service_details } = service || {}

  const getCostTrailer = () => {
    if (["consultation", "individual"].includes(serviceType)) {
      return " / per session"
    } else if (serviceType === "recurring") {
      const periodString =
        serviceValues?.recurring.recurringFrequency === "weekly"
          ? "week"
          : "month"
      return ` / per ${periodString}`
    } else {
      return " / for program"
    }
  }

  const _ = require("lodash")
  _.mixin(require("lodash-inflection"))

  const getCost = () => {
    if (["consultation", "individual"].includes(serviceType)) {
      return serviceValues.sessionCost
    } else if (serviceType === "bundle") {
      return serviceValues.package.packageCost
    } else if (serviceType === "recurring") {
      return serviceValues.recurring.periodCost
    }
  }

  const getSessionDuration = () => {
    return (
      <div>
        {parseInt(serviceValues?.sessionLength?.minutes)} minutes
        <span className="text-darkGray"> / session</span>
      </div>
    )
  }

  const getLengthOrExpiration = () => {
    let expirationString = "No expiration"
    if (["consultation", "individual", "bundle"].includes(serviceType)) {
      if (serviceValues?.expiration?.expires) {
        expirationString = `Expires after ${serviceValues?.expiration?.expireTime} ${serviceValues?.expiration?.expireType}`
      } else {
        expirationString = "No expiration"
      }
    } else if (["recurring"].includes(serviceType)) {
      const units =
        serviceValues?.expiration?.expireType === "weeks" ? "week" : "month"
      expirationString = `${serviceValues?.expiration?.expireTime} ${units} program`
    } else {
      expirationString = "Expiration not found"
    }
    return expirationString
  }

  const getType = () => {
    type ResProps = {
      image: React.ReactNode | string
      string: string
    }
    let result: ResProps = { image: "", string: "" }

    if (["consultation"].includes(serviceType)) {
      result = { image: <SvgConsultation />, string: "Consultation" }
    } else if (["individual"].includes(serviceType)) {
      result = { image: <SvgUserCheck />, string: "Individual" }
    } else if (["recurring"].includes(serviceType)) {
      result = { image: <SvgRecurring />, string: "Recurring" }
    } else if (["bundle"].includes(serviceType)) {
      result = { image: <SvgBundle />, string: "Bundle" }
    } else if (["group"].includes(serviceType)) {
      result = { image: multiple, string: "Group Program" }
    }
    return result
  }

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(serviceType)) {
      return "1 session"
    } else if (["bundle"].includes(serviceType)) {
      return `${serviceValues?.package?.packageSessions} ${_(
        "session"
      ).pluralize(serviceValues?.package?.packageSessions)}`
    } else if (["recurring"].includes(serviceType)) {
      return (
        <div>
          {`${serviceValues?.recurring?.sessionsPerPeriod}
                        ${_("session").pluralize(
                          serviceValues?.recurring?.sessionsPerPeriod
                        )}`}
          <span className="text-darkGray">{getCostTrailer()}</span>
        </div>
      )
    } else {
      return "Not Found"
    }
  }

  const getFirstInstallmentAmount = () => {
    const upFrontPercentage = serviceValues?.package?.upFrontPercentage / 100
    return (serviceValues?.package?.packageCost * upFrontPercentage).toFixed(2)
  }

  const getSubsequentInstallmentAmount = () => {
    const firstInstallmentAmount = parseInt(getFirstInstallmentAmount())
    const numberInstallments =
      serviceValues?.package?.installmentIncrements?.length
    const remainingAmount =
      parseInt(serviceValues?.package?.packageCost) - firstInstallmentAmount
    return (remainingAmount / numberInstallments).toFixed(2)
  }

  const getOrdinalSuffix = (index: number) => {
    var j = (index + 1) % 10,
      k = (index + 1) % 100
    if (j === 1 && k !== 11) {
      return index + 1 + "st"
    }
    if (j === 2 && k !== 12) {
      return index + 1 + "nd"
    }
    if (j === 3 && k !== 13) {
      return index + 1 + "rd"
    }
    return index + 1 + "th"
  }

  const getStartDates = () => {
    const meetingDates = serviceValues?.group?.meetingDates || []
    return meetingDates.map((date: any) => {
      const formattedDate = moment(date).format("MMMM Do, YYYY [at] h:mm A")
      return formattedDate
    })
  }

  const handleClick = () => {
    if (isGroupService) {
      console.log("group yes")
      groupCallback()
    } else {
      callback()
    }
  }

  return (
    <div
      className="zoee-backdrop-filter fixed left-0 top-0 z-[1008] flex
            h-screen w-screen items-center justify-center"
    >
      <div
        ref={ref}
        className="main-shadow relative flex h-screen w-screen flex-col justify-between bg-white px-[24px]
                py-[24px] md:h-auto md:max-h-[90%] md:w-[1280px] md:max-w-[90%] md:flex-row md:rounded-[16px] md:py-[40px] md:pl-[64px] md:pr-[40px]"
      >
        <div className="border-r-grayMist flex h-[70vh] flex-col overflow-y-auto overflow-y-auto md:h-auto md:w-[800px] md:border-r-[1px] md:pr-[64px]">
          <div
            className={`mb-[32px] h-[54px] w-full rounded-[14px] pb-20 md:h-[140px] ${
              previewImgUrl ? "bg-white" : serviceValues.color
            }`}
          >
            {previewImgUrl && (
              <img
                className="aspect-[3/1] w-full rounded-[14px] object-cover"
                src={previewImgUrl}
                alt="service preview"
              />
            )}
          </div>
          <div className="mb-[17px] mt-12 pt-5 flex w-full flex-col justify-between md:flex-row md:items-center">
            <h2 className="mt-6 text-[18px] font-bold md:text-[22px]">
              {serviceValues.title}
            </h2>
            <div className="text-primaryBlue mt-6 text-[28px] font-semibold">
              {serviceType === "consultation" &&
              serviceValues?.consultation?.isFree ? (
                <>Free</>
              ) : (
                <>
                  ${getCost()}
                  <span className="text-graySlate text-base">
                    {getCostTrailer()}
                  </span>
                </>
              )}
            </div>
          </div>

          <div>
            <div className="bg-grayMist mb-[9px] h-[1px] w-full"> </div>
            <div className="mb-[5] flex flex-row items-center justify-between gap-[3px] text-base font-bold p-5">
              {isGroupService ? (
                <>
                  <img src={multiple} alt="multiple user icon" />
                  <span className="mr-auto">Group Program</span>
                </>
              ) : (
                <>
                  <span className="mr-auto flex-row">
                    {getType().image && (
                      <div className="flex items-center gap-[4px]">
                        {getType().image}
                        {`${getType().string}`}
                      </div>
                    )}
                    {!getType().image && `${getType().string}`}
                  </span>
                </>
              )}

              <div className="mb-[5] flex flex-row items-center gap-[5px] text-base font-bold">
                <div className="flex flex-col md:flex-row items-center gap-[11px]">
                  <div
                    className="bg-grayFlash ml-auto flex
      items-center justify-center rounded-[8px] px-[8px] py-[4px] text-base font-bold  text-black"
                  >
                    {getSessionCount()}
                  </div>
                  <div
                    className="bg-grayFlash ml-auto flex
      items-center justify-center rounded-[8px] px-[8px] py-[4px] text-base font-bold text-black"
                  >
                    {getSessionDuration()}
                  </div>
                  <div
                    className="bg-grayFlash ml-auto flex
      items-center justify-center rounded-[8px] px-[8px] py-[4px] text-base font-bold text-black"
                  >
                    {getLengthOrExpiration()}
                  </div>
                </div>
              </div>
            </div>

            {/* Program Details */}
            <div className="bg-grayFlash mt-3 flex h-[32px] w-[142px] items-center justify-center rounded-[8px]">
              Program Details
            </div>

            {/* Features If Value Exists */}
            <div className="flex items-center gap-[12px] mt-6">
              {serviceValues?.feature1 && (
                <>
                  <SvgCheckCircle />
                  {serviceValues.feature1}
                </>
              )}
            </div>
            <div className="flex items-center gap-[12px]">
              {serviceValues?.feature2 && (
                <>
                  <SvgCheckCircle />
                  {serviceValues.feature2}
                </>
              )}
            </div>
            <div className="flex items-center gap-[12px]">
              {serviceValues?.feature3 && (
                <>
                  <SvgCheckCircle />
                  {serviceValues.feature3}
                </>
              )}
            </div>
          </div>
          <div className="mb-[32px] mr-auto flex flex-col gap-[12px] text-base w-full">
            {isGroupService && (
              <div className="mr-auto flex flex-col mb-[12px] text-base w-full">
                <div className="flex items-center gap-[12px]">
                  <SvgCheckCircle />
                  Group Program
                </div>
                {/* <div className="flex items-center gap-[12px]">
                  <SvgCheckCircle />
                  Enrollment open until{" "}
                  {moment(serviceValues?.group?.enrollByDate).format(
                    "MMMM Do, YYYY"
                  )}
                </div> */}
                <div className="flex text-start items-center gap-[12px] mb-[12px]">
                  <SvgCheckCircle />
                  Maximum Clients: {serviceValues?.group?.maximumClients}
                </div>
                <div className="bg-grayMist mb-[16px] h-[1px] w-full">
                  &nbsp;
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2  w-full">
                  <div
                    className="bg-grayFlash mb-[10px] flex h-[32px]
              w-[142px] items-center justify-center rounded-[8px] font-base"
                  >
                    Scheduled Dates
                  </div>
                  <br />
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2  w-full">
                    {getStartDates().map((date: string, index: number) => (
                      <div key={index} className="flex justify-start">
                        <Badge variant="secondary" className="w-auto">
                          {date}
                        </Badge>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="bg-grayMist mb-[16px] h-[1px] w-full">&nbsp;</div>
          <p className="mb-4 text-base">{serviceValues.description}</p>
          {serviceType === "consultation" && (
            <div className="border-1 border-gray mt-2 flex w-full flex-col rounded-[16px] border p-[20px]">
              <h5 className="text-graySlate mb-[32px] text-base">
                Payment Details
              </h5>
              <div className="mb-[16px] flex w-full items-center justify-between">
                <div className="flex min-w-fit items-center gap-[16px]">
                  <div
                    className="bg-primaryBlue flex h-[24px] w-[24px]
                                items-center justify-center rounded-full font-bold text-white"
                  >
                    $
                  </div>
                  <p className="text-[16px]">Payment at checkout</p>
                </div>
                <div className="border-grayCloud mx-[24px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                <span className="text-base">
                  $
                  {serviceType === "consultation" &&
                  serviceValues?.consultation?.isFree
                    ? 0.0
                    : serviceValues?.sessionCost?.toFixed(2)}
                </span>
              </div>
              <div
                className="text-graySlate bg-grayMist flex w-full
                        items-center justify-center rounded-[12px] p-[26px] text-base"
              >
                <p className="text-center">
                  Payment will be charged{" "}
                  <strong className="text-black">immediately</strong> at
                  checkout.
                </p>
              </div>
            </div>
          )}
          {serviceType === "individual" && (
            <div className="border-1 border-gray mt-2 flex w-full flex-col rounded-[16px] border p-[20px]">
              <h5 className="text-graySlate mb-[32px] text-base">
                Payment Details
              </h5>
              <div className="mb-[16px] flex w-full items-center justify-between">
                <div className="flex min-w-fit items-center gap-[16px]">
                  <div
                    className="bg-primaryBlue flex h-[24px] w-[24px]
                                items-center justify-center rounded-full font-bold text-white"
                  >
                    $
                  </div>
                  <p className="text-[16px]">Payment at checkout</p>
                </div>
                <div className="border-grayCloud mx-[24px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                <span className="text-base">
                  ${serviceValues?.sessionCost?.toFixed(2)}
                </span>
              </div>
              <div
                className="text-graySlate bg-grayMist flex w-full
                        items-center justify-center rounded-[12px] p-[26px] text-base"
              >
                <p className="text-center">
                  Payment will be charged{" "}
                  <strong className="text-black">immediately</strong> at
                  checkout.
                </p>
              </div>
            </div>
          )}
          {serviceType === "bundle" &&
            serviceValues.package.packageBillingType === "installment" && (
              <div className="mb-[60px]">
                <div className="border-1 border-gray flex w-full flex-col rounded-[16px] border p-[20px]">
                  <h5 className="text-graySlate mb-[32px] text-base">
                    Payment Schedule
                  </h5>
                  <div className="mb-[16px] flex w-full items-center justify-between">
                    <div className="flex min-w-fit items-center gap-[16px]">
                      <SvgPiechartOneQuarter />
                      <p className="text-[16px]">Payment at booking</p>
                    </div>
                    <div className="border-grayCloud mx-[24px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                    <span className="text-base">
                      ${getFirstInstallmentAmount()}
                    </span>
                  </div>
                  {serviceValues?.package?.installmentIncrements?.length > 0 &&
                    serviceValues?.package?.installmentIncrements.map(
                      (installment: any, index: number) => (
                        <div
                          key={index}
                          className="mb-[16px] flex w-full items-center justify-between"
                        >
                          <div className="flex min-w-fit items-center gap-[16px]">
                            <SvgPiechartOneQuarter />
                            <p className="text-[16px]">{`${getOrdinalSuffix(
                              index
                            )} installment ${installment.time} ${_(
                              installment.type
                            ).pluralize(installment.time)} later`}</p>
                          </div>
                          <div className="border-grayCloud mx-[24px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                          <span className="text-base">
                            ${getSubsequentInstallmentAmount()}
                          </span>
                        </div>
                      )
                    )}
                  <div
                    className="text-graySlate bg-grayMist flex w-full
                              items-center justify-center rounded-[12px] p-[26px] text-base"
                  >
                    <p className="text-center">
                      Payments will be charged{" "}
                      <strong className="text-black">
                        {serviceValues?.recurring?.recurringFrequency}
                      </strong>{" "}
                      on the anniversary of the first payment.
                    </p>
                  </div>
                </div>
              </div>
            )}

          {serviceType === "bundle" &&
            serviceValues.package.packageBillingType === "bulk" && (
              <>
                <div className="bg-grayMist my-[32px] h-[1px] w-full"></div>
                <div className="border-1 border-gray flex w-full flex-col rounded-[16px] border p-[20px]">
                  <h5 className="text-graySlate mb-[32px] text-base">
                    Payment Details
                  </h5>
                  <div className="mb-[16px] flex w-full items-center justify-between">
                    <div className="flex min-w-fit items-center gap-[16px]">
                      <div
                        className="bg-primaryBlue flex h-[24px] w-[24px]
                                items-center justify-center rounded-full font-bold text-white"
                      >
                        $
                      </div>
                      <p className="text-[16px]">Payment at checkout</p>
                    </div>
                    <div className="border-grayCloud mx-[24px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                    <span className="text-base">
                      {`$${parseInt(
                        serviceValues?.package?.packageCost
                      ).toFixed(2)}`}
                    </span>
                  </div>
                  <div
                    className="text-graySlate bg-grayMist flex w-full
                        items-center justify-center rounded-[12px] p-[26px] text-base"
                  >
                    <p className="text-center">
                      Payment will be charged{" "}
                      <strong className="text-black">immediately</strong> at
                      checkout.
                    </p>
                  </div>
                </div>
              </>
            )}

          {serviceType === "recurring" && (
            <div className="border-1 border-gray flex w-full flex-col rounded-[16px] border p-[20px]">
              <h5 className="text-graySlate mb-[32px] text-base">
                Payment Details
              </h5>
              <div className="mb-[16px] flex w-full items-center justify-between">
                <div className="flex min-w-fit items-center gap-[16px]">
                  <SvgPiechartOneQuarter />
                  <p className="text-[16px]">Payment at booking</p>
                </div>
                <div className="border-grayCloud mx-[24px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                <span className="text-base">
                  ${parseInt(serviceValues?.recurring?.periodCost)?.toFixed(2)}
                </span>
              </div>
              <div className="mb-[16px] flex w-full items-center justify-between">
                <div className="flex min-w-fit items-center gap-[16px]">
                  <SvgPiechartOneQuarter />
                  <p className="text-[16px]">
                    {serviceValues?.recurring.recurringFrequency === "weekly"
                      ? "Weekly"
                      : "Monthly"}{" "}
                    recurring charge
                  </p>
                </div>
                <div className="border-grayCloud mx-[24px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                <span className="text-base">
                  ${parseInt(serviceValues?.recurring?.periodCost)?.toFixed(2)}
                </span>
              </div>
              <div
                className="text-graySlate bg-grayMist flex w-full
                     items-center justify-center rounded-[12px] p-[26px] text-base"
              >
                <p className="text-center">
                  Payments will be charged{" "}
                  <strong className="text-black">
                    {serviceValues?.recurring?.recurringFrequency}
                  </strong>{" "}
                  on the anniversary of the first payment.
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="my-auto mt-[32px] flex flex-col items-center md:mt-0 md:w-[336px]">
          <SvgStarBox className="mb-[32px] hidden md:block" />
          <div className="mb-[48px] hidden w-[167px] text-center text-[22px] font-bold md:block">
            Everything look good?
          </div>
          <div className="mb:pb-0 absolute bottom-0 left-0 w-full bg-white px-[20px] pb-[64px] pt-[12px] shadow-lg md:static md:w-[80%] md:px-0 md:pb-0 md:shadow-none">
            <Button
              className="text-md mb-[16px] w-full"
              onClick={handleClick}
              size={"lg"}
            >
              {`${
                saveType === "create"
                  ? serviceValues.enabled
                    ? "Create"
                    : "Save"
                  : "Save"
              } ${serviceValues.enabled ? "Service" : "Draft"}`}
            </Button>
            <button
              className="btn-primary btn-secondary w-full gap-[10px]"
              onClick={() => setShowPreview(false)}
            >
              Back to Editing
              <SvgEdit />
            </button>
            <div className="flex gap-[10px] mt-[32px]">
              <h4 className="text-base mb-[8px] mt[10px]">
                Enable clients to use your promo codes?
              </h4>
              <div className="flex items-center gap-2">
                <Switch
                  onCheckedChange={() =>
                    setServiceValues({
                      ...serviceValues,
                      allowPromo: !serviceValues.allowPromo,
                    })
                  }
                  checked={serviceValues.allowPromo}
                  id="promo"
                />
                <Label htmlFor="free">Yes</Label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicePreview
