import React from "react";
import DragDropArea from "../../../DragDropArea";
import { CoachPrivateProfileContext } from "../../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import { ProfileRequests, UtilityRequests } from "../../../../api/app.service";
import PortraitVideoPreview from "../PortraitVideoPreview";
import axios from "axios";
import CloseModal from "../../../CloseModal";
import Loader from "../../../ui/loader"
import { Button } from "../../../ui/button";
import { SvgWarning } from "../../../icons";

interface Props {}

const EditPortraitVideo: React.FC<Props> = ({}) => {
    const { 
        coachPublicProfileData,
        getCoachPrivateProfile,
        editPortraitVideo,
        setEditPortraitVideo
    } = React.useContext(CoachPrivateProfileContext);
    const {
        setPopupNotification,
        renderSuccess,
        renderError,
        hideAlert
    } = React.useContext(CommonFunctionCtx);
    // VIDEO STATE
    const [videoFile, setVideoFile] = React.useState<File>();
    const [awaitNewVideo, setAwaitNewVideo] = React.useState<boolean>(false);
    const [videoProperties, setVideoProperties] = React.useState<any>(null);
    const [formErrors, setFormErrors] = React.useState<any>({});
    const [videoTitle, setVideoTitle] = React.useState<string>(editPortraitVideo?.video?.title || "");
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const [addingVideo, setAddingVideo] = React.useState<boolean>(false)


    const validateForm = () => {
        const errors: any = {};
        (!videoTitle) && (errors.videoTitle = "A title is required.")
        return errors;
    };

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setVideoTitle(e.target.value);
    }

    const validateFile = (videoFile:File) => {
        if((videoFile.type === "image/png" || videoFile.type === "image/jpg" || videoFile.type === "image/jpeg") 
        && videoFile.size <= 20000000 && videoFile.size > 0){
            return true;
        } else if (
            (
                videoFile.type === "video/mp4"
                || videoFile.type === "video/mov"
                || videoFile.type === "video/quicktime"
                || videoFile.type.toLowerCase() === "video/hevc"
            ) && videoFile.size <= 200000000 && videoFile.size > 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    const deletePortraitVideo = () => {
        if (editPortraitVideo?.video?.id){
            setAddingVideo(true)
            ProfileRequests.deleteProfileVideo({
                coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
                video_id: editPortraitVideo?.video?.id,
                video_type: "about",
                video_extension: getExtension(editPortraitVideo?.video?.video_extension)
            })
            .then(() => {
                getCoachPrivateProfile().then(() => {
                    renderSuccess("Deleted profile video");
                    setVideoFile(undefined);
                    setAwaitNewVideo(false);
                    setEditPortraitVideo(false);
                    setAddingVideo(false);
                })
            })
            .catch((ex:any) => {
                console.log(ex);
                setVideoFile(undefined);
                setVideoProperties(null);
                setAddingVideo(false);
                renderError(ex.response.data.message);
            })
        }
    }

    const getExtension = (fileExtension:any) => {
        if(fileExtension === "quicktime"){
            return "mov";
        } else {
            return fileExtension;
        }
    }

    const postVideoToS3 = async (presignedUrl:any) => {
        let form = new FormData();
        Object.keys(presignedUrl?.fields).forEach(key => form.append(key, presignedUrl?.fields[key]));
        if(videoFile){
            form.append('file', videoFile);
        }
        await axios.post(presignedUrl?.url, form)
            .then(() => {
                getCoachPrivateProfile().then(() => {
                    renderSuccess("Uploaded profile video");
                    setEditPortraitVideo(false);
                    setAddingVideo(false);
                    setTimeout(() => hideAlert(), 5000);
                });
            }).catch((err) => {
                renderError("Failed to upload video.")
                setTimeout(() => hideAlert(), 5000);
            })
    }

    const getPresignedUrl = async (videoId:number) => {
        UtilityRequests.getPresignedS3Url({
            object_name: `${coachPublicProfileData?.coach_public_profile_id}_about_${videoId}.${getExtension(videoProperties?.base64extension)}`
        }).then((data) => {
                postVideoToS3(data.presigned_url);
            })
            .catch((ex:any) => {
                console.log(ex);
                renderError(ex.response.data.message);
                setTimeout(() => hideAlert(), 5000);
            })
    }

    const saveVideoDetails = async () => {
        setAddingVideo(true);
        const requestObject = {
            video_id: editPortraitVideo?.video?.id || null,
            coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
            title: videoTitle,
            video_file_extension: getExtension(videoProperties?.base64extension) || null,
            video_type: "about"
        }
        await ProfileRequests.editProfileVideo(requestObject)
            .then((data) => {
                if(videoProperties?.base64extension){
                    getPresignedUrl(data.video_id);
                    renderSuccess("Please wait... your video is loading", 60000)
                } else {
                    getCoachPrivateProfile().then(() => {
                        renderSuccess("Updated profile video details");
                        setAddingVideo(false);
                        setEditPortraitVideo(false);
                        setTimeout(() => hideAlert(), 5000);
                    });
                }
            })
            .catch((ex:any) => {
                console.log(ex);
                setVideoFile(undefined);
                setVideoProperties(null);
                setAddingVideo(false);
                renderError(ex.response.data.message);
            })
    }

    const handleSubmit = () => {
        setFormErrors(validateForm())
        setIsSubmit(true);
    }

    React.useEffect(() => {
        if (isSubmit && Object.keys(formErrors).length === 0) {
            setIsSubmit(false);
            saveVideoDetails();
        } else {
            setIsSubmit(false);
        }
    }, [formErrors])

    const buttonsSection = () => {
        return <>
          {editPortraitVideo?.video ? (
            <button
              onClick={deletePortraitVideo}
              className="btn-primary bg-vividRed"
            >
              Delete Video
            </button>
          ) : (
            <></>
          )}
          {(editPortraitVideo?.video && !awaitNewVideo) ? (
            <button
              onClick={() => {
                setVideoFile(undefined);
                setAwaitNewVideo(true);
                setVideoProperties(null);
              }}
              className="btn-primary btn-secondary"
            >
              Change Video
            </button>
        ) : (
            <></>
        )}
        <Button
            onClick={handleSubmit}
            className="text-md"
        >
            Save Video
        </Button>
        </>
    }

    const setPortraitVideoDetails = () => {
        if(videoFile){
            if(validateFile(videoFile)){
                let base64 = "";
                const type = videoFile.type.split("/")[1];
                const reader = new FileReader();
                reader.readAsDataURL(videoFile);
                reader.onload = function () {
                    const string = (reader.result as string).split('\n').map((data) => {
                        return data.split(',');
                    })
                    base64 = string[0][1];
                    setVideoProperties({
                        "base64": base64,
                        "base64extension": type
                    });
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
                // setAwaitNewVideo(false);
            } else {
                setPopupNotification({
                    show: true,
                    title: "Bummer! Your video file was not accepted.",
                    message: "Give it another try with a file under 200 megabytes, and in mp4, mov, quicktime or HEVC format.",
                    callback: null
                });
                setVideoFile(undefined);
                setAwaitNewVideo(true);
                setVideoProperties(null);
            }
        }
    }

    React.useEffect(() => {
        setPortraitVideoDetails();
    }, [videoFile]);

    return (
        <div 
            className="fixed top-0 left-0 w-screen h-screen flex items-center
            justify-center zoee-backdrop-filter md:z-[801] z-[1009]"
        >
            <div 
                className="w-[650px] max-w-[90%] max-h-[90%] overflow-y-auto main-shadow relative
                rounded-[16px] pt-[90px] pb-[40px] px-[40px] bg-white flex flex-col items-center"
            >
                <CloseModal callback={() => {
                    setEditPortraitVideo(false);
                    setVideoFile(undefined);
                    setVideoProperties(null);
          
                }} styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash" />
                <h2 className="absolute top-[26px] left-[32px] font-bold text-[18px]">
                    Add Video
                </h2>
                <div className="mb-[48px] flex items-center gap-[34px] justify-center">
                    {/* VIDEO DROP ZONE / PREVIEW */}
                    <div className="flex flex-col items-center">
                        <h5 className="font-bold mb-[4px]">Add your video here</h5>
                        <p
                            className="text-graySlate mb-[12px] text-base font-bold"
                        >
                            Portrait videos work best. Max file size: 200mb. File types: MP4, MOV, HEVC.
                        </p>
                            <p className="text-blurple text-center">Don't have a video? Get a free stock video at</p> 
                            <a href="https://pixabay.com/videos/" className="text-blurple text-center mb-5">https://pixabay.com/videos/</a>
                        <div className="w-[254px] h-[413px] mx-auto">
                            {editPortraitVideo?.video ? (
                                <>
                                    {awaitNewVideo ? (
                                        <DragDropArea
                                            styling={` w-full h-full rounded-[14px]`}
                                            mediaFile={videoFile}
                                            setMediaFile={setVideoFile}
                                            mediaType="video"
                                        />
                                    ) : (
                                        <>
                                            <PortraitVideoPreview
                                                video={editPortraitVideo?.video}
                                                showHoverEffect={false}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <DragDropArea
                                    styling={`w-full h-full rounded-[14px]`}
                                    mediaFile={videoFile}
                                    setMediaFile={setVideoFile}
                                    mediaType="video"
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/* TITLE */}
                <div className="flex flex-col gap-[10px] w-full mb-[24px]">
                    <p className="text-graySlate text-[16px]">Title</p>
                    <div className={`default-input w-full group ${formErrors.videoTitle && "input-error"}`}>
                        <input 
                            type="text"
                            name="videoTitle"
                            placeholder='Title'
                            className='w-full'
                            onChange={handleChange}
                            value={videoTitle}
                        />
                        <div className="overlay">Title</div>
                    </div>
                    {formErrors.videoTitle && (
                        <div className="field-error">
                            <SvgWarning />
                            {formErrors.videoTitle}
                        </div>
                    )}
                </div>
                <div className="flex items-center gap-[12px] mx-auto">
                  { addingVideo ? <Loader/> : buttonsSection() }
                </div>
            </div>
        </div>
    )
}

export default EditPortraitVideo;
