import React from "react";
import DateRangePicker from "../date-time/DateRangePicker";
import LineChart from "./LineChart";
// import SyncBankInstructions from "./SyncBankInstructions";
import type { ChartData } from "chart.js";
import { ClientPaymentsItem } from "../../types/clientPaymentTypes";
import moment from "moment";
import PaymentsSetupInstructions from "./PaymentsSetupInstructions";
import Loader from "../ui/loader";
import { SvgComingSoon } from "../icons";

type Props = {
  connectAccountData: any;
  clientPaymentsData: Array<ClientPaymentsItem> | null;
  setActiveDates: React.Dispatch<React.SetStateAction<Date[] | null>>;
};

const PaymentsDashboard: React.FC<Props> = ({
  clientPaymentsData,
  connectAccountData,
  setActiveDates,
}) => {
  const [chartData, setChartData] = React.useState<ChartData<"line"> | null>(
    null
  );
  const chartOptions = {
    title: {
      display: true,
      text: "Payments",
    },
    fill: false,
  };

  // Generate chart labels and net payments data when clientPaymentsData is available
  React.useEffect(() => {
    const monthYearAndStripeNetArrOfObj = clientPaymentsData?.map((e) => {
      return {
        date: e.month_year,
        stripeNet: e.stripe_amount_net,
      };
    });

    const reducer = (map: any, obj: any) => {
      if (map[obj.date] == null) {
        map[obj.date] = obj.stripeNet;
      } else {
        map[obj.date] += obj.stripeNet;
      }
      return map;
    };

    const reducedMonthYearAndStripeNetArrOfObj: {
      date: string;
      stripeNet: number;
    }[] = monthYearAndStripeNetArrOfObj?.map((obj) => obj).reduce(reducer, {});

    if (reducedMonthYearAndStripeNetArrOfObj) {
      const labels: Array<string> = Object.keys(
        reducedMonthYearAndStripeNetArrOfObj
      ).map((e) => moment(e).format("MMM 'YY"));
        
      const netData: Array<number> = Object.values(
        reducedMonthYearAndStripeNetArrOfObj
      ).map((e: any) => e / 100);

      // console.log(labels, netData);

      setChartData({
        labels,
        datasets: [
          {
            label: "Total Net Paid",
            data: netData,
          },
        ],
      });
    }
  }, [clientPaymentsData]);

  const netTotals = clientPaymentsData?.map((e) => e.stripe_amount_net);
  const netTotalsSum = netTotals
    ? netTotals.reduce((previous, current) => previous + current, 0) / 100
    : 0;
  const formattedPaidTotal = netTotalsSum.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });

  const grossTotals = clientPaymentsData?.map((e) => e.stripe_amount_gross);
  const grossTotalsSum = grossTotals
    ? grossTotals.reduce((previous, current) => previous + current, 0) / 100
    : 0;
  const formattedGrossTotal = grossTotalsSum.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });

  const feeTotals = clientPaymentsData?.map((e) => e.total_fee_amount);
  const feeTotalsSum = feeTotals
    ? feeTotals.reduce((previous, current) => previous + current, 0) / 100
    : 0;
  const formattedFeeTotal = feeTotalsSum.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });

  return (
    <div className="md:mb-0 mb-[64px]">
      <div className="mb-[24px]">
        <DateRangePicker setActiveDates={setActiveDates} />
      </div>
      <div className="flex flex-col lg:flex-row gap-[12px] md:gap-[24px] mb-[24px] md:mb-[32px]">
        <div className="bg-white flex flex-col justify-between w-full h-[138px] md:h-[194px] px-[24px] pb-[32px] pt-[24px] md:p-[40px] box-shadow-1 rounded-[16px]">
          <h5 className="text-graySlate text-[16px]">Paid</h5>
          {!clientPaymentsData ? (
            <span className="flex flex-col items-center gap-[12px] mt-[24px]">
              <Loader />
            </span>
          ) : (
            <p className="text-[48px] font-bold">
              <span className="text-gray ">$</span>
              {formattedPaidTotal}
            </p>
          )}
        </div>
        {/* UPCOMING PAYMENTS WILL BE 'COMING SOON' FOR 2.0 LAUNCH */}
        <div className="bg-white flex flex-col justify-between w-full h-[138px] md:h-[194px] px-[24px] pb-[32px] pt-[24px] md:p-[40px] box-shadow-1 rounded-[16px]">
          <h5 className="text-graySlate text-[16px]">Upcoming Payments</h5>
          {/* <p className="text-[48px] font-bold">
                        <span className="text-gray ">$</span>
                        {formattedUpcomingPaymentsTotal}
                    </p> */}
          <div className="flex items-center gap-[12px]">
            <SvgComingSoon className="h-[52px] w-[52px]" />
            <p className="font-bold text-[18px]">Coming Soon!</p>
          </div>
        </div>
      </div>

      {chartData?.datasets[0].data &&
      chartData?.datasets[0].data.length > 0 &&
      connectAccountData?.setup_status === "complete" ? (
        <div className="bg-white w-full p-[40px] mb-[32px] box-shadow-1 rounded-[16px]">
          <h5 className="text-graySlate text-[16px] mb-[32px]">Paid</h5>
          <div className="mb-[32px] rounded-[12px] flex items-center justify-center">
            <LineChart options={chartOptions} chartData={chartData} />
          </div>
          <div className="font-bold flex flex-col md:flex-row w-full justify-between items-center gap-[14px] md:gap-[8px] px-[32px] md:px-[64px] py-[24px] md:py-[40px] border border-grayMist rounded-[12px]">
            {/* REMOVE CLIENTS NUM FOR 2.0 LAUNCH */}
            {/* <div className="flex flex-col items-center">
                            <h5 className="mb-[8px] text-base text-graySlate">Clients</h5>
                            <p className="font-semibold text-[28px]">50</p>
                        </div> */}
            <div className="flex flex-col items-center">
              <h5 className="mb-[2px] md:mb-[8px] text-base text-graySlate">
                Total Gross
              </h5>
              <p className="font-bold md:font-semibold text-[22px] md:text-[28px]">
                ${formattedGrossTotal}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h5 className="mb-[2px] md:mb-[8px] text-base text-graySlate">
                Total Fees
              </h5>
              <p className="font-bold md:font-semibold text-[22px] md:text-[28px]">
                ${formattedFeeTotal}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <h5 className="mb-[2px] md:mb-[8px] text-base text-graySlate">
                Total Net
              </h5>
              <p className="font-bold md:font-semibold text-[22px] md:text-[28px]">
                ${formattedPaidTotal}
              </p>
            </div>
            {/* REMOVE ZOEE CREDIT FOR 2.0 LAUNCH */}
            {/* <div className="flex flex-col items-center">
                            <h5 className="text-base text-graySlate">Zoee Credit</h5>
                            <p className="font-semibold text-[28px]">$250</p>
                        </div> */}
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* {connectAccountData?.setup_complete ? <></> : <SyncBankInstructions />} */}
      {connectAccountData?.setup_status === "complete" ? (
        <></>
      ) : (
        <PaymentsSetupInstructions connectAccountData={connectAccountData} />
      )}
    </div>
  );
};

export default PaymentsDashboard;
