/* eslint-disable jsx-a11y/no-autofocus */
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useContext,
  useState,
} from "react"
import { z } from "zod"

import { UserExists } from "../../../api/auth.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import PasswordRequirements from "../../account-settings/PasswordRequirements"
import { Button } from "../../ui/button"
import { Dialog, DialogContent, DialogFooter } from "../../ui/dialog"
import { Input } from "../../ui/input"
import Loader from "../../ui/loader"
import { useAuthModal } from "../use-auth-modal"
import { RegisterFormContext } from "./context"
import { validateFirstAndLastName } from "./name-form"
import Recaptcha from "./Recaptcha"

interface Props {
  showConfirm: boolean
  setShowConfirm: Dispatch<SetStateAction<boolean>>
  coachName: string
  connectAndRedirect: (user: any) => void
}

const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        email,
      },
    })
    return { found: data.user_found }
  } catch (error) {
    console.error(error)
  }
}

export const ProfileMessageForm: FC<Props> = ({
  showConfirm,
  setShowConfirm,
  coachName,
  connectAndRedirect,
}) => {
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  )
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(false)
  const { renderError } = useContext(CommonFunctionCtx)
  const { showLogin } = useAuthModal()
  const [showPaswReqs, setShowPassReq] = useState<boolean>(false)
  const [isEntreeValid, setIsEntryValid] = useState<boolean>(false)
  const {
    email,
    firstName,
    lastName,
    password,
    setEmail,
    setFirstName,
    setLastName,
    setPassword,
    createAccount,
  } = useContext(RegisterFormContext)
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)

  const lengthError = !firstName.length || !lastName.length || !email.length
  const inputError =
    firstNameError !== undefined ||
    lastNameError !== undefined ||
    emailError !== undefined ||
    !isEntreeValid
  const isDisabled = lengthError || inputError || !isNotRobot

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const inputValue = e.target.value
    const inputSuccess = validateFirstAndLastName(inputValue).success
    const errorMsg = "Invalid Input"

    if (inputName === "email") {
      setEmailError(undefined)
      setEmail(inputValue)
    }

    if (inputName === "first") {
      setFirstNameError(undefined)
      setFirstName(inputValue)
      if (!inputSuccess) {
        setFirstNameError(errorMsg)
      }
    }

    if (inputName === "last") {
      setLastNameError(undefined)
      setLastName(inputValue)
      if (!inputSuccess) {
        setLastNameError(errorMsg)
      }
    }

    if (inputName === "password") {
      setPassword(inputValue)
    }
  }

  const handleAuthentication = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    const emailSchema = z.string().email().safeParse(email)
    if (!emailSchema.success) {
      setEmailError(emailSchema.error.issues[0].message)
      setLoading(false)
      return
    }
    const emailExists = await doesEmailExist(email)
    if (emailExists?.found) {
      setEmailError(
        "Email is already in use. Please login using your credentials"
      )
      setLoading(false)
      return
    }
    createAccount()
      .then(() => {
        let userJSON = localStorage.getItem("user")
        if (userJSON !== null) {
          let user = JSON.parse(userJSON)
          connectAndRedirect(user)
          setLoading(false)
          setShowConfirm(false)
        }
      })
      .catch((err) => renderError("Something went wrong. Please, try again"))
  }

  return (
    <Dialog open={showConfirm} onOpenChange={setShowConfirm} modal={false}>
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <h3 className="font-bold text-lg text-center">{`Message ${coachName}`}</h3>
        <p className="text-sm text-center">
          Please provide your information below to establish a secure, private
          messaging account.
        </p>
        <form
          onSubmit={(e) => handleAuthentication(e)}
          className="flex flex-col gap-3"
        >
          <Input
            label="Email"
            placeholder="Enter Your Email"
            value={email}
            onChange={(e) => handleChange(e, "email")}
            error={emailError}
            disabled={loading}
            onFocus={() => setShowPassReq(false)}
            autoFocus
          />
          <Input
            label="First Name"
            placeholder="Enter Your First Name"
            value={firstName}
            onChange={(e) => handleChange(e, "first")}
            error={firstNameError}
            onFocus={() => setShowPassReq(false)}
            disabled={loading}
          />
          <Input
            label="Last Name"
            placeholder="Enter Your Last Name"
            value={lastName}
            onChange={(e) => handleChange(e, "last")}
            error={lastNameError}
            onFocus={() => setShowPassReq(false)}
            disabled={loading}
          />
          {showPaswReqs && (
            <PasswordRequirements
              validEntree={password}
              setIsEntryValid={setIsEntryValid}
            />
          )}
          <Input
            label="Password"
            placeholder="Enter Your Password"
            value={password}
            type="password"
            onChange={(e) => handleChange(e, "password")}
            disabled={loading}
            onFocus={() => setShowPassReq(true)}
            onBlur={() => setShowPassReq(false)}
          />
          <Recaptcha setIsNotRobot={setIsNotRobot} />
          <DialogFooter className="grid grid-cols-1 gap-3">
            <Button
              className="m-auto w-[174px]"
              variant="default"
              size="lg"
              type="submit"
              disabled={isDisabled}
              onFocus={() => setShowPassReq(false)}
            >
              {loading ? <Loader /> : "Start Messaging"}
            </Button>
          </DialogFooter>
          <p className="text-muted-foreground text-sm text-center">
            Already have an account?{" "}
            <Button
              className="w-fit mx-auto"
              variant="link"
              size="default"
              onClick={() => {
                setShowConfirm(false)
                showLogin()
              }}
            >
              Login
            </Button>
          </p>
        </form>
      </DialogContent>
    </Dialog>
  )
}
export default ProfileMessageForm
