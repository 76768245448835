import * as React from "react"
import { useNavigate } from "react-router"
import * as z from "zod"
import { EmailLogin } from "../../../api/auth.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { getUserStats } from "../../../utils/getUserStats"
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import { LoginFormContext } from "./context"
import Loader from "../../ui/loader"
import useLoading from "../../../hooks/useLoading"
import Recaptcha from "../register-form/Recaptcha"

export const PasswordForm: React.FC = () => {
  const {
    password,
    setPassword,
    setView,
    email,
    getIdentityStatus,
    getSetupInfo,
    updateTimezone,
    onSuccess,
  } = React.useContext(LoginFormContext)

  const [error, setError] = React.useState<string | undefined>(undefined)
  const [loading, setLoading] = React.useState(false)
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)

  const navigate = useNavigate()
  const { renderError, getUser } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const passwordSchema = z
      .string()
      .min(8, "Invalid password")
      .regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/, {
        message: "Invalid password",
      })
      .safeParse(password)

    if (!passwordSchema.success) {
      setError(passwordSchema.error.issues[0].message)
      setLoading(false)
      return
    }

    await login()
    setLoading(false)
  }

  const login = async () => {
    try {
      const data = await EmailLogin.loginEmail({
        email,
        password,
        stats: getUserStats(),
      })

      if (data) {
        if (isLocalStorageAvailable()) {
          localStorage.setItem("user", JSON.stringify(data))
        } else {
          renderError("Local storage is not available")
        }

        await getIdentityStatus(data.user_id)
        await getSetupInfo(data.user_id)
        await updateTimezone(data.user_id, data.access_token)
        const user = await getUser()

        onSuccess(user)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.data.message === "Incorrect password.") {
        setError("Incorrect password")
      } else {
        console.error(error)
        renderError(error.response.data.message)
      }
    }
  }

  const forgotPassword = async () => {
    startLoading("Sending instructions")

    try {
      const resetPassword = await EmailLogin.resetPasswordSendLink({ email })
      if (resetPassword.message === "complete") {
        navigate("/?reset=true")
      }
    } catch (error) {
      console.error(error)
      renderError("Something went wrong. Please try again.")
    } finally {
      stopLoading()
    }
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <Input
        label="Password"
        placeholder="Enter your password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={error}
        disabled={loading}
        autoFocus
      />

      <Recaptcha setIsNotRobot={setIsNotRobot} />

      <Button className="w-full" disabled={loading || !isNotRobot}>
        {loading ? <Loader /> : "Sign In"}
      </Button>

      <div className="flex flex-col gap-2">
        <Button variant="link" type="button" onClick={forgotPassword}>
          Forgot Password
        </Button>
        <div className="flex items-center gap-4">
          <div className="h-px bg-border flex-1"></div>
          <span className="text-sm">or</span>
          <div className="h-px bg-border flex-1"></div>
        </div>
        <Button
          variant="link"
          type="button"
          onClick={() => setView("one-time-password")}
        >
          Send One-time Password
        </Button>
      </div>
    </form>
  )
}
