import React from "react";
import CloseModal from "../../CloseModal";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { UserCtx } from "../../../context/userContext";

interface Props {
  setNotesInfo: any;
  isCoach: boolean;
  
}

const NotesInfo: React.FC<Props> = ({ setNotesInfo, isCoach }) => {
  const { setPopupNotification } = React.useContext(CommonFunctionCtx);
const { user } = React.useContext(UserCtx)
  

  return (
    
    <>
    {user?.activeProfile === "coach" ?
   (<div
        className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
      >
      <div
          className="px-[40px] py-[60px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
        >
        <CloseModal
            callback={setNotesInfo}
            styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Coaching Notes: Private and Confidential
        </h1>
        <div className="w-full flex flex-col gap-[40px]">
          <p className="text-black">
            As a coach, the notes you take during sessions are critical to the success of your clients, and ultimately, 
            your business. That's why Zoee offers "My Client Notes" - a private and confidential tool that can only be accessed 
            by you. Keep your observations secure, note down pertinent details, and track your clients' progress with ease using Zoee.
          </p>
        </div>
      </div>
    </div>)
    :
    (<div
        className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
      >
      <div
          className="px-[40px] py-[60px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
        >
        <CloseModal
            callback={setNotesInfo}
            styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Member Notes: Private and Confidential
        </h1>
        <div className="w-full flex flex-col gap-[40px]">
          <p className="text-black">
            Create a personalized journal during your coaching session and/or program. Your personal notes feature is a private and
confidential tool that can only be accessed by you. Keep your observations secure, note down pertinent details, and track your progress with ease using Zoee.
          </p>
        </div>
      </div>
    </div>)
}
    </>
  );
};

export default NotesInfo;
