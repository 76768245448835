import React from "react";
import contacts from "../../../assets/images/contacts.png";

interface Props {}

const StartProfileCreation: React.FC<Props> = (props: any) => {
    return (
        <div className="mt-[200px] w-full flex flex-col items-center">
            <div className="flex items-center gap-[133px]">
                {/* LEFT CONTENT */}
                <div className="flex flex-col gap-[22px]">
                    <div className="flex flex-col gap-[8px]">
                        <div className="flex items-center gap-[13px] font-bold text-[22px]">
                            <span>1.</span>
                            <span>Tell us about you and your practice</span>
                        </div>
                        <p className="ml-[45px] w-[370px] text-[16px]">
                            This is how potential clients get to know you and your practice better. We will ask some questions and you can help us find the right clients for you.
                        </p>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <div className="flex items-center gap-[13px] font-bold text-[22px]">
                            <span>2.</span>
                            <span>Add some pazazz</span>
                        </div>
                        <p className="ml-[45px] w-[370px] text-[16px]">
                            Demonstrate to others why you stand out from the crowd and what make you unique.
                        </p>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <div className="flex items-center gap-[13px] font-bold text-[22px]">
                            <span>3.</span>
                            <span>Add social proof & education</span>
                        </div>
                        <p className="ml-[45px] w-[370px] text-[16px]">
                        Demonstrate to potential clients the value and effectiveness of your service through customer success stories, transparency, and a professional education, in order to build trust and showcase your impact within your market.
                        </p>
                    </div>
                </div>
                {/* RIGHT CONTENT */}
                <div className="flex flex-col items-center gap-[38px]">
                    <h1 className="font-bold text-[32px] m-0">Let's get your profile set up</h1>
                    <div className="w-[600px] h-[223px] bg-white rounded-[16px] main-shadow p-[20px]">
                        <img src={contacts} alt="contacts" />
                    </div>
                    <button className="btn-primary btn-blue">Let's Get Started</button>
                </div>
            </div>
        </div>
    )
}

export default StartProfileCreation;
