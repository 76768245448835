"use client";

import * as React from "react";
import Group6 from "../../../assets/images/coach-testimonials/Group 6.png";
import Group7 from "../../../assets/images/coach-testimonials/Group 7.png";
import Group8 from "../../../assets/images/coach-testimonials/Group 8.png";
import Group9 from "../../../assets/images/coach-testimonials/Group-9.png";
import Group10 from "../../../assets/images/coach-testimonials/Group-10.png";
import { cn } from "../../../utils/cn";

export const CoachCommentSlider = () => {
  const slides = [
    { image: Group6 },
    { image: Group7 },
    { image: Group8 },
    { image: Group9 },
    { image: Group10 },
  ];

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const goToNext = () => {
    const newIndex = (currentIndex + 1) % slides.length;
    setCurrentIndex(newIndex);
  };

  React.useEffect(() => {
    let interval: NodeJS.Timeout;

    interval = setInterval(goToNext, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="lg:hidden flex justify-center pt-8 mb-6">
      <div className="h-[430px] w-[400px] relative flex justify-center">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            className={cn("absolute transition-opacity duration-500", {
              "opacity-100": slideIndex === currentIndex,
              "opacity-0": slideIndex !== currentIndex,
            })}
          >
            <img
              src={slide.image}
              alt={`Slide ${slideIndex + 1}`}
              className="w-full h-full object-cover ml-4"
            />
          </div>
        ))}

        <div className="absolute bottom-0 w-full flex justify-center gap-4">
          {slides.map((_, slideIndex) => (
            <button
              key={slideIndex}
              className={cn("block cursor-pointer rounded-full w-4 h-4 focus-visible:ring", {
                "bg-blurple": slideIndex === currentIndex,
                "bg-mediumGray": slideIndex !== currentIndex,
              })}
              onClick={() => goToSlide(slideIndex)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
