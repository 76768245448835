import React from "react"
import { VerifyEmail } from "../../api/auth.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { UserCtx } from "../../context/userContext"
import { useNavigate } from "react-router-dom"
import LoadingPage from "../../components/LoadingPage"
import useLoading from "../../hooks/useLoading"

interface Props {}

const ConfirmEmail: React.FC<Props> = () => {
  const { user } = React.useContext(UserCtx)
  const { renderError, getUser, hideAlert } =
    React.useContext(CommonFunctionCtx)
  const navigate = useNavigate()
  const { stopLoading } = useLoading()

  const sendConfirmationCode = (code: any) => {
    VerifyEmail.verifyEmailCode({
      verification_code: code,
    })
      .then((data) => {
        getUser().then(() => {
          const message =
            data?.message === "complete" ? "success" : "wrong-code"
          navigate(`${user ? "/coach" : "/home"}`, {
            state: {
              message: message,
              // verifyPhone: true
            },
          })
        })
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setTimeout(() => hideAlert(), 5000)
      })
    stopLoading()
  }
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get("t")
    if (code) {
      sendConfirmationCode(code)
    } else {
      window.location.href = "/home?message=no-code"
    }
  }, [])

  return <LoadingPage />
}

export default ConfirmEmail
