"use client";

import * as React from "react";
import CEO from "../../../assets/images/CEO.png";
import { motion } from "framer-motion";
import ceoSignature from "../../../assets/images/CEO-signature.png";
import { useInView } from "react-intersection-observer";

export const CeoQuote: React.FC = () => {
  const [ref, inViewSecond] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  return (
    <>
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: inViewSecond ? 1 : 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75 }}
        className="py-36 container grid auto-rows-min gap-12 items-center md:grid-cols-2"
      >
        <img
          className="h-48 w-48 mx-auto md:h-auto md:w-full max-w-96"
          src={CEO}
          alt="Nichole Lowe founder of Zoee"
        />
        <div className="flex flex-col gap-6">
          <p className="italic lg:text-lg">
            "As an entrepreneur and a certified coach, I vividly recall the
            overwhelming journey of starting and navigating my coaching
            business. I made it my mission to find a solution. I asked myself,
            'How can I make this easier for others? What tools and support would
            have helped me the most?' And Zoee was born.”
          </p>
          <div className="flex flex-wrap gap-6 items-center">
            <img
              className="w-36 mix-blend-multiply"
              src={ceoSignature}
              alt="Nichole Lowe's signature"
            />
            <div className="flex flex-col">
              <h2 className="font-bold lg:text-lg">
                Nichole Lowe
              </h2>
              <h3 className="text-xs">CEO and Founder of Zoee</h3>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};
