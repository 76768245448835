import TimezoneSelect, { allTimezones } from "react-timezone-select"
import React, { FC } from "react"
import Select, { SingleValue } from "react-select"
import _ from "lodash"
import DialogueModal from "../../DialogueModal"
import { defaultAvailability } from "../../../objects/availabilityObjects"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { CalendarRequests } from "../../../api/app.service"
import { hours, bufferTime } from "../../../objects/timeObjects"
import {
  AvailabilityObjs,
  AvailabilityRulesData,
  Days,
} from "../../../types/availabilityTypes"
import Tooltip from "../../Tooltip"
import useScrollToTop from "../../../hooks/useScrollToTop"
import Loader from "../../ui/loader"
import useLoading from "../../../hooks/useLoading"
import { Button } from "../../ui/button"
import {
  SvgCheckCircleSolid,
  SvgCheckbox,
  SvgCheckboxOff,
  SvgComingSoon,
  SvgDuplicate,
  SvgInformationCircle,
  SvgPlus,
  SvgStar,
  SvgStarSolid,
  SvgTrash,
} from "../../icons/"
import MinNoticeDetails from "./MinNoticeDetails"
import BufferTimeDetails from "./BufferTimeDetails"
import { Input } from "../../ui/input"

const Availability: FC = () => {
  const { user } = React.useContext(UserCtx)
  const { renderError, hideAlert, renderSuccess } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [isDisplayStatic, setIsDisplayStatic] = React.useState<boolean>(true)
  const [showDeleteDialogueModal, setShowDeleteDialogueModal] =
    React.useState<boolean>(false)
  const [isAddedNewAvailability, setIsAddedNewAvailability] =
    React.useState<boolean>(false)
  const [availabilities, setAvailabilities] = React.useState<
    Array<AvailabilityRulesData>
  >([defaultAvailability])
  const [loadingAvailabilityRules, setLoadingAvailabilityRules] =
    React.useState<boolean>(false)
  const [selectedSchedule, setSelectedSchedule] =
    React.useState<AvailabilityRulesData>(defaultAvailability)
  const [isUnsavedCalendar, setIsUnsavedCalendar] =
    React.useState<boolean>(false)
  const [showMinNotice, setShowMinNotice] = React.useState<boolean>(false)
  const [showBufferTime, setShowBufferTime] = React.useState<boolean>(false)
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]

  const setAvailabilityAlertTimeout = () => {
    setTimeout(() => {
      hideAlert()
    }, 5000)
  }

  const getAvailabilityRules = () => {
    if (user?.coachProfile) {
      setLoadingAvailabilityRules(true)
      CalendarRequests.getAvailabilityRules({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          if (data.availability_rules.length > 0) {
            setAvailabilities(data.availability_rules)
            const defaultSchedule = data.availability_rules.find(
              (a) => a.is_default
            )
            if (defaultSchedule) {
              setSelectedSchedule(defaultSchedule)
            } else {
              setSelectedSchedule(data.availability_rules[0])
            }
          } else {
            setIsUnsavedCalendar(true)
            setSelectedSchedule(defaultAvailability)
          }
          setLoadingAvailabilityRules(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setTimeout(() => hideAlert(), 5000)
          setLoadingAvailabilityRules(false)
        })
    }
  }

  const setAvailability = () => {
    if (
      !(
        selectedSchedule.name &&
        selectedSchedule.timezone &&
        selectedSchedule.weekly_periods.length > 0
      )
    ) {
      if (!selectedSchedule.name) {
        renderError("Please add a name for this schedule before saving.")
        setAvailabilityAlertTimeout()
      } else if (!selectedSchedule.timezone) {
        renderError("Please select a timezone for this schedule before saving.")
        setAvailabilityAlertTimeout()
      } else if (!(selectedSchedule.weekly_periods.length > 0)) {
        renderError(
          "You must choose at least one day to be available before saving."
        )
        setAvailabilityAlertTimeout()
      }
    } else {
      if (user?.coachProfile) {
        startLoading()
        let updatedAvailabilities = availabilities
        if (selectedSchedule.id) {
          updatedAvailabilities = availabilities.map((a) => {
            return a.id === selectedSchedule.id ? selectedSchedule : a
          })
        } else {
          updatedAvailabilities = _.remove(availabilities, (a) => {
            return a !== defaultAvailability
          })
          updatedAvailabilities = [...updatedAvailabilities, selectedSchedule]
        }
        CalendarRequests.setAvailabilityRules({
          user_id: parseInt(user.user_id),
          availability_rules: updatedAvailabilities,
        })
          .then(() => {
            stopLoading()
            setIsUnsavedCalendar(false)
          })
          .catch((ex) => {
            console.log(ex)
            renderError(ex.response.data.message)
            setTimeout(() => hideAlert(), 5000)
            stopLoading()
          })
          .finally(() => {
            getAvailabilityRules()
          })
      }
    }
  }

  const duplicateAvailability = () => {
    if (
      !(
        selectedSchedule.name &&
        selectedSchedule.timezone &&
        selectedSchedule.weekly_periods.length > 0
      )
    ) {
      if (!selectedSchedule.name) {
        renderError("Please add a name for this schedule before proceeding.")
        setAvailabilityAlertTimeout()
      } else if (!selectedSchedule.timezone) {
        renderError(
          "Please select a timezone for this schedule before proceeding."
        )
        setAvailabilityAlertTimeout()
      } else if (!(selectedSchedule.weekly_periods.length > 0)) {
        renderError(
          "You must choose at least one day to be available before proceeding."
        )
        setAvailabilityAlertTimeout()
      }
    } else {
      if (user?.coachProfile) {
        startLoading()
        const updatedAvailabilities = availabilities.map((a) => {
          return a.id === selectedSchedule.id
            ? {
                ...selectedSchedule,
                id: null,
                name: `${selectedSchedule.name} (Copy)`,
                is_default: false,
              }
            : a
        })
        CalendarRequests.setAvailabilityRules({
          user_id: parseInt(user.user_id),
          availability_rules: updatedAvailabilities,
        })
          .then((data) => {
            stopLoading()
            setIsUnsavedCalendar(false)
          })
          .catch((ex) => {
            console.log(ex)
            renderError(ex.response.data.message)
            setTimeout(() => hideAlert(), 5000)
            stopLoading()
          })
          .finally(() => {
            getAvailabilityRules()
          })
      }
    }
  }

  const setDefaultAvailability = () => {
    if (
      !(
        selectedSchedule.name &&
        selectedSchedule.timezone &&
        selectedSchedule.weekly_periods.length > 0
      )
    ) {
      if (!selectedSchedule.name) {
        renderError("Please add a name for this schedule before proceeding.")
        setAvailabilityAlertTimeout()
      } else if (!selectedSchedule.timezone) {
        renderError(
          "Please select a timezone for this schedule before proceeding."
        )
        setAvailabilityAlertTimeout()
      } else if (!(selectedSchedule.weekly_periods.length > 0)) {
        renderError(
          "You must choose at least one day to be available before proceeding."
        )
        setAvailabilityAlertTimeout()
      }
    } else {
      if (user?.coachProfile) {
        startLoading()
        //  FIX, duplicating whole array for some reason
        let updatedAvailabilities = availabilities
        if (selectedSchedule.is_default) {
          updatedAvailabilities = availabilities.map((a) => {
            return a.id === selectedSchedule.id
              ? {
                  ...selectedSchedule,
                  is_default: false,
                }
              : a
          })
        } else {
          availabilities.forEach((a) => (a.is_default = false))
          updatedAvailabilities = availabilities.map((a) => {
            return a.id === selectedSchedule.id
              ? {
                  ...selectedSchedule,
                  is_default: true,
                }
              : a
          })
        }
        CalendarRequests.setAvailabilityRules({
          user_id: parseInt(user.user_id),
          availability_rules: updatedAvailabilities,
        })
          .then((data) => {
            stopLoading()
            setIsUnsavedCalendar(false)
          })
          .catch((ex) => {
            console.log(ex)
            renderError(ex.response.data.message)
            setTimeout(() => hideAlert(), 5000)
            stopLoading()
          })
          .finally(() => {
            getAvailabilityRules()
          })
      }
    }
  }

  const deleteAvailabilityRule = () => {
    if (!selectedSchedule.id) {
      renderError("You must save a newly created calendar before deleting it.")
      setAvailabilityAlertTimeout()
      return
    }

    if (user?.coachProfile && selectedSchedule.id) {
      startLoading()

      let remainingAvailabilities = availabilities.filter(
        (a) => a.id !== selectedSchedule.id
      )
      const isDeletingDefault = selectedSchedule.is_default

      CalendarRequests.deleteAvailabilityRule({
        user_id: parseInt(user.user_id),
        availability_rule_id: selectedSchedule.id,
      })
        .then(() => {
          setShowDeleteDialogueModal(false)
          renderSuccess("Availability deleted")

          if (isDeletingDefault && remainingAvailabilities.length > 0) {
            remainingAvailabilities[0].is_default = true
          }
          if (remainingAvailabilities.length === 1) {
            remainingAvailabilities[0].is_default = true
          }
          setAvailabilities(remainingAvailabilities)
          setSelectedSchedule(remainingAvailabilities[0] || defaultAvailability)
          setTimeout(() => hideAlert(), 5000)
          stopLoading()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
          setTimeout(() => hideAlert(), 5000)
        })
        .finally(() => {
          getAvailabilityRules()
        })
    }
  }

  React.useEffect(() => {
    getAvailabilityRules()
  }, [])

  useScrollToTop()

  React.useEffect(() => {
    setSelectedSchedule(_.last(availabilities) || availabilities[0])
    setIsAddedNewAvailability(false)
  }, [isAddedNewAvailability])

  const toggleDayChecked = (day: any) => {
    let foundDay = selectedSchedule.weekly_periods.find(
      (e: any) => e.day === day
    )
    const currentlySelectedDays = Object.values(
      selectedSchedule.weekly_periods
    ).map((e) => e.day)
    let updatedSchedule = { ...selectedSchedule }
    let newWeeklyPeriods: Array<any> = []
    if (foundDay) {
      if (currentlySelectedDays.includes(foundDay.day)) {
        updatedSchedule.weekly_periods.forEach((obj) => {
          if (obj.day === foundDay?.day) {
            //
          } else {
            newWeeklyPeriods.push(obj)
          }
        })
      }
    } else {
      newWeeklyPeriods = selectedSchedule.weekly_periods
      newWeeklyPeriods.push({
        day,
        start_time: "09:00",
        end_time: "17:00",
      })
    }
    updatedSchedule = {
      ...selectedSchedule,
      weekly_periods: newWeeklyPeriods,
    }
    setSelectedSchedule(updatedSchedule)
    setSelectedSchedule({ ...updatedSchedule })
    setIsUnsavedCalendar(true)
  }

  // Uncomment when we need to work on the dynamic view
  //
  // const availabilityRulesOptions = {
  //     demo: true,
  //     target_id: "cronofy-availability-rules",
  //     id: "work_hours",
  //     config: {
  //         start_time: "08:00",
  //         end_time: "18:00",
  //         duration: 60,
  //     },
  //     styles: {
  //         colors: {
  //             available: "#EFF7FF",
  //             unavailable: "white",
  //         },
  //         prefix: "AvailabilityRules",
  //     },
  //     tzid: "Etc/UTC",
  // };
  const handleTimeZoneChange = (e: any) => {
    setSelectedSchedule({
      ...selectedSchedule,
      timezone: e,
    })
    setIsUnsavedCalendar(true)
  }

  const handleAvailabilityNameChange = (e: any) => {
    setIsUnsavedCalendar(true)
    setSelectedSchedule({
      ...selectedSchedule,
      name: e.target.value,
    })
  }

  const handleAddAvailabilityOption = (day: string): void => {
    setIsUnsavedCalendar(true)
    const schedule = selectedSchedule
    const defaultAvailabilityOption = {
      day: day as Days,
      start_time: "09:00",
      end_time: "17:00",
    }
    schedule.weekly_periods.push(defaultAvailabilityOption)
    setSelectedSchedule({ ...schedule })
  }

  const handleRemoveAvailabilityOption = (
    day: string,
    availabilityObj: AvailabilityObjs
  ): void => {
    setIsUnsavedCalendar(true)
    const schedule = selectedSchedule
    _.remove(schedule.weekly_periods, (e) => e === availabilityObj)
    setSelectedSchedule({ ...schedule })
  }

  // TODO fix this function so newly created schedule is active, doesn't quite work when there are multiple new calendars
  const handleAddNewScheduleClick = () => {
    if (isUnsavedCalendar) {
      renderError(
        "Please save your current schedule before creating a new one."
      )
      setAvailabilityAlertTimeout()
    } else {
      if (user.timezone) {
        const defaultWithTimezone = defaultAvailability
        defaultWithTimezone.timezone = user.timezone
        setAvailabilities([...availabilities, defaultWithTimezone])
      } else {
        setAvailabilities([...availabilities, defaultAvailability])
      }
      setIsAddedNewAvailability(!isAddedNewAvailability)
      setIsUnsavedCalendar(true)
    }
  }

  const handleDuplicateScheduleClick = () => {
    if (isUnsavedCalendar) {
      renderError("Please save your current schedule before duplicating.")
      setAvailabilityAlertTimeout()
    } else {
      duplicateAvailability()
    }
  }

  const handleTimeChange = (
    e: any,
    availabilityObject: AvailabilityObjs,
    startOrEnd: "start" | "end"
  ) => {
    let newAvailabilityObj
    if (startOrEnd === "start") {
      newAvailabilityObj = {
        ...availabilityObject,
        start_time: e.value,
      }
    } else {
      newAvailabilityObj = {
        ...availabilityObject,
        end_time: e.value,
      }
    }

    // validations
    if (newAvailabilityObj.start_time >= newAvailabilityObj.end_time) {
      renderError("The start time must be earlier than the end time.")
      setAvailabilityAlertTimeout()
      return
    }
    // TODO validate whether the existing times for a given day overlap
    // (do it here or somewhere else? does cronofy have anything built in to handle this)

    const weeklyPeriods = selectedSchedule.weekly_periods
    const foundIndex = weeklyPeriods.indexOf(availabilityObject)

    if (foundIndex >= 0) {
      weeklyPeriods.splice(foundIndex, 1, newAvailabilityObj)
    } else {
      renderError("Error setting availability. Please reload.")
      return
    }

    setSelectedSchedule({
      ...selectedSchedule,
      weekly_periods: weeklyPeriods,
    })

    setIsUnsavedCalendar(true)
  }

  const handleSetDefault = () => {
    if (isUnsavedCalendar) {
      renderError(
        "Please save your current schedule before setting it as default."
      )
      setAvailabilityAlertTimeout()
    } else {
      const defaultCount = availabilities.filter((a) => a.is_default).length
      if (selectedSchedule.is_default && defaultCount === 1) {
        renderError("Please select another availability as your default.")
        setAvailabilityAlertTimeout()
        return
      }

      setDefaultAvailability()
    }
  }

  const setInitialTimezone = () => {
    return selectedSchedule.id
      ? selectedSchedule?.timezone?.value
      : user?.timezone?.value
  }

  const handleAvailabilityBufferBeforeChange = (
    e: SingleValue<{
      value: number
      label: string
    }>
  ) => {
    setIsUnsavedCalendar(true)
    setSelectedSchedule({
      ...selectedSchedule,
      buffer_before: e?.value || 0,
    })
  }

  const handleAvailabilityBufferAfterChange = (
    e: SingleValue<{
      value: number
      label: string
    }>
  ) => {
    setIsUnsavedCalendar(true)
    setSelectedSchedule({
      ...selectedSchedule,
      buffer_after: e?.value || 0,
    })
  }

  return (
    <div className="md:pt-[32px] w-full md:max-w-[1112px] px-[20px] mx-auto">
      <div className="">
        <div className="flex items-center justify-between mb-[12px]">
          <h2 className="font-bold text-[18px]">Schedules</h2>
        </div>

        {/* SCHEDULE SELECT BUTTONS */}
        <div className="mb-[12px] md:mb-[32px] flex items-center flex-wrap gap-[8px] text-base font-bold">
          {availabilities
            .filter((a) => a.id)
            .map((a, idx) => {
              const isSelectedSchedule =
                idx === availabilities.indexOf(selectedSchedule)

              return (
                <Button
                  key={idx}
                  onClick={() => {
                    if (isUnsavedCalendar) {
                      renderError(
                        "Please save your current calendar before switching to another calendar."
                      )
                      setAvailabilityAlertTimeout()
                    } else {
                      setSelectedSchedule(a)
                    }
                  }}
                  variant={isSelectedSchedule ? "outline" : "ghost"}
                  className="rounded-full"
                >
                  {a.name ? a.name : "New Schedule"}
                  {a.is_default ? " (Default)" : ""}
                </Button>
              )
            })}

          <Button onClick={handleAddNewScheduleClick} variant="link">
            <SvgPlus />
            Add new
          </Button>
        </div>
      </div>

      <div
        className={`${
          !loadingAvailabilityRules &&
          !selectedSchedule.id &&
          "border-2 border-luminousAmber"
        } w-full sm:max-w-[1112px] px-[10px] md:px-[16px] mx-auto py-[24px] md:py-[40px] bg-white promotion-shadow rounded-[16px]`}
      >
        <div className="w-full mb-[24px] md:mb-[32px] flex items-center justify-between">
          <div className="w-full mr-4">
            <Input
              label="Schedule Name"
              type="text"
              placeholder="Schedule Name"
              onChange={handleAvailabilityNameChange}
              name="scheduleName"
              disabled={
                selectedSchedule.name === "Default Consultation Availability"
              }
              value={selectedSchedule.name || ""}
            />
          </div>
          <div className="fixed md:static bottom-0 left-0 z-[1005] md:z-20 py-3 md:h-auto w-full md:w-auto bg-white flex items-center justify-center md:justify-start gap-2 box-shadow-2 md:shadow-none">
            <div className="flex items-center gap-2">
              <div className="relative">
                <Button
                  onClick={handleSetDefault}
                  className="peer"
                  variant="ghost"
                  size="icon"
                  aria-label="Make Default Availability"
                >
                  {selectedSchedule.is_default ? (
                    <SvgStarSolid className="text-orange" />
                  ) : (
                    <SvgStar />
                  )}
                </Button>
                <Tooltip
                  message={
                    selectedSchedule.is_default
                      ? "Remove as default schedule"
                      : "Set as default schedule"
                  }
                />
              </div>
              <div>
                <div className="relative">
                  <Button
                    onClick={handleDuplicateScheduleClick}
                    variant="ghost"
                    className="peer"
                    size="icon"
                    aria-label="Duplicate Availability"
                  >
                    <SvgDuplicate />
                  </Button>
                  <Tooltip message="Duplicate this schedule" />
                </div>
              </div>
              <Button
                variant="secondary"
                onClick={() => {
                  if (!selectedSchedule.id) {
                    renderError(
                      "You must save a newly created calendar before deleting it."
                    )
                    setAvailabilityAlertTimeout()
                  } else {
                    setShowDeleteDialogueModal(() => !showDeleteDialogueModal)
                  }
                }}
              >
                <SvgTrash />
                Delete
              </Button>
              {showDeleteDialogueModal && (
                <DialogueModal
                  setShowDialogueModal={setShowDeleteDialogueModal}
                  header={`Caution! You are about to permanently delete this schedule.`}
                  helpText={`Are you sure you want to delete the ${selectedSchedule.name} schedule?`}
                  confirmCallbackFn={deleteAvailabilityRule}
                  confirmButtonText="Delete Schedule"
                  isWarningVariant={true}
                />
              )}
            </div>
            <div className="flex items-center gap-2">
              {/* TODO Add function or hide this See Preview button */}
              {/*<Button
                                disabled
                                variant="secondary"
                            >
                                See Preview
                            </Button>*/}
              <Button onClick={setAvailability} disabled={!isUnsavedCalendar}>
                Save
                <SvgCheckCircleSolid />
              </Button>
            </div>
          </div>
        </div>

        {showMinNotice && (
          <MinNoticeDetails setShowMinNotice={setShowMinNotice} />
        )}
        {showBufferTime && (
          <BufferTimeDetails setShowBufferTime={setShowBufferTime} />
        )}
        {/* <span className="font-normal text-sm text-graySlate ml-[353px] -mb-[10px] hidden md:block">advanced</span> */}
        {/* SERVICES ATTACHED AND TIMEZONE SELECT DROPDOWNS */}
        <div className="flex flex-col md:flex-row items-center gap-[24px] md:gap-[40px] mb-[24px]">
          {/* Hide Services Attached for 2.0 launch */}
          {/* <div className="flex flex-col gap-[12px] w-full">
                        <div className="flex items-center">
                            <h4 className="font-bold text-base">Services Attached</h4>
                            <img src={faq} alt="faq" className="h-[24px] w-[24px] ml-[8px]" />
                        </div>
                        <Select
                            className="basic-single md:w-[325px]"
                            classNamePrefix="select"
                            // TODO Update with services options
                            // options={}
                        />
                    </div> */}
          <div className="flex flex-col gap-[12px] w-full">
            <div className="flex items-center">
              <h4 className="font-bold text-base">Timezone</h4>
              {/* Minimum Notice */}
              {/* <div className="ml-[285px] flex items-center gap-[12px]">
                            <h4 className="font-bold text-base hidden md:block">Minimum Notice</h4>
                                <Button 
                                    onClick={() => setShowMinNotice(true)}
                                    variant={"link"}
                                    className="text-graySlate pl-0 pr-0 hidden md:block"
                                    >
                                    <Icons.info  />
                                </Button>
                            </div> */}
              <div className="flex items-center gap-[12px] mx-auto">
                <h4 className="font-bold text-base hidden md:block">
                  Buffer Windows
                </h4>
                <Button
                  onClick={() => setShowBufferTime(true)}
                  variant="link"
                  size="icon"
                  className="hidden md:flex"
                >
                  <SvgInformationCircle />
                </Button>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-[16px] md:gap-[12px]">
              <div className="flex items-center w-full">
                <TimezoneSelect
                  className={`md:w-[325px] w-full basic-single `}
                  classNamePrefix="select"
                  value={setInitialTimezone()}
                  name="timezone"
                  id="timezone"
                  onChange={(e) => handleTimeZoneChange(e)}
                  timezones={{
                    ...allTimezones,
                    [user?.timezone?.value]: user?.timezone?.value,
                  }}
                />
              </div>
              {/* Minium Notice */}
              {/* <div className="hidden md:flex flex-col w-full ml-[15px]">
                                    <Select 
                                        className="basic-single w-full md:w-[156px]"
                                        classNamePrefix="select"
                                        options={minHours}
                                        placeholder="0 Hour"
                                    />
                                </div>  */}
              <div className="hidden md:flex items-center w-full gap-[12px] mr-[90px]">
                <span className="font-bold text-sm text-graySlate mr-auto md:mr-0">
                  Before
                </span>
                <div className="flex items-center w-full">
                  <Select
                    className="basic-single w-full md:w-[156px] "
                    classNamePrefix="select"
                    options={bufferTime}
                    placeholder="0 minutes"
                    value={bufferTime.filter(
                      (option) =>
                        option.value === selectedSchedule.buffer_before
                    )}
                    onChange={handleAvailabilityBufferBeforeChange}
                  />
                </div>
                <span className="font-bold text-sm text-graySlate mr-auto md:mr-0">
                  After
                </span>
                <div className="flex items-center w-full">
                  <Select
                    className="basic-single w-full md:w-[156px] "
                    classNamePrefix="select"
                    options={bufferTime}
                    placeholder="0 minutes"
                    value={bufferTime.filter(
                      (option) => option.value === selectedSchedule.buffer_after
                    )}
                    onChange={handleAvailabilityBufferAfterChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* mobile */}
          <div className="flex flex-col gap-[12px] w-full md:hidden">
            {/* Minimum Notice  */}
            {/* <span className="font-normal text-sm text-graySlate -mb-[20px]">advanced</span> 
                         <div className="flex items-center gap-[12px]">
                              <h4 className="font-bold text-base">Minimum Notice</h4>
                                <Button 
                                    onClick={() => setShowMinNotice(true)}
                                    variant={"link"}
                                    className="text-graySlate pl-0 pr-0"
                                    >
                                    <Icons.info  />
                                </Button>
                        </div> 
                     
                        <div className="flex items-center w-full -mt-2">
                            <Select 
                                className="basic-single w-full md:w-[156px]"
                                classNamePrefix="select"
                                options={minHours}
                                placeholder="0 Hour"
                            />
                        </div>              */}
          </div>
          <div className="flex flex-col gap-[12px] w-full md:hidden -mt-[30px]">
            <div className="flex items-center gap-[12px]">
              <h4 className="font-bold text-base">Buffer Windows</h4>
              <Button
                onClick={() => setShowBufferTime(true)}
                variant="link"
                size="icon"
              >
                <SvgInformationCircle />
              </Button>
            </div>
            <div className="flex flex-col md:flex-row items-center w-full gap-[12px] -mt-2">
              <span className="font-bold text-sm text-graySlate mr-auto md:mr-0">
                Before
              </span>
              <div className="flex items-center w-full">
                <Select
                  className="basic-single w-full md:w-[156px] "
                  classNamePrefix="select"
                  options={bufferTime}
                  placeholder="0 minutes"
                  value={bufferTime.filter(
                    (option) => option.value === selectedSchedule.buffer_before
                  )}
                  onChange={handleAvailabilityBufferBeforeChange}
                />
              </div>
              <span className="font-bold text-sm text-graySlate mr-auto md:mr-0">
                After
              </span>
              <div className="flex items-center w-full">
                <Select
                  className="basic-single w-full md:w-[156px] "
                  classNamePrefix="select"
                  options={bufferTime}
                  placeholder="0 minutes"
                  value={bufferTime.filter(
                    (option) => option.value === selectedSchedule.buffer_after
                  )}
                  onChange={handleAvailabilityBufferAfterChange}
                />
              </div>
            </div>
          </div>
        </div>
        {loadingAvailabilityRules ? (
          <div className="flex flex-col items-center gap-[12px] mt-[24px]">
            <Loader />
            <h3 className="font-bold text-[16px] mb-[4px] text-center">
              Looking for your availability settings...
            </h3>
          </div>
        ) : (
          <>
            {/* STATIC VIEW */}
            {isDisplayStatic ? (
              <div className="w-full flex flex-col gap-[8px] last:mb-[80px] md:last:mb-0">
                {daysOfWeek.map((d, idx) => {
                  const availabilityArr =
                    selectedSchedule?.weekly_periods.filter(
                      (obj) => obj.day === d
                    )
                  return (
                    <div key={idx}>
                      {availabilityArr.length > 0 ? (
                        <div className="w-full min-h-[96px] main-shadow rounded-[14px] p-[24px] bg-white flex flex-col md:flex-row md:items-center justify-between">
                          <div className="flex items-center justify-between min-w-[176px] mb-[18px] md:mb-0">
                            <button
                              className="flex items-center gap-[12px]"
                              onClick={() =>
                                toggleDayChecked(availabilityArr[0].day)
                              }
                            >
                              <SvgCheckbox />
                              <span className="font-bold text-base capitalize">
                                {availabilityArr[0].day}
                              </span>
                            </button>

                            <Button
                              className=" md:hidden"
                              size="icon"
                              variant="link"
                              onClick={() =>
                                handleAddAvailabilityOption(
                                  availabilityArr[0].day
                                )
                              }
                            >
                              <SvgPlus />
                            </Button>
                          </div>
                          <div className="flex w-full flex-col gap-[12px]">
                            {availabilityArr.map((availabilityObj, idx) => {
                              return (
                                <div key={idx} className="flex w-full">
                                  <div className="flex items-center w-full">
                                    <div className="flex max-w-[504px] items-center gap-[4px] md:gap-[16px]">
                                      <Select
                                        className="basic-single w-[109px] sm:w-[134px] md:w-[200px] "
                                        classNamePrefix="select"
                                        options={hours}
                                        value={hours.filter(
                                          (option) =>
                                            option.value ===
                                            availabilityObj.start_time
                                        )}
                                        onChange={(e) =>
                                          handleTimeChange(
                                            e,
                                            availabilityObj,
                                            "start"
                                          )
                                        }
                                        name="startTime"
                                      />
                                      <p className="text-gray text-[16px]">-</p>
                                      <Select
                                        className="basic-single w-[109px] sm:w-[124px] md:w-[200px] "
                                        classNamePrefix="select"
                                        options={hours}
                                        value={hours.filter(
                                          (option) =>
                                            option.value ===
                                            availabilityObj.end_time
                                        )}
                                        onChange={(e) =>
                                          handleTimeChange(
                                            e,
                                            availabilityObj,
                                            "end"
                                          )
                                        }
                                        name="endTime"
                                      />
                                      {availabilityArr.length > 1 && (
                                        <button
                                          onClick={() =>
                                            handleRemoveAvailabilityOption(
                                              availabilityArr[0].day,
                                              availabilityObj
                                            )
                                          }
                                        >
                                          <SvgTrash />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                          <Button
                            variant="link"
                            size="icon"
                            className="hidden md:flex"
                            onClick={() =>
                              handleAddAvailabilityOption(
                                availabilityArr[0].day
                              )
                            }
                          >
                            <SvgPlus />
                          </Button>
                        </div>
                      ) : (
                        <div className="w-full min-h-[96px] main-shadow rounded-[14px] p-[24px] bg-white flex items-center last:mb-[30px] md:last:mb-0">
                          <div className="flex w-full items-center justify-between">
                            <div className="flex items-center md:min-w-[176px]">
                              <button
                                className="flex items-center gap-[12px]"
                                onClick={() => toggleDayChecked(d)}
                              >
                                <SvgCheckboxOff />
                                <span className="font-bold text-base capitalize">
                                  {d}
                                </span>
                              </button>
                            </div>
                            <div className="md:w-full">
                              <p className="text-base font-bold text-graySlate">
                                Unavailable
                              </p>
                            </div>
                            <Button
                              variant="link"
                              size="icon"
                              onClick={() => {
                                handleAddAvailabilityOption(d)
                              }}
                            >
                              <SvgPlus />
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ) : (
              // DYNAMIC VIEW
              <div className="flex items-center justify-center h-[400px]">
                <div className="flex flex-col items-center">
                  <SvgComingSoon className="w-[50px] mb-[8px]" />
                  <h3 className="text-[22px] font-bold">Coming soon!</h3>
                </div>
                {/* Uncomment when ready to work on dynamic view */}
                {/* <div className="-translate-y-4 mb-[8px] z-[600]">
                         <WeekPicker />
                     </div>
                     <div className="z-[300] w-full px-[8px] py-[16px] rounded-[16px] border border-grayMist">
                         <AvailabilityRules options={availabilityRulesOptions}  />
                     </div> */}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Availability
