import React, { FC, useEffect, useState } from "react"
import { UserCtx } from "../../context/userContext"
import { motion } from "framer-motion"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { Button } from "../ui/button"
import NavShell from "./NavShell"
import UserButton from "./UserButton"
import UserButtonMobile from "./UserButtonMobile"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { getUserColor } from "../../utils/getUserColor"
import { getTextColor } from "../../utils/getTextColor"
import useName from "../../hooks/useName"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgClose, SvgHamburger, SvgZoeeIcon, SvgZoeeLogo } from "../icons/"
import { useAuthModal } from "../auth/use-auth-modal"

const LINKS: {
  label: string
  href: string
}[] = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Features",
    href: "/features",
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
  {
    label: "Our Story",
    href: "/why-zoee",
  },
  {
    label: "Blog",
    href: "/blog",
  },
  {
    label: "Find A Coach",
    href: "/find-a-coach",
  },
]

const MarketingNav: FC = () => {
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const { initial } = useName()
  const { previewImgUrl } = React.useContext(CommonFunctionCtx)

  const [searchParams, setSearchParams] = useSearchParams()
  const register = searchParams.get("register")
  const coachregister = searchParams.get("coachregister")
  const location = useLocation()

  const authenticatedUser = useAuthenticatedUser(user)
  const { showLogin, showRegister } = useAuthModal()

  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [showUserButton, setShowUserButton] = React.useState<boolean>(false)

  const openCreateAccountModal = () => {
    setModalOpen(true)
    showRegister()

    searchParams.set("register", "true")
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (!modalOpen) {
      searchParams.delete("register")
      setSearchParams(searchParams)
    }
  }, [modalOpen])

  useEffect(() => {
    if (coachregister === "true") {
      searchParams.set("register", "coach")
      searchParams.delete("coachregister")
    }

    if (register === "true") {
      openCreateAccountModal()
    } else if (register === "coach") {
      setShowMobileNav(false)
      showRegister({ accountType: "coach" })
    }
  }, [register, coachregister])

  useEffect(() => {
    if (showMobileNav) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden"
      }
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showMobileNav])

  useEffect(() => {
    const currentPath = window.location.pathname
    if (currentPath === "/blog") {
      navigate("/find-a-coach/zoee-coaching?featured=true", { replace: false })
    }
  }, [navigate])

  return (
    <NavShell className="flex items-center justify-between text-base font-semibold">
      {authenticatedUser && (
        <Button
          variant="ghost"
          className="ml-4 lg:hidden"
          size="icon"
          onClick={() => setShowMobileNav(() => !showMobileNav)}
        >
          {showMobileNav ? <SvgClose /> : <SvgHamburger />}
        </Button>
      )}
      <div onClick={() => setShowMobileNav(false)}>
        {authenticatedUser ? (
          <Link to="/">
            <SvgZoeeLogo className="hidden lg:block" />
          </Link>
        ) : (
          <Link to="/">
            <SvgZoeeLogo className="hidden md:block" />
            <SvgZoeeIcon className="md:hidden" />
          </Link>
        )}
      </div>
      <div className="text-grayCharcoal hidden flex-1 items-center text-[16px] md:ml-[40px] md:gap-[26px] lg:flex xl:ml-[108px] xl:gap-[64px]">
        {LINKS.map(({ label, href }) => (
          <motion.div
            key={href}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Link to={href}>{label}</Link>
          </motion.div>
        ))}
      </div>

      <div className="flex">
        {authenticatedUser ? (
          <div className="mr-2 flex items-center gap-4">
            <Button variant="link" asChild>
              <Link
                to={`/${user.activeProfile}`}
                className="text-grayCharcoal text-base font-semibold"
              >
                Back to Account
              </Link>
            </Button>
            <div
              className={`hidden items-center justify-end gap-[20px] md:flex lg:gap-[24px]`}
            >
              <UserButton />
            </div>
            <div className={`relative md:hidden`}>
              {showUserButton ? (
                <SvgClose />
              ) : (
                <Button
                  variant="ghost"
                  className="h-10 w-10 rounded-full"
                  onClick={() => setShowUserButton(!showUserButton)}
                >
                  <Avatar size="xs">
                    {Boolean(user.hasAvatar) && (
                      <AvatarImage
                        alt="avatar"
                        src={previewImgUrl || user.avatar_url}
                      />
                    )}
                    <AvatarFallback className={getUserColor(user.userColor)}>
                      <div
                        style={{
                          color: getTextColor(user.userColor),
                        }}
                      >
                        {initial}
                      </div>
                    </AvatarFallback>
                  </Avatar>
                </Button>
              )}
              <UserButtonMobile
                setShowUserButton={setShowUserButton}
                showUserButton={showUserButton}
              />
            </div>
          </div>
        ) : (
          <div className="hidden items-center gap-4 sm:flex">
            <Button onClick={() => showLogin()} variant="outline">
              Sign In
            </Button>
            <Button
              onClick={() => showRegister({ accountType: "coach" })}
              variant="default"
            >
              Start Free Trial
            </Button>
          </div>
        )}
      </div>
      {!authenticatedUser && (
        <Button
          variant="ghost"
          className="ml-4 lg:hidden"
          size="icon"
          onClick={() => setShowMobileNav(() => !showMobileNav)}
        >
          {showMobileNav ? <SvgClose /> : <SvgHamburger />}
        </Button>
      )}
      {showMobileNav && (
        <div className="zoee-backdrop-filter fixed left-0 top-0 z-[601] h-screen w-screen"></div>
      )}
      <div
        className={`fixed top-0 flex min-h-screen flex-col rounded-lg bg-white ${
          authenticatedUser ? "left-0" : "right-0"
        } bottom-0 overflow-y-auto bg-white p-8 transition-all duration-500 ${
          showMobileNav
            ? "w-[279px] " + (authenticatedUser ? "left-0" : "right-0")
            : "w-0 " + (authenticatedUser ? "left-[-279px]" : "right-[-279px]")
        } z-[1000]`}
        style={{
          boxShadow: authenticatedUser
            ? "5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.1)"
            : "-5px 0 15px -2px rgba(111, 89, 246, 0.15), -5px 0 15px -2px rgba(111, 89, 246, 0.15), -5px 0 15px -2px rgba(111, 89, 246, 0.1)",
        }}
        onClick={() => setShowMobileNav(false)}
      >
        <Button
          className="bg-grayFlash absolute right-[20px] top-[25px] rounded-full"
          size="icon"
          variant="ghost"
          onClick={() => setShowMobileNav(() => !showMobileNav)}
        >
          <SvgClose />
        </Button>
        <Link
          to="/"
          onClick={() => setShowMobileNav(false)}
          className="mb-[48px] mt-[10px]"
        >
          <SvgZoeeLogo className="h-auto w-[155px]" />
        </Link>
        <div className="mb-[56px] flex flex-col gap-[28px] text-center text-[18px]">
          {LINKS.map(({ label, href }, index) => (
            <Link
              key={index}
              to={href}
              className={`hover:bg-hover ml-1 w-fit rounded-md px-3 py-2 hover:text-black ${
                location.pathname === href ? "bg-hoverDark font-bold" : ""
              }`}
            >
              <motion.div
                whileHover={{ color: "#007AFF" }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                // className="w-fit ml-2 rounded-md hover:bg-hover hover:text-black px-2 py-2"
                onClick={() => setShowMobileNav(false)}
              >
                {label}
              </motion.div>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-grayCharcoal text-base font-semibold"
              ></motion.p>
            </Link>
            // <NavLinkItem key={index} to={href}>
            //   {label}
            // </NavLinkItem>
          ))}
        </div>
        <div className="mx-auto flex w-56 flex-col gap-10">
          {authenticatedUser ? (
            <></>
          ) : (
            <div className="flex flex-col items-center gap-4">
              <Button
                onClick={() => {
                  setShowMobileNav(false)
                  showRegister({ accountType: "coach" })
                }}
                className="w-[150px]"
                size="lg"
              >
                Start Free Trial
              </Button>

              <Button
                onClick={() => {
                  setShowMobileNav(false)
                  showLogin()
                }}
                variant="outline"
                className="w-[150px]"
                size="lg"
              >
                Sign in
              </Button>
            </div>
          )}
        </div>
      </div>
    </NavShell>
  )
}

export default MarketingNav
