import React from "react";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { ClientPaymentRequests } from "../../api/app.service";
import StripeVerifyIdentityButton from "./StripeVerifyIdentityButton";
import TakeMeToStripeModal from "./TakeMeToStripeModal";
import { AnimatePresence } from "framer-motion";
import useLoading from "../../hooks/useLoading";
import { SvgBank2, SvgPoweredByStripe, SvgVerifyIdentity } from "../icons";

type Props = {
  connectAccountData: any;
};

const syncAccountSteps = [
  {
    number: "01",
    image: (
      <SvgVerifyIdentity className="h-[40px] w-[40px] md:h-auto md:w-auto" />
    ),
    title: "Verify Your Identity",
    content:
      "Confirm your identity to prevent fraud, streamline risk operations, and increase trust and safety.",
  },
  {
    number: "02",
    image: <SvgBank2 className="h-[40px] w-[40px] md:h-auto md:w-auto" />,
    title: "Connect Your Bank Account",
    content:
      "This is where you'll sync your bank account and view payments from Zoee to you—the super awesome and successful coach.",
  },
];

const PaymentsSetupInstructions: React.FC<Props> = ({ connectAccountData }) => {
  const { user } = React.useContext(UserCtx);
  const { setPopupNotification, renderError } =
    React.useContext(CommonFunctionCtx);

  const { startLoading, stopLoading } = useLoading();

  const [showTakeMeToStripeModal, setShowTakeMeToStripeModal] =
    React.useState<boolean>(false);

  const getOnboardingLink = () => {
    if (user) {
      startLoading();
      ClientPaymentRequests.getStripeConnectOnboardingLink({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          const link = data.link;
          window.location.href = link;
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  return (
    <>
      <div className="box-shadow-1 flex min-h-[234px] flex-col justify-between rounded-[16px] bg-white px-[16px] py-[32px] md:p-[40px]">
        <div className="mb-[40px] flex w-full flex-col justify-between md:flex-row md:items-center">
          <div className="mb-[18px] flex flex-col gap-[8px] md:mb-0">
            <h6 className="text-[22px] font-bold">
              Verify identity & connect your bank account to get paid
            </h6>
            <p className="text-graySlate text-[16px]">
              <span>
                Learn more about how{" "}
                <a
                  href="https://intercom.help/zoee/en/articles/8623117-how-does-zoee-protect-your-information-on-stripe"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primaryBlue font-bold"
                >
                  Zoee protects your payment information
                </a>
              </span>{" "}
              and{" "}
              <span>
                <a
                  href="https://intercom.help/zoee/en/articles/8187172-how-do-i-get-paid-in-zoee#h_112090f95a"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primaryBlue font-bold"
                >
                  fees
                </a>
              </span>
              .
            </p>
          </div>
          {/* TODO reenable if we decide to later; it would need to launch Verify Identity / Retrieve Identity / Connect Bank Account based on account status */}
          {/* <div>
                    <button
                        className="btn-primary btn-blue md:w-auto w-[128px]"
                        onClick={getOnboardingLink}
                    >
                        Get Started
                    </button>
                </div> */}
        </div>
        <div className="bg-grayCloud flex w-full flex-col gap-[24px] rounded-[16px] px-[8px] py-[24px] md:gap-[32px] md:px-[40px] md:py-[40px]">
          <div className="flex w-full items-center justify-between">
            <div className="mb-[12px] flex flex-col gap-[8px] md:mb-0">
              <h2 className="flex items-center text-[22px] font-bold text-black md:text-[32px]">
                <span
                  className="flex h-[32px] w-[32px] items-center
                                justify-center rounded-full bg-white text-[22px] text-black"
                >
                  2
                </span>
                &nbsp; steps to get paid
              </h2>
            </div>
            <SvgPoweredByStripe />
          </div>
          <div className="flex flex-col items-center justify-between gap-[8px] md:flex-row md:gap-[24px]">
            {syncAccountSteps.map((step: any, idx: number) => (
              <div
                key={idx}
                className="card-hover-shadow relative flex min-h-[180px] w-full flex-col items-center
                            justify-between gap-[16px] rounded-[12px] bg-white px-[32px] py-[30px] md:h-[265px] md:px-[14px]"
              >
                <h5 className="text-graySlate absolute left-[24px] top-[24px] text-base font-bold">
                  {step.number}
                </h5>
                <div>
                  {/* Hide image when in failed state to make room for failure message */}
                  {step.number === "01" &&
                  connectAccountData.identity_status === "requires_input" &&
                  connectAccountData.identity_last_error ? (
                    <></>
                  ) : (
                    <div className="mb-[8px] flex flex-col items-center">
                      {step.image}
                    </div>
                  )}
                  <div className="flex max-w-[326px] flex-col items-center gap-[4px]">
                    <h3 className="text-center text-base font-bold lg:text-[16px]">
                      {step.title}
                    </h3>
                    {(step.number === "01" &&
                      connectAccountData.identity_status === "incomplete") ||
                    step.number === "02" ? (
                      <p className="text-graySlate text-center text-base md:text-base">
                        {step.content}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* Stripe Identity verify step */}
                {step.number === "01" && (
                  <StripeVerifyIdentityButton
                    connectAccountData={connectAccountData}
                  />
                )}
                {/* Stripe connect bank account step */}
                {step.number === "02" && (
                  <>
                    {connectAccountData?.setup_status === "incomplete" ? (
                      <button
                        onClick={() => setShowTakeMeToStripeModal(true)}
                        disabled={
                          !(connectAccountData.identity_status === "verified")
                        }
                        className={`${
                          connectAccountData.identity_status === "verified"
                            ? "text-blueVibrant"
                            : "text-graySlate"
                        } text-base font-bold`}
                      >
                        Get Started
                      </button>
                    ) : (
                      <></>
                    )}
                    {connectAccountData?.setup_status === "pending" ? (
                      <button
                        onClick={() => setShowTakeMeToStripeModal(true)}
                        disabled={
                          !(connectAccountData.identity_status === "verified")
                        }
                        className={`${
                          connectAccountData.identity_status === "verified"
                            ? "text-yellow"
                            : "text-graySlate"
                        } text-base font-bold`}
                      >
                        Fix Banking Details
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showTakeMeToStripeModal && (
          <TakeMeToStripeModal
            setShowTakeMeToStripeModal={setShowTakeMeToStripeModal}
            onClickCallback={getOnboardingLink}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default PaymentsSetupInstructions;
