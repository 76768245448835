import React from "react"
import PortraitVideoPreview from "./PortraitVideoPreview"
import AddPortraitVideoButton from "./AddPortraitVideoButton"
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { ProfileRequests } from "../../../api/app.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import Loader from "../../ui/loader"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { SvgDraggable } from "../../icons"

interface Props {
  post?: any
  service?: any
}

const PortraitVideoDeck: React.FC<Props> = ({ post, service }) => {

  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )
  const { renderError, hideAlert } = React.useContext(CommonFunctionCtx)
  const [videos, setVideos] = React.useState<any[]>([])
  const newVideos = [...videos]
  const [loadingVideos, setLoadingVideos] = React.useState<boolean>(false)
  const [orderedVideos, setOrderedVideos] = React.useState<any[]>(newVideos)

  const getVideos = () => {
    setLoadingVideos(true)
    ProfileRequests.getCoachProfileVideos({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data) => {
        setVideos(data.videos)
        setLoadingVideos(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setLoadingVideos(false)
      })
  }

  React.useEffect(() => {
    getVideos()
  }, [coachPublicProfileData])
  
  React.useEffect(() => {
    setOrderedVideos(videos)
  }, [videos])
  
  const reorderProfileVideos = (ids: number[]) => {
    console.log('loading...')
    ProfileRequests.reorderProfileVideos({
      video_ids: ids,
    })
      .then(() => {
      console.log('success')
        setTimeout(() => hideAlert(), 5000);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message)
        setTimeout(() => hideAlert(), 5000)
      });
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const items = Array.from(orderedVideos)
    
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    setOrderedVideos(items);

    items.forEach((item: any, index) => {
        console.log(`Update order -> id: ${item.id}, order: ${index}, title: ${item.title}`)
      })

    reorderProfileVideos(items.map((item: any) => item.id))
  };
  console.log('videos', orderedVideos)
  return (
    <>
      <div className="w-full mx-auto flex flex-wrap items-center justify-start gap-[16px] -mt-0 md:-mt-[35px]">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="profileVideos">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full flex items-center justify-start gap-[21px] flex-wrap"
              >
                {loadingVideos ? (
                  <Loader />
                ) : (
                  <>
                      <AddPortraitVideoButton numOfVideos={videos.length} />
                    {orderedVideos?.map((video, index) => (
                      <Draggable
                        key={video.id} 
                        draggableId={String(video.id)} 
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="md:w-[300px] h-[462px] mt-[35px]" 
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              opacity: snapshot.isDragging ? 0.5 : 1,
                            }}
                          >
                              <div {...provided.dragHandleProps} className="px-2 py-4 opacity-50 hover:opacity-100 transition-all inline-flex">
                                <SvgDraggable />
                              </div>
                            <PortraitVideoPreview
                              video={video}
                              showHoverEffect={true}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  )
}

export default PortraitVideoDeck
