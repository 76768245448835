import * as ReactDOMClient from "react-dom/client";
import App from "./components/app/App";
import { BrowserRouter } from "react-router-dom";
import "../src/css/index.css";
import "react-image-crop/dist/ReactCrop.css";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { HelmetProvider } from "react-helmet-async";
import { UserContextProvider } from "./context/userContext";
import { CommonFunctionProvider } from "./context/commonFunctionContext";
import { NotificationCtxProvider } from "./context/notificationContext";
import { SubscriptionProvider } from "./context/subscriptionContext";
import { LoadingProvider } from "./context/loadingContext";
import { AuthProvider } from "./components/auth/context";
import { IntercomProvider } from "react-use-intercom";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = ReactDOMClient.createRoot(rootElement);

root.render(
  <BrowserRouter>
    <HMSRoomProvider>
      <HelmetProvider>
        <LoadingProvider>
          <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM || ""}
            autoBoot
          >
            <UserContextProvider>
              <CommonFunctionProvider>
                <AuthProvider>
                  <SubscriptionProvider>
                    <NotificationCtxProvider>
                      <App />
                    </NotificationCtxProvider>
                  </SubscriptionProvider>
                </AuthProvider>
              </CommonFunctionProvider>
            </UserContextProvider>
          </IntercomProvider>
        </LoadingProvider>
      </HelmetProvider>
    </HMSRoomProvider>
  </BrowserRouter>,
);
