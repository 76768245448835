import * as React from "react";
import landingDesktop from "../../../assets/images/contact-landing-desktop.webp";
import { useLocation } from "react-router-dom";
import ValidationWarning from "../../../components/marketing/uiux/ValidationWarning";
import { MarketingRequests } from "../../../api/public.service";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import { SvgCheckCircle } from "../../../components/icons/";

const ContactPage: React.FC = () => {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState<boolean>(false);
  const [validationWarning, setValidationWarning] = React.useState<
    string | null
  >(null);

  const { pathname } = useLocation();

  useScrollToTop(pathname);

  interface FormData {
    name: string | null;
    email: string | null;
    message: string | null;
  }

  const [contactFormData, setContactFormData] = React.useState<FormData>({
    name: null,
    email: null,
    message: null,
  });

  const submitContactForm = async (data: FormData = contactFormData) => {
    if (
      !contactFormData.name ||
      !contactFormData.email ||
      !contactFormData.message
    ) {
      console.error("Form incomplete");
      return;
    } else {
      MarketingRequests.submitContactForm({
        name: data?.name,
        email: data?.email,
        message: data?.message,
      })
        .then((data) => {
          // console.log(data);
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  };

  const validateEmail = (email: string) => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat);
  };

  const showValidationWarning = (text: string) => {
    setValidationWarning(text);
    setTimeout(() => {
      setValidationWarning(null);
    }, 2000);
  };

  const handleSubmitClick = (e: React.FormEvent<EventTarget>) => {
    const isValidEmail = validateEmail(contactFormData.email || "");
    if (!isValidEmail) {
      showValidationWarning("Please enter a valid email address");
    } else if (!contactFormData.name) {
      showValidationWarning("Please enter a name");
    } else if (!contactFormData.message) {
      showValidationWarning("Please enter a message");
    } else {
      e.preventDefault();
      submitContactForm()
        .then((data) => {
          setContactFormData({ name: null, email: null, message: null });
          setIsSuccessfullySubmitted(true);
        })
        .catch((err) => {
          showValidationWarning(
            "Form failed to submit. Please check your internet connection and try again.",
          );
          console.error(err);
        });
    }
  };

  return (
    <div
      className="relative mx-auto mt-[88px] max-w-screen-2xl overflow-hidden bg-cover"
      style={{
        backgroundImage: `url(${landingDesktop})`,
      }}
    >
      <div className="relative z-10 mb-[96px] flex min-w-full max-w-[600px] flex-col px-[24px] pt-[56px] md:px-[40px] lg:flex-row lg:gap-[48px] xl:px-[80px] ">
        <div className="md:pb-[32px] lg:pt-[64px]">
          <h2 className="mb-[32px] text-[54px] font-bold leading-[110%] md:mb-[16px] xl:text-[88px]">
            CONTACT US
          </h2>
          <div className="hidden md:block">
            <h5 className="text-[24px] font-bold">hello@zoee.com</h5>
            <h5 className="text-[24px] font-bold">877-FOR-ZOEE</h5>
            <h5 className="text-[18px] font-bold">(877-367-9633)</h5>
          </div>
        </div>
        {isSuccessfullySubmitted ? (
          <div className="mx-auto flex min-w-[622px] flex-col items-center justify-center gap-2 rounded-[24px] bg-white px-[24px] pb-[60px] pt-[56px] shadow-md md:w-[622px] md:px-[64px] ">
            <SvgCheckCircle />
            <p className="max-w-[400px] text-center font-semibold">
              Thank you for reaching out! Keep an eye on your inbox for our
              response.
            </p>
          </div>
        ) : (
          <div className="relative mx-auto md:min-w-[622px]">
            {validationWarning && (
              <div className="absolute bottom-[220px] left-0 right-0 mx-auto text-center">
                <ValidationWarning text={validationWarning} />
              </div>
            )}
            <form className="flex flex-col gap-6 rounded-xl bg-white px-[24px] pb-[60px] pt-[56px] shadow-md md:px-[64px] ">
              <Input
                label="Name"
                placeholder="Your name"
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    name: e.target.value,
                  })
                }
                value={contactFormData.name || ""}
                id="name"
                type="text"
                required
              />
              <Input
                label="Email"
                placeholder="Your email"
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    email: e.target.value,
                  })
                }
                value={contactFormData.email || ""}
                id="email"
                type="email"
                required
              />
              <Textarea
                onChange={(e) =>
                  setContactFormData({
                    ...contactFormData,
                    message: e.target.value,
                  })
                }
                value={contactFormData.message || ""}
                id="message"
                placeholder="Your message"
                required
                rows={10}
              />
              <Button
                onClick={handleSubmitClick}
                className="w-full"
                size="lg"
                type="button"
              >
                Send
              </Button>
              <p className="text-graySlate text-base">
                Zoee does not share any personal information. By submitting your
                email address, you may also receive email offers and updates on
                Zoee products and services. You may unsubscribe at any time.
              </p>
            </form>
          </div>
        )}
        <div className="mt-[64px] md:hidden">
          <h5 className="text-[24px] font-semibold">hello@zoee.com</h5>
          <h5 className="text-[24px] font-bold">877-FOR-ZOEE</h5>
          <h5 className="text-[18px] font-bold">(877-367-9633)</h5>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
