import React from "react";
import ContactAvatar from "../cards/ContactAvatar";
import moment from "moment";

type Props = {
    nameOrEmail: string;
    recipientId: string | null;
    hasAvatar?: boolean;
    userColor?: string;
    status: string;
    expiresAt: string;
    earningsAmount: number | null;
};

const ReferralItem: React.FC<Props> = ({
    nameOrEmail,
    recipientId,
    hasAvatar,
    userColor,
    status,
    expiresAt,
    earningsAmount,
}) => {
    const emailOnlyContactObject = {
        user_id: 0,
        contact_name: nameOrEmail,
        has_avatar: false,
        user_color: "lightPurple",
    };

    const fullContactObject = {
        user_id: recipientId,
        contact_name: nameOrEmail,
        has_avatar: hasAvatar,
        user_color: userColor,
    };

    const expiresAtMoment = moment(expiresAt);
    const today = moment();
    const expiresAtDays = expiresAtMoment.diff(today, "days");

    const getStatusTag = () => {
        switch (status) {
            case "active":
                return (
                    <div className="flex items-center justify-end gap-[16px]">
                        {/* TODO add Send reminder button */}
                        <div className="bg-paleCyanBlue bg-opacity-25 rounded-[4px] px-[8px] py-[4px] flex items-center justify-center gap-[2px]">
                            <p className="text-base text-blueVibrant font-bold">Sent Invite</p>
                            <p className="mt-[1px] text-base text-graySlate font-bold">
                                Expires {expiresAtDays} days
                            </p>
                        </div>
                    </div>
                );
            case "expired":
                return (
                    <div className="flex items-center justify-end gap-[16px]">
                        <div className="bg-vividRed bg-opacity-20 rounded-[4px] px-[8px] py-[4px] flex items-center justify-center gap-[2px]">
                            <p className="text-base text-vividRed font-bold">Expired</p>
                        </div>
                    </div>
                );
            case "trial":
                return (
                    <div className="flex items-center justify-between gap-[16px]">
                        <p className="text-base text-graySlate font-bold">Trial Membership</p>
                        <div className="bg-paleCyanBlue bg-opacity-25 rounded-[4px] px-[8px] py-[4px] flex items-center justify-center gap-[2px]">
                            <p className="text-base text-blueVibrant font-bold">Signed Up</p>
                        </div>
                    </div>
                );
            case "pending":
                return (
                    <div className="flex items-center justify-between gap-[16px]">
                        <p className="text-base text-graySlate font-bold">Annual Subscription</p>
                        <div className="bg-yellow bg-opacity-30 rounded-[4px] px-[8px] py-[4px] flex items-center justify-center gap-[2px]">
                            <p className="text-base text-luminousAmber font-bold">Pending</p>
                        </div>
                    </div>
                );
            case "subscribed":
                return (
                    <div className="flex items-center justify-between gap-[16px]">
                        {earningsAmount && (
                            <p className="text-base font-bold">${earningsAmount}</p>
                        )}
                        <div className="bg-green bg-opacity-20 rounded-[4px] px-[8px] py-[4px] flex items-center justify-center gap-[2px]">
                            <p className="text-base text-green font-bold">Signed Up</p>
                        </div>
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <div className="w-full flex md:flex-row flex-col md:items-center md:justify-between gap-[7px] md:gap-[4px]">
            <div className="flex items-center gap-[9px]">
                {recipientId ? (
                    <ContactAvatar
                        contact={fullContactObject}
                        width={40}
                        height={40}
                        bold={false}
                    />
                ) : (
                    <ContactAvatar
                        contact={emailOnlyContactObject}
                        width={40}
                        height={40}
                        bold={false}
                    />
                )}
                <p>{nameOrEmail}</p>
            </div>

            <div>{getStatusTag()}</div>
        </div>
    );
};

export default ReferralItem;
