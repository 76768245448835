import React from "react";
import IndividualDetails from "./IndividualDetails";
import GroupDetails from "./GroupDetails";
import CloseModal from "../CloseModal";
import { Button } from "../ui/button";
import {
  SvgInformationCircle,
  SvgMultiUserCheck,
  SvgUserCheck,
} from "../icons";

interface Props {
  setGroupVsIndividualSelector: any;
  setCreateService: any;
  setIsGroupService: any;
}

const GroupVsIndividualSelector: React.FC<Props> = ({
  setGroupVsIndividualSelector,
  setCreateService,
  setIsGroupService,
}) => {
  const [showIndividualDetails, setShowIndividualDetails] =
    React.useState<boolean>(false);
  const [showGroupDetails, setShowGroupDetails] =
    React.useState<boolean>(false);

  const selectIndividualType = () => {
    setCreateService(true);
    setIsGroupService(false);
    setGroupVsIndividualSelector(false);
  };

  const selectGroupType = () => {
    setCreateService(true);
    setIsGroupService(true);
    setGroupVsIndividualSelector(false);
  };

  return (
    <>
      {showIndividualDetails && (
        <IndividualDetails
          setShowIndividualDetails={setShowIndividualDetails}
        />
      )}
      {showGroupDetails && (
        <GroupDetails setShowGroupDetails={setShowGroupDetails} />
      )}
      <div
        className="fixed top-0 left-0 w-screen h-full flex items-center
                justify-center zoee-backdrop-filter md:z-[801] z-[1009]"
      >
        <div
          className="w-[900px] max-w-[90%] main-shadow rounded-[16px] p-[44px] flex flex-col items-center gap-[30px]
                    max-h-[95vh] bg-white fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] overflow-y-auto"
        >
          <CloseModal
            callback={() => setGroupVsIndividualSelector(false)}
            styling="absolute top-[16px] right-[16px] sm:top-[24px] sm:right-[24px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          />
          <h2 className="font-bold text-[18px] text-center">
            Will this be an individual or group service?
          </h2>
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-[30px] w-full sm:justify-center">
            <button
              onClick={selectIndividualType}
              className="w-[390px] max-w-full p-[20px] border border-grayMist rounded-[16px]
                            flex flex-col items-center gap-[12px] min-h-[306px] hover:border-blurple justify-center"
            >
              <div className="w-[48px] h-[48px] rounded-[12px] bg-[#F8F8F8] flex items-center justify-center">
                <SvgUserCheck />
              </div>
              <div className="flex items-center gap-[8px]">
                <span className="font-bold">1:1 Coaching Service</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowIndividualDetails(true);
                  }}
                  className="p-2 text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                >
                  <SvgInformationCircle />
                </button>
              </div>
              <Button className="text-md" size={"lg"}>
                Select
              </Button>
            </button>
            <button
              onClick={selectGroupType}
              className="w-[390px] max-w-full p-[20px] border border-grayMist rounded-[16px] relative
                            flex flex-col items-center gap-[12px] min-h-[306px] hover:border-blurple justify-center"
            >
              <span
                className="absolute bottom-[16px] left-[16px] md:bottom-[100%] md:left-[32px] md:translate-y-[50%]
                                text-white font-bold flex items-center justify-center
                                bg-vividOrange px-[16px] py-[4px] rounded-[100px] z-[10]"
              >
                Beta
              </span>
              <div className="w-[48px] h-[48px] rounded-[12px] bg-[#F8F8F8] flex items-center justify-center">
                <SvgMultiUserCheck />
              </div>
              <div className="flex items-center gap-[8px]">
                <span className="font-bold">Group Coaching Service</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowGroupDetails(true);
                  }}
                  className="p-2 text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]"
                >
                  <SvgInformationCircle />
                </button>
              </div>
              <Button className="text-md" size={"lg"}>
                Select
              </Button>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupVsIndividualSelector;
