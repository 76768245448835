"use client";

import { useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import { Hero } from "../../components/marketing/home/hero";
import { ThriveTogether } from "../../components/marketing/home/thrive-together";
import { ShowcaseVideo } from "../../components/marketing/home/showcase-video";
import { FeaturesSelect } from "../../components/marketing/home/FeaturesSelect";
import { CeoQuote } from "../../components/marketing/home/ceo-quote";
import { CoachTestimonials } from "../../components/marketing/home/coach-testimonials";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import useScrollToTop from "../../hooks/useScrollToTop";
import SubscribeToNewsletterForm from "../../components/marketing/SubscribeToNewsletterForm";
import { FeaturedArticles } from "../../components/marketing/home/FeaturedArticles";
import { useSearchParams } from "react-router-dom";
import { useAuthModal } from "../../components/auth/use-auth-modal";

const HomePage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setPopupNotification, setShowAuthModal } = React.useContext(
    CommonFunctionCtx,
  );
  const { showRegister } = useAuthModal();
  const [searchParams, setSearchParams] = useSearchParams();

  const checkUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const reset = params.get("reset");
    const emailParams = params.get("email");
    const phoneParams = params.get("phone");
    const firstNameParams = params.get("firstname");
    const lastNameParams = params.get("lastname");
    const signupType = params.get("signuptype");
    const login = params.get("login");
    const message = location?.state?.message;

    if (message && (message === "no-code" || message === "wrong-code")) {
      setPopupNotification({
        show: true,
        title: "Invalid Email Verification!",
        message:
          "Oops! Looks like your email verification link didn't work properly. Try sending a new verification link by clicking the 'Verify Email' button on this page.",
        callback: null,
      });
      window.history.replaceState(null, "", window.location.pathname);
    }
    if (login) {
      setShowAuthModal(true);
    }
    if (message && message === "success") {
      setPopupNotification({
        show: true,
        title: "Email Verified!",
        message:
          "Your email address is now verified with Zoee. You can change your primary email address whenever you'd like, but will need to verify it after each change.",
        callback: null,
      });
      window.history.replaceState(null, "", window.location.pathname);
    }

    if (reset && reset === "true") {
      setPopupNotification({
        show: true,
        title: "Reset Password Link Sent!",
        message:
          "An email has been sent to the email address associated with your account. Please check your email for a link to reset your password. 🎉",
        callback: null,
      });
      window.history.replaceState(null, "", window.location.pathname);
    } else if (reset && reset === "complete") {
      setPopupNotification({
        show: true,
        title: "Reset Password Complete!",
        message: "You can now log in with your email and your new password. 🎉",
        callback: null,
      });
      window.history.replaceState(null, "", window.location.pathname);
    }

    if (emailParams && phoneParams && firstNameParams && lastNameParams) {
      showRegister({
        accountType: "coach",
        email: emailParams,
        phone: phoneParams,
        firstName: firstNameParams,
        lastName: lastNameParams,
      });
      if (signupType) {
        localStorage.setItem("signupType", signupType);
      }
    }

    if (signupType === "member" && emailParams) {
      showRegister({ accountType: "member", email: emailParams });
    }
  };

  useScrollToTop();

  React.useEffect(() => {
    checkUrlParams();
  }, []);

  React.useEffect(() => {
    const coachRegisterParam = searchParams.get("coachRegister");
    if (coachRegisterParam === "true") {
      showRegister({ accountType: "coach" });
    }
  }, []);

  React.useEffect(() => {
    if (location?.state?.storageAvailable === false) {
      setPopupNotification({
        show: true,
        title: "Oh no! Browser Storage is Disabled",
        message:
          "You'll need to enable cookies in your browser to login or sign up for the Zoee application.",
      });
      navigate(location.pathname, { replace: true });
    }
  }, []);

  return (
    <>
      <Hero />
      <ThriveTogether />
      <ShowcaseVideo />
      <FeaturesSelect />
      <CeoQuote />
      <CoachTestimonials />
      <FeaturedArticles />
      <SubscribeToNewsletterForm />
    </>
  );
};

export default HomePage;
