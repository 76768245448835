import React from "react";
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext";
import { SvgCredentials, SvgEducation } from "../icons";

interface Props {}

const PublicCredentials: React.FC<Props> = (props: any) => {
  const _ = require("lodash");
  const { coachPublicProfileData } = React.useContext(
    CoachPublicProfileContext
  );

  return (
    <div className="w-[800px] max-w-[90%] flex items-start justify-between">
      <div className="flex flex-col items-start w-[80%] gap-[40px]">
        {/* CREDENTIALS SECTION */}
        <div className="flex flex-col items-start">
          <h5 className="font-bold flex items-center gap-[8px] mb-[12px]">
            <SvgCredentials
              fill="black"
              className="w-[20px]"
            />
            Credentials
          </h5>
          {/* LOOP OVER CREDENTIALS ARRAY FROM CREDENTIALS JSON */}
          {coachPublicProfileData?.credentials?.credentials?.length > 0 ? (
            <>
              {coachPublicProfileData?.credentials?.credentials?.map(
                (credential: any, index: number) => (
                  <div
                    key={index}
                    className="flex flex-col items-start"
                  >
                    {/* NAME */}
                    <div className="font-bold text-[16px]">
                      {credential?.name}
                    </div>
                    {/* ISSUING ORGANIZATION */}
                    <div className="font-semibold text-[16px] text-graySlate">
                      {credential?.issuingOrganization}
                    </div>
                    <div className="flex flex-col gap-[4px] text-[16px] text-graySlate">
                      {/* ISSUE DATE */}
                      {credential?.issueMonth && credential?.issueYear && (
                        <>
                          <span>
                            Issued {_.capitalize(credential?.issueMonth)}{" "}
                            {credential?.issueYear}
                          </span>
                          {/* EXPIRATION DATE */}
                          {credential?.expireMonth &&
                            credential?.expireYear && (
                              <span>
                                Expires {_.capitalize(credential?.expireMonth)}{" "}
                                {credential?.expireYear}
                              </span>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                )
              )}
            </>
          ) : (
            <div className="text-graySlate">No credentials listed</div>
          )}
        </div>

        {/* EDUCATION SECTION */}
        <div className="flex flex-col items-start">
          <h5 className="font-bold flex items-center gap-[8px] mb-[12px]">
            <SvgEducation
              fill="black"
              className="w-[20px]"
            />
            Education
          </h5>
          {/* LOOP OVER EDUCATION ARRAY FROM CREDENTIALS JSON */}
          {coachPublicProfileData?.credentials?.education?.length > 0 ? (
            <>
              {coachPublicProfileData?.credentials?.education?.map(
                (education: any, index: number) => (
                  <div
                    key={index}
                    className="flex flex-col items-start"
                  >
                    {/* SCHOOL */}
                    <div className="font-bold text-[16px] text-grayCharcoal">
                      {education?.school}
                    </div>
                    {/* DEGREE / FIELD OF STUDY */}
                    <div className="font-semibold text-[16px] text-graySlate">
                      {education?.degree} - {education?.fieldOfStudy}
                    </div>
                    <div className="flex items-center gap-[4px] text-[16px] text-graySlate">
                      {/* YEAR STARTED */}
                      {education?.startYear && (
                        <>
                          <span>{education.startYear}</span>
                          <span>-</span>
                          {/* YEAR FINISHED */}
                          {education?.endYear && (
                            <span>{education.endYear}</span>
                          )}
                        </>
                      )}
                    </div>
                    {/* Activities and societies */}
                    {education?.activitiesAndSocieties && (
                      <div className="font-semibold text-[16px] text-graySlate">
                        Activities and societies:{" "}
                        {education?.activitiesAndSocieties}
                      </div>
                    )}
                  </div>
                )
              )}
            </>
          ) : (
            <div className="text-graySlate">No education listed</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicCredentials;
