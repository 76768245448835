import React from "react";
import SyncBankInstructions from "./SyncBankInstructions";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { ClientPaymentRequests } from "../../api/app.service";
import caution from "../../assets/images/identity-caution.png";
import failed from "../../assets/images/identity-failed.png";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import { SvgCheckCircleSolid, SvgInProgress } from "../icons";

type Props = {
  address?: string;
  bankInfo?: Object;
  signatoryInfo?: Object;
  connectAccountData: any;
};

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

const Banking: React.FC<Props> = ({
  address,
  bankInfo,
  signatoryInfo,
  connectAccountData,
}) => {
  const { account_data } = connectAccountData;
  const { user } = React.useContext(UserCtx);
  const { renderError, hideAlert } =
    React.useContext(CommonFunctionCtx);
  const [isStripeRequestProcessing, setIsStripeRequestProcessing] =
    React.useState<boolean>(false);

  const { startLoading, stopLoading } = useLoading();

  const getStripeVerificationSession = async () => {
    const stripe = await stripePromise;
    if (user) {
      setIsStripeRequestProcessing(true);
      startLoading();
      ClientPaymentRequests.getStripeVerificationSession({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          if (stripe) {
            stripe.verifyIdentity(data.stripe_client_secret);
          }
          setIsStripeRequestProcessing(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setIsStripeRequestProcessing(false);
        }).finally(() => {
          stopLoading();
        });
      setTimeout(() => hideAlert(), 5000);
    }
  };

  const retrieveStripeVerificationSession = async () => {
    const stripe = await stripePromise;
    if (user) {
      startLoading();
      setIsStripeRequestProcessing(true);
      ClientPaymentRequests.retrieveStripeVerificationSession({
        verification_session_id: connectAccountData.verification_id,
      })
        .then((data) => {
          if (stripe) {
            stripe.verifyIdentity(data.stripe_client_secret);
          }
          setIsStripeRequestProcessing(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setIsStripeRequestProcessing(false);
        }).finally(() => {
          stopLoading();
        });
    }
  };

  const getOnboardingLink = () => {
    if (user) {
      startLoading();
      ClientPaymentRequests.getStripeConnectOnboardingLink({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          const link = data.link;
          window.location.href = link;
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        }).finally(() => {
          stopLoading();
        });
      setTimeout(() => hideAlert(), 5000);
    }
  };

  return (
    <>
      {/* Identity Section */}
      <div
        className={`${
          connectAccountData.setup_status === "complete"
            ? "md:w-[760px] w-[361px]"
            : "w-auto mb-[32px]"
        } mx-auto p-[24px] md:p-[48px] rounded-[16px] bg-white box-shadow-1 flex flex-col`}
      >
        <h3 className="text-[28px] font-semibold mb-[32px]">Identity</h3>
        <>
          {(!connectAccountData.identity_status ||
            connectAccountData.identity_status === "incomplete") && (
            <div className="flex items-center justify-between">
              <div className="flex gap-[12px]">
                <img
                  src={caution}
                  alt="caution"
                  className="w-[40px] h-[40px]"
                />
                <div className="flex flex-col font-bold">
                  <p className="text-graySlate text-[16px]">Status:</p>
                  <p className="">Incomplete</p>
                </div>
              </div>
              <Button
                onClick={getStripeVerificationSession}
                role="link"
                disabled={isStripeRequestProcessing}
                className="btn-primary btn-blue"
              >
                Get Started
              </Button>
            </div>
          )}

          {connectAccountData.identity_status === "requires_input" && (
            <>
              {/* Stripe Identity application failed state */}
              {!connectAccountData.identity_last_error ? (
                <div className="flex items-center justify-between">
                  <div className="flex gap-[12px]">
                    <img
                      src={caution}
                      alt="caution"
                      className="w-[40px] h-[40px]"
                    />
                    <div className="flex flex-col font-bold">
                      <p className="text-graySlate text-[16px]">Status:</p>
                      <p className="">Incomplete</p>
                    </div>
                  </div>
                  <Button
                    onClick={retrieveStripeVerificationSession}
                    role="link"
                    disabled={isStripeRequestProcessing}
                    className="btn-primary btn-blue"
                  >
                    Get Started
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row gap-[12px] items-center justify-between">
                  <div className="flex flex-col md:flex-row md:gap-[40px] gap-[20px] items-center">
                    <div className="flex gap-[12px]">
                      <img
                        src={failed}
                        alt="failed"
                        className="w-[40px] h-[40px]"
                      />
                      <div className="flex flex-col font-bold">
                        <p className="text-graySlate text-[16px]">Status:</p>
                        <p className="">Failed</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="text-vividRed text-[16px] md:mb-[4px] mb-[16px]">
                        {connectAccountData.identity_last_error.reason}
                      </p>
                      <p className="text-[16px] text-grayCharcoal">
                        Please try again or contact Zoee support at{" "}
                        <span className="font-bold">support@zoee.com</span>.
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={retrieveStripeVerificationSession}
                    role="link"
                    disabled={isStripeRequestProcessing}
                    className="btn-primary btn-blue min-w-[100px]"
                  >
                    Retry
                  </button>
                </div>
              )}
            </>
          )}

          {connectAccountData?.identity_status === "processing" && (
            <div className="flex flex-col md:flex-row md:gap-[40px] gap-[20px] items-center">
              <div className="flex gap-[12px]">
                <SvgInProgress />
                <div className="flex flex-col font-bold">
                  <p className="text-graySlate text-[16px]">Status:</p>
                  <p className="">Under review</p>
                </div>
              </div>
              <p className="text-[16px] text-graySlate">
                Your application is being reviewed by Stripe. This process may
                take a few minutes.
              </p>
            </div>
          )}

          {connectAccountData?.identity_status === "verified" && (
            <>
              <div className="flex gap-[12px]">
                <SvgCheckCircleSolid />
                <div className="flex flex-col font-bold">
                  <p className="text-graySlate text-[16px]">Status:</p>
                  <p className="">Verified</p>
                </div>
              </div>
            </>
          )}
        </>
        {connectAccountData?.identity_status === "verified" &&
          connectAccountData?.verification_report_data && (
            <div className="mt-[26px] flex flex-col gap-[20px] px-[16px] md:px-[38px] py-[16px] md:py-[22px] border border-grayCloud rounded-[16px]">
              <div className="flex justify-between w-full">
                <h5 className="text-base font-bold text-graySlate">Name:</h5>
                <p className="font-bold">
                  {connectAccountData?.verification_report_data?.first_name}{" "}
                  {connectAccountData?.verification_report_data?.last_name}
                </p>
              </div>
              <div className="flex justify-between w-full">
                <h5 className="text-base font-bold text-graySlate">Address:</h5>
                {connectAccountData?.verification_report_data?.address ? (
                  <div className="text-right">
                    <p className="font-bold">
                      {
                        connectAccountData?.verification_report_data?.address
                          ?.line1
                      }
                    </p>
                    {connectAccountData?.verification_report_data?.address
                      ?.line2 && (
                      <p className="font-bold">
                        {
                          connectAccountData?.verification_report_data?.address
                            ?.line2
                        }
                      </p>
                    )}
                    <p className="font-bold">
                      {
                        connectAccountData?.verification_report_data?.address
                          ?.city
                      }
                    </p>
                    <p className="font-bold">
                      {
                        connectAccountData?.verification_report_data?.address
                          ?.state
                      }
                      {", "}
                      {
                        connectAccountData?.verification_report_data?.address
                          ?.postal_code
                      }{" "}
                      {
                        connectAccountData?.verification_report_data?.address
                          ?.country
                      }
                    </p>
                  </div>
                ) : (
                  <p className="font-bold">No address listed</p>
                )}
              </div>
              <div className="flex justify-between w-full">
                <h5 className="text-base font-bold text-graySlate">Type:</h5>
                <p className="font-bold">
                  {_.capitalize(
                    connectAccountData?.verification_report_data?.type
                  )}
                </p>
              </div>
            </div>
          )}
      </div>
      {/* Banking Section */}
      {connectAccountData?.setup_status === "complete" ? (
        <div className="md:w-[760px] md:min-h-[734px] w-[361px] mx-auto p-[24px] md:p-[48px] rounded-[16px] bg-white box-shadow-1 flex flex-col mt-[16px] md:mt-[32px] mb-[80px]">
          <h3 className="font-bold md:font-semibold text-[22px] md:text-[28px] mb-[32px]">
            Payment Details
          </h3>
          <h5 className="text-base text-graySlate font-bold mb-[16px]">
            Bank Account
          </h5>
          <div className="mb-[32px] p-[16px] md:p-[32px] border border-grayMist rounded-[16px]">
            <div className="flex justify-between items-center mb-[24px] gap-[4px]">
              <div className="flex items-center">
                {/* TODO Add bank account icon */}
                {/* <div className="h-[40px] w-[40px] bg-primaryPurple rounded-full mr-[16px]"></div> */}
                <h4 className="text-[16px] md:text-[18px] font-bold">
                  {account_data?.bank_name || "Bank Name Unknown"}
                </h4>
              </div>
              <p className="text-base text-graySlate">Bank Account</p>
            </div>
            <div className="mb-[8px] flex items-center justify-between bg-hover px-[24px] py-[12px] rounded-[12px]">
              <p className="text-base text-grayCharcoal font-bold">
                Account Number
              </p>
              <p className="text-base font-bold text-black">
                {account_data?.account_last4
                  ? `XXXXXX${account_data?.account_last4}`
                  : "No account number"}
              </p>
            </div>
            <div className="mb-[24px] flex justify-between items-center bg-hover px-[24px] py-[12px] rounded-[12px]">
              <p className="text-base text-grayCharcoal font-bold">
                Routing Number
              </p>
              <p className="text-base font-bold text-black">
                {account_data?.routing_number
                  ? account_data?.routing_number
                  : "No routing number"}
              </p>
            </div>
            <div className="flex justify-end items-center">
              <Button
                className="h-[32px] px-[20px] py-[4px] w-auto text-md"
                onClick={getOnboardingLink}
              >
                Manage
              </Button>
            </div>
          </div>
          <div>
            <p className="mb-[8px] text-base text-graySlate font-bold">
              Name Signatory
            </p>
            {/* <p className="text-[16px] text-black font-bold">Sole Propietorship</p> */}
            <p className="text-[16px] text-black font-bold">
              {account_data?.business_name ||
                account_data?.dashboard_name ||
                "No business name on file"}
            </p>
            <p className="text-[16px] text-black font-bold">
              {account_data?.business_email ||
                account_data?.email ||
                "No business email on file"}
            </p>
            <p className="text-[16px] text-black font-bold">
              {account_data?.business_url || "No business website url on file"}
            </p>
          </div>
        </div>
      ) : (
        <SyncBankInstructions connectAccountData={connectAccountData} />
      )}
    </>
  );
};

export default Banking;
