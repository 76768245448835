import React from "react";
import { EmailLogin } from "../../api/auth.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { type View } from "./AuthModal";
import { Button } from "../ui/button";
import useLoading from "../../hooks/useLoading";
import { SvgArrowLeft, SvgWarning } from "../icons";

interface Props {
    isModalVariant?: boolean;
    setView: React.Dispatch<React.SetStateAction<View>>
}

const ForgotPassword: React.FC<Props> = ({ isModalVariant, setView }) => {
    const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
    const [values, setValues] = React.useState<any>({
        email: "",
    });
    const [formErrors, setFormErrors] = React.useState<any>({});
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    
    const { startLoading, stopLoading } = useLoading();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setValues({ ...values, [target.name]: target.value });
    };

    const validateForm = (values: any) => {
        const errors: any = {};
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        const match = values.email.match(mailformat);
        (!values.email || !match) && (errors.email = "Valid email is required");
        return errors;
    };

    React.useEffect(() => {
        if (isSubmit && Object.keys(formErrors).length === 0) {
            startLoading();
            setIsSubmit(false);
            EmailLogin.resetPasswordSendLink({ email: values.email })
                .then((data) => {
                    if (data.message === "complete") {
                        stopLoading();
                        window.location.href = "/home?reset=true";
                    } else {
                        stopLoading();
                    }
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    stopLoading();
                });
                setTimeout(() => hideAlert(), 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormErrors(validateForm(values));
        setIsSubmit(true);
    };

    const handleBackToSignInClick = () => {
        setView("email")
    };

    return (
        <div className={`${isModalVariant ? "" : "pb-[200px] mt-[190px]"} flex items-center flex-col justify-start`}>
            <div className="font-semibold text-[28px] mb-[24px]">Forgot Password?</div>
            <div className="text-center w-[260px] text-graySlate text-base mb-[40px]">
                To reset your password, enter your login email and we`ll send you instructions
            </div>
            <form action="#" onSubmit={handleSubmit} className="flex flex-col items-center">
                <div className="mb-[24px]">
                    <div className={`default-input ${formErrors.email && "input-error"}`}>
                        <input
                            type="text"
                            placeholder="Email"
                            onChange={handleChange}
                            name="email"
                            autoComplete="off"
                        />
                        <div className="overlay">Email</div>
                    </div>
                    {formErrors.email && (
                        <div className="field-error">
                            <SvgWarning />
                            {formErrors.email}
                        </div>
                    )}
                </div>
                <Button size="lg" type="submit">Send Instructions</Button>
            </form>

            <Button
                onClick={handleBackToSignInClick}
                className="mt-[50px]"
                variant="ghost"
            >
                <SvgArrowLeft className="back-arrow" />
                Sign In
            </Button>
        </div>
    );
};

export default ForgotPassword;
