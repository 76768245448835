import React from "react";
import { SvgCheck } from "../icons";
import { Button } from "../ui/button";

type Props = {
  plan: any
  title: String
  handleChoosePlanClick: (plan:any) => void
};

const SubscriptionPlanCard: React.FC<Props> = ({plan, title, handleChoosePlanClick}) => { 
  const [cardDetailsColor, setCardDetailsColor] = React.useState<string>("");
  const [cardColor, setCardColor] = React.useState<string>("");
  const [paymentPeriod, setPaymentPeriod] = React.useState<string>("");
 
  const assignCardColor = () => {
    if (plan?.recurring_interval === 'annual' && !plan?.is_promotional) {
      setCardDetailsColor('darkPurple')
    }
    if (plan?.recurring_interval === 'month' && !plan?.is_promotional) {
      setCardDetailsColor('blurple')
    }
    if (plan?.amount === 500 && plan?.is_promotional) {
      setCardDetailsColor('mainBlack')
      setCardColor('subscription-cta-gradient')
    }   
  }

  const setPeriod = () => {
    if (plan?.recurring_interval === 'annual' && !plan?.is_promotional) {
      setPaymentPeriod('/annual')
    }
    if (plan?.recurring_interval === 'month' && !plan?.is_promotional) {
      setPaymentPeriod('/month')
    }
    if (plan?.amount === 500 && plan?.recurring_interval_count === 2) {
      setPaymentPeriod('one time cost')
    }
  }

  React.useEffect(() => {
    assignCardColor();
    setPeriod();
  }, [plan]);

  return (
    <div className={`${cardColor} w-[406px] md:h-[540px] flex flex-col px-[32px] md:px-[40px] md:py-[48px] pt-[24px] pb-[32px] rounded-[32px] main-shadow bg-white`}>
      <h2 className="mb-[8px] font-bold md:font-semibold text-[22px] md:text-[28px]">
        {title}
      </h2>
      <p className="mb-[24px] md:mb-[40px] text-graySlate">
        {plan?.stripe_product_description}
      </p>
      <div className="mb-[18px] md:mb-[40px] flex font-bold items-start gap-[4px] leading-none">
        <h4 className="text-[36px] text-grayCharcoal">$</h4>
        <h4 className="text-[80px] -translate-y-[10px]">
          {plan?.amount}
        </h4>
        <h4 className="text-[18px] text-graySlate">{paymentPeriod}</h4>
      </div>
    <div className="flex flex-col gap-[12px]">
      <div className="flex items-center gap-[16px]">
        <SvgCheck stroke="#00D084" />
        <p className="text-base font-bold">
          {plan?.stripe_product_metadata?.feature1}
        </p>
      </div>
      <div className="flex items-center gap-[16px]">
        <SvgCheck stroke="#00D084" />
        <p className="text-base font-bold">
          {plan?.stripe_product_metadata?.feature2}
        </p>
      </div>
      <div className="flex items-center gap-[16px]">
        <SvgCheck stroke="#00D084" />
        <p className="text-base font-bold">
          {plan?.stripe_product_metadata?.feature3}
        </p>
      </div>
      <div className="flex items-center gap-[16px]">
        <SvgCheck stroke="#00D084" />
        <p className="text-base font-bold">
          {plan?.stripe_product_metadata?.feature4}
        </p>
      </div>
      <Button
        onClick={() => handleChoosePlanClick(plan)}
        className={`bg-${cardDetailsColor} mt-[24px] md:mt-[40px] mx-[8px] md:mx-0 text-md`}
        size={"lg"}
      >
        Choose Plan
      </Button>
    </div>
  </div>
  );
};

export default SubscriptionPlanCard;
