import { FC } from "react";
import useScrollToTop from "../../../hooks/useScrollToTop";

const TermsAndConditionsPage: FC = () => {
  useScrollToTop();

  return (
    <div className="mx-auto mt-[88px] max-w-screen-2xl overflow-hidden">
      <div className="bg-grayFlash flex h-[240px] items-center justify-center">
        <h3 className="px-[24px] text-center text-[36px] font-bold md:max-w-[880px]">
          Zoee Terms of Use
        </h3>
      </div>
      <div className="mx-auto mb-[64px] mt-[64px] px-[24px] text-[18px] md:max-w-[880px]">
        <h4 className="font-bold">
          THESE TERMS OF USE, TOGETHER WITH AN ORDER FORM IF YOU ARE A COACH
          (THE “AGREEMENT”) CONSTITUTES A BINDING CONTRACT AND GOVERNS YOUR USE
          OF AND ACCESS TO THE SERVICES BY YOU.
        </h4>
        <div className="h-[16px]" />
        <h4 className="font-bold">
          By using any of the Services, You agree to be bound by this Agreement.
          If You are entering into this Agreement on behalf of a company,
          organization or another legal entity (an “Entity”), You are agreeing
          to this Agreement for that Entity and representing to Company that You
          have the authority to bind such Entity to this Agreement, in which
          case the terms “Subscriber,” “You,” and “Your” shall refer to such
          Entity.
        </h4>
        <div className="h-[32px]" />
        <h2 className="font-bold">1. DEFINITIONS</h2>
        <div className="h-[16px]" />
        <p>
          When used in this Agreement, in addition to the terms defined
          elsewhere in this Agreement, the following terms have the following
          meanings:
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Account:</strong> means an account established for the
          Subscriber under which the Subscriber is granted access to the
          Software Services.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Account Identifier:</strong> means the username, or email
          address and password of the Subscriber required to access the Software
          Services.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Beta Service(s):</strong> means a product, service, or
          functionality provided by Company that may be made available to You to
          use at Your option at no additional charge which is designated as
          beta, non-production, early access, or by similar description.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Coach(es):</strong> means an authorized person provided with
          an Account Identifier associated with a Subscriber account in order to
          provide coaching services to a Member.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Company:</strong> means SoleLife, Inc (d/b/a Zoee), a Delaware
          corporation, or any of its successors or assignees.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Company Technology:</strong> means the Software and other
          technology built by, or developed for Company and incorporated into
          the Software Services to which authorized Subscribers are granted
          access by Company.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Confidential Information:</strong> means all information
          disclosed by You to Company or by Company to You which is in a
          tangible form and labeled “CONFIDENTIAL” (or with a similar marking)
          or which a reasonable person would understand to be confidential given
          the nature of the information or circumstances of disclosure.
          Notwithstanding the foregoing, Confidential Information shall not
          include information that (a) was already known to the receiving Party
          at the time of disclosure by the disclosing Party; (b) was or is
          obtained by the receiving Party by a third party not known by the
          receiving Party to be under an obligation of confidentiality with
          respect to such information; (c) is or becomes generally available to
          the public other than by violation of this Agreement or another valid
          agreement between the Parties; (d) was or is independently developed
          by the receiving Party without use of the disclosing Party’s
          Confidential Information; or (e) was lawfully disclosed the receiving
          Party by a third party under no obligation of confidentiality to the
          other party.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Content:</strong> means the artwork, copy, text, logos,
          designs, keywords, images, videos, audio, URLs and other content
          submitted, registered or created in connection with your use of the
          Software Services.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Customer Data: </strong> means electronic data,
          communications, or other materials submitted to and stored within a
          Service by You.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Member(s):</strong> means an authorized person provided with
          an Account Identifier associated with a Subscriber account in order to
          receive coaching services from a Coach.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Order Form:</strong> means any order form or other contract
          entered into between the Company and a Coach pursuant to which the
          Company grants access to the Software Services to such Coach, whether
          on a trial or paid basis for such access.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Platform:</strong> means the collective system of Software
          Services and Company Technology.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Service(s):</strong> means the products and services that are
          subscribed to by You online, whether on a trial or paid basis, and
          made available online by Us.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Service Plan(s):</strong> means the subscription plan(s) for
          the Software Services to which You subscribe or to which you are
          granted use by the Company.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Software:</strong> means software provided by Company (either
          by download or access through the Internet) that allows Subscribers to
          use any of the functionality in connection with the applicable
          Software Services.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Subscriber:</strong> means a Member and/or Coach, as
          applicable.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Subscription Term:</strong> means the period during which You
          have agreed to subscribe to a Software Services.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>“We,” “Us”</strong> or <strong>“Our”:</strong> means Company
          as defined above.
        </p>
        <div className="h-[16px]" />
        <p>
          <strong>Website(s): </strong> means a website operated by Solelife,
          Inc. including https://www.zoee.com/, as well as all other websites
          that Company operates.
        </p>
        <div className="h-[32px]" />
        <h2 className="font-bold">
          2. GENERAL CONDITIONS; ACCESS TO AND USE OF THE SERVICES
        </h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.1</p>
          <p>
            During the Subscription Term and subject to compliance by You, with
            this Agreement, You have the limited right to access and use a
            Software Service consistent with the Service Plan(s) that You
            subscribe to.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.2</p>
          <p>
            In addition to complying with the other terms, conditions and
            restrictions set forth below in this Agreement, You agree not to (a)
            license, sublicense, sell, resell, rent, lease, transfer, assign,
            distribute, time share or otherwise commercially exploit or make the
            Software Services available to any third party, other than
            authorized Members and in furtherance of Your internal business
            purposes as expressly permitted by this Agreement; (b) copy,
            distribute, modify, adapt, hack or reverse engineer to extract
            software code from the Software Services or Company Technology or
            otherwise attempt to gain unauthorized access to Software Services,
            Company Technology or related systems or networks, or otherwise take
            action inconsistent with Your acknowledgement that title to Company
            Technology, and all Intellectual Property incorporated therein,
            shall remain the sole and exclusive property of Company; or (c)
            falsely imply any sponsorship or association with Company.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.3</p>
          <p>
            In connection with the use of the Software Services, the Subscriber
            agrees to not submit any Content that (i) contains malicious code or
            (ii) collects, reads or stores Member information. You further agree
            that (a) you solely own the Content; and (b) the Content (i)
            complies with all applicable laws and regulations; (ii) does not
            infringe, misappropriate or otherwise violate any third party
            intellectual property right, including trademarks, copyrights, trade
            secrets and patents; (iii) does not breach the rights of any person
            or entity, including, without limitation, contract rights, rights of
            publicity or privacy; (iv) is not defamatory, unlawful, obscene,
            threatening, pornographic, sexually explicit, harassing, false,
            hateful, racially or ethnically offensive or encourage violence or
            conduct that would be considered a criminal offense; and (v) does
            not and will not result in consumer fraud (including being false or
            misleading), product liability, tort, breach of contract, injury,
            damage or harm of any kind to any person or entity (all such items
            described in (i) – (v) are referred to hereinafter as “Content
            Violations”).
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.4</p>
          <p>
            Subscriber acknowledges that the Website and Company Platform may
            include links to other websites or services solely as a convenience.
            Company does not endorse any such linked sites or the information,
            material, products, or services contained on other linked sites or
            accessible through other linked sites. Your correspondence or
            business dealings with, or participation in promotions of, third
            parties found on or through the Company Website or the Company
            Platform are solely between the Subscriber and such third parties.
            ACCESS AND USE OF LINKED SITES, INCLUDING THE INFORMATION, MATERIAL,
            PRODUCTS, AND SERVICES ON LINKED SITES OR AVAILABLE THROUGH LINKED
            SITES, IS SOLELY AT SUBSCRIBER’S OWN RISK.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.5</p>
          <p>
            You agree and acknowledge that Your right to access the Software
            Services is associated uniquely with You and will be identified by a
            unique Account Identifier. You will not share Your Account
            Identifier with any other person. You are responsible for
            maintaining the confidentiality of the Account Identifier for Your
            Account. You agree to be solely responsible for all transactions or
            activities that occur on Your Account. You shall immediately notify
            Company of any unauthorized use, or suspected unauthorized use of
            any Account Identifier associated with Your Account. Company
            reserves the right to periodically verify that Your use of the
            Software Services complies with the Agreement and Service Plan
            restrictions on the Company Website.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.6</p>
          <p>
            In addition to Our rights as set forth in Section 2 and Section 8.3,
            We reserve the right, in Our reasonable discretion, to temporarily
            suspend Your access to and use of a Software Service if We suspect
            or detect any Malicious Software, code, or violation of these terms
            in connection with Your Account or use of a Software Service by You.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.7</p>
          <p>
            Notwithstanding anything to the contrary, Company may at any time
            change, modify, or discontinue any aspect or feature of the Software
            Services. Such changes, modifications, additions, or deletions shall
            be effective immediately upon notice to you thereof, which may be
            given by means, including but not limited to, posting on the Company
            Website, email or written communication.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.8</p>
          <p>
            Company may offer a free trial for any of the Software Services. We
            will make such Software Services available to You during the trial
            free of charge, until either (a) the end of the free trial period;
            or (b) termination of the trial by Us in our sole discretion. You
            acknowledge that any configuration, customizations, Content and
            Customer Data available during the trial period will be permanently
            lost unless You purchase the applicable Service, or export such
            Customer Data prior to the end of the trial period.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>2.9</p>
          <p>
            From time to time, We may make Beta Services available to You at no
            charge. You acknowledge your use of Beta Services is at your sole
            discretion. You further acknowledge that Beta Services are not
            supported, are not warranted, may not be error free, and may be
            discontinued at any time, at Our sole discretion. Company will not
            be liable for any loss, harm or damage arising out of or in
            connection with a Beta Service.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">3. COACHING SERVICES</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>3.1</p>
          <p>
            If You are a Coach, You may use the Services to provide coaching
            services to Members who are Your clients. You agree that with
            respect to Your coaching services: (a) You are solely responsible
            and liable for Your provision of such coaching services and for
            addressing any claims, disputes, or other issues arising from any
            coaching services You provide to Members; (b) You shall comply, and
            are solely responsible for complying with, any laws, regulations,
            and codes of ethics or other rules or standards that apply to the
            coaching services, and to Your collection, use, and disclosure of
            Members’ personal information, Customer Data and other Content
            provided or made available to You by Members as part of the
            Services; and (c) without limiting the generality of the foregoing,
            You will provide any notices to, and obtain any consents from,
            Members that are or may be required under applicable privacy or data
            security laws, rules, or regulations with regard to Your receipt,
            collection, use, and disclosure of their personal information and
            Customer Data and Content.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>3.2</p>
          <p>
            If You are a Member, you acknowledge and agree that Coaches are
            independent operators and not employees or agents of Company. You
            further agree that any coaching services You receive from a Coach in
            connection with your use of the Services are not provided or
            controlled by Company, and that Company is not responsible or liable
            for the actions or omissions of any Coach in connection with their
            provision of the coaching services or use of the Services.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">
          4. MARKETPLACE{" "}
          <a href="/find-a-coach" className="text-blueVibrant underline">
            https://zoee.com/find-a-coach
          </a>
        </h2>
        <div className="h-[16px]" />
        <p>
          Welcome to Zoee Marketplace, an online platform connecting users with
          professional life, career, and business coaches. Zoee is operated by
          SoleLife, Inc d/b/a/ Zoee. By accessing and using Zoee's website and
          services (referred to as "the Service" or "the Services"), you agree
          to comply with the following terms and conditions. Please read them
          carefully.
        </p>
        <div className="h-[16px]" />
        <div className="mb-5 flex gap-[16px]">
          <p>4.1</p>
          <p>
            Acceptance of Terms: Your use of Zoee's website and services is
            subject to the following terms and conditions. By clicking to accept
            or agree to the terms or by using the website, you acknowledge and
            agree to be bound by these terms. If you do not accept these terms,
            please refrain from using the service.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.2</p>
          <p>
            Eligibility: You must be of legal age in your jurisdiction to form a
            binding contract with Zoee. If you are barred from receiving the
            services under any applicable laws, you may not use the service.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.3</p>
          <p>
            User Responsibilities: You agree not to use a false email address,
            impersonate any person or entity, or mislead as to the origin of
            yourself or any content contributed to the service. Zoee reserves
            the right to remove information or terminate accounts in case of
            misappropriation of identity or fraud.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.4</p>
          <p>
            Changes to Terms: Zoee reserves the right to change, modify, add, or
            delete portions of these terms at any time without notice. Continued
            use of the service after such changes constitutes acceptance of the
            new terms.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.5</p>
          <p>
            Service Usage: You can browse the Zoee website without registering.
            To be listed as a Professional Coach and access additional features,
            you must create an account. As a Coach, you are responsible for
            maintaining the confidentiality of login information and agree to
            accept responsibility for all activities under your account.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.6</p>
          <p>
            Coaching and Professional Boundaries: Zoee serves as a platform to
            connect individuals seeking coaching with professional coaches.
            Coaches are not licensed to provide counseling, psychotherapy, or
            medical services. It is crucial to understand the distinction
            between coaching and other mental health services. Zoee recommends
            consulting licensed professionals for mental health needs.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.7</p>
          <p>
            {" "}
            User Risks and Disclaimer: Users assume all risks associated with
            online or offline interactions, including consultations with
            coaches. Zoee does not guarantee the accuracy of information
            provided by or about coaches. The service is provided "as-is," and
            Zoee is not responsible for the compatibility or conduct of
            individuals using the service.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.8</p>
          <p>
            {" "}
            Content and Links: Zoee does not guarantee the accuracy, timeliness,
            or suitability of information provided by coaches. Users are
            responsible for ensuring the suitability of products or services
            obtained through the website. Zoee is not responsible for linked
            websites' content or products.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.9</p>
          <p>
            {" "}
            User Conduct: Users agree not to engage in activities such as
            advertising, harassment, or the misuse of site content. Zoee
            reserves the right to remove inappropriate content and terminate
            accounts violating these terms.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.10</p>
          <p>
            Ownership of Content: Zoee owns or licenses all intellectual
            property rights on the site and its content. User submissions become
            Zoee's property, granting exclusive rights for use without
            acknowledgment or compensation.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.11</p>
          <p>
            Liability of User-Generated Content: Zoee disclaims liability for
            user-generated content and user activities. Users bear
            responsibility for content and contributions. Zoee does not
            pre-audit user content but reserves the right to remove content
            violating the terms.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.12</p>
          <p>
            Disclaimer and Limitations on Liability: Zoee, its affiliates, and
            agents are not liable for indirect, incidental, or consequential
            damages arising from service use. Zoee does not guarantee specific
            results and disclaims warranties on website content accuracy. Users
            assume all risks associated with service usage.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.13</p>
          <p>
            Miscellaneous Terms: The site may be temporarily unavailable for
            maintenance. Zoee reserves the right to change content and terminate
            memberships for any reason. These terms constitute the entire
            agreement, and any invalid provision shall not affect the remaining
            terms.
          </p>
        </div>
        <div className="mb-5 flex gap-[16px]">
          <p>4.14</p>
          <p>
            Governing Law: By using Zoee, you agree that the laws of Delaware,
            USA, govern these terms. Any disputes shall be resolved in the
            provincial and federal courts of Delaware.
          </p>
        </div>
        <div className="h-[16px]" />
        <p>
          <strong>Coach Terms of Use: </strong>This section applies to coaches
          registering for Zoee's services. Coaches agree to represent themselves
          accurately, adhere to high professional standards, and comply with
          Zoee's terms. Zoee disclaims liability for coaching services and
          advises coaches to verify users' details. Coaches are responsible for
          maintaining confidentiality and agree to Zoee's membership fees and
          renewal charges.
        </p>

        <div className="h-[32px]" />
        <h2 className="font-bold">5. PRIVACY</h2>
        <div className="h-[16px]" />
        <p>
          You acknowledge and agree that when you access and use the Services,
          Company may collect, use, and disclose Your personal information,
          including as may be included in Customer Data and Content, in
          accordance with the Company’s Privacy Policy as set forth at&nbsp;
          <a href="/privacy-policy" className="text-blueVibrant underline">
            https://zoee.com/privacy-policy
          </a>
          .
        </p>
        <div className="h-[32px]" />
        <h2 className="font-bold">6. CONFIDENTIALITY</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>6.1</p>
          <p>
            In connection with the express permissions of this Agreement, each
            Party will protect the other’s Confidential Information from
            unauthorized use, access or disclosure in the same manner as each
            protects its own Confidential Information, but with no less
            reasonable care. Except as otherwise expressly permitted pursuant to
            this Agreement, each Party may use each other’s Confidential
            Information solely to exercise our respective rights and perform our
            respective obligations under this Agreement. Each Party agrees that
            it shall only disclose such Confidential Information (a) solely to
            the employee or employees or non-employee service providers and
            contractors who have a need to know such Confidential Information
            solely for purposes of the performance of this Agreement and
            delivery of the Services, provided that the disclosing Party shall
            be responsible and obligated for any breaches by such employees,
            service providers and contractors of the confidentiality obligations
            set forth herein; or (b) as necessary to comply with an order or
            subpoena of any administrative agency, provided that the other Party
            is given reasonable notice to allow such Party to seek a protective
            order or other appropriate remedy unless legally prohibited from
            doing so.
          </p>
        </div>
        <div className="h-[16px]" />

        <div className="flex gap-[16px]">
          <p>6.2</p>
          <p>
            You agree that Company and third-party service providers that are
            authorized by Company to assist in providing the Services to You
            shall have the right to access Your Account and to use, modify,
            reproduce, distribute, display and disclose Customer Data to the
            extent necessary to provide the Services.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">7. INTELLECTUAL PROPERTY RIGHTS</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>7.1</p>
          <p>
            You grant a worldwide, royalty-free, transferable, non-exclusive,
            sublicensable, assignable license to Company to use the Customer
            Data and Content to deliver the Services to You.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>7.2</p>
          <p>
            Company retains sole and exclusive ownership of all right, title and
            interest in and to the Platform and all associated intellectual
            property rights. No rights or licenses are granted to you with
            respect to the Platform other than the right to access and use the
            Software Services as described herein.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">
          8. BILLING, PLAN MODIFICATION AND PAYMENTS
        </h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>8.1</p>
          <p>
            If You are a Coach, all charges associated with Your access to and
            use of a Service (“Subscription Charges”) are due in full in
            accordance with your Order Form. If we are unable to charge your
            credit card or withdraw funds from your debit card for the amount
            due, we may charge interest of 1% on all unpaid amounts due and We
            may suspend access to the Software Services or terminate the
            Agreement with respect to the applicable Order Forms. For the
            avoidance of doubt, this Section 8.1 does not apply to Members.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>8.2</p>
          <p>
            All Subscribers agree to the use of Our payment processor’s system
            to process payments between Members and Coaches as part of the
            Software Services, pursuant to the terms set by the Company’s
            payment processor and separately agreed to by You.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">9. CANCELLATION AND TERMINATION</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>9.1</p>
          <p>
            Either Party may elect to terminate this Agreement on (30) days
            prior written notice.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>9.2</p>
          <p>
            We reserve the right to modify, suspend or terminate this Agreement
            or any part of the Software Services, and remove, disable and
            discard any Customer Data if We believe that You have violated this
            Agreement. Unless legally prohibited from doing so, We will use
            commercially reasonable efforts to contact You directly via email to
            notify You when taking any of the foregoing actions. We shall not be
            liable to You or any third party for any such modification,
            suspension or discontinuation of Your rights to access and use the
            Services. Any suspected fraudulent, abusive, or illegal activity by
            You may be referred to law enforcement authorities at Our sole
            discretion.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>9.3</p>
          <p>
            Either Party may terminate this Agreement for cause (a) upon thirty
            (30) days’ written notice to the other Party of a material breach if
            such breach remains uncured at the expiration of such period; or (b)
            if the other Party becomes the subject of a petition in bankruptcy
            or any other proceeding relating to insolvency, receivership,
            liquidation or assignment for the benefit of creditors. If this
            Agreement is terminated by You in accordance with this section, We
            will, to the extent permitted by applicable law, refund You any
            prepaid fees covering the remainder of the Subscription Term after
            the effective date of termination. If this Agreement is terminated
            by Us in accordance with this section, You will pay any unpaid fees
            covering the remainder of the Subscription Term pursuant to all
            applicable Order Forms. In no event will termination relieve You of
            Your obligation to pay any fees payable to Us for the period prior
            to the effective date of termination.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>9.4</p>
          <div>
            <p>
              Except for Your termination under Section 8.4, if You terminate
              this Agreement (i.e., by terminating Your subscription to a
              Software Services or cancelling Your Account) prior to the end of
              Your then effective Subscription Term or We effect such
              termination or cancellation pursuant to Sections 7, 8.3 or 8.4,
              (a) no refunds, credits or proration’s for Subscription Charges or
              other fees or payments will be provided to You and (b) in addition
              to the other amounts You may owe Company, You must immediately pay
              any then unpaid Subscription Charges associated with the remainder
              of such Subscription Term. This amount will not be payable by You
              in the event You terminate Your subscription to a Software Service
              or cancel Your Account as a result of a material breach of this
              Agreement by Company, provided that You provide advance notice of
              such breach to Company and afford Company not less than thirty
              (30) days to reasonably cure such breach.
            </p>
            <div className="h-[16px]" />
            <p>
              Upon request by You made within thirty (30) days after the
              effective date of termination or expiration of this Agreement
              (other than following a termination by Company pursuant to Section
              8.2 or 8.3), We will make Customer Data available to You for
              export or download in a format determined by us in our sole
              discretion. After such 30-day period, We will have no obligation
              to maintain or provide any Customer Data, and, will have the right
              to delete or destroy all copies of Customer Data in Our systems,
              backups, or otherwise in our possession or control, unless
              prohibited by law.
            </p>

            <div className="h-[16px]" />
            <p>
              No refunds, credits or proration’s for Subscription Charges or
              other fees or payments will be provided to You if You elect to
              terminate this Agreement (i.e., by terminating Your subscription
              to a Software Services or cancelling Your Account) prior to the
              end of Your then effective Subscription Term.
            </p>
          </div>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">10. DISCLAIMERS</h2>
        <div className="h-[16px]" />
        <p>
          THE PLATFORM AND SOFTWARE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND TO THE FULLEST
          EXTENT PERMITTED BY LAW, AND WE EXPRESSLY DISCLAIM ANY AND ALL
          WARRANTIES, WHETHER EXPRESS OR IMPLIED, BUT NOT LIMITED TO, ANY
          IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR
          PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT WE DO NOT WARRANT
          THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR
          FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE, AND NO INFORMATION OR
          ADVICE OBTAINED BY YOU FROM US OR THROUGH THE SERVICES SHALL CREATE
          ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
        </p>
        <div className="h-[32px]" />
        <h2 className="font-bold">
          11. PROHIBITED & RESTRICTED CONTENT POLICY FOR PUBLISHING PHOTOGRAPHY,
          VIDEO, AND CONTENT
        </h2>
        <div className="h-[16px]" />
        <p>Effective Date: January 22, 2023</p>
        <div className="h-[16px]" />
        <p>
          This Prohibited & Restricted Content Policy outlines the guidelines
          and restrictions for publishing photography, video, and content on our
          platform. Users are required to adhere to these guidelines to ensure a
          safe and respectful environment for all community members.
        </p>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>11.1</p>{" "}
          <p>
            Prohibited Content: The following types of content are strictly
            prohibited on our platform:
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px] pl-[22px]">
          <p>a</p>
          <p>
            Illegal Content: Any content that violates local, national, or
            international laws is strictly prohibited. This includes, but is not
            limited to, content involving illegal substances, activities, or
            materials.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>b</p>
          <p>
            Hate Speech: Content that promotes discrimination, hatred, or
            violence against individuals or groups based on race, ethnicity,
            religion, gender, sexual orientation, disability, or other protected
            characteristics is not allowed.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>c</p>
          <p>
            Violent or Graphic Content: Content that depicts violence, gore, or
            graphic images that may disturb or harm viewers is prohibited. This
            includes explicit depictions of accidents, injuries, or harm to
            animals.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>d</p>
          <p>
            Harassment and Bullying: Any content that harasses, threatens, or
            bullies individuals or groups is strictly prohibited. This includes
            but is not limited to cyberbullying, doxxing, and stalking.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>e</p>
          <p>
            Adult Content: Pornographic, sexually explicit, or adult content is
            not allowed. This includes nudity, explicit sexual content, and any
            content that is intended to arouse or titillate.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>f</p>
          <p>
            Invasive Privacy Violations: Content that invades the privacy of
            individuals without their consent, such as unauthorized sharing of
            personal information, is strictly prohibited.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>11.2</p>{" "}
          <p>
            Restricted Content: Certain types of content may be allowed under
            specific conditions but are subject to additional scrutiny and
            restrictions. Users must comply with the following guidelines for
            restricted content:
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>a</p>
          <p>
            Sensitive Content: Content that may be considered sensitive or
            potentially offensive must be appropriately labeled. This includes
            content related to political issues, religious beliefs, or other
            controversial topics.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>b</p>
          <p>
            Intellectual Property: Users must respect copyright and intellectual
            property rights. Unauthorized use of copyrighted material without
            proper attribution or permission is not allowed.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>c</p>
          <p>
            Violent or Dangerous Activities: Content depicting potentially
            harmful or dangerous activities must be carefully reviewed. Users
            should provide proper warnings and context to ensure responsible
            consumption.
          </p>
        </div>
        <div className="flex gap-[16px] pl-[22px]">
          <p>d</p>
          <p>
            Moderation of Comments: Users are responsible for moderating
            comments on their content. Any comments that violate this policy
            should be promptly addressed, and appropriate action taken.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>11.3</p>{" "}
          <p>
            Reporting Violations: Users are encouraged to report any content
            that violates this policy. Our platform will investigate reports
            promptly and take appropriate action, including content removal and
            account suspension.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>11.4</p>{" "}
          <p>
            Enforcement: Failure to comply with this Prohibited & Restricted
            Content Policy may result in content removal, account suspension, or
            permanent account termination. Our platform reserves the right to
            enforce this policy at its discretion.
          </p>
        </div>
        <div className="h-[16px]" />
        <p>
          This policy is subject to change, and users are advised to review it
          regularly to stay informed of any updates.
        </p>

        <div className="h-[32px]" />
        <h2 className="font-bold">12. LIMITATION OF LIABILITY</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>12.1</p>
          <p>
            UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
            CONTRACT, TORT, NEGLIGENCE OR OTHERWISE) WILL EITHER PARTY TO THIS
            AGREEMENT, OR THEIR AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS, SERVICE PROVIDERS, SUPPLIERS OR LICENSORS BE LIABLE TO THE
            OTHER PARTY OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST SALES OR
            BUSINESS, LOST DATA, (BEING DATA LOST IN THE COURSE OF TRANSMISSION
            VIA YOUR SYSTEMS OR OVER THE INTERNET THROUGH NO FAULT OF COMPANY),
            BUSINESS INTERRUPTION, LOSS OF GOODWILL, OR FOR ANY TYPE OF
            INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE
            LOSS OR DAMAGES, OR ANY OTHER LOSS OR DAMAGES INCURRED BY THE OTHER
            PARTY OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT, THE
            SERVICES OR CONSULTING SERVICES, REGARDLESS OF WHETHER SUCH PARTY
            HAS BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN SUCH
            DAMAGES.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>12.2</p>
          <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT,
            COMPANY’S AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY ARISING OUT
            OF THIS AGREEMENT, THE SERVICES, SHALL IN NO EVENT EXCEED THE
            CHARGES AND FEES PAID BY YOU DURING THE TWELVE (12) MONTH PERIOD
            PRIOR TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO SUCH
            LIABILITY. YOU ACKNOWLEDGE AND AGREE THAT THE ESSENTIAL PURPOSE OF
            THIS SECTION 10.2 IS TO ALLOCATE THE RISKS UNDER THIS AGREEMENT
            BETWEEN THE PARTIES AND LIMIT POTENTIAL LIABILITY GIVEN THE
            SUBSCRIPTION CHARGES AND CONSULTING FEES, WHICH WOULD HAVE BEEN
            SUBSTANTIALLY HIGHER IF WE WERE TO ASSUME ANY FURTHER LIABILITY
            OTHER THAN AS SET FORTH HEREIN. WE HAVE RELIED ON THESE LIMITATIONS
            IN DETERMINING WHETHER TO PROVIDE YOU WITH THE RIGHTS TO ACCESS AND
            USE THE SERVICES AND/OR CONSULTING SERVICES PROVIDED FOR IN THIS
            AGREEMENT.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>12.3</p>
          <p>
            We acknowledge that some jurisdictions do not allow for the
            inclusion of implied warranties or limitation of liability for
            incidental or consequential damages, and as such some of the above
            limitations may not apply to You. IN JURISDICTIONS THAT DO NOT
            RECOGNIZE IMPLIED WARRANTIES OR LIMITATIONS OF LIABILITY, COMPANY’S
            LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>12.4</p>
          <p>
            You acknowledge that any and all claims or damages that You may have
            against Company shall only be enforceable against Company and not
            other entities, its officers, directors, representatives or agents.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">13. INDEMNIFICATION</h2>
        <div className="h-[16px]" />
        <p>
          You will indemnify and hold Company harmless, from and against any
          claim brought by a third party against Company arising from the use of
          Services by You in breach of this Agreement or matters for which You
          have expressly agreed to be responsible pursuant to this Agreement,
          including any Content Violations. You at Your expense shall defend
          such claim and pay damages finally awarded against the Company,
          including reasonable fees and expenses of the attorneys engaged by You
          for such defense.
        </p>
        <div className="h-[32px]" />
        <h2 className="font-bold">
          14. ASSIGNMENT, ENTIRE AGREEMENT AND AMENDMENTS
        </h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>14.1</p>
          <p>
            You may not, directly or indirectly, by operation of law or
            otherwise, assign all or any part of this Agreement or Your rights
            under this Agreement without Our prior consent, which consent will
            not be unreasonably withheld. Company may, without Your consent,
            assign this Agreement in whole as part of a corporate
            reorganization, consolidation, merger, or sale of substantially all
            of its assets, provided that such assignment will become effective
            only upon written notice to the other parties.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>14.2</p>
          <p>
            This Agreement, together with any Order Form constitutes the entire
            agreement, and supersedes any and all prior agreements between You
            and Company. This Agreement shall apply in lieu of the terms or
            conditions in any purchase order or other documentation You or any
            Entity with You represent provides, and, except as expressly stated
            herein, there are no other agreements, representations, warranties,
            or commitments which may be used by either Party with respect to the
            subject matter hereof. The Parties further agree that there are no
            oral promises, conditions, representations, understandings,
            interpretations, or terms of any kind between the Parties, except as
            may otherwise be expressly provided herein.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>14.3</p>
          <p>
            We may amend this Agreement from time to time, in which case the new
            Agreement will supersede prior versions. We will notify You not less
            than ten (10) days prior to the effective date of and such amendment
            and Your continued use of the Services following the effective date
            of any such amendment may be relied upon by Company as Your consent
            to any such amendment. If you do not agree to such amendment, then
            your sole and exclusive remedy will be to terminate the Software
            Services. The failure of any party to insist upon or enforce strict
            performance by the other or to exercise any right under this
            Agreement shall not be construed as a waiver or relinquishment to
            any extent of such party’s rights to assert or rely upon any such
            provision or right in that or any other instance, and the same shall
            be and remain in full force and effect.
          </p>
        </div>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>14.4</p>
          <p>
            The parties agree that this Agreement is not intended to confer and
            does not confer any rights or remedies upon any person other than
            Company and Subscriber.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">15. SEVERABILITY</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>15.1</p>
          <p>
            If any provision (or part thereof) of this Agreement is determined
            by a court of competent jurisdiction as part of a final
            non-appealable ruling to be invalid, illegal, or otherwise
            unenforceable, such provision shall be enforced as nearly as
            possible in accordance with the stated intention of the parties,
            while the remainder of this Agreement shall remain in full force and
            effect and bind the parties according to its terms.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">
          16. EXPORT COMPLIANCE AND USE RESTRICTIONS
        </h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>16.1</p>
          <p>
            The Services and other Software or components of the Services that
            We may provide or make available to You, may be subject to U.S.
            export control and economic sanction laws. You agree to comply with
            all such laws and regulations as they relate to access to and use of
            the Services. You shall not access or use the Services if You are
            located in any jurisdiction in which the provision of the Services
            is prohibited under U.S. or other applicable laws or regulations (a
            “Prohibited Jurisdiction”) and You shall not provide access to the
            Services to any government, entity or individual located in any
            Prohibited Jurisdiction. You represent, warrant and covenant that
            (a) You are not named on any U.S. government list of persons or
            entities prohibited from receiving U.S. exports, or transacting with
            U.S. person; (b) You are not a national of, or a company registered
            in, any Prohibited Jurisdiction; (c) You shall not permit Members to
            access or use the Services in violation of any U.S. or other
            applicable export embargoes, prohibitions or restrictions; and (d)
            You shall comply with all applicable laws regarding the transmission
            of technical data exported from the United States and the country in
            which You are located.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">17. RELATIONSHIP OF THE PARTIES</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>17.1</p>
          <p>
            The relationship of the parties established by this Agreement is
            that of independent contractors, and no party is an employee, agent,
            partner, or joint venture of the other. The parties acknowledge that
            this Agreement sets forth a non-exclusive relationship between the
            parties. The detailed operations of Company under this Agreement are
            subject to the sole control and management of Company.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">18. NOTICE</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>18.1</p>
          <div>
            <p>
              All notices provided by Company to You under this Agreement may be
              delivered in writing (a) by electronic mail to the electronic mail
              address provided for Your Account owner; or (b) delivered by
              registered or certified mail, postage prepaid, return receipt
              requested or by nationally recognized overnight courier service.
              All notices provided by You to Company under this Agreement may be
              delivered in writing (a) by electronic mail to legal@solelife.com;
              or (b) delivered by registered or certified mail, postage prepaid,
              return receipt requested or by nationally recognized overnight
              courier service to the service address of:
            </p>
            <div className="h-[16px]" />
            <p>SoleLife, Inc</p>
            <p>Attn: Legal Compliance</p>
            <p>310 South Harrington Street</p>
            <p>Raleigh, NC 27603</p>
            <p>877-367-9633</p>
            <p>hello@zoee.com</p>

            <div className="h-[16px]" />
            <p>
              All notices, demands, and requests so given will be deemed to be
              received: (i) when actually received, if personally delivered,
              deposited for next day delivery with an overnight courier, or
              transmitted via electronic mail or other electronic means, or (ii)
              as indicated upon the return receipt if deposited in the United
              States mail.
            </p>
          </div>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">19. JURISDICTION AND GOVERNING LAW</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>19.1</p>
          <p>
            This Agreement shall be governed by and construed under the internal
            laws of the State of North Carolina without reference to conflict of
            law principles. Any disputes under this Agreement shall be resolved
            in a court of general jurisdiction in Wake County, North Carolina.
            You expressly agree to submit to the exclusive personal jurisdiction
            of this jurisdiction for the purposes of resolving any dispute
            relating to this Agreement or access to or use of the Services by
            You.
          </p>
        </div>
        <div className="h-[32px]" />
        <h2 className="font-bold">20. SURVIVAL</h2>
        <div className="h-[16px]" />
        <div className="flex gap-[16px]">
          <p>20.1</p>
          <p>
            Sections 1, 3-5, and 8-18 shall survive any termination of our
            agreement with respect to use of the Software Services by You.
            Termination of such agreement shall not limit a Party’s liability
            for obligations accrued as of or prior to such termination or for
            any breach of this Agreement.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
