import { cn } from "../../../utils/cn";

interface ContactAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  contact: any;
}

export const ContactAvatar: React.FC<ContactAvatarProps> = ({
  contact,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        "bg-lightPurple flex h-12 w-12 items-center justify-center overflow-hidden rounded-full",
        className,
      )}
      {...props}
    >
      {contact.has_avatar ? (
        <img
          src={contact.avatar_url}
          alt={`Avatar of ${contact.contact_name}`}
          className="h-full w-full object-cover"
        />
      ) : (
        <span className="text-xl font-semibold text-black">
          {contact.contact_name?.charAt(0).toUpperCase() ||
            contact.email.charAt(0).toUpperCase()}
        </span>
      )}
    </div>
  );
};
