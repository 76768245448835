import * as React from "react";
import { useContacts } from "./ContactsLayout";
import { ListContacts } from "../../components/contacts/v2/ListContacts";

export const Prospects: React.FC = () => {
  const { contacts, view } = useContacts();
  const prospects = contacts.filter(
    (contact) => contact.contact_type === "prospect",
  );

  return (
    <div>
      <ListContacts
        contacts={prospects}
        view={view}
        emptyState="Looks like you don't have any prospective clients yet."
      />
    </div>
  );
};
