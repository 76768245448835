import React, { useEffect, useState, FC } from "react";
// import TimezoneSelect from "react-timezone-select";
import Select from "react-select";
import { durations } from "../../../../objects/timeObjects";
import { format, add } from "date-fns";
import { Meeting } from "../../../../types/meetingTypes";
import { AvailabilityRulesData } from "../../../../types/availabilityTypes";
import { defaultAvailabilityOption } from "../../../../objects/availabilityObjects";
import { CalendarRequests } from "../../../../api/app.service";
import { UserCtx } from "../../../../context/userContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import moment from "moment";
import { GetAvailability } from "../../../../models/app.interface";
import TimeOptions from "./TimeOptions";
import CustomDayPicker from "../../../date-time/CustomDayPicker";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import Loader from "../../../ui/loader";
import { Label } from "../../../ui/label";
import { Switch } from "../../../ui/switch";
import { Button } from "../../../ui/button";
import { SvgInformationCircle } from "../../../icons";

type Props = {
  setStep: any;
  newMeetingData: Meeting;
  setNewMeetingData: any;
  /** Availabilities for current logged in user */
  availabilities: Array<AvailabilityRulesData>;
};

const CheckAvailability: FC<Props> = ({
  newMeetingData,
  setNewMeetingData,
  setStep,
  availabilities,
}) => {
  const [activeDate, setActiveDate] = React.useState<Date>(
    newMeetingData.date || new Date()
  );
  const [startTime, setStartTime] = React.useState<Date | null>(null);
  const [availabilityOptions, setAvailabilityOptions] = React.useState<
    Array<any>
  >(defaultAvailabilityOption);
  const [activeAvailability, setActiveAvailability] =
    React.useState<AvailabilityRulesData | null>(null);
  const { user } = React.useContext(UserCtx);
  const { renderError, setPopupNotification, hideAlert } =
    React.useContext(CommonFunctionCtx);
  const [availablePeriods, setAvailablePeriods] = React.useState<Array<any>>(
    []
  );

  const [avail, setAvail] = React.useState<Array<string>>([]);
  const [clientHasCalendar, setClientHasCalendar] =
    React.useState<boolean>(false);
  const [loadingAvailablePeriods, setLoadingAvailablePeriods] =
    React.useState<boolean>(false);
  const [showAllTimesMode, setShowAllTimesMode] =
    React.useState<boolean>(false);
  const [userCalendars, setUserCalendars] = React.useState<any>({});
  const defaultColor = "#4750F5";

  const durationDefault = 60;

  const [timeSelected, setTimeSelected] = useState<boolean>(false);
  /**
   * Sets availabile periods based on user availability and coach availability attached to a service
   */
  const getAvailability = () => {
    setLoadingAvailablePeriods(true);

    let activeAvailabilityIdArr: Array<string> = [];
    if (activeAvailability && activeAvailability.id) {
      activeAvailabilityIdArr = [activeAvailability?.id.toString()];

      // ADD THIS SO THAT USERS AVAILBITY CAN BE SET IF THEY DONT CURRENTLY HAVE ONE!!!
    }
    // else {
    //   let availabilityString = activeAvailability?.toString(); // Optional chaining
    //   if (availabilityString !== undefined) {
    //     activeAvailabilityIdArr = [availabilityString];
    //   }
    // }

    const profileIds = newMeetingData?.contacts?.map(
      (contact) => contact.profile_id
    );
    const coach = newMeetingData?.contacts?.filter(
      (contact) => contact.profile_type === "coach"
    )[0];

    if (!profileIds.includes(parseInt(user.activeProfileId))) {
      profileIds.push(parseInt(user.activeProfileId));
    }

    // if activeDate is today, make sure that the time sent to GetAvailability is not before now, otherwise the request will fail
    let now = new Date();
    let start_date: any = activeDate;
    if (activeDate < now) {
      start_date = moment.utc(now).toISOString();
    } else {
      start_date = moment.utc(activeDate).toISOString();
    }

    const m = moment.tz(activeDate, user.timezone.value);
    const end = m.clone().endOf("day").utc().toISOString();

    let coachUserId: any;
    if (coach) {
      coachUserId = coach.user_id;
    } else if (user.activeProfile === "coach") {
      coachUserId = user.user_id;
    }

    const getAvailabilityRequest: GetAvailability = {
      profile_ids: profileIds,
      creator_profile_id: parseInt(user.activeProfileId),
      availability_rule_ids: activeAvailabilityIdArr,
      meeting_duration: newMeetingData.duration || durationDefault,
      start_date: start_date,
      end_date: end,
      coach_user_id: coachUserId,
      show_all_times: showAllTimesMode,
    };

    CalendarRequests.getAvailability(getAvailabilityRequest)
      .then((data) => {
        setAvailablePeriods(data.available_periods);
        setLoadingAvailablePeriods(false);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setLoadingAvailablePeriods(false);
        setTimeout(() => hideAlert(), 5000);
      });
  };

  const fetchClientCalendars = () => {
    if (user) {
      CalendarRequests.getCalendarList({
        user_id: parseInt(newMeetingData?.contacts[0]?.user_id),
      })
        .then((data: any) => {
          setUserCalendars({
            profileCount: data.profile_count,
            calendars: data.calendars,
          });
          if (data.profile_count > 0) {
            setClientHasCalendar(true);
          }
        })
        .catch((ex: any) => {
          console.log(ex);
          renderError(ex.response.data.message);
        });
    }
  };

  useEffect(() => {
    setNewMeetingData(() => {
      return { ...newMeetingData, date: activeDate };
    });
  }, [activeDate]);

  useEffect(() => {
    if (startTime) {
      setNewMeetingData(() => {
        return {
          ...newMeetingData,
          startTime: startTime,
          endTime: add(startTime, {
            minutes: newMeetingData.duration || durationDefault,
          }),
        };
      });
    }
  }, [startTime]);

  useEffect(() => {
    const defaultAvailability = availabilities.find((a) => a.is_default);

    const existingServiceAvailability =
      newMeetingData?.service_details?.availability_rule_id;

    const options = availabilities?.map((a) => ({
      value: a.name,
      label: a.name,
    }));

    setActiveAvailability(
      existingServiceAvailability || defaultAvailability || availabilities[0]
    );
    setAvailabilityOptions(options || defaultAvailabilityOption);
  }, [availabilities, newMeetingData?.service_details?.availability_rule_id]);

  useScrollToTop();

  const handleAvailabilityOptionSelect = (e: any) => {
    const matched = availabilities.find((a) => a.name === e.value);
    if (matched) {
      setActiveAvailability(matched);
    }
  };

  const showShowAllTimesModeDescription = () => {
    if (!clientHasCalendar) {
      setPopupNotification({
        show: true,
        title: "Joint Availability Disabled",
        message:
          "Joint availability option is disabled when the member you are scheduling with does not have a calendar connected.",
        callback: null,
      });
    } else {
      setPopupNotification({
        show: true,
        title: "Disable Joint Availability",
        message:
          "Turning off the joint availability mode will show you all available meeting periods based on your availability only. Availability of meeting attendees will be disregarded.",
        callback: null,
      });
    }
  };

  useEffect(() => {
    fetchClientCalendars();
  }, []);

  useEffect(() => {
    if (activeAvailability) {
      getAvailability();
    }
  }, [
    activeDate,
    activeAvailability,
    newMeetingData.duration,
    showAllTimesMode,
  ]);

  const handleNextButtonClick = () => {
    if (!startTime) {
      setPopupNotification({
        show: true,
        title: "Select Time",
        message: "Please select a time to continue.",
        callback: null,
      });
      return;
    }
    setStep((previousStep: number) => previousStep + 1);
  };

  return (
    <div className="w-full min-w-[330px] max-w-[75vw] pb-[180px] md:pb-0">
      <div className="mb-[24px] flex w-full items-center justify-between md:mb-[32px]">
        <h3 className="text-[18px] font-bold md:text-[28px] md:font-semibold">
          Check Availability
        </h3>
        <Button
          onClick={() => setStep((previousStep: number) => previousStep - 1)}
          className="text-md"
          size={"lg"}
        >
          Back
        </Button>
        <Button
          onClick={handleNextButtonClick}
          className="text-md"
          size={"lg"}
        >
          Next
        </Button>
      </div>
      <p></p>
      <div className="grid gap-[12px] md:grid-cols-2 md:gap-[48px]">
        <div className="w-full">
          {availabilities?.length > 0 && (
            <div className="mb-[16px] md:mb-[24px]">
              <h4 className="text-graySlate mb-[8px] text-base">
                Availability
              </h4>
              <Select
                className="basic-single w-full"
                classNamePrefix="select"
                value={availabilityOptions.filter((a) => {
                  return a.value === activeAvailability?.name;
                })}
                options={availabilityOptions}
                name="availability"
                onChange={handleAvailabilityOptionSelect}
                isDisabled={showAllTimesMode}
              />
            </div>
          )}

          {user.activeProfile === "coach" && (
            <div className="text-grayCharcoal mb-[16px] flex items-center gap-[8px] text-base font-semibold md:mb-[24px]">
              <Label htmlFor="joint-availability">Joint Availability</Label>
              <Switch
                id="joint-availability"
                onCheckedChange={() => setShowAllTimesMode((prev) => !prev)}
                checked={showAllTimesMode}
                disabled={!clientHasCalendar}
              />
              <SvgInformationCircle
                onClick={showShowAllTimesModeDescription}
                className="hover:bg-grayFlash text-graySlate h-[20px] w-[20px] cursor-pointer rounded-[4px] duration-150"
              />
            </div>
          )}

          <div className="flex justify-center px-[20px]">
            <CustomDayPicker
              setActiveDate={setActiveDate}
              disablePastDates={true}
              dayColor={defaultColor}
            />
          </div>
        </div>
        <div className="promotion-shadow flex w-full flex-col rounded-[16px] bg-white px-[24px]">
          <h4 className="mx-auto mb-[16px] mt-[24px] text-[16px] font-bold">
            {format(activeDate, "iiii, MMMM do")}
          </h4>
          <div className="mb-[24px]">
            <h4 className="text-graySlate mb-[8px] text-base">Duration</h4>
            {newMeetingData.service_usage_id ? (
              <p className="font-bold">{newMeetingData.duration} minutes</p>
            ) : (
              <Select
                className="basic-single w-full"
                classNamePrefix="select"
                value={
                  newMeetingData.duration
                    ? durations.filter(
                        (option) => option.value === newMeetingData.duration
                      )
                    : durations.find((d) => d.value === durationDefault)
                }
                options={durations}
                name="duration"
                onChange={(e: any) =>
                  setNewMeetingData({
                    ...newMeetingData,
                    duration: e.value,
                  })
                }
              />
            )}
          </div>
          <div className="mb-[24px]">
            <h4 className="text-graySlate mb-[8px] text-base">Time</h4>
            <div className="flex max-h-[240px] w-full flex-col gap-[8px] overflow-y-auto">
              {loadingAvailablePeriods ? (
                <div className="px-[8px] pt-[24px] text-center font-bold">
                  <Loader />
                  <h3 className="text-center text-[16px] font-bold">
                    Loading available meeting times.
                  </h3>
                </div>
              ) : (
                <>
                  {availablePeriods.length > 0 ? (
                    <>
                      {availablePeriods.map((availablePeriod, idx) => {
                        return (
                          <>
                            <TimeOptions
                              availabilityStart={moment(
                                availablePeriod.start
                              ).toDate()}
                              availabilityEnd={moment(
                                availablePeriod.end
                              ).toDate()}
                              duration={
                                newMeetingData.duration || durationDefault
                              }
                              setStartTime={setStartTime}
                              startTime={newMeetingData.startTime}
                              dayColor={defaultColor}
                            />
                            <div className="bg-hover text-graySlate flex items-center justify-center rounded-[10px] p-[12px] text-base font-bold last:hidden">
                              Unavailable
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div className="px-[8px] pt-[40px] text-center font-bold">
                      No available meeting times for you and your meeting
                      participant(s) on this day. Please select another day.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckAvailability;
