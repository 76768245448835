import { SvgChevronRight } from "../../icons";
import { ContactStatusTag } from "./ContactStatusTag";
import { ContactAvatar } from "./ContactAvatar";

export const ContactListItem: React.FC<{ contact: any }> = ({ contact }) => {
  return (
    <div className="grid grid-cols-5 items-center py-2">
      <ContactAvatar contact={contact} />
      <h2 className="font-bold">{contact.contact_name}</h2>
      <p>{contact.email}</p>
      <ContactStatusTag contact={contact} />
      <SvgChevronRight />
    </div>
  );
};
