import React, { type FC, useState, useEffect } from "react";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import {
  ContactRequests,
  NotificationRequests,
  SessionsRequests,
} from "../../../api/app.service";
import topBackgroundGradient from "../../../assets/images/background-gradient.png";
import classes from "./CoachDashboardBento.module.css";
import NotificationCenter from "../../../components/notifications/NotificationCenter";
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser";
import { NotificationCtx } from "../../../context/notificationContext";
import CoachScheduling from "../../scheduling/CoachScheduling";
import Greeting from "../../../components/dashboard/Greeting";
import { Link } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { DashboardContacts } from "../DashboardContacts";
import DashboardNotes from "../DashboardNotes";
import { SvgCalendar, SvgNotebook } from "../../../components/icons/";

const CoachDashboard: FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userContacts, setUserContacts] = React.useState<any>([]);
  const { user } = React.useContext(UserCtx);
  const authenticatedUser = useAuthenticatedUser(user);
  const [sessionList, setSessionList] = React.useState<any[]>([]);
  const [activeJoinButton, setActiveJoinButton] = React.useState(false);
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = React.useState<any[]>([]);
  const { setInboxNotifications, setContactsNotifications, notificationAlert } =
    React.useContext(NotificationCtx);
  const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
  const [isNewNotificationAlert] = React.useState<boolean>(
    notificationAlert.message !== "",
  );
  // ADDED COMMENT LINE TO TEST PUSHING TO BITBUCKET
  const itemStyle = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 0 20px 5px rgba(150, 150, 150, 0.2)",
  };

  const getNotifications = () => {
    if (authenticatedUser) {
      NotificationRequests.getNotifications({
        profile_id: parseInt(user.activeProfileId),
      })
        .then((data) => {
          const filteredNotifications = data.notifications.filter((n) => {
            return n.notification_category !== "messages";
          });
          setNotifications(filteredNotifications);
          const unreadContactNotifications = data.notifications.filter((n) => {
            return n.slug === "received_connection_request" && n.viewed === 0;
          });
          setContactsNotifications(unreadContactNotifications);
          const unreadInboxNotifications = data.notifications.filter((n) => {
            return n.notification_category === "messages" && n.viewed === 0;
          });
          setInboxNotifications(unreadInboxNotifications);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        });
    }
  };

  const getContacts = () => {
    if (user?.coachProfile) {
      ContactRequests.getContactList({
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setUserContacts(data.contact_list);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        });
    }
  };

  const getSessionList = () => {
    if (user) {
      SessionsRequests.get100msSessionList({
        profile_id: user.activeProfileId,
      })
        .then((data) => {
          setSessionList(data.session_list);
          setActiveJoinButton(data?.session_list[0]?.host_joined);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        });
    }
  };

  const _ = require("lodash");
  _.mixin(require("lodash-inflection"));

  React.useEffect(() => {
    getNotifications();
    getContacts();
    getSessionList();
  }, []);

  React.useEffect(() => {
    getNotifications()
    getContacts()
    getSessionList()
  }, [notificationAlert.show])

  React.useEffect(() => {
    if (isNewNotificationAlert) {
      getNotifications();
    }
  }, [isNewNotificationAlert]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="flex flex-col items-center justify-center bg-cover md:-mt-40 "
        style={{
          backgroundImage: `url(${topBackgroundGradient})`,
        }}
      >
        <div className="z-20 mt-8 flex w-full items-center justify-center md:mt-32">
          <Greeting coach />
        </div>
        <div className={classes.container}>
          <div
            style={{ ...itemStyle, gridArea: "meet" }}
            className={`h-[423px] min-w-[350px] lg:h-[531px] lg:w-[820px]`}
          >
            <div className=" -mt-10 flex flex-row items-center justify-between gap-2 px-2 text-lg font-bold lg:hidden">
              My Calendar
              <Button
                className="-mt-2"
                variant={"link"}
                onClick={() => setPickerOpen(!pickerOpen)}
              >
                <SvgCalendar />
              </Button>
            </div>
            <div className="-mt-12 hidden w-full justify-between p-2 lg:flex">
              <h2 className="justify-ceneter flex flex-row items-center gap-2 text-lg font-bold">
                <SvgCalendar />
                My Calendar
              </h2>
              <Link to="/coach/scheduling" className="z-20">
                <Button variant="link">See all</Button>
              </Link>
            </div>
            <CoachScheduling
              isTodayPage
              setPickerOpen={setPickerOpen}
              pickerOpen={pickerOpen}
            />
          </div>
          {windowWidth >= 1280 && (
            <div
              style={{ ...itemStyle, gridArea: "cale" }}
              className="h-[351px] w-[400px]"
            ></div>
          )}

          <div
            style={{ ...itemStyle, gridArea: "noti" }}
            className={`mt-6 h-[355px] min-w-[350px] md:w-[400px]`}
          >
            <NotificationCenter
              isTodayPage
              notifications={notifications}
              setNotifications={setNotifications}
            />
          </div>

          <div
            style={{ ...itemStyle, gridArea: "cont" }}
            className={`mt-6 h-[355px] min-w-[350px] md:w-[400px]`}
          >
            <DashboardContacts userContacts={userContacts} />
          </div>

          {windowWidth >= 1280 && (
            <div
              style={{ ...itemStyle, gridArea: "sess" }}
              className="mt-6 flex h-[538px] w-[396px] flex-col items-center justify-center"
            >
              <div className="flex w-full items-start justify-start">
                <h2 className="-mt-8 mb-1 ml-2 flex items-center gap-2 text-lg font-bold">
                  <SvgNotebook />
                  My Notes
                </h2>
              </div>
              <DashboardNotes userContacts={userContacts} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CoachDashboard;
