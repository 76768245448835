import React from "react"
import ChannelItem from "./ChannelItem"
import ChatWindow from "./ChatWindow"
import ChannelLoading from "./ChannelLoading"
import { ChannelList } from "stream-chat-react"
import { UserCtx } from "../../context/userContext"
import { ShowChatCtx } from "../../context/showChatContext"
import { useChatContext } from "stream-chat-react"
import NewChatSelector from "./NewChatSelector"
import { useLocation } from "react-router-dom"
import useLoading from "../../hooks/useLoading"
import { SvgCheckbox, SvgSearch } from "../icons/"

interface Props {
  setSelectNewChatParams: any
  selectNewChatParams: any
  setEditChatPopover: any
  editChatPopover: boolean
  desktop: boolean
}

const InboxMessaging: React.FC<Props> = (props: any) => {
  const location = useLocation()
  const { client, setActiveChannel } = useChatContext()
  const [filterByName, setFilterByName] = React.useState<boolean>(false)
  const [filterByMembers, setFilterByMembers] = React.useState<boolean>(true)
  const { user } = React.useContext(UserCtx)
  const showChatContext = React.useContext(ShowChatCtx)
  const [filters, setFilters] = React.useState<any>({
    type: "messaging",
    members: { $in: [user.activeProfileId.toString()] },
  })
  const [channelCount, setChannelCount] = React.useState<number>(0)

  const { startLoading, stopLoading } = useLoading()

  const updateFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    if (searchValue !== "") {
      if (filterByMembers) {
        setFilters({
          type: "messaging",
          members: { $in: [user.activeProfileId.toString()] },
          "member.user.name": { $autocomplete: searchValue },
        })
      }
      if (filterByName) {
        setFilters({
          type: "messaging",
          members: { $in: [user.activeProfileId.toString()] },
          name: { $autocomplete: searchValue },
        })
      }
    } else {
      setFilters({
        type: "messaging",
        members: { $in: [user.activeProfileId.toString()] },
      })
    }
  }

  // const toggleFilters = () => {
  //     if(filterByName){
  //         setFilterByMembers(true);
  //         setFilterByName(false);
  //     } else {
  //         setFilterByMembers(false);
  //         setFilterByName(true);
  //     }
  // }

  const createChannel = async (newChatProfileIds: any) => {
    const stringifiedMembers = newChatProfileIds.map((id: number) =>
      id.toString()
    )

    if (client) {
      const channel = client.channel("messaging", {
        members: stringifiedMembers,
      })
      await channel.create().then(() => {
        if (channel) {
          setTimeout(() => {
            setActiveChannel(channel)
            showChatContext?.setShowChat(true)
            window.history.replaceState(null, "", window.location.pathname)
            stopLoading()
          }, 1000)
        }
      })
    }
  }

  const checkUrlParams = () => {
    const params = new URLSearchParams(window.location.search)
    const newChatProfileId = params.get("chat")
    const loc: any = location

    if (newChatProfileId) {
      createChannel([newChatProfileId, client?.user?.id])
    } else if (loc?.state?.participantIds) {
      createChannel(loc.state.participantIds)
    } else {
      stopLoading()
    }
  }

  React.useEffect(() => {
    setFilters({
      type: "messaging",
      members: { $in: [user.activeProfileId.toString()] },
    })
    checkUrlParams()
    setChannelCount(Object.keys(client?.activeChannels).length)
  }, [])

  return (
    <div className="w-full">
      {props.selectNewChatParams.show && (
        <NewChatSelector
          setSelectNewChatParams={props.setSelectNewChatParams}
          selectNewChatParams={props.selectNewChatParams}
          user={user}
        />
      )}
      <div className="flex flex-col md:flex-row md:justify-between">
        <div
          className={`w-full md:w-[392px] max-h-[672px] 
                    ${showChatContext?.showChat && "hidden md:block"}`}
        >
          <div className="default-input mx-auto rounded-[100px] h-[44px] md:h-[48px]">
            <input
              type="text"
              className="rounded-md p-0 indent-[48px]"
              onChange={updateFilters}
            />
            <SvgSearch className="absolute top-[50%] -translate-y-[50%] left-[12px]" />
          </div>
          <div className="hidden w-[335px] md:w-[360px] mx-auto mt-[16px] md:flex items-center justify-between font-bold text-base">
            <div className="ml-[4px]">Filter by:</div>
            <div className="flex items-center gap-[12px]">
              {/* <div className="flex items-center gap-[8px] cursor-pointer" onClick={toggleFilters}>
                                <p>Program Name</p>
                                <div 
                                    className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${filterByName && "bg-cyanBlue"}`}    
                                >
                                    {filterByName && (
                                        <img src={whiteCheck} alt="check" />
                                    )}
                                </div>
                            </div> */}
              <div
                className="flex items-center gap-[8px] cursor-pointer"
                // onClick={toggleFilters}
              >
                <p>Members</p>
                <div
                  className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${filterByMembers && "bg-cyanBlue"}`}
                >
                  {filterByMembers && <SvgCheckbox />}
                </div>
              </div>
            </div>
          </div>

          <div className="flex md:px-auto mx-auto md:w-auto w-[420px] px-[16px] relative z-20">
            <ChannelList
              Preview={ChannelItem}
              filters={filters}
              LoadingIndicator={ChannelLoading}
            />
          </div>
        </div>
        {(showChatContext?.showChat || props.desktop) && (
          <div className="z-5">
            <ChatWindow
              setEditChatPopover={props.setEditChatPopover}
              editChatPopover={props.editChatPopover}
              channelCount={channelCount}
              setChannelCount={setChannelCount}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default InboxMessaging
