import React from "react";
import { SvgCheck } from "../icons";
import TrialSubscriptionCard from "./TrialSubscriptionCard"

type Props = {
    isNewPlan: boolean;
    subscription: any;
};

const SubscriptionCheckoutCard: React.FC<Props> = ({
    isNewPlan,
    subscription
}) => {    
    const [paymentPeriod, setPaymentPeriod] = React.useState<string>("");
    const [subscriptionCardTitle, setSubscriptionCardTitle] = React.useState<string>("");
    const [twoYearSubscription, setTwoYearSubscription] = React.useState<boolean>(false);

    const setSubscriptionCardData = () => {
      if (subscription?.recurring_interval === 'annual' && !subscription?.is_promotional) {
        setPaymentPeriod('/annual')
        setSubscriptionCardTitle('Annual Plan')
      }
      if (subscription?.recurring_interval === 'month' && !subscription?.is_promotional) {
        setPaymentPeriod('/month')
        setSubscriptionCardTitle('Monthly Plan')
      }
      if (subscription?.amount === 500 && subscription?.recurring_interval_count === 2) {
        setPaymentPeriod('one time cost')
        setSubscriptionCardTitle('Two Year Plan')
        setTwoYearSubscription(true)
      }
    }

    React.useEffect(() => {
      setSubscriptionCardData();
    }, [subscription]);

    return (
        <div
            className={`${ isNewPlan && "border border-blurple" } ${ twoYearSubscription && "subscription-cta-gradient" } 
            min-w-[300px] w-[350px] md:h-[534px] flex flex-col px-[40px] py-[24px] md:py-[48px] rounded-[32px] main-shadow bg-white`}
        >
            {subscription ? (
                <>
                    <h2 className="md:mb-[8px] mb-[4px] font-semibold text-[28px]">{ subscriptionCardTitle }</h2>
                    <p className="md:mb-[40px] mb-[20px] text-graySlate">
                        {subscription?.stripe_product_description}
                    </p>
                    <div className="md:mb-[80px] mb-[16px] flex font-bold items-start gap-[4px] leading-none">
                      <h4 className="text-[36px] text-grayCharcoal">$</h4>
                      <h4 className="md:text-[80px] text-[64px] -translate-y-[10px]">
                        {parseInt(subscription?.amount)}
                      </h4>
                      <h4 className="md:text-[18px] text-base text-graySlate">{paymentPeriod}</h4>   
                    </div>
                    <div className="flex flex-col gap-[12px]">
                        <div className="flex items-center gap-[16px]">
                            <SvgCheck stroke="#00D084" />
                            <p className="text-base font-bold">
                                {subscription?.stripe_product_metadata?.feature1}
                            </p>
                        </div>
                        <div className="flex items-center gap-[16px]">
                            <SvgCheck stroke="#00D084" />
                            <p className="text-base font-bold">
                                {subscription?.stripe_product_metadata?.feature2}
                            </p>
                        </div>
                        <div className="flex items-center gap-[16px]">
                            <SvgCheck stroke="#00D084" />
                            <p className="text-base font-bold">
                                {subscription?.stripe_product_metadata?.feature3}
                            </p>
                        </div>
                        <div className="flex items-center gap-[16px]">
                            <SvgCheck stroke="#00D084" />
                            <p className="text-base font-bold">
                                {subscription?.stripe_product_metadata?.feature4}
                            </p>
                        </div>
                    </div>
                </>
            ) : (
              <TrialSubscriptionCard />
            )}
        </div>
    );
};

export default SubscriptionCheckoutCard;
