import React from 'react';
import { HMSNoiseSuppressionPlugin } from '@100mslive/hms-noise-suppression';
import VideoControlsBottom from './VideoControlsBottom';
import SpeakerView from "./SpeakerView";
import GalleryView from "./GalleryView";
import Screenshare from "./Screenshare";
import EmptyRoomPrompt from './EmptyRoomPrompt';
import { SessionContext } from "../../pages/sessions/context/sessionContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { NotificationCtx } from "../../context/notificationContext";
import { SubscriptionCtx } from '../../context/subscriptionContext';
import {
    useHMSStore,
    useHMSActions,
    selectIsLocalAudioPluginPresent,
    selectIsConnectedToRoom,
    selectPeerScreenSharing,
    selectIsLocalScreenShared,
    selectIsSomeoneScreenSharing,
    selectRoomID,
    selectRecordingState
  } from "@100mslive/react-sdk";
import { SvgGallery, SvgRecord, SvgSpeakerView } from '../icons';

interface Props {}

const Conference:React.FC<Props> = () => {
  const { lastWebsocketMessage } = React.useContext(NotificationCtx);
  const { setShowMobileNavBottom, renderError, hideAlert } = React.useContext(CommonFunctionCtx);
  const { setShowSubscribeBanner } = React.useContext(SubscriptionCtx);
  const noiseSuppressionPlugin = new HMSNoiseSuppressionPlugin();
  const hmsActions = useHMSActions();
  const { viewType, setViewType, mobileView, fullScreen } = React.useContext(SessionContext);
  const isNoiseSuppressed = useHMSStore(selectIsLocalAudioPluginPresent(noiseSuppressionPlugin.getName()));
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const presenter = useHMSStore(selectPeerScreenSharing);
  const amIScreenSharing = useHMSStore(selectIsLocalScreenShared);
  const screenshareOn = useHMSStore(selectIsSomeoneScreenSharing);
  const currentRoomId = useHMSStore(selectRoomID);
  const recordingState = useHMSStore(selectRecordingState);
  
  const [showEmptyRoomPrompt, setShowEmptyRoomPrompt] = React.useState<boolean>(false);
  const [sendReview, setSendReview] = React.useState<boolean>(false);

  const handleChangeViewType = async () => {
      setViewType(viewType === "speaker" ? "gallery" : "speaker")
  }

  const pluginSupport = hmsActions.validateAudioPluginSupport(noiseSuppressionPlugin);
  if (pluginSupport.isSupported) {
      console.log('NOISE PLUGIN SUPPORTED');
  } else {
      console.log("NOISE PLUGIN NOT SUPPORTED");
      const err = pluginSupport.errMsg;
      console.error(err);
  }

  const initNoiseSuppression = async () => {
      if (!isNoiseSuppressed) {
        console.log("STARTING NOISE SUPPRESSION");
        await hmsActions.addPluginToAudioTrack(noiseSuppressionPlugin);
      }
  }

  const toggleScreenShare = async () => {
      if(amIScreenSharing){
        await hmsActions.setScreenShareEnabled(false, {
          videoOnly: true
        }).then(() => {
          console.log("disabled screenshare");
        }).catch((err) => {
          console.log(err);
        })
      } else {
        if(screenshareOn){
          renderError(`${presenter?.name || "Someone"} is already sharing.`);
          setTimeout(() => {
            hideAlert();
          }, 3000)
        } else {
          await hmsActions.setScreenShareEnabled(true, {
            videoOnly: true
          }).then(() => {
            console.log("enabled screenshare");
          }).catch((err) => {
            console.log(err);
          })
        }
      }
  }

  React.useEffect(() => {
      if(mobileView && isConnected){
          setShowMobileNavBottom(false);
          setShowSubscribeBanner(false);
      }
  }, [])

  React.useEffect(() => {
    if(lastWebsocketMessage?.type === "session"){
      if(
        lastWebsocketMessage?.data?.event_type === "participant_left"
        && lastWebsocketMessage?.data?.participant_count <= 1
        && lastWebsocketMessage?.data?.room_id === currentRoomId
      ){
        setShowEmptyRoomPrompt(true)
      }
    }
  }, [lastWebsocketMessage])

  React.useEffect(() => {
    initNoiseSuppression();
  }, [])

  // uncomment below to enable waiting room!
  // React.useEffect(() => {
  //   setInWaitingRoom(false);
  // }, [])
  
  return (
    <>
      {showEmptyRoomPrompt && (
        <EmptyRoomPrompt
          setShowEmptyRoomPrompt={setShowEmptyRoomPrompt}
          sendReview={sendReview}
        />
      )}
      <div 
        className={`bg-grayCharcoal ${fullScreen ? "fixed w-screen h-screen top-0 left-0 z-[1500]" 
        : "relative w-full md:w-[1080px] h-screen md:h-[607.5px]"}`}
      >
          {/* video window */}
          {presenter && !presenter.isLocal ? (
              <>
                  {/* screen share */}
                  <Screenshare presenter={presenter} />
              </>
          ) : (
              <>
                  {/* screen sharing on indicator */}
                  {presenter && presenter.isLocal && (
                      <div
                          className={`${mobileView ? "left-[50%] -translate-x-[50%]" : "right-[10px]"}
                          w-full md:w-auto fixed top-0  z-[100] text-white md:rounded-b-[4px] 
                          font-bold flex justify-center h-[30px]`}
                      >
                          <div className="bg-primaryBlue px-[10px] rounded-b-[4px] h-full flex items-center">You are Screensharing</div>
                          <button
                              onClick={toggleScreenShare}
                              className="bg-vividRed px-[10px] rounded-b-[4px] h-full flex items-center"
                          >
                              Stop Sharing
                          </button>
                      </div>
                  )}
                  {/* speaker/gallery toggle */}
                  <button 
                      className={`absolute top-6 left-6 px-[12px] h-12 items-center
                      justify-center gap-[12px] bg-black/[0.24] zoee-backdrop-filter-xl rounded-md
                      text-base text-white font-bold flex cursor-pointer z-20`}
                      onClick={handleChangeViewType}
                  >
                      {viewType === "gallery" ? <SvgGallery/> : <SvgSpeakerView/>}
                      {viewType === "gallery" ? "Gallery View" : "Speaker View"}
                  </button>
              
                  {recordingState.browser.running && (
                    <div className="rounded-md bg-background absolute top-6 right-6 py-2 px-3 z-20 text-black font-bold flex items-center gap-2">
                      <SvgRecord className="text-vividRed" />
                      Recording
                </div>
                  )}
              
                  {viewType === "speaker" ? (
                      <SpeakerView />
                  ) : (
                      <GalleryView />
                  )}
              </>
          )}
          {/* bottom controls */}
          <VideoControlsBottom 
            sendReview={sendReview}
            setSendReview={setSendReview}
          />

          {/* NOISE SUPPRESSION TOGGLE */}
          {/* <button 
            className="absolute -bottom-[50px] left-[50%]
            -translate-x-[50%] btn-primary"
            onClick={toggleNoiseSuppression}
          >
              {isNoiseSuppressed ? "Stop Noise Suppression" : "Start Noise Suppression"}
          </button> */}
      </div>
    </>
  );
}

export default Conference;
