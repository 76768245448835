import React from "react";
import moment from "moment";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import useDate from "../../hooks/useDate";
import { SubscriptionContext } from "../../pages/subscription/context/subscriptionContext";
import { SvgQuestionCircle, SvgRedo } from "../icons";
import { Button } from "../ui/button";

const UpcomingPayments: React.FC = () => {
  const { user } = React.useContext(UserCtx);
  const { subscription } = React.useContext(SubscriptionContext);
  const { setPopupNotification } = React.useContext(CommonFunctionCtx);
  const { mdDate } = useDate();

  const getTrialDaysRemaining = () => {
    const expiration = moment(user?.trialExpiration);
    const now = moment();

    return Math.max(0, expiration.diff(now, "days"));
  };

  const getDiscountAmount = () => {
    const discountType = subscription?.promo_value_type;
    const discountUnits = subscription?.promo_value;
    if (discountType === "percent") {
      const discountDollars = (discountUnits / 100) * subscription?.amount;
      return discountDollars;
    } else if (
      discountType === "amount" &&
      subscription.subscription_promo_code !== "FALL2023"
    ) {
      return discountUnits;
    } else {
      return 0.0;
    }
  };

  const getTotalAmount = () => {
    if (subscription.subscription_promo_code === "FALL2023") {
      return subscription?.amount;
    } else {
      const discountAmount = getDiscountAmount();
      return subscription?.amount - discountAmount;
    }
  };

  const getNextPaymentDate = () => {
    if (!subscription) {
      return null;
    }

    const nextPaymentDate = new Date(
      subscription?.subscription_next_payment_date,
    );
    if (subscription.recurring_interval === "month") {
      nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1);
    } else if (
      subscription.recurring_interval === "annual" &&
      subscription.subscription_promo_code === "500TWOYEAR"
    ) {
      nextPaymentDate.setFullYear(nextPaymentDate.getFullYear() + 2);
    } else if (
      subscription.recurring_interval === "annual" &&
      subscription.subscription_promo_code !== "500TWOYEAR"
    ) {
      nextPaymentDate.setFullYear(nextPaymentDate.getFullYear() + 1);
    } else {
      return null;
    }
    return mdDate(nextPaymentDate);
  };

  const upcomingPaymentDate = getNextPaymentDate();

  const lineItems: { label: string; amount: number }[] = [
    { label: "Zoee Subscription", amount: subscription?.amount },
    {
      label: `Discount: ${subscription?.subscription_promo_code || ""}`,
      amount: getDiscountAmount(),
    },
    { label: "Interest or fees", amount: 0 },
  ];

  const totalDaysGivenForTrial = 14;
  const getTrialDaysRemainingPercentage =
    (getTrialDaysRemaining() / totalDaysGivenForTrial) * 100;

  return (
    <div className="main-shadow w-full rounded-[16px] p-[24px] sm:min-w-[360px] md:h-[340px] md:p-[32px]">
      <div className="mb-[32px] flex w-full items-center justify-between">
        <h2 className="text-xl font-bold">Upcoming Payments</h2>
        {subscription && subscription?.subscription_end_date === "None" ? (
          <Button
            variant="link"
            size="icon"
            onClick={() => {
              setPopupNotification({
                show: true,
                title: "Upcoming Payments",
                message:
                  "This section shows your next subscription payment due, which will be charged to the payment method you selected when you subscribed.",
              });
            }}
          >
            <SvgQuestionCircle />
          </Button>
        ) : (
          <></>
        )}
      </div>
      {!subscription && (
        <>
          <div className="mb-[36px] flex items-center gap-[8px] text-[16px]">
            <SvgRedo />
            <div className="flex items-center gap-[4px]">
              <span className="font-bold">{getTrialDaysRemaining()}</span>
              <span className="text-graySlate">
                days remaining on your trial
              </span>
            </div>
          </div>
          <div className="bg-grayFlash relative h-1.5 w-full rounded-full">
            <div
              className="bg-blurple absolute left-0 top-0 z-10 h-1.5 rounded-full"
              style={{ width: `${getTrialDaysRemainingPercentage}%` }}
            />
          </div>
        </>
      )}
      {subscription && subscription?.subscription_end_date === "None" ? (
        <>
          <div className="mb-6 flex items-center gap-2">
            <SvgRedo />
            <div className="flex items-center gap-1">
              <span className="text-graySlate">Next payment on</span>
              <span className="font-bold">
                {upcomingPaymentDate !== null
                  ? upcomingPaymentDate
                  : "No upcoming payment date"}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {lineItems.map(({ label, amount }, index) => (
              <div
                className="text-grayCharcoal flex w-full items-center justify-between text-base"
                key={index}
              >
                <p className="min-w-fit whitespace-nowrap">{label}</p>
                <div className="border-grayCloud mx-2 h-2 w-full grow -translate-y-1 transform border-b border-dashed"></div>
                <p className="min-w-fit">${amount.toFixed(2)}</p>
              </div>
            ))}
            <div className="flex items-center justify-between font-bold">
              <p className="min-w-fit">Total</p>
              <div className="border-grayCloud mx-[8px] h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
              <p className="min-w-fit">${getTotalAmount()?.toFixed(2)}</p>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UpcomingPayments;
