import React from 'react';
import AttendanceListItem from './AttendanceListItem';
import AttendanceLogItem from './AttendanceLogItem';
import { SessionContext } from "../../../pages/sessions/context/sessionContext";
import CloseModal from '../../CloseModal';
import { SvgSearch } from '../../icons';

interface Props {
    participants: any;
    setViewAttendance: any;
    logItems: any[];
}

const AttendanceModal: React.FC<Props> = ({participants, setViewAttendance, logItems}) => {
    const [selected, setSelected] = React.useState<string>("list");
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [foundAttendees, setFoundAttendees] = React.useState<any>(participants);
    const [foundLogItems, setFoundLogItems] = React.useState<any>(logItems);
    const { mobileView } = React.useContext(SessionContext);

    const updateSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    }

    const updateFoundAttendees = () => {
        const keyword = searchValue;
        if (keyword !== '') {
            const results = participants.filter((participant:any) => {
                const fullName = `${participant.first_name} ${participant.last_name}`
                return fullName.toLowerCase().includes(keyword.toLowerCase());
            });
            setFoundAttendees(results);
        } else {
            setFoundAttendees(participants);
        }
    }

    const updateFoundLogItems = () => {
        const keyword = searchValue;
        if (keyword !== '') {
            const results = logItems.filter((item:any) => {
                const fullName = `${item.firstName} ${item.lastName}`
                return fullName.toLowerCase().includes(keyword.toLowerCase());
            });
            setFoundLogItems(results);
        } else {
            setFoundLogItems(logItems);
        }
    }

    React.useEffect(() => {
        if(selected === "list"){
            updateFoundAttendees();
        }
        if(selected === "log"){
            updateFoundLogItems();
        }
    }, [searchValue, participants, logItems])

    return (
        <div
            className='fixed top-0 left-0 w-screen h-screen zoee-backdrop-filter
            z-[801] flex items-start pt-[100px] md:pt-0 md:items-center justify-center'
        >
            <div 
                className={`py-[16px] sm:py-[32px] px-[24px] sm:px-[48px] w-[351px] sm:w-[520px] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative ${mobileView ? "max-h-[400px]" : ""}`}
            >
				<CloseModal callback={() => setViewAttendance(false)} styling="absolute top-[24px] right-[24px]" />
                <h2 className="text-[22px] font-bold mb-[24px]">Attendance</h2>
                <div className="w-full flex bg-[#f8f8f8] rounded-[12px] h-[40px] items-center justify-center mb-[16px]">
                    <div className="w-[50%] flex justify-end">
                        <button 
                            className={`h-[32px] w-[95%] font-bold text-base flex items-center justify-center
                            ${selected === "list" ? "bg-white rounded-[8px] attendee-toggle-shadow text-primaryBlue"
                            : "bg-transparent text-gray"}`}
                            onClick={() => setSelected("list")}
                        >
                            List
                        </button>
                    </div>
                    <div className="w-[50%] flex justify-start">
                        <button 
                             className={`h-[32px] w-[95%] font-bold text-base flex items-center justify-center
                             ${selected === "log" ? "bg-white rounded-[8px] attendee-toggle-shadow text-primaryBlue"
                             : "bg-transparent text-gray"}`}
                             onClick={() => setSelected("log")}
                        >
                            Log
                        </button>
                    </div>
                </div>
                <div className="search-input w-full mb-[24px]">
                    <input type="text" onChange={updateSearchValue} placeholder="Search participant name" />
                    <SvgSearch className='left-[12px] absolute top-[50%] -translate-y-[50%]'/>
                </div>
                <span className='text-graySlate text-base mb-[4px] mr-auto'>
                    {`${participants.length} Attendees`}
                </span>
                <div className='h-[300px] flex flex-col w-full overflow-auto'>
                    {selected === "list" ? (
                        <>
                            {foundAttendees.map((attendee:any, idx:number) => (
                                <div key={idx}>
                                    <AttendanceListItem
                                        participant={attendee}
                                    />
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            {foundLogItems.length > 0 ? (
                                <>
                                    {foundLogItems.map((log:any, idx:number) => (
                                        <div key={idx}>
                                            <AttendanceLogItem
                                                log={log}
                                            />
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className='mt-[10px] font-bold text-graySlate'>No found log items.</p>
                            )}
                        </>
                    )}
                    
                </div>
            </div>
        </div>
    );
}

export default AttendanceModal;
