import * as React from "react";
import { useContacts } from "./ContactsLayout";
import { ListContacts } from "../../components/contacts/v2/ListContacts";

export const ReceivedInvites: React.FC = () => {
  const { contacts, view } = useContacts();
  const receivedInvites = contacts.filter(
    (contact) => contact.contact_type === "requested",
  );

  return (
    <div>
      <ListContacts
        contacts={receivedInvites}
        view={view}
        emptyState="Looks like you don't have any pending contact requests at the moment."
      />
    </div>
  );
};
