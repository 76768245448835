import { useContext, useEffect, useState } from "react"
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom"

// context and hooks imports
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { NotificationCtx } from "../../context/notificationContext"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import { SubscriptionContext } from "../../pages/subscription/context/subscriptionContext"

// 3rd party imports
import { selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk"
import useWebSocket, { ReadyState } from "react-use-websocket"

// marketing site component imports
import AffiliateProgramTermsPage from "../../pages/(marketing)/affiliate-program-terms/page"
import BlogPage from "../../pages/(marketing)/blog/page"
import ContactPage from "../../pages/(marketing)/contact/page"
import CookiePolicyPage from "../../pages/(marketing)/cookie-policy/page"
import FeaturesPage from "../../pages/(marketing)/features/page"
import HomePage from "../../pages/(marketing)/page"
import PostPage from "../../pages/(marketing)/blog/[slug]/page"
import PricingPage from "../../pages/(marketing)/pricing/page"
import PrivacyPolicyPage from "../../pages/(marketing)/privacy-policy/page"
import TermsAndConditionsPage from "../../pages/(marketing)/terms-and-conditions/page"
import WhyZoeePage from "../../pages/(marketing)/why-zoee/page"
import Landing from "../../pages/(marketing)/Landing"
import MarketingPageLayout from "../../pages/(marketing)/layout"

import AccountSettingsPage from "../../pages/(app)/account-settings/page"
import CalendarSettingsPage from "../../pages/(app)/account-settings/calendars/page"
import LoginSecurityPage from "../../pages/(app)/account-settings/login-security/page"
import NotificationsPage from "../../pages/(app)/account-settings/notifications/page"
import PaymentMethodsPage from "../../pages/(app)/account-settings/payment-methods/page"
import PersonalInfoPage from "../../pages/(app)/account-settings/personal-info/page"

// ICF site component imports
import IcfPageLayout from "../../pages/(icf)/layout"
import IcfRegistrationPage from "../../pages/(icf)/IcfRegistrationPage"
import ICFSubscription from "../../pages/subscription/ICFSubscription"
import ICFSubscriptionCheckout from "../../pages/(icf)/ICFSubscriptionCheckout"

// private route imports
import DirectLinkRouter from "../DirectLinkRouter"
import PrivateRouteCoach from "../privateRouteCoach"
import PrivateRouteContact from "../privateRouteContact"
import PrivateRouteEmailVerified from "../privateRouteEmailVerified"
import PrivateRouteMember from "../privateRouteMember"
import PrivateRouteStorageAvailable from "../privateRouteStorageAvailable"
import PrivateRouteUser from "../privateRouteUser"

import ClientPayments from "../../pages/manage-business/ClientPayments"
import CoachAuthedNav from "../navigation/CoachAuthedNav"
import CoachContacts from "../../pages/contacts/CoachContacts"
import CoachDashboard from "../../pages/dashboard/CoachDashboard/index"
import CoachInbox from "../../pages/inbox/CoachInbox"
import CoachPrivateProfile from "../../pages/manage-business/profile/CoachPrivateProfile"
import CoachPublicProfile from "../../pages/public-profile/CoachPublicProfile"
import CoachScheduling from "../../pages/scheduling/CoachScheduling"
import ConfirmEmail from "../../pages/confirm-email/ConfirmEmail"
import ContactServicesFlow from "../contacts/contact-profile/ContactServicesFlow"
import DynamicContactProfile from "../contacts/contact-profile/DynamicContactProfile"
import FullBlogPost from "../coach-public-profile/FullBlogPost"
import ListBlogCards from "../coach-profile/ListBlogCards"
import ManageBusiness from "../../pages/manage-business/ManageBusiness"
import MarketplaceNav from "../navigation/MarketplaceNav"
import MemberAuthedNav from "../navigation/MemberAuthedNav"
import MemberContacts from "../../pages/contacts/MemberContacts"
import MemberDashboard from "../../pages/dashboard/MemberDashboard/index"
import MemberInbox from "../../pages/inbox/MemberInbox"
import MemberScheduling from "../../pages/scheduling/MemberScheduling"
import MobileNav from "../app-navigation/MobileNav"
import NoMatch from "../NoMatch"
import NonUserSubscriptionCtaBanner from "../app-navigation/NonUserSubscriptionCtaBanner"
import NotesPDFPreview from "../notes/NotesPDFPreview"
import NotificationAlert from "../alerts/NotificationAlert"
import Notifications from "../../pages/notifications/Notifications"
import PopupNotification from "../alerts/PopupNotification"
import PrivateRouteSubscription from "../privateRouteSubscription"
import ProfileAboutTab from "../coach-private-profile/normal-state/ProfileAboutTab"
import ProgramCheckout from "../services/ProgramCheckout"
import PublicCredentials from "../coach-public-profile/PublicCredentials"
import PublicProfileServices from "../coach-public-profile/PublicProfileServices"
import PublicServiceDetails from "../coach-public-profile/PublicServiceDetails"
import QuickSubscriptionCheckout from "../subscription/QuickSubscriptionCheckout"
import ReferACoach from "../../pages/manage-business/ReferACoach"
import ResetPassword from "../../pages/reset-password/ResetPassword"
import Services from "../../pages/manage-business/Services"
import SessionDraggable from "../sessions/SessionDraggable"
import Sessions from "../../pages/sessions/Sessions"
import SessionsRouter from "../../pages/sessions/SessionsRouter"
import Subscription from "../../pages/subscription/Subscription"
import SubscriptionCtaBanner from "../app-navigation/SubscriptionCtaBanner"
import useLoading from "../../hooks/useLoading"
import { AllContacts } from "../../pages/contacts/AllContacts"
import { ContactsLayout } from "../../pages/contacts/ContactsLayout"
import { FindACoachPage } from "../../pages/find-a-coach/page"
import { Leads } from "../../pages/contacts/Leads"
import { Prospects } from "../../pages/contacts/Prospects"
import { QuickSession } from "../../pages/sessions/QuickSession"
import { ReceivedInvites } from "../../pages/contacts/ReceivedInvites"
import { SentInvites } from "../../pages/contacts/SentInvites"
import { StandardAlert } from "../alerts/StandardAlert"
import { TestingPage } from "../../pages/testing"
import { useCurrentWidth } from "../../hooks/useWidth"
import ICFUpgradeSubscription from "../../pages/(icf)/ICFUpgradeSubscription"

const App = () => {
  const { user } = useContext(UserCtx)
  const {
    success,
    error,
    renderError,
    renderSuccess,
    updateNextStepsData,
    popupNotification,
    endActiveSession,
  } = useContext(CommonFunctionCtx)
  const { setNotificationAlert, notificationAlert, setLastWebsocketMessage } =
    useContext(NotificationCtx)
  const {
    showSubscribeBanner,
    showSubscriptionCheckout,
    setShowSubscriptionCheckout,
  } = useContext(SubscriptionCtx)

  const { subscription, selectedSubscription, showSubscriptionPlans, plans } =
    useContext(SubscriptionContext)
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLoading()

  // TODO
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [, setShowContactProfile] = useState<boolean>(false)
  const [activeContactProfile, setActiveContactProfile] = useState<any>(null)
  const isConnected = useHMSStore(selectIsConnectedToRoom)
  const [showDraggable, setShowDraggable] = useState<boolean>(false)
  const history = useNavigate()
  const width = useCurrentWidth()
  const [, setPhoneVerified] = useState<boolean>(user?.phoneVerified)

  const matchesPrivatePathname = () => {
    const privatePaths = [
      "coach",
      "member",
      "notifications",
      "account-settings",
      "feature-request",
      "report-bug",
      "nav-mobile",
      "affiliate",
    ]
    const routeFirstPart = window.location.pathname.split("/")[1]
    return privatePaths.includes(routeFirstPart)
  }

  // **********
  // WEBSOCKETS
  // **********
  const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || ""
  const [, wsSetMessageHistory] = useState<any[]>([])
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(wsUrl, {
    onOpen: () => wsSendUserDetails(),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState]

  const wsSendUserDetails = () => {
    if (!user) {
      console.log("websocket: no user")
    } else {
      const payload = {
        action: "websocketGetConnectionDetails",
        user_id: user.user_id,
      }
      sendJsonMessage(payload)
    }
  }

  const parseWebsocketNotificationMessage = (event: MessageEvent<any>) => {
    const parsedObj = JSON.parse(event.data)

    if (parsedObj.type === "notification") {
      setNotificationAlert({
        message: parsedObj.message,
        show: true,
        link: parsedObj.data?.link,
        profile_type: parsedObj.data?.profile_type || "coach",
      })
    }
  }

  const checkStripeRedirectParams = () => {
    startLoading()
    const params = new URLSearchParams(window.location.search)
    const redirectStatus = params.get("redirect_status")
    const quickCheckout = params.get("quickcheckout")
    if (redirectStatus && quickCheckout) {
      if (redirectStatus === "succeeded") {
        renderSuccess("Added Payment Method!")
        setTimeout(() => {
          setShowSubscriptionCheckout(true)
          stopLoading()
        }, 2000)
      } else {
        renderError(
          "Adding payment method failed. Please check your details and try again."
        )
        setShowSubscriptionCheckout(true)
        stopLoading()
      }
    } else {
      stopLoading()
    }
  }

  // USE EFFECTS

  useEffect(() => {
    checkStripeRedirectParams()
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      // TODO remove debug
      // console.log("lastMessage", JSON.parse(lastMessage.data));
      setLastWebsocketMessage(JSON.parse(lastMessage.data))
      // THIS IS WHERE INCOMING MESSAGES FROM SERVER SHOULD BE PARSED

      parseWebsocketNotificationMessage(lastMessage)

      wsSetMessageHistory((prev) => prev.concat(lastMessage))
    }
  }, [lastMessage, wsSetMessageHistory])

  useEffect(() => {
    if (width >= 768 && window.location.pathname === "/nav-mobile") {
      navigate("/")
    }
  }, [width])

  useEffect(() => {
    window.location.pathname === `/${user?.activeProfile}/sessions` ||
    window.location.pathname.includes(`/sessions/`)
      ? setShowDraggable(false)
      : setShowDraggable(true)
  }, [history])

  useEffect(() => {
    wsSendUserDetails()
  }, [user])

  useEffect(() => {
    console.log("ws connectionStatus:", connectionStatus)
  }, [readyState])

  useEffect(() => {
    window.addEventListener("unload", () => updateNextStepsData)
    window.addEventListener("unload", endActiveSession)
    return () => {
      window.removeEventListener("unload", () => updateNextStepsData)
      window.removeEventListener("unload", endActiveSession)
      const created_chat = false
      updateNextStepsData(created_chat)
      endActiveSession()
    }
  }, [])

  return (
    <>
      <div className="app bg-background relative md:block">
        <StandardAlert {...error} variant="error" />
        <StandardAlert {...success} variant="success" />

        {popupNotification.show && (
          <PopupNotification popupNotification={popupNotification} />
        )}

        {notificationAlert.show && (
          <div className="fixed left-1/2 z-50 mx-auto w-full max-w-screen-xl -translate-x-1/2 -translate-y-1/2 transform p-2">
            <NotificationAlert notification={notificationAlert} />
          </div>
        )}

        {showSubscriptionCheckout && user.activeProfile === "coach" && (
          <QuickSubscriptionCheckout />
        )}

        {/* Draggable session video */}
        {isConnected && showDraggable && (
          <div className="hidden md:block">
            <SessionDraggable />
          </div>
        )}

        {/* Navigation */}
        {/* {user ? (
          <>
            {user.activeProfile === "coach" && <SubscriptionCtaBanner />}
            {user.activeProfile === "member" && (
              <NonUserSubscriptionCtaBanner />
            )}
          </>
        ) : (
          <NonUserSubscriptionCtaBanner />
        )} */}

        {/* Navigation */}
        {user && matchesPrivatePathname() ? (
          <>
            {user.activeProfile === "coach" ? (
              <>
                <CoachAuthedNav />
                <div className="relative md:hidden">
                  {showMobileNav && (
                    <MobileNav setShowMobileNav={setShowMobileNav} />
                  )}
                </div>
              </>
            ) : (
              <>
                <MemberAuthedNav />
                <div className="relative md:hidden">
                  {showMobileNav && (
                    <MobileNav setShowMobileNav={setShowMobileNav} />
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {!window.location.pathname.includes("/direct-routes") && (
              <>
                {window.location.pathname.includes("/find-a-coach") && (
                  <MarketplaceNav />
                )}
              </>
            )}
          </>
        )}

        {/* Routing */}
        <Routes>
          {/* Main Routes */}
          <Route path="/direct-routes" element={<DirectLinkRouter />} />

          {/* Public pages */}
          <Route element={<MarketingPageLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/why-zoee" element={<WhyZoeePage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/blog">
              <Route index element={<BlogPage />} />
              <Route path="/blog/:slug" element={<PostPage />} />
            </Route>
            <Route path="/contact" element={<ContactPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            <Route
              path="/affiliate-program-terms"
              element={<AffiliateProgramTermsPage />}
            />
            <Route path="/welcome" element={<Landing variation="normal" />} />
            <Route
              path="/moneyflow"
              element={<Landing variation="moneyflow" />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />

          <Route path="/find-a-coach">
            <Route index element={<FindACoachPage />} />
          </Route>

          {/* ICF Page */}
          <Route element={<IcfPageLayout />}>
            <Route path="/icf" element={<IcfRegistrationPage />} />
            <Route path="/icf/registration" element={<ICFSubscription/>} />
            <Route path="/icf/subscription" element={<ICFSubscriptionCheckout />} />
            <Route path="/icf/checkout" element={<ICFUpgradeSubscription/>} />
          </Route>

          {/* Coach Public Profile */}
          <Route
            path="/find-a-coach/:endpoint_slug"
            element={<CoachPublicProfile />}
          >
            <Route
              path="/find-a-coach/:endpoint_slug/services"
              element={<PublicProfileServices />}
            />
            <Route
              path="/find-a-coach/:endpoint_slug/blog"
              element={<ListBlogCards />}
            />
            <Route
              path="/find-a-coach/:endpoint_slug/credentials"
              element={<PublicCredentials />}
            />
            <Route
              path="/find-a-coach/:endpoint_slug/about"
              element={<ProfileAboutTab />}
            />
          </Route>

          <Route
            path="/find-a-coach/:endpoint_slug/services/:service_id"
            element={<PublicServiceDetails />}
          />
          <Route
            path="/find-a-coach/:endpoint_slug/blog/:post_id"
            element={<FullBlogPost />}
          />
          {/* Public Quick Session Routes */}
          <Route path="/sessions/:room_code" element={<QuickSession />} />

          {/* Private Routes */}
          <Route element={<PrivateRouteStorageAvailable />}>
            <Route element={<PrivateRouteEmailVerified />}>
              <Route element={<PrivateRouteUser />}>
                <Route path="account-settings">
                  <Route
                    index
                    element={
                      <AccountSettingsPage
                        setShowSubscriptionCheckout={
                          setShowSubscriptionCheckout
                        }
                      />
                    }
                  />
                  <Route path="personal-info" element={<PersonalInfoPage />} />
                  <Route
                    path="login-security"
                    element={<LoginSecurityPage />}
                  />
                  <Route
                    path="payment-methods"
                    element={<PaymentMethodsPage />}
                  />
                  <Route path="calendars" element={<CalendarSettingsPage />} />
                  <Route path="notifications" element={<NotificationsPage />} />
                </Route>
                <Route path="notifications" element={<Notifications />} />
                <Route
                  path="nav-mobile"
                  element={<MobileNav setShowMobileNav={setShowMobileNav} />}
                />
                <Route path="sessions" element={<SessionsRouter />} />
                <Route path="affiliate" element={<ReferACoach />} />

                {/* Coach Routes */}

                <Route path="coach">
                  <Route element={<PrivateRouteCoach />}>
                    <Route index element={<ManageBusiness />} />
                    <Route path="profile" element={<CoachPrivateProfile />} />
                    <Route path="services" element={<Services />} />
                    <Route
                      path="client-payments"
                      element={<ClientPayments />}
                    />
                    <Route path="dashboard" element={<CoachDashboard />} />
                    <Route path="inbox" element={<CoachInbox />} />
                    <Route
                      path="sessions/:meeting_id?"
                      element={<Sessions />}
                    />
                    <Route path="contacts">
                      <Route
                        index
                        element={
                          <CoachContacts
                            setShowContactProfile={setShowContactProfile}
                          />
                        }
                      />
                      <Route element={<PrivateRouteContact />}>
                        <Route
                          path="/coach/contacts/:contact_id"
                          element={
                            <DynamicContactProfile
                              setActiveContactProfile={setActiveContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/coach/contacts/:contact_id/services"
                          element={
                            <ContactServicesFlow
                              contact={activeContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/coach/contacts/:contact_id/notes"
                          element={<NotesPDFPreview />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="scheduling"
                      element={<CoachScheduling isSchedulePage />}
                    />
                  </Route>
                  <Route element={<PrivateRouteSubscription />}>
                    <Route
                      path="subscription"
                      element={
                        showSubscriptionCheckout ? (
                          <QuickSubscriptionCheckout />
                        ) : (
                          <Subscription />
                        )
                      }
                    />
                  </Route>
                </Route>


                {/* Member Routes */}

                <Route path="member">
                  <Route element={<PrivateRouteMember />}>
                    <Route index element={<MemberDashboard />} />
                    <Route
                      path="inbox"
                      element={
                        <MemberInbox
                          phone={user?.phone}
                          setPhoneVerified={setPhoneVerified}
                        />
                      }
                    />
                    <Route
                      path="sessions/:meeting_id?"
                      element={<Sessions />}
                    />
                    {/* <Route path="/member/sessions/:session_id" element={<ConsultationSessionLanding />} /> */}
                    <Route path="contacts">
                      <Route
                        index
                        element={
                          <MemberContacts
                            setShowContactProfile={setShowContactProfile}
                          />
                        }
                      />
                      <Route element={<PrivateRouteContact />}>
                        <Route
                          path="/member/contacts/:contact_id"
                          element={
                            <DynamicContactProfile
                              setActiveContactProfile={setActiveContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/member/contacts/:contact_id/services"
                          element={
                            <ContactServicesFlow
                              contact={activeContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/member/contacts/:contact_id/services/:service_id"
                          element={<ProgramCheckout />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="scheduling"
                      element={<MemberScheduling isSchedulePage />}
                    />
                  </Route>
                </Route>

                {/* V2 Routes */}
                {/* TODO implement private route coach logic */}
                {/* TODO subscription banner */}
                <Route path="v2">
                  <Route
                    path="coach"
                    element={
                      <>
                        <CoachAuthedNav />
                        <div className="container px-2.5 sm:px-4">
                          <Outlet />
                        </div>
                      </>
                    }
                  >
                    <Route path="contacts" element={<ContactsLayout />}>
                      <Route index element={<AllContacts />} />
                      <Route path="leads" element={<Leads />} />
                      <Route path="prospects" element={<Prospects />} />
                      <Route
                        path="received-invites"
                        element={<ReceivedInvites />}
                      />
                      <Route path="sent-invites" element={<SentInvites />} />
                    </Route>
                  </Route>
                </Route>
                {/* End V2 Routes */}
              </Route>
            </Route>
          </Route>

          {/* Public Routes */}

          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/testing/*" element={<TestingPage />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </>
  )
}

export default App
