import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { UserCtx } from "../../context/userContext";
import ForceVerifyEmail from "../../pages/ForceVerifyEmail";

const PrivateRouteEmailVerified = () => {
  const { user } = useContext(UserCtx);

  if (!user.emailVerified) {
    return <ForceVerifyEmail />;
  }

  return <Outlet />;
};

export default PrivateRouteEmailVerified;
