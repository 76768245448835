import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import type { Chart, ChartData } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import Loader from "../ui/loader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

// (GRADIENT) TODO: Fix creategradient function
// function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
//     // const colorStart = 'blue';
//     // const colorMid = faker.random.arrayElement(
//     //   colors.filter(color => color !== colorStart)
//     // );
//     // const colorEnd = faker.random.arrayElement(
//     //   colors.filter(color => color !== colorStart && color !== colorMid)
//     // );

//     const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

//     gradient.addColorStop(0, "#111111");
//     gradient.addColorStop(0.5, "#aabbcc");
//     gradient.addColorStop(1, "#9955dd");

//     return gradient;
// }

type Props = {
    options: any;
    chartData: ChartData<"line">;
};

const LineChart: React.FC<Props> = ({ chartData, options }) => {
    const chartRef = useRef<Chart<"line">>(null);
    // (GRADIENT) Needed for setting gradient fill
    // const [data, setData] = useState<ChartData<"line">>(chartData);

    const zoeeDefaultOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                position: "top",
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
        },
        fill: false,
        borderColor: "#5DC388",
        tension: 0.3,
        spanGaps: true,
    };
    
    // TODO: The default options and additional options prop combine successfully, but it is not reflected in the rendered Chart
    const combinedOptions = { ...zoeeDefaultOptions, ...options };
    // console.log("combinedoptions", combinedOptions);


    // (GRADIENT) TODO: Fix useEffect code for setting gradient fill
    // useEffect(() => {
    //     const chart = chartRef.current;

    //     if (!chart) {
    //         return;
    //     }

    //     const chartData = {
    //         ...data,
    //         datasets: data.datasets.map((dataset) => ({
    //             ...dataset,
    //             backgroundColor: createGradient(chart.ctx, chart.chartArea),
    //             borderColor: "#aabbcc"
    //         })),
    //     };

    //     console.log(chartData)

    //     setData(chartData);

    // }, [data && chartData]);

    return (
        <div className="w-full h-full relative">
            <Line ref={chartRef} options={combinedOptions} data={chartData} />
            {!chartData && (
                <div className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
                    <Loader />
                </div>
            )}
        </div>
    );
};

export default LineChart;
