import * as React from "react";
import { Button } from "../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";
import { UserInfo, VerifyEmail } from "../api/app.service";
import { UserCtx } from "../context/userContext";
import { CommonFunctionCtx } from "../context/commonFunctionContext";
import Loader from "../components/ui/loader";
import { SvgZoeeIcon } from "../components/icons/";

const ForceVerifyEmail: React.FC = () => {
  const { user } = React.useContext(UserCtx);
  const { renderError, hideAlert, getUser, renderSuccess } = React.useContext(
    CommonFunctionCtx,
  );
  const [sendingEmail, setSendingEmail] = React.useState<boolean>(false);

  const sendVerifyEmail = () => {
    setSendingEmail(true);
    if (user) {
      VerifyEmail.sendEmailCode({ user_id: parseInt(user.user_id) })
        .then((data: any) => {
          if (data.message === "complete") {
            renderSuccess("Email verification sent!");
            setSendingEmail(false);
          }
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        });
    }
  };

  const checkForVerification = async () => {
    hideAlert();
    await getUser();
    
    const userToValidateEmail = await UserInfo.getUser({ user_id: parseInt(user.user_id) })
    
    if (userToValidateEmail.user_data.email_verified === false) {
      renderError("Please check your email to verify");
    }
  };

  return (
    <Dialog open={true} onOpenChange={checkForVerification} modal={true}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-center">
            You have been sent a Verification Email!
          </DialogTitle>
        </DialogHeader>

        <DialogDescription className="text-center">
          Please check your inbox for a message from no-reply@zoee.com. Follow
          the instructions in the email to get started and unlock a world of
          possibilities! Let us know how we can best support you on your
          journey.
        </DialogDescription>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {sendingEmail
            ? (
              <div className="flex items-center justify-center">
                <Loader />
              </div>
            )
            : (
              <Button
                onClick={sendVerifyEmail}
                size="lg"
                disabled={sendingEmail}
                variant="secondary"
              >
                Resend Verification
              </Button>
            )}

          <Button
            onClick={checkForVerification}
            size="lg"
          >
            Check Verification
          </Button>
        </div>

        <DialogDescription className="text-center">
          We take the privacy and security of your account very seriously.
        </DialogDescription>

        <SvgZoeeIcon className="mx-auto" />
      </DialogContent>
    </Dialog>
  );
};

export default ForceVerifyEmail;
