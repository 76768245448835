import * as React from "react";
import SavedCard from "../../../../components/account-settings/payment-methods/SavedCard";
import { PaymentRequests } from "../../../../api/app.service";
import { UserCtx } from "../../../../context/userContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import PaymentMethodModal from "../../../../components/account-settings/payment-methods/PaymentMethodModal";
import DeleteCardModal from "../../../../components/account-settings/payment-methods/DeleteCardModal";
import Loader from "../../../../components/ui/loader";
import useLoading from "../../../../hooks/useLoading";
import { Button } from "../../../../components/ui/button";
import {
  SvgLock2,
  SvgPlus,
  SvgProtectedPayment,
} from "../../../../components/icons/";

const PaymentMethodsPage: React.FC = () => {
  const { user } = React.useContext(UserCtx);
  const { renderError, setPopupNotification, hideAlert } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [paymentMethods, setPaymentMethods] = React.useState<any[]>([]);
  const [paymentMethodModal, setPaymentMethodModal] =
    React.useState<boolean>(false);
  const [showDeleteCard, setShowDeleteCard] = React.useState<any>({
    show: false,
    card: {},
  });
  const [options, setOptions] = React.useState<any>({});
  const [loadingPaymentMethods, setLoadingPaymentMethods] =
    React.useState<boolean>(false);

  const createSetupIntent = () => {
    startLoading();
    if (user) {
      PaymentRequests.createSetupIntent({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setOptions({ clientSecret: data.stripe_client_secret });
          setPaymentMethodModal(true);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const getPaymentMethods = () => {
    setLoadingPaymentMethods(true);
    if (user) {
      PaymentRequests.getPaymentMethods({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setPaymentMethods(data.payment_methods);
          setLoadingPaymentMethods(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
          setLoadingPaymentMethods(false);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const delayedGetPaymentMethods = () => {
    setLoadingPaymentMethods(true);
    setTimeout(() => {
      getPaymentMethods();
    }, 2500);
  };

  const checkUrlParams = () => {
    setLoadingPaymentMethods(true);
    const params = new URLSearchParams(window.location.search);
    const redirectStatus = params.get("redirect_status");
    if (redirectStatus) {
      if (redirectStatus === "succeeded") {
        setPopupNotification({
          show: true,
          title: "Added Payment Method!",
          message:
            "You can now use this method of payment throughout the Zoee application. 🎉",
          callback: delayedGetPaymentMethods,
        });
        setLoadingPaymentMethods(false);
      } else {
        renderError(
          "Adding payment method failed. Please check your details and try again.",
        );
        setLoadingPaymentMethods(false);
      }
      window.history.replaceState(null, "", window.location.pathname);
    } else {
      window.history.replaceState(null, "", window.location.pathname);
      getPaymentMethods();
    }
  };

  React.useEffect(() => {
    checkUrlParams();
  }, []);

  return (
    <>
      {showDeleteCard.show && (
        <DeleteCardModal
          setShowDeleteCard={setShowDeleteCard}
          showDeleteCard={showDeleteCard}
          getPaymentMethods={getPaymentMethods}
        />
      )}
      {paymentMethodModal && (
        <PaymentMethodModal
          callback={setPaymentMethodModal}
          options={options}
        />
      )}
      <div className="mx-auto flex w-full flex-col px-[20px] xl:w-[1280px]">
        <div className="mb-[32px] flex w-full items-center justify-between">
          <h1 className="hidden text-[36px] font-bold md:block">
            My Payment Methods
          </h1>
          <Button className="mx-auto md:m-0" onClick={createSetupIntent}>
            Add New Card
            <SvgPlus />
          </Button>
        </div>

        <div className="flex w-full flex-col items-start justify-between md:flex-row">
          {loadingPaymentMethods ? (
            <div className="flex w-full flex-col items-center gap-[12px]">
              <Loader />
              <h3 className="mb-[4px] text-center text-[16px] font-bold">
                Checking for payment methods...
              </h3>
            </div>
          ) : (
            <div
              className={`flex w-full ${paymentMethods.length === 0 ? "flex-row" : "flex-col"} mb-[54px] items-center justify-center gap-[16px] md:mb-0 md:flex-col md:items-start md:justify-start md:gap-[32px]`}
            >
              {paymentMethods.length === 0 ? (
                <div className="bg-hover flex h-[64px] w-full items-center rounded-[16px] px-[24px] text-base font-bold md:h-[78px] md:text-[16px] xl:w-[900px]">
                  No Payment Methods
                </div>
              ) : (
                <div className="flex w-full flex-col gap-[8px] xl:w-[900px]">
                  {paymentMethods.map((card) => (
                    <SavedCard
                      card={card}
                      setShowDeleteCard={setShowDeleteCard}
                      getPaymentMethods={getPaymentMethods}
                      key={card.stripe_payment_method_id}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="main-shadow relative mx-auto flex h-[360px] min-w-[316px] flex-col items-center overflow-hidden rounded-[16px] md:ml-[32px] md:mr-0">
            <SvgProtectedPayment className="text-gray absolute h-40 w-40" />
            <p className="z-[5] mb-[47px] mt-[201px] w-[252px] text-center text-[16px]">
              {" "}
              Zoee protects your payment information with Stripe.
              <span
                onClick={() =>
                  window.open(
                    "https://intercom.help/zoee/en/articles/8623117-how-does-zoee-protect-your-information-on-stripe",
                    "_blank",
                  )
                }
                className="text-blurple cursor-pointer font-bold"
              >
                {" "}
                Learn more.
              </span>
            </p>
            <span className="text-muted-foreground flex items-center gap-2 text-base">
              <SvgLock2 className="h-4 w-4" />
              Safe & Secure Payments with Stripe
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethodsPage;
