// TODO: make the search work better

import * as React from "react";
import DateRangePicker from "../date-time/DateRangePicker";
import InvoiceCard from "./InvoiceCard";
import Select, { type SingleValue } from "react-select";
import { ClientPaymentsItem } from "../../types/clientPaymentTypes";
import { SvgSearch } from "../icons";

type Props = {
  setActiveDates?: React.Dispatch<React.SetStateAction<Date[] | null>>;
  clientPaymentsData: Array<ClientPaymentsItem> | null;
};

const Invoices: React.FC<Props> = ({
  setActiveDates,
  clientPaymentsData,
}) => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [activeServiceOption, setActiveServiceOption] = React.useState<
    string | null
  >(null);
  const [activeClientOption, setActiveClientOption] = React.useState<
    number | null
  >(null);
  const [activeStatusOption, setActiveStatusOption] = React.useState<
    string | null
  >(null);
  const [filteredInvoices, setFilteredInvoices] = React.useState<
    ClientPaymentsItem[] | null
  >(clientPaymentsData);

  const serviceOptions = (clientPaymentsData || [])
    .map(function (e) {
      return e.service_name;
    })
    .filter(function (element, index, arr) {
      return arr.indexOf(element) === index;
    })
    .map(function (e) {
      return {
        value: e,
        label: e,
      };
    });

  const handleServiceSelectChange = (
    e: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => {
    if (!e) {
      setActiveServiceOption(null);
      return;
    }

    setActiveServiceOption(e.value);
  };

  const statusOptions = [
    {
      value: "charge",
      label: "Paid",
    },
    {
      value: "refund",
      label: "Refund",
    },
    {
      value: "failed",
      label: "Failed",
    },
  ];

  const handleStatusSelectChange = (
    e: SingleValue<{
      value: string;
      label: string;
    }>,
  ) => {
    if (!e) {
      setActiveStatusOption(null);
      return;
    }

    setActiveStatusOption(e.value);
  };

  const uniqueUserIds: number[] = [];
  const clientOptions = (clientPaymentsData || []).reduce(
    (acc: { value: number; label: string }[], clientPayment) => {
      const clientName =
        `${clientPayment.first_name} ${clientPayment.last_name}`;
      const userId = clientPayment.user_id;

      if (!uniqueUserIds.includes(userId)) {
        uniqueUserIds.push(userId);
        acc.push({ value: userId, label: clientName });
      }

      return acc;
    },
    [],
  );

  const handleClientSelectChange = (
    e: SingleValue<{
      value: number;
      label: string;
    }>,
  ) => {
    if (!e) {
      setActiveClientOption(null);
      return;
    }

    setActiveClientOption(e.value);
  };

  React.useEffect(() => {
    if (
      activeServiceOption ||
      activeStatusOption ||
      activeClientOption
    ) {
      let filteredData = clientPaymentsData;

      if (activeServiceOption) {
        filteredData = filteredData?.filter(
          (e) => e.service_name === activeServiceOption,
        ) || null;
      }
      if (activeStatusOption) {
        filteredData = filteredData?.filter(
          (e) => e.stripe_type === activeStatusOption,
        ) || null;
      }
      if (activeClientOption) {
        filteredData = filteredData?.filter(
          (e) => e.user_id === activeClientOption,
        ) || null;
      }

      setFilteredInvoices(filteredData);
    } else {
      setFilteredInvoices(clientPaymentsData);
    }
  }, [
    activeServiceOption,
    activeStatusOption,
    activeClientOption,
    clientPaymentsData,
  ]);

  const updateSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  React.useEffect(() => {
    if (!filteredInvoices) {
      setFilteredInvoices(null);
    }

    const keyword = searchValue;
    if (keyword !== "") {
      const results = filteredInvoices?.filter((inv) => {
        const clientName = inv.first_name + inv.last_name;
        const serviceName = inv.service_name;
        return (
          clientName.toString().toLowerCase().includes(keyword.toLowerCase()) ||
          serviceName.toString().toLowerCase().includes(keyword.toLowerCase())
        );
      });

      if (results) {
        setFilteredInvoices(results);
      } else {
        setFilteredInvoices(null);
      }
    } else {
      setFilteredInvoices(clientPaymentsData);
      // TODO Reapply Select filters
    }
  }, [searchValue]);

  return (
    <div>
      <div className="mb-[24px]">
        {setActiveDates && <DateRangePicker setActiveDates={setActiveDates} />}
      </div>
      <div className="min-h-[400px] px-0 md:p-[24px] rounded-[16px]">
        <div className="flex flex-col md:flex-row items-center justify-between gap-[12px] md:gap-0 mb-[48px] px-[16px] md:px-0 w-full">
          <div className="default-input flex items-center md:mr-[12px] w-[342px] ">
            <SvgSearch className="left-[12px] absolute top-[50%] -translate-y-[50%]" />
            <input
              className="pl-[24px] h-full pt-[2px]"
              type="text"
              value={searchValue}
              onChange={updateSearchValue}
            />
          </div>
          <div className="flex items-center gap-[8px]">
            <Select
              className="basic-single w-[110px] md:w-[160px] border-grayCloud text-graySlate rounded-[10px]"
              classNamePrefix="select"
              options={serviceOptions}
              name="Service"
              placeholder="Service"
              onChange={handleServiceSelectChange}
              isClearable={true}
            />
            <Select
              className="basic-single w-[110px] md:w-[160px] border-grayCloud text-graySlate rounded-[10px]"
              classNamePrefix="select"
              options={statusOptions}
              name="Status"
              placeholder="Status"
              isClearable={true}
              onChange={handleStatusSelectChange}
            />
            <Select
              className="basic-single w-[110px] md:w-[160px] border-grayCloud text-graySlate rounded-[10px]"
              classNamePrefix="select"
              options={clientOptions}
              name="Client"
              placeholder="Client"
              onChange={handleClientSelectChange}
              isClearable={true}
            />
          </div>
        </div>

        <div className="hidden md:grid grid-cols-12 px-[24px] mb-[16px] text-base text-graySlate">
          <p className="col-span-1">PAID ON</p>
          <p className="col-span-4">CLIENT</p>
          <p className="col-span-3">SERVICE</p>
          <p className="col-span-2">STATUS</p>
          <p className="col-span-2">AMOUNT</p>
        </div>

        <div className="w-full mb-[32px]">
          {filteredInvoices
            ? (
              <>
                {filteredInvoices.length > 0
                  ? (
                    <>
                      {filteredInvoices.map((inv: ClientPaymentsItem) => {
                        return (
                          <div key={inv.stripe_invoice_id} className="mb-[8px]">
                            <InvoiceCard invoiceData={inv} />
                          </div>
                        );
                      })}
                    </>
                  )
                  : (
                    <>
                      <div className="pt-[64px] w-full flex justify-center">
                        <h4 className="font-bold">
                          You don't have any invoices for this date range
                        </h4>
                      </div>
                    </>
                  )}
              </>
            )
            : (
              <div className="pt-[64px] w-full flex justify-center">
                <h4 className="font-bold">You don't have any invoices yet</h4>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Invoices;
