import * as React from "react";
import moment from "moment";
import { UserCtx } from "../../../context/userContext";
import { cn } from "../../../utils/cn";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  contact: any;
}

export const ContactStatusTag: React.FC<Props> = ({ contact }) => {
  const { user } = React.useContext(UserCtx);

  const getRequestedTag = () => {
    const date = moment(contact.contact_date).format("ll");
    if (origin === "full") {
      return `Requested on ${date}`;
    } else {
      return "Requested";
    }
  };

  const getStatusInfo = () => {
    if (user?.activeProfile === "coach") {
      switch (contact.contact_type) {
        case "connected":
          switch (contact.status) {
            case "zoee lead":
              return { color: "bg-primaryBlue", text: "Lead" };
            case "lead":
              return { color: "bg-blurple", text: "Zoee Lead" };
            case "connected":
              return { color: "bg-grassGreen", text: "Member" };
            default:
              return { color: "bg-vividRed", text: "Invalid" };
          }
        case "prospect":
          return { color: "bg-fallOrange", text: "Prospect" };
        case "invited":
          return { color: "bg-mainBlack", text: "Invited" };
        case "requested":
          return { color: "bg-vividOrange", text: getRequestedTag() };
        default:
          return { color: "bg-vividRed", text: "Invalid" };
      }
    }

    if (user?.activeProfile === "member") {
      return { color: "bg-green", text: "Coach" };
    }

    return { color: "bg-vividRed", text: "Invalid" };
  };

  const { color, text } = getStatusInfo();

  return (
    <div className="inline-flex items-center gap-1.5 rounded-lg bg-white px-2 py-1.5 text-base font-bold">
      <div className={cn("h-2 w-2 rounded-full", color)}></div>
      {text}
    </div>
  );
};
