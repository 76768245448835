import React, { useRef, type FC } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import InboxMessaging from "../../components/inbox/InboxMessaging";
import InboxActivity from "../../components/inbox/InboxActivity";
import EditChatPopover from "../../components/inbox/EditChatPopover";
import { StreamChat } from "stream-chat";
import { Chat, LoadingIndicator } from "stream-chat-react";
import "@stream-io/stream-chat-css/dist/css/index.css";
import { UserCtx } from "../../context/userContext";
import { ShowChatCtx } from "../../context/showChatContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { NotificationCtx } from "../../context/notificationContext";
import { NotificationRequests } from "../../api/app.service";
import useLoading from "../../hooks/useLoading";
import { Button } from "../../components/ui/button";
import { SvgChat } from "../../components/icons/";

const CoachInbox: FC = () => {
    const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
    const { startLoading } = useLoading();
    const { inboxNotifications, setInboxNotifications} = React.useContext(NotificationCtx);
    const [showMessages, setShowMessages] = React.useState<boolean>(true);
    const [showActivity, setShowActivity] = React.useState<boolean>(false);
    const [showChat, setShowChat] = React.useState<boolean>(false);
    const [editChatPopover, setEditChatPopover] = React.useState<boolean>(false);
    const [client, setClient] = React.useState(null);
    const [desktop, setDesktop] = React.useState<boolean>(window.innerWidth > 768);
    const [, setNewChatMenu] = React.useState<boolean>(false);
    const [selectNewChatParams, setSelectNewChatParams] = React.useState<any>({
        show: false,
        messageType: "",
    });
    const { user } = React.useContext(UserCtx);
    const ref = useRef(null);
    const showChatValue = {
        showChat,
        setShowChat,
    };

    const setHeight = () => {
        const auth = document.getElementById("authentication");
        if (auth) {
            auth.style.minHeight = window.innerHeight + "px";
        }
    };

    const updateMedia = () => {
        setDesktop(window.innerWidth > 768);
    };

    const addWidthListener = () => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    };

    const initChat = async () => {
        const streamToken =
            user.activeProfile === "coach"
                ? user.coachProfile.stream_token
                : user.memberProfile.stream_token;
        const streamClientId = process.env.REACT_APP_STREAM_CLIENT_ID;
        const avatar = user.hasAvatar
            ? user.avatar_url
            : null;
        if (streamClientId) {
            const client: any = StreamChat.getInstance(streamClientId);
            await client.connectUser(
                {
                    id: user.activeProfileId.toString(),
                    image: avatar,
                    user_color: `bg-${user?.userColor}`
                },
                streamToken.toString()
            );
            setClient(client);
        }
    };

    const handleDisconnectUser = () => {
        const streamClientId = process.env.REACT_APP_STREAM_CLIENT_ID;
        if (streamClientId) {
            const client: any = StreamChat.getInstance(streamClientId);
            client.disconnectUser();
        }
    };

    const markInboxNotificationsAsRead = () => {    
        if (inboxNotifications.length === 0) return;
        
        const unreadNotificationIds = inboxNotifications
            .filter((n: any) => n.viewed === 0)
            .map((n: any) => n.id);
        NotificationRequests.markRead({
            profile_id: parseInt(user.activeProfileId),
            notification_ids: unreadNotificationIds,
        })
            .then(() => {
                const allReadContactsNotifications = inboxNotifications.map((n: any) => {
                    n.viewed = true;
                    return n;
                });
                setInboxNotifications(allReadContactsNotifications);
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
                setTimeout(() => hideAlert(), 5000);
            });
    };

    React.useEffect(() => {
        if (inboxNotifications.length !== 0) {
            markInboxNotificationsAsRead()
        }

        initChat();
        addWidthListener();
        let deviceWidth = window.matchMedia("(max-width: 768px)");
        if (deviceWidth.matches) {
            window.addEventListener("resize", setHeight);
            setHeight();
        }
        window.addEventListener("unload", handleDisconnectUser);
        return () => {
            window.removeEventListener("unload", handleDisconnectUser);
            handleDisconnectUser();
        };
    }, []);

    useOutsideClick(ref, () => {
        setNewChatMenu(false);
    });

    if (!client) {
        return <LoadingIndicator />;
    }

    return (
        <div className="w-full md:max-w-[1112px] px-[16px] md:m-auto md:pt-0 pt-[8px] flex flex-col items-center">
            <Chat client={client}>
                {/* MOBILE */}
                <div className="md:hidden">
                    {!showActivity && (
                        <div className="w-full flex justify-center mt-[24px]">
                            <Button
                                onClick={() =>
                                    setSelectNewChatParams({
                                        show: true,
                                        messageType: "direct",
                                    })
                                }
                            >
                                New Chat
                                <SvgChat />
                            </Button>
                        </div>
                    )}
                    <div className="mt-[16px]">
                        <ShowChatCtx.Provider value={showChatValue}>
                            {showMessages && (
                                <InboxMessaging
                                    selectNewChatParams={selectNewChatParams}
                                    setSelectNewChatParams={setSelectNewChatParams}
                                    setEditChatPopover={setEditChatPopover}
                                    editChatPopover={editChatPopover}
                                    desktop={desktop}
                                />
                            )}
                        </ShowChatCtx.Provider>
                    </div>

                    <div>{showActivity && <InboxActivity />}</div>
                </div>

                {/* DESKTOP */}
                <div className="md:block hidden">
                    {(!showChat || desktop) && (
                        <div
                            className={`w-full py-[16px] px-[20px] flex justify-between items-center`}
                        >
                            {!showActivity && (
                                <div 
                                className="ml-auto"
                                ref={ref}>
                                    <Button
                                        onClick={() => {
                                            startLoading();
                                            setSelectNewChatParams({show: true, messageType: "direct"})
                                        }}
                                    >
                                        New Chat
                                        <SvgChat />
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                    <ShowChatCtx.Provider value={showChatValue}>
                        {showMessages && (
                            <InboxMessaging
                                selectNewChatParams={selectNewChatParams}
                                setSelectNewChatParams={setSelectNewChatParams}
                                setEditChatPopover={setEditChatPopover}
                                editChatPopover={editChatPopover}
                                desktop={desktop}
                            />
                        )}
                    </ShowChatCtx.Provider>
                    {showActivity && <InboxActivity />}
                    {editChatPopover && <EditChatPopover setEditChatPopover={setEditChatPopover} />}
                </div>
            </Chat>
        </div>
    );
};

export default CoachInbox;
