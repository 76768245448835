import React, { FC } from "react";
import ConnectedContactCard from "../../../cards/ConnectedContactCard";
import SelectedContactsOverlay from "../SelectedContactsOverlay";
import { Meeting } from "../../../../types/meetingTypes";
import { UserCtx } from "../../../../context/userContext";
import { Link } from "react-router-dom";
import MobileInviteContactsListItem from "./MobileInviteContactsListItem";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import Loader from "../../../ui/loader";
import { Button } from "../../../ui/button";
import { SvgAlert, SvgChevronDown, SvgPlus, SvgSearch } from "../../../icons";

type Props = {
  userContacts: any;
  newMeetingData: Meeting;
  setStep: any;
  setNewMeetingData: any;
  newMeetingType: "individual" | "group" | "custom" | null;
  isLoading: boolean;
};

const SelectContacts: FC<Props> = ({
  userContacts,
  setStep,
  newMeetingType,
  newMeetingData,
  setNewMeetingData,
  isLoading,
}) => {
  const { user } = React.useContext(UserCtx);
  const [showSelectedContactsOverlay, setShowSelectedContactsOverlay] =
    React.useState<boolean>(false);
  const [reachedMaxInvitedContacts, setReachedMaxInvitedContacts] =
    React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>("");

  //   const handleSearch = () => {
  //     return userContacts.filter((contact: any) =>
  //       contact.contact_name.toLowerCase().includes(serchValue.toLowerCase())
  //     );
  //   };

  console.log(userContacts);

  const handleSelect = (selectedId: number): void => {
    let contact = userContacts.find((c: any) => c.profile_id === selectedId);
    if (newMeetingType === "group" || newMeetingType === "custom") {
      if (
        !newMeetingData.contacts
          .map((c) => c.profile_id)
          .includes(contact.profile_id)
      ) {
        setNewMeetingData({
          ...newMeetingData,
          contacts: [...newMeetingData.contacts, contact],
        });
      } else {
        let temp = newMeetingData.contacts;
        temp.splice(
          temp.map((c) => c.profile_id).indexOf(contact.profile_id),
          1
        );
        setNewMeetingData({
          ...newMeetingData,
          contacts: [...temp],
        });
      }
    } else {
      setNewMeetingData({ ...newMeetingData, contacts: [contact] });
      setStep((previousStep: number) => previousStep + 1);
    }
  };

  React.useEffect(() => {
    newMeetingData.contacts.length > 0
      ? setShowSelectedContactsOverlay(true)
      : setShowSelectedContactsOverlay(false);
  }, [newMeetingData.contacts]);

  React.useEffect(() => {
    newMeetingData.contacts.length >= 6
      ? setReachedMaxInvitedContacts(true)
      : setReachedMaxInvitedContacts(false);
  }, [newMeetingData.contacts]);

  useScrollToTop();

  return (
    <>
      {/* HEADER ELEMENTS - SHARED ON MOBILE AND DESKTOP */}
      <div className="md:w-[798px] w-full relative">
        <h3 className="text-[18px] md:text-[28px] font-bold md:font-semibold mb-[10px] md:mb-[8px]">
          Invite Contacts
        </h3>
        <div className="md:absolute top-0 right-0 gap-[24px] flex items-center">
          <button className="hidden md:flex text-base font-bold items-center gap-[8px]">
            Sort by
            <SvgChevronDown />
          </button>
          <div className="search-input md:w-[250px] w-full">
            {/* TODO add search functionality */}
            <input
              type="text"
              placeholder="Search contact"
              // id=""
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <SvgSearch className="left-[12px] absolute top-[50%] -translate-y-[50%]" />
          </div>
        </div>

        {/* MOBILE */}
        {isLoading ? (
          <div className="md:hidden flex flex-col items-center gap-[12px] mt-[40px]">
            <Loader />
            <h3 className="font-bold text-[16px] mb-[4px] text-center">
              Looking for your contacts...
            </h3>
          </div>
        ) : (
          <>
            {userContacts.length > 0 ? (
              <div className="md:hidden mt-[10px] mb-[68px]">
                <div className="flex flex-col">
                  {userContacts
                    .filter((c: any) => c.contact_type === "connected")
                    .filter((c: any) =>
                      c.contact_name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    )
                    .map((contact: any, idx: number) => {
                      return (
                        <div
                          key={idx}
                          className="w-full"
                        >
                          <MobileInviteContactsListItem
                            contact={contact}
                            isSelected={newMeetingData.contacts
                              .map((e) => e.profile_id)
                              .includes(contact.profile_id)}
                            parentHandleSelect={handleSelect}
                          />
                        </div>
                      );
                    })}
                </div>

                <Button
                  onClick={() => setStep((prev: number) => prev + 1)}
                  className="mt-[24px] w-fit text-md mb-[80px] sm:mb-0 absolute top-[-35px] right-0"
                >
                  Next
                </Button>
                <div className="h-[75px]" />
              </div>
            ) : (
              <div className="md:hidden mt-[64px] font-bold text-[16px] text-center w-full min-w-[320px] flex justify-center">
                <p className="max-w-[250px]">
                  You have no connected contacts. Please add a contact before
                  you begin.
                </p>
              </div>
            )}
          </>
        )}

        {/* DESKTOP */}
        <div>
          {isLoading ? (
            <div className="hidden md:flex flex-col items-center gap-[12px] mt-[40px]">
              <Loader />
              <h3 className="font-bold text-[16px] mb-[4px] text-center">
                Looking for your contacts...
              </h3>
            </div>
          ) : (
            <>
              {userContacts.length > 0 ? (
                <div className="hidden md:grid mt-[32px] mb-[120px] lg:grid-cols-3 grid-cols-2 gap-[16px]">
                  {userContacts
                    .filter((c: any) => c.contact_type === "connected")
                    .filter((c: any) =>
                      c.contact_name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    )
                    .map((contact: any, idx: number) => {
                      return (
                        <ConnectedContactCard
                          variant="select"
                          key={idx}
                          contact={contact}
                          parentHandleSelect={handleSelect}
                          isSelected={newMeetingData.contacts
                            .map((e) => e.profile_id)
                            .includes(contact.profile_id)}
                          reachedMaxInvitedContacts={reachedMaxInvitedContacts}
                        />
                      );
                    })}
                </div>
              ) : (
                <div className="mt-[90px] mx-auto w-[388px] h-[319px] flex flex-col items-center justify-between text-center p-[24px] bg-white promotion-shadow rounded-[16px]">
                  <div className="flex flex-col items-center mt-[24px]">
                    <SvgAlert className="mb-[24px]" />
                    <h4 className="mb-[8px] text-[18px] font-bold">
                      You don't have any contacts...
                      <span className="italic">&nbsp;yet</span>.
                    </h4>
                    <p className="text-base text-graySlate">
                      Let's start by inviting some clients
                      <br /> using the button below!
                    </p>
                  </div>
                  <Link
                    to={`/${user.activeProfile}/contacts`}
                    className="w-full"
                  >
                    <button className="btn-primary btn-blue w-full h-[48px] flex items-center gap-[10px] rounded-[10px]">
                      Add New
                      <SvgPlus fill="white" />
                    </button>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
        <div className="hidden md:block">
          {showSelectedContactsOverlay && (
            <SelectedContactsOverlay
              contacts={newMeetingData.contacts}
              setStep={setStep}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SelectContacts;
