import React from 'react';
import { ProfileRequests } from '../../../api/app.service';
import { CommonFunctionCtx } from '../../../context/commonFunctionContext';
import { CoachPrivateProfileContext } from '../../../pages/manage-business/profile/context/coachPrivateProfileContext';
import CloseModal from '../../CloseModal';
import { Button } from '../../ui/button';
import Loader from '../../ui/loader';
import { SvgCheckbox, SvgCheckboxOff, SvgHelp } from '../../icons';
import { SvgArrowLeftRight } from '../../icons/arrows/arrow-left-right';

interface Props {
  handleClick: any;
  updateBento: (bentoSpot: number, selection: string, contentId?: number, content?: string, url?: string) => void;
  bentoSelection: number
}
const BentoTestimonialFlow: React.FC<Props> = ({
  handleClick,
  updateBento,
  bentoSelection,
}: Props) => {

  const [testimonials, setTestimonials] = React.useState<any[]>([])
  const { coachPublicProfileData } = React.useContext(CoachPrivateProfileContext)
  const { renderSuccess, renderError, hideAlert } = React.useContext(CommonFunctionCtx)
  const [selectedTestimonial, setSelectedTestimonial] = React.useState<any>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const getCoachTestimonials = () => {
    setIsLoading(true)
    ProfileRequests.getTestimonials({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data: any) => {
        const allTestimonials = data.testimonials
        const isShownTestimonials = allTestimonials.filter((testimonial: any) => testimonial.is_shown === 1)
        setTestimonials(isShownTestimonials)
        setIsLoading(false)
      })
      .catch((ex: any) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  React.useEffect(() => {
    getCoachTestimonials()
  }, [coachPublicProfileData])

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
      <div className="w-[775px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] md:min-h-[70vh] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto"
      >
        <CloseModal
          callback={() => {
            handleClick("cancel")
          }}
          styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />
        <h2 className="text-center top-[10px] left-[10px] sm:left-[30px] sm:top-[30px] md:left-[50px] text-[16px] md:text-[28px] lg:text-[16px] sm:mt-0">
          Featured Content
        </h2>
        <div className="flex gap-x-2 justify-center items-center">
          <SvgHelp height="40" width="40" />
          <p className="font-bold text-[20px] sm:mt-4 md:-translate-y-2">
            Testimonial
          </p>
        </div>
        <Button
          variant={"link"}
          onClick={() => {
            handleClick("switch content")
          }}
        >
          <SvgArrowLeftRight />
          Switch content type
        </Button>

        <div className="flex flex-col gap-2 justify-center items-center mt-4 w-full gap-y-4 h-full overflow-auto">
          {testimonials.length > 0 ? (
            <>
              {testimonials.map((testimonial: any, index: number) => {
                return (
                  <div className='self-start hover:cursor-pointer'
                    onClick={() => {
                      setSelectedTestimonial(testimonial)
                    }}
                  >
                    <div className='flex gap-x-6'>
                      {selectedTestimonial === testimonial ? (
                        <>
                          <SvgCheckbox />
                        </>
                      ) : (
                        <>
                          <SvgCheckboxOff />
                        </>
                      )}
                      <p className='text-md truncate md:max-w-[600px] max-w-[250px]'>
                        <b>{testimonial.client_name ? testimonial.client_name : "Anonymous"}</b>
                        <br />
                        {testimonial.testimonial}
                      </p>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              <div className={`h-full flex flex-col items-center justify-center gap-y-8`}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <p className="text-graySlate text-md text-center">
                    No client testimonials available.

                  </p>
                )}

              </div>
            </>
          )}
        </div>
        <div className="flex gap-x-2 mt-auto">
          <Button className="mt-8" size="lg"
            variant={"ghost"}
            onClick={() => {
              handleClick("cancel")

            }}
          >
            Cancel
          </Button>

          <Button className="mt-8" size="lg"
            variant={`${selectedTestimonial ? "default" : "secondary"}`}
            onClick={() => {
              updateBento(bentoSelection, "testimonial", selectedTestimonial.id)
            }}
            disabled={!selectedTestimonial}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BentoTestimonialFlow