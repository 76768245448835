import React from "react"
import DatePicker from "react-datepicker"
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { daysInWeek, hours } from "../../../objects/timeObjects"
import { Switch } from "../../ui/switch"
import { Label } from "../../ui/label"
import { SvgWarning } from "../../icons"

interface Props {
  formErrors: any
  serviceValues: any
  setServiceValues: any
  serviceType: any
  isGroupService: boolean
}

const GroupServiceDetailsForm: React.FC<Props> = ({
  formErrors,
  serviceValues,
  setServiceValues,
  serviceType,
}) => {
  const { renderError, hideAlert } = React.useContext(CommonFunctionCtx)

  const addOneYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1)
    return date
  }

  // const updateEnrollByDate = (date: Date) => {
  //   setServiceValues({
  //     ...serviceValues,
  //     group: {
  //       ...serviceValues?.group,
  //       enrollByDate: date,
  //     },
  //   })
  // }

  const getSessionDateText = () => {
    switch (serviceType) {
      case "individual":
        return "When will the group session take place?"
      case "bundle":
        return "When will the group sessions take place?"
      case "recurring":
        return "When will the group sessions take place?"
      default:
        return "When will the group session(s) take place?"
    }
  }

  const updateDayInWeek = (e: any, index: number) => {
    console.log(e.target.value)
    console.log(index)
  }

  const updateTimeOfDay = (e: any, index: number) => {
    console.log(e.target.value)
    console.log(index)
  }

  const updateMeetingDate = (date: Date, index: number) => {
    let meetingDates = serviceValues?.group?.meetingDates
    const lastMeetingDate = meetingDates[index - 1]
    if (lastMeetingDate && date <= lastMeetingDate) {
      renderError("Meeting date must be greater than prior meeting date")
      setTimeout(() => {
        hideAlert()
      }, 5000)
      return
    }
    // if (date <= serviceValues?.group?.enrollByDate) {
    //   renderError("Meeting date must be greater than enroll by date")
    //   setTimeout(() => {
    //     hideAlert()
    //   }, 5000)
    //   return
    // }
    meetingDates[index] = date
    setServiceValues({
      ...serviceValues,
      group: {
        ...serviceValues?.group,
        meetingDates: meetingDates,
      },
    })
  }

  const incrementDate = (date: Date) => {
    let count: any = 1
    let unit: any = "days"
    if (["individual", "bundle"].includes(serviceType)) {
      count = 7
      unit = "days"
    } else if (serviceType === "recurring") {
      count = 7
      unit = "days"
    }
    return moment(date).add(count, unit).toDate()
  }

  const getRecurringCount = () => {
    const sessionsPerPeriod = serviceValues?.recurring?.sessionsPerPeriod
    const expireTime = serviceValues?.expiration?.expireTime
    return parseInt(sessionsPerPeriod) * parseInt(expireTime)
  }

  const updateMonthlyDayTimeArray = () => {
    let weeklyDayTimes = []
    let weeklySessionCount = 0
    weeklySessionCount = serviceValues?.recurring?.sessionsPerPeriod
    let i = 0
    const time = { value: "18:00", label: "6:00 PM" }
    while (i < weeklySessionCount) {
      const day = daysInWeek[i]
      weeklyDayTimes.push({ day: day, time: time })
      i++
    }
    setServiceValues({
      ...serviceValues,
      group: {
        ...serviceValues.group,
        weeklyDayTimes: weeklyDayTimes,
      },
    })
  }
  const updateMeetingDateArray = () => {
    let meetingDates = []
    let meetingCount = 0
    if (serviceType === "individual") {
      meetingCount = 1
    } else if (serviceType === "bundle") {
      meetingCount = parseInt(serviceValues.package.packageSessions)
    } else if (serviceType === "recurring") {
      meetingCount = getRecurringCount()
    }
    let i = 0
    let date = serviceValues?.group?.enrollByDate
    let lastDate = date
    while (i < meetingCount) {
      const doWeHaveDateAlready = !!serviceValues?.group?.meetingDates?.[i]

      if (doWeHaveDateAlready) {
        lastDate = serviceValues.group.meetingDates[i]
      }
      if (serviceValues?.group?.meetingDates?.[i]) {
        meetingDates.push(serviceValues?.group?.meetingDates?.[i])
      } else {
        date = incrementDate(lastDate)
        meetingDates.push(date)
      }
      i++
    }
    setServiceValues({
      ...serviceValues,
      group: {
        ...serviceValues.group,
        meetingDates: meetingDates,
      },
    })
  }

  const showDatepickers = () => {
    let show = false
    if (["individual", "bundle"].includes(serviceType)) {
      show = true
    }
    if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "monthly"
    ) {
      show = true
    }
    return show
  }

  const showDayTimeSelectors = () => {
    let show = false
    if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "weekly"
    ) {
      show = true
    }
    return show
  }

  const updateGroupMeetingDetails = () => {
    if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "monthly"
    ) {
      updateMeetingDateArray()
    } else if (
      serviceType === "recurring" &&
      serviceValues?.recurring?.recurringFrequency === "weekly"
    ) {
      updateMonthlyDayTimeArray()
    } else if (["bundle", "individual"].includes(serviceType)) {
      updateMeetingDateArray()
    }
  }

  React.useEffect(() => {
    updateGroupMeetingDetails()
  }, [
    serviceType,
    serviceValues?.package?.packageSessions,
    serviceValues?.recurring?.sessionsPerPeriod,
    serviceValues?.expiration?.expireTime,
  ])

  return (
    <div
      className="md:w-[620px] main-shadow flex flex-col rounded-[16px]
            bg-white p-[32px] md:p-[40px]"
    >
      <h2 className="font-bold text-[22px] mb-[20px] md:mb-[24px]">
        Group Service Details
      </h2>
      <p className="text-graySlate text-base mb-[32px]">
        Set up more of the specifics around this group service.
      </p>

      {/* QUESTIONS */}
      {/* <h4 className="font-bold text-base mb-[16px]">
        When should clients enroll by?
      </h4>
      <div className="w-full relative">
        <DatePicker
          selected={serviceValues?.group?.enrollByDate}
          onChange={(date: Date) => updateEnrollByDate(date)}
          showTimeSelect
          allowSameDay={true}
          minDate={new Date()}
          maxDate={addOneYear(new Date())}
          dateFormat={"MM/dd/yyyy hh:mm a"}
          className="text-center w-full pr-[25px]"
        />
      </div> */}

      {/* <div className="w-full h-[1px] bg-grayMist my-[32px]"></div> */}

      {/* SESSION DATE(S) */}
    
      <h4 className="font-bold text-base mb-[16px]">{getSessionDateText()}</h4>
      <div className="flex flex-col gap-[12px]">
        {showDatepickers() && (
          <>
            {serviceValues?.group?.meetingDates?.map(
              (meetingDate: Date, index: number) => {
                return (
                  <div key={index}>
                    <h4 className="font-bold text-base mb-[4px] text-graySlate">
                      {`Session #${index + 1}`}
                    </h4>
                    <div className="w-full relative">
                      <DatePicker
                        selected={meetingDate}
                        onChange={(date: Date) =>
                          updateMeetingDate(date, index)
                        }
                        showTimeSelect
                        minDate={new Date()}
                        maxDate={addOneYear(new Date())}
                        dateFormat={"MM/dd/yyyy hh:mm a"}
                        required
                        className="text-center w-full pr-[25px]"
                      />
                    </div>
                  </div>
                )
              }
            )}
          </>
        )}
        {showDayTimeSelectors() && (
          <>
            {serviceValues?.group?.weeklyDayTimes?.map(
              (dayAndTime: any, index: number) => {
                return (
                  <div key={index}>
                    <h4 className="font-bold text-base mb-[4px] text-graySlate">
                      {`Weekly Session #${index + 1}`}
                    </h4>
                    <div className="w-full relative flex items-center justify-between">
                      <Select
                        className="basic-single w-[48%] border-grayCloud text-graySlate rounded-[10px]"
                        classNamePrefix="select"
                        value={daysInWeek.filter(
                          (day) => day.value === dayAndTime?.day?.value
                        )}
                        options={daysInWeek}
                        name="Day of Week"
                        placeholder="Day of Week"
                        onChange={(e: any) => updateDayInWeek(e, index)}
                      />
                      <Select
                        className="basic-single w-[48%] border-grayCloud text-graySlate rounded-[10px]"
                        classNamePrefix="select"
                        value={hours.filter(
                          (hour) => hour.value === dayAndTime?.time?.value
                        )}
                        options={daysInWeek}
                        name="Day of Week"
                        placeholder="Day of Week"
                        onChange={(e: any) => updateTimeOfDay(e, index)}
                      />
                    </div>
                  </div>
                )
              }
            )}
          </>
        )}
      </div>

      <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>

      {/* MAX CLIENTS */}
      <h4 className="font-bold text-base mb-[16px]">
        What is the maximum number of clients that can enroll?
      </h4>
      <div
        className={`${
          formErrors?.maxClients && "input-error"
        } flex items-center gap-[12px]`}
      >
        <input
          type="number"
          min="0"
          max="20"
          onWheel={() => (document.activeElement as HTMLElement)?.blur()}
          className="w-[54px] h-[54px] bg-white focus:outline-0 border
                    border-1 border-grayCloud rounded-[10px] text-graySlate text-center"
          name="maximumClients"
          value={serviceValues.group.maximumClients}
          onChange={(e: any) =>
            setServiceValues({
              ...serviceValues,
              group: {
                ...serviceValues.group,
                maximumClients: e.target.value,
              },
            })
          }
        />
        <span className="font-bold text-base">clients</span>
      </div>
      {formErrors?.maxClients && (
        <div className="field-error w-[100%]">
          <SvgWarning />
          {formErrors?.maxClients}
        </div>
      )}

      {/* <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>

      <h4 className="font-bold text-base mb-[8px]">
        Do you want Zoee to start a group chat for this service?
      </h4>
      <div className="flex items-center gap-2">
        <Switch
          onCheckedChange={() =>
            setServiceValues({
              ...serviceValues,
              group: { groupChat: !serviceValues?.group?.groupChat },
            })
          }
          checked={serviceValues?.group?.groupChat}
          id="group-chat"
        />
        <Label htmlFor="group-chat">Yes</Label>
      </div> */}
    </div>
  )
}

export default GroupServiceDetailsForm
