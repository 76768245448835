"use client"

import * as React from "react"
import FeaturesControlCarousel from "./FeaturesCarouselControls"
import { features } from "../../../data/featureData"
import { motion } from "framer-motion"
import { cn } from "../../../utils/cn"
import { SvgChevronRight } from "../../icons"

export const FeaturesSelect: React.FC = () => {
  const [activeFeature, setActiveFeature] = React.useState<number>(1)

  return (
    <section className="py-24 bg-gradient-to-t from-[#BACDFF] to-[#FFFFFF]">
      <div className="container">
        <h3 className="sm:mb-[52px] mb-[8px] px-[32px] text-[25px] lg:text-[42px] text-black font-bold text-center">
          The Tools You Need Most <br />
          <span className="text-blurple">All in One Place</span>
        </h3>

        <div className="grid lg:grid-cols-2">
          <div className="hidden lg:flex flex-col mb-[50px]">
            {features.map(({ id, text, icon }) => (
              <motion.button
                key={id}
                onClick={() => setActiveFeature(id)}
                className={cn(
                  "relative w-fit p-6 text-xl font-bold flex items-center gap-4 rounded-lg transition-colors duration-500 text-left",
                  {
                    "text-blurple bg-white": id === activeFeature,
                  }
                )}
              >
                {icon}
                {text}
                {id === activeFeature && (
                  <motion.div
                    initial={{ x: 30, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                      type: "spring",
                      duration: 0.5,
                      bounce: 0.45,
                    }}
                  >
                    <SvgChevronRight />
                  </motion.div>
                )}
              </motion.button>
            ))}
          </div>

          <div>
            {features.find((feature) => feature.id === activeFeature)?.content}
          </div>

          <div className="lg:hidden flex justify-center">
            <FeaturesControlCarousel
              activeFeature={activeFeature}
              setActiveFeature={setActiveFeature}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
