import React from "react";
import ServiceCardPreview from "./ServiceCardPreview";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ServiceRequests } from "../../api/app.service";
import { Link } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollToTop";
import useLoading from "../../hooks/useLoading";

interface Props {
    contact?: any;
    setInviteToService: any;
    setConfirmServiceInvite: any;
}

const InviteToService: React.FC<Props> = ({
    setInviteToService,
    setConfirmServiceInvite,
    contact
}) => {
    const [services, setServices] = React.useState<any[]>([]);
    const { user } = React.useContext(UserCtx);
    const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();

    const getServices = () => {
        if (user) {
            startLoading();
            ServiceRequests.getClientServiceList({
                profile_id: parseInt(user.activeProfileId),
                profile_type: user.activeProfile,
            })
                .then((data) => {
                    setServices(data.service_list);
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setTimeout(() => hideAlert(), 5000);
                })
                .finally(() => {
                    stopLoading();
                });
        }
    };

    React.useEffect(() => {
        getServices();
    }, []);
    
    useScrollToTop();

    const goToConfirm = (service: any) => {
        setConfirmServiceInvite(service);
        setInviteToService(false);
    };

    return (
        <div className="px-[20px] md:px-0 w-full md:w-[1080px] mx-auto flex flex-col">
            <h1 className="hidden md:block font-bold text-[36px] mb-[32px]">Invite to Service</h1>
            <div className="flex flex-wrap justify-center md:justify-start gap-[24px]">
                {services.filter((service) => service.service_details.status === "active")
                    .length === 0 ? (
                    <div className="w-full font-bold flex flex-col justify-center text-center items-center pt-[40px]">
                        <p className="mb-[24px] max-w-xl">
                            You currently do not have any active services. Please create an active
                            service before inviting a contact to participate.
                        </p>
                        <Link
                            to="/coach/services"
                            className="btn-primary btn-blue bg-blurple rounded-md w-fit"
                        >
                            Go To My Services
                        </Link>
                    </div>
                ) : (
                    <></>
                )}
                {services
                    ?.filter((service) => service.service_details.status === "active")
                    .map((service, idx) => (
                        <div className="w-full md:w-[48%]">
                            <ServiceCardPreview
                                key={idx}
                                service={service}
                                buttonText="Choose Service"
                                buttonCallback={() => goToConfirm(service)}
                                secondaryButtonCallback={null}
                            />
                        </div>
                        
                    ))}
            </div>
        </div>
    );
};

export default InviteToService;
