import React from "react";
import { SubscriptionRequests, PaymentRequests } from "../../api/app.service";
import CloseModal from "../CloseModal";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { SubscriptionCtx } from "../../context/subscriptionContext";
import CardSelector from "./checkout-process/CardSelector";
import PaymentMethodModal from "../account-settings/payment-methods/PaymentMethodModal";
import Loader from "../ui/loader";
import QuickSubscriptionDetails from "./QuickSubscriptionDetails";
import _ from "lodash";
import DeleteDialogueModal from "../DialogueModal";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import useDate from "../../hooks/useDate";
import {
  SvgArrow,
  SvgCheckbox,
  SvgCheckboxOff,
  SvgLock2,
  SvgPiechartOneQuarter,
  SvgPlus,
  SvgRecurring,
} from "../icons/";

type Props = {};

const QuickSubscriptionCheckout: React.FC<Props> = () => {
  const { user } = React.useContext(UserCtx);
  const { renderError, renderSuccess, getUser, hideAlert } =
    React.useContext(CommonFunctionCtx);
  const {
    activeSubscription,
    activeSubscriptionType,
    setUserActiveSubscription,
    setShowSubscriptionCheckout,
    monthAmount,
    monthPromo,
    subscriptionPlans,
    setZoeeSubscriptionPlans,
    monthPromoAmountString,
  } = React.useContext(SubscriptionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [activePlan, setActivePlan] = React.useState<any>(null);
  const [isTermsAgreed, setIsTermsAgreed] = React.useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<any>(
    {}
  );
  const [paymentMethods, setPaymentMethods] = React.useState<any>(null);
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [paymentMethodModal, setPaymentMethodModal] =
    React.useState<any>(false);
  const [stripeRedirectParams, setStripeRedirectParams] = React.useState<
    string | null
  >(null);
  const [options, setOptions] = React.useState<any>({});
  const [cancelSubscriptionStarted, setCancelSubscription] =
    React.useState<boolean>(false);
  const [showCancelSubscriptionButton, setShowCancelSubscriptionButton] =
    React.useState<boolean>(false);
  const { mdDate } = useDate();

  const createSetupIntent = async () => {
    startLoading();
    if (user) {
      await PaymentRequests.createSetupIntent({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setOptions({ clientSecret: data?.stripe_client_secret });
          setPaymentMethodModal(true);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const clearHistoryState = () => {
    window.history.replaceState(null, "", window.location.pathname);
  };

  const resetNewPaymentMethod = () => {
    setPaymentMethodModal(false);
    setStripeRedirectParams("");
  };

  const createSubscription = async () => {
    startLoading();
    await SubscriptionRequests.createSubscription({
        user_id: parseInt(user.user_id),
        stripe_payment_method_id: selectedPaymentMethod.stripe_payment_method_id,
        stripe_price_id: activePlan?.stripe_price_id,
        promotion_code: monthPromo
    })
      .then(() => {
        renderSuccess("Subscription purchased!");
        setUserActiveSubscription();
        setTimeout(() => {
          getUser().then(() => {
            setShowSubscriptionCheckout(false);
            window.location.href = "/coach/subscription?purchased=true";
          });
          hideAlert();
        }, 2500);
      })
      .catch((ex) => {
        console.log(ex);
        const errorMessage =
          ex.response?.data?.error_detail ||
          ex.response?.data?.message ||
          "An unexpected error occurred.";
        renderError(errorMessage);
        setTimeout(() => hideAlert(), 5000);
        stopLoading();
      });
  };

  const submitSubscriptionPurchase = () => {
    if (
      isTermsAgreed &&
      Object.keys(selectedPaymentMethod)?.length >= 1 &&
      activeSubscriptionType === "trial"
    ) {
      createSubscription();
    } else {
      if (!isTermsAgreed) {
        renderError("You must agree to the terms to complete your purchase");
      } else if (Object.keys(selectedPaymentMethod)?.length === 0) {
        renderError("You must add a payment method to complete your purchase");
      } else if (activeSubscriptionType !== "trial") {
        setShowCancelSubscriptionButton(true);
        renderError(
          "You have active subscription! You can cancel your current subscription to complete your purchase."
        );
      } else {
        renderError("Please agree to terms and add a payment method");
      }
    }
  };

  const getPaymentMethods = () => {
    PaymentRequests.getPaymentMethods({
      user_id: parseInt(user.user_id),
    })
      .then((data: any) => {
        setPaymentMethods(data?.payment_methods);
        if (data?.payment_methods?.length > 0) {
          setSelectedPaymentMethod(data?.payment_methods[0]);
        }
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setTimeout(() => hideAlert(), 5000);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const setActiveSubscriptionPlan = () => {
    let activePlan = null;
    // if (activeSubscriptionType === 'promoMonth' || activeSubscription === 'paid') {
    //   activePlan = subscriptionPlans?.find((plan:any) => plan?.amount === lifetimeAmount);
    // }
     if (activeSubscriptionType === 'trial') {
     activePlan = subscriptionPlans?.find((plan:any) => plan?.amount === monthAmount);
    }
    setActivePlan(activePlan)
  }

  const handleCancelSubscriptionClick = () => {
    setCancelSubscription(!cancelSubscriptionStarted);
  };
  React.useEffect(() => {
    clearHistoryState();
    setUserActiveSubscription();
    setActiveSubscriptionPlan();
    if (!paymentMethods) {
      getPaymentMethods();
    }
    if (subscriptionPlans.length === 0) {
      setZoeeSubscriptionPlans();
    }
  }, [subscriptionPlans]);

  const cancelSubscription = () => {
    SubscriptionRequests.cancelSubscription({
      subscription_id: activeSubscription?.subscription_id,
    })
      .then((data) => {
        setUserActiveSubscription();
        setCancelSubscription(false);
        setShowCancelSubscriptionButton(false);
        renderSuccess("Subscription is canceled!");
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setTimeout(() => hideAlert(), 5000);
      });
  };

  console.log("active sub", activeSubscriptionType);
  console.log("active plan", activePlan);
  console.log("sub", subscriptionPlans);

  return (
    <>
      {showDetails && (
        <QuickSubscriptionDetails setShowDetails={setShowDetails} />
      )}
      {paymentMethodModal && (
        <PaymentMethodModal
          callback={resetNewPaymentMethod}
          options={options}
          stripeRedirectParams={stripeRedirectParams}
        />
      )}

      <div
        className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[3001] flex items-center justify-center"
      >
        <div
          className="md:p-[48px] p-[24px] max-h-[90vh] w-[90%] md:w-[600px] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
        >
          <CloseModal
            callback={setShowSubscriptionCheckout}
            styling={"absolute top-[24px] right-[24px]"}
          />
          <div className="flex flex-col">
            <div className="flex items-center gap-[16px] mb-[32px]">
              <div className="text-[22px] font-bold">Subscription Details</div>
            </div>
            {activePlan && paymentMethods ? (
              <>
                <h5 className="text-base text-graySlate mb-[16px]">
                  PAYMENT SCHEDULE
                </h5>
                <div className="flex items-center mb-[14px]">
                  <SvgPiechartOneQuarter className="mr-[16px]" />

                  <p className="text-base min-w-fit">1st payment today</p>
                  <div className="w-full h-[2px] mx-[24px] border-b border-dashed border-grayCloud" />
                  <p className="text-base min-w-fit">
                    $
                    {activePlan?.amount === monthAmount
                      ? monthPromoAmountString
                      : activePlan?.amount?.toFixed(2)}
                  </p>
                </div>
                {activePlan?.promotion_details?.lifetime ||
                activePlan?.recurring_interval_count === 2 ? (
                  <>
                    <div className="flex items-center mb-[24px]">
                      <SvgRecurring className="mr-[16px]" />
                      <p className="text-base min-w-fit">Recurring payments</p>
                      <div className="w-full h-[2px] mx-[24px] border-b border-dashed border-grayCloud" />
                      <p className="text-base min-w-fit">$0.00</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center mb-[12px]">
                      <SvgRecurring className="mr-[16px]" />
                      <p className="text-base min-w-fit">Recurring payments</p>
                      <div className="w-full h-[2px] mx-[24px] border-b border-dashed border-grayCloud" />
                      <p className="text-base min-w-fit">
                        ${activePlan?.amount?.toFixed(2)}
                      </p>
                    </div>
                    <p className="text-base text-graySlate mb-[24px]">
                      Payments will be charged&nbsp;
                      {activePlan?.recurring_interval === "annual" ? (
                        <span className="text-black font-semibold text-mainBlack">
                          annually
                        </span>
                      ) : (
                        <span className="text-black font-semibold text-mainBlack">
                          monthly
                        </span>
                      )}
                      &nbsp;to your{" "}
                      {_.upperCase(selectedPaymentMethod?.card_brand)} ...{" "}
                      {selectedPaymentMethod?.card_last4}
                    </p>
                  </>
                )}
                <div className="h-[1px] w-full bg-grayMist mb-[24px]" />

                <div className="relative">
                  <div className="flex items-center w-full justify-between mb-[16px]">
                    <h5 className={`text-base text-graySlate`}>SUMMARY</h5>
                  </div>

                  <div className="flex items-center mb-[8px]">
                    <p className="text-base min-w-fit">Interest or fees</p>
                    <div className="w-full h-[2px] mx-[24px] border-b border-dashed border-grayCloud" />
                    <p className="text-base min-w-fit">$0.00</p>
                  </div>
                </div>

                <div className="flex items-center mb-[24px]">
                  <p className="text-[18px] font-bold min-w-fit">Order total</p>
                  <div className="w-full h-[2px] mx-[24px] border-b border-dashed border-grayCloud" />
                  <p className="text-[18px] font-bold min-w-fit">
                    $
                    {activePlan?.amount === monthAmount
                      ? monthPromoAmountString
                      : activePlan?.amount?.toFixed(2)}
                  </p>
                </div>
                <div className="h-[1px] w-full bg-grayMist mb-[24px]" />
                <div className="flex items-center justify-between mb-[16px]">
                  <h5 className="text-base text-graySlate">PAYMENT METHOD</h5>
                </div>
                {paymentMethods?.length > 0 ? (
                  <div className="mb-[24px]">
                    <CardSelector
                      paymentMethods={paymentMethods}
                      selectedPaymentMethod={selectedPaymentMethod}
                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                      callback={() => {
                        createSetupIntent().then(() => {
                          setStripeRedirectParams(
                            `?checkout=true&quickcheckout=true&plan=${activePlan?.stripe_price_id}`
                          );
                        });
                      }}
                    />
                  </div>
                ) : (
                  <button
                    className="flex w-full justify-start items-center cursor-pointer
                                    text-primaryBlue gap-[8px] mb-[24px]"
                    onClick={() => {
                      createSetupIntent().then(() => {
                        setStripeRedirectParams(
                          `?checkout=true&quickcheckout=true&plan=${activePlan?.stripe_price_id}`
                        );
                      });
                    }}
                  >
                    <SvgPlus fill="#4750F5" />
                    Add New Payment Method
                  </button>
                )}

                <div className="mb-[24px] flex gap-[16px]">
                  <div>
                    {isTermsAgreed ? (
                      <button
                        onClick={() => setIsTermsAgreed(() => !isTermsAgreed)}
                      >
                        <SvgCheckbox />
                      </button>
                    ) : (
                      <button
                        onClick={() => setIsTermsAgreed(() => !isTermsAgreed)}
                      >
                        <SvgCheckboxOff
                          stroke="#4750F5"
                          strokeWidth="1"
                        />
                      </button>
                    )}
                  </div>
                  <div className="text-base text-graySlate">
                    I have read and agree to the&nbsp;
                    <a
                      href="https://zoee.com/terms-and-conditions"
                      className="text-blurple"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Agreement &amp; Disclosures
                    </a>
                    &nbsp;and&nbsp;
                    {/* TODO add href */}
                    <a
                      href="#"
                      className="text-blurple"
                    >
                      Payment Authorization
                    </a>
                    , and the
                    {/* TODO add href */}
                    <a
                      href="#"
                      className="text-blurple"
                    >
                      &nbsp;refund policy&nbsp;
                    </a>
                    provided by Zoee
                  </div>
                </div>
                {showCancelSubscriptionButton ? (
                  <>
                    <button
                      className="btn-primary bg-vividRed h-[42px] mt-[24px] mx-auto"
                      onClick={handleCancelSubscriptionClick}
                    >
                      Cancel Subscription
                    </button>
                    {cancelSubscriptionStarted && (
                      <>
                        <DeleteDialogueModal
                          isWarningVariant
                          setShowDialogueModal={setCancelSubscription}
                          helpText={`Your subscription is active until ${mdDate(
                            activeSubscription.subscription_next_payment_date
                          )}`}
                          header="Are you sure you want to cancel your subscription?"
                          confirmButtonText="Cancel Subscription"
                          confirmCallbackFn={cancelSubscription}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Button
                    className="max-w-[336px] w-[336px] mx-auto text-md"
                    size={"lg"}
                    onClick={submitSubscriptionPurchase}
                  >
                    Purchase
                  </Button>
                )}
                <div className="flex mt-[12px] items-center gap-[8px] mx-auto">
                  <SvgLock2
                  />
                  <p className="text-base text-gray">
                    Guaranteed safe &amp; secure payments
                  </p>
                </div>
              </>
            ) : (
              <div className="mx-auto">
                <Loader />
              </div>
            )}
          </div>
          <button
            onClick={() => {
              setShowSubscriptionCheckout(false);
              window.location.href = "/coach/subscription";
            }}
            className="text-blurple font-bold mt-[32px] flex items-center gap-[4px]"
          >
            Other subscription options
            <SvgArrow stroke="#4750F5" />
          </button>
        </div>
      </div>
    </>
  );
};

export default QuickSubscriptionCheckout;
