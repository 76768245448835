import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import { UserCtx } from "../../context/userContext";
import { NotificationCtx } from "../../context/notificationContext";
import NotificationCenter from "../notifications/NotificationCenter";
import { NotificationRequests } from "../../api/app.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import moment from "moment";
import NavShell from "./NavShell";
import UserButton from "./UserButton";
import { NavLinkItem } from "./NavLinkItem";
import { Button } from "../ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { getUserColor } from "../../utils/getUserColor";
import { getTextColor } from "../../utils/getTextColor";
import UserButtonMobile from "./UserButtonMobile";
import useName from "../../hooks/useName";
import {
  SvgCalendar,
  SvgChat,
  SvgClose,
  SvgHamburger,
  SvgHome,
  SvgNotifications,
  SvgSwap,
  SvgUser3,
  SvgVideoOutline,
  SvgZoeeIcon,
  SvgZoeeLogo,
} from "../icons/";
import { Badge } from "../ui/badge";
import { StreamChat } from "stream-chat";
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable";

const MemberAuthedNav: FC = () => {
  const { user } = useContext(UserCtx);
  const { setUser } = useContext(UserCtx);
  const { initial } = useName();
  const sessionExpiration = moment.utc(user?.id_token_expiration).format();
  const now = moment.utc().format();
  const authenticatedUser = user && sessionExpiration > now;
  const location = useLocation();
  const { startLoading, stopLoading } = useLoading();
  const {
    renderError,
    hideAlert,
    previewImgUrl,
    setPopupNotification,
    endActiveSession,
  } = useContext(CommonFunctionCtx);
  const {
    inboxNotifications,
    setInboxNotifications,
    contactsNotifications,
    setContactsNotifications,
    notificationAlert,
  } = useContext(NotificationCtx);
  const [isNewNotificationAlert] = useState<boolean>(
    notificationAlert.message !== "",
  );
  const [showNotificationCenter, setShowNotificationCenter] =
    useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const [showUserButton, setShowUserButton] = useState<boolean>(false);

  const handleClientSwitch = async () => {
    let activeProfile = null;
    let activeProfileId;
    startLoading();
    if (user.activeProfile === "member") {
      await endActiveSession()
        .then(() => {
          activeProfile = "coach";
          activeProfileId = user.coachProfile.profile_id;
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          stopLoading();
        });
    } else {
      await endActiveSession()
        .then(() => {
          activeProfile = "member";
          activeProfileId = user.memberProfile.profile_id;
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          stopLoading();
        });
    }
    const userObject = {
      ...user,
      activeProfile: activeProfile,
      activeProfileId: activeProfileId,
    };
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject));
    }
    setUser(userObject);
    const streamClientId = process.env.REACT_APP_STREAM_CLIENT_ID;
    if (streamClientId) {
      const client: any = StreamChat.getInstance(streamClientId);
      client.disconnectUser();
    }
    setPopupNotification({
      show: true,
      title: `Welcome to your ${
        user.activeProfile === "member" ? "Coach" : "Member"
      } profile!`,
      message: `${
        user.activeProfile === "member"
          ? "Here, you can interact with all of your Zoee clients. Feel free to switch back to your member profile at any time."
          : "Here, you can interact with all of your Zoee coaches. Feel free to switch back to your coach profile at any time."
      }`,
      callback: null,
    });
  };

  const unreadInboxNotificationNumber = useMemo(() => {
    return inboxNotifications.filter((n: any) => !n.viewed).length;
  }, [inboxNotifications]);

  const unreadContactsNotificationNumber = useMemo(() => {
    return contactsNotifications.filter((n: any) => !n.viewed).length;
  }, [contactsNotifications]);

  const getNotifications = () => {
    if (authenticatedUser) {
      NotificationRequests.getNotifications({
        profile_id: parseInt(user.activeProfileId),
      })
        .then((data) => {
          const filteredNotifications = data.notifications.filter((n) => {
            return n.notification_category !== "messages";
          });

          setNotifications(filteredNotifications);

          const unreadContactNotifications = data.notifications.filter((n) => {
            return n.slug === "received_connection_request" && n.viewed === 0;
          });
          setContactsNotifications(unreadContactNotifications);

          const unreadInboxNotifications = data.notifications.filter((n) => {
            return n.notification_category === "messages" && n.viewed === 0;
          });
          setInboxNotifications(unreadInboxNotifications);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        });
    }
  };

  useEffect(() => {
    if (showNotificationCenter) {
      setShowNotificationCenter(false);
    }
  }, [location]);

  useEffect(() => {
    getNotifications();
  }, []);
  
  useEffect(() => {
    getNotifications();
  }, [notificationAlert.show]);

  useEffect(() => {
    if (isNewNotificationAlert) {
      getNotifications();
    }
  }, [isNewNotificationAlert]);

  return (
    <>
      <NavShell
        className="flex items-center justify-between"
        outerClassName="hidden md:flex"
      >
        <Link to="/">
          <SvgZoeeIcon />
        </Link>

        <div className="hidden items-center md:flex lg:gap-2">
          <NavLinkItem to="/member" end>
            <SvgHome />
            Home
          </NavLinkItem>
          <NavLinkItem
            to="/member/inbox"
            notifications={unreadInboxNotificationNumber}
          >
            <SvgChat />
            Inbox
          </NavLinkItem>
          <NavLinkItem
            to="/member/contacts"
            notifications={unreadContactsNotificationNumber}
          >
            <SvgUser3 />
            My Coaches
          </NavLinkItem>
          <NavLinkItem to="/member/scheduling">
            <SvgCalendar />
            My Calendar
          </NavLinkItem>
          <NavLinkItem to="/member/sessions">
            <SvgVideoOutline />
            Video Hub
          </NavLinkItem>
        </div>

        <div className="relative flex items-center gap-[20px] lg:gap-[24px]">
          {user.isCoach && (
            <Button onClick={handleClientSwitch}>
              <SvgSwap />
              Switch to Coach
            </Button>
          )}
          <div className="relative">
            <SvgNotifications
              onClick={() => {
                setShowNotificationCenter((prev) => !prev);
              }}
              className="cursor-pointer"
            />
            {notifications.filter((n) => !n.viewed).length > 0 && (
              <Badge dot variant="alert" className="absolute -right-1 -top-1" />
            )}
          </div>

          {showNotificationCenter && (
            <NotificationCenter
              notifications={notifications}
              setNotifications={setNotifications}
              showNotificationCenter={showNotificationCenter}
              setShowNotificationCenter={setShowNotificationCenter}
            />
          )}

          <UserButton />
        </div>
      </NavShell>

      {/* Mobile */}
      <nav className="relative sticky top-2.5 z-30 mx-2.5 mb-10 flex md:hidden">
        <div
          className="relative mx-auto flex h-20 w-full max-w-screen-xl items-center justify-between rounded-lg bg-white px-2.5 py-5 sm:px-4"
          style={{
            boxShadow:
              "0 10px 20px -5px rgba(111, 89, 246, 0.15), 0 -5px 10px -5px rgba(111, 89, 246, 0.15), 0 6px 10px -5px rgba(111, 89, 246, 0.10)",
          }}
        >
          <Button
            variant="ghost"
            className="ml-4 lg:hidden"
            size="icon"
            onClick={() => setShowMobileNav(() => !showMobileNav)}
          >
            {showMobileNav ? <SvgClose /> : <SvgHamburger />}
          </Button>
          {showMobileNav && (
            <div className="zoee-backdrop-filter fixed left-0 top-0 z-[601] h-screen w-screen"></div>
          )}

          <div
            className={`fixed bottom-0 left-0 top-0 flex min-h-screen flex-col overflow-y-auto rounded-lg bg-white bg-white p-8 transition-all duration-500 ease-in-out ${
              showMobileNav ? "left-0 w-[279px]" : "left-[-279px] w-0"
            } z-[1000]`}
            style={{
              boxShadow:
                "5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.1)",
            }}
            onClick={() => setShowMobileNav(false)}
          >
            <Button
              className="bg-grayFlash absolute right-[20px] top-[25px] rounded-full"
              size="icon"
              variant="ghost"
              onClick={() => setShowMobileNav(() => !showMobileNav)}
            >
              <SvgClose />
            </Button>
            <Link
              to="/"
              onClick={() => setShowMobileNav(false)}
              className="mb-[48px] mt-[10px]"
            >
              <SvgZoeeLogo className="h-auto w-[155px]" />
            </Link>
            <div className="mb-[56px] flex flex-col gap-[28px] text-center text-[18px]">
              <NavLinkItem to="/member/">
                <SvgHome />
                Home
              </NavLinkItem>
              <NavLinkItem
                to="/member/inbox"
                notifications={unreadInboxNotificationNumber}
              >
                <SvgChat />
                Inbox
              </NavLinkItem>
              <NavLinkItem
                to="/member/contacts"
                notifications={unreadContactsNotificationNumber}
              >
                <SvgUser3 />
                My Coaches
              </NavLinkItem>
              <NavLinkItem to="/member/scheduling">
                <SvgCalendar />
                My Calendar
              </NavLinkItem>
              <NavLinkItem to="/member/sessions">
                <SvgVideoOutline />
                Video Hub
              </NavLinkItem>
              {user.isCoach && (
                <Button onClick={handleClientSwitch}>
                  <SvgSwap />
                  Switch to Coach
                </Button>
              )}
            </div>
          </div>

          <div className="relative mr-2 flex items-center justify-end gap-[20px] lg:gap-[24px]">
            <div className="relative">
              <SvgNotifications
                className="cursor-pointer"
                onClick={() => {
                  setShowNotificationCenter((prev) => !prev);
                }}
              />
              {notifications.filter((n) => !n.viewed).length > 0 && (
                <Badge
                  dot
                  variant="alert"
                  className="absolute -right-1 -top-1"
                />
              )}
            </div>

            {showNotificationCenter && (
              <NotificationCenter
                notifications={notifications}
                setNotifications={setNotifications}
                showNotificationCenter={showNotificationCenter}
                setShowNotificationCenter={setShowNotificationCenter}
              />
            )}
            {showUserButton ? (
              <SvgClose />
            ) : (
              <Button
                variant="ghost"
                className="h-10 w-10 rounded-full"
                onClick={() => setShowUserButton(() => !showUserButton)}
              >
                <Avatar size="xs">
                  {Boolean(user.hasAvatar) && (
                    <AvatarImage
                      alt="avatar"
                      src={previewImgUrl || user.avatar_url}
                    />
                  )}
                  <AvatarFallback className={getUserColor(user.userColor)}>
                    <div
                      style={{
                        color: getTextColor(user.userColor),
                      }}
                    >
                      {initial}
                    </div>
                  </AvatarFallback>
                </Avatar>
              </Button>
            )}
            <UserButtonMobile
              setShowUserButton={setShowUserButton}
              showUserButton={showUserButton}
            />
          </div>
        </div>
      </nav>

      <div className="hidden h-24 md:block"></div>
    </>
  );
};

export default MemberAuthedNav;
