"use client";

import React from "react"
import { motion, useScroll } from "framer-motion"

export const ThriveTogether = () => {
  const words = "You were never meant to do this alone. That's why we created Zoee: to evolve how you do business with a like-minded community to support and grow with you.".split(" ")

  const [animateWords, setAnimateWords] = React.useState(words.map(() => false))
  const controls = useScroll()

  React.useEffect(() => {
    // TODO: There has to be a better way to do this.
    const revealProgress = [
      0.12, 0.122, 0.124, 0.126, 0.128, 0.13, 0.132, 0.134, 0.136, 0.138, 0.14,
      0.142, 0.144, 0.146, 0.148, 0.15, 0.152, 0.154, 0.156, 0.158, 0.16, 0.162,
      0.164, 0.166, 0.168, 0.17, 0.172, 0.174, 0.176,
    ]
    
    const handleScroll = () => {
      const scrollProgress = controls.scrollYProgress.get()
      const animateWordsArray = words.map((_, i) => {
        const wordStartThreshold = revealProgress[i] || 0
        const shouldAnimateWord = scrollProgress >= wordStartThreshold
        return shouldAnimateWord
      })

      setAnimateWords(animateWordsArray)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [words, controls])

  return (
    <motion.div className="h-screen bg-white flex flex-col justify-center items-center p-6 md:p-16 text-center">
      <h1 className="text-[48px] font-bold mb-12">We Need Each other.</h1>
      <p className="mb-12 text-[20px] lg:text-[35px] max-w-[675px]">
        {words.map((word, index) => (
          <motion.span
            key={index}
            className="animate-word"
            initial={{ opacity: 0.15 }}
            animate={{ opacity: animateWords[index] ? 1 : 0.15 }}
            transition={{ duration: 0.15, ease: "easeIn" }}
          >
            {word}{" "}
          </motion.span>
        ))}
      </p>
      <h2 className="text-[35px] font-bold">Let's Thrive Together.</h2>
    </motion.div>
  )
}
