import React from "react";
import ServiceCardBannerOverlay from "./ServiceCardBannerOverlay";
import moment from "moment";
import { Button } from "../ui/button";
import {
  SvgBundle,
  SvgCheckCircle,
  SvgConsultation,
  SvgRecurring,
  SvgUserCheck,
} from "../icons";

interface Props {
  service: any;
  buttonText: string;
  buttonCallback: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled: boolean | undefined;
  setServiceUsage: any;
}

const SelectServiceCardPreview: React.FC<Props> = ({
  service,
  buttonText,
  buttonCallback,
  setServiceUsage,
}) => {
  const { billing, expiration, image, session, title, type } =
    service.service_details;

  // const getPrice = () => {
  //     const amount = parseInt(billing?.amount);
  //     let result = amount === 0 ? "Free" : `$${amount.toFixed(2)}`;
  //     return result;
  // };

  // const getUnits = () => {
  //     const unit = billing.unit;
  //     if(parseInt(billing?.amount) === 0){
  //         return "";
  //     } else if (unit === "bulk" || unit === "installment") {
  //         return " / program";
  //     } else if (["month", "monthly"].includes(unit)) {
  //         return " / month";
  //     } else if (["year", "yearly"].includes(unit)) {
  //         return " / year";
  //     } else if (["week", "weekly"].includes(unit)) {
  //         return " / week";
  //     } else if (["consultation", "individual"].includes(type)) {
  //         return " / session";
  //     } else {
  //         return "";
  //     }
  // };

  const _ = require("lodash");
  _.mixin(require("lodash-inflection"));

  const getSessionType = () => {
    if (type === "individual") {
      return "Single Session";
    } else if (type === "consultation") {
      return "Consultation Session";
    } else if (type === "recurring") {
      return "Recurring Sessions";
    } else if (type === "bundle") {
      return "Multiple Sessions";
    }
  };

  const getSessionTypeImage = () => {
    if (type === "individual") {
      return <SvgUserCheck />;
    } else if (type === "consultation") {
      return <SvgConsultation />;
    } else if (type === "recurring") {
      return <SvgRecurring />;
    } else if (type === "bundle") {
      return <SvgBundle />;
    }
  };

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(type)) {
      return `${session.count} session`;
    } else if (["bundle"].includes(type)) {
      return `${session?.count} ${_("session").pluralize(session.count)}`;
    } else if (["recurring"].includes(type)) {
      return (
        <div>
          {`${session?.count} 
                        ${_("session").pluralize(session?.count)}`}
          <span className="text-darkGray"> / {`${billing?.unit}`}</span>
        </div>
      );
    } else {
      return "Not Found";
    }
  };

  const getSessionDuration = () => {
    return (
      <div>
        {parseInt(session?.duration) / 60} minutes
        <span className="text-darkGray"> / session</span>
      </div>
    );
  };

  const getLengthOrExpiration = () => {
    let expirationString = "No expiration";
    if (["consultation", "individual", "bundle"].includes(type)) {
      if (expiration?.expires) {
        expirationString = `Expires after ${expiration?.time} ${expiration?.type}`;
      } else {
        expirationString = "No expiration";
      }
    } else if (["recurring"].includes(type)) {
      const units = expiration?.type === "weeks" ? "week" : "month";
      expirationString = `${expiration?.time} ${units} program`;
    } else {
      expirationString = "Expiration not found";
    }
    return expirationString;
  };

  return (
    <div className="w-[374px] main-shadow rounded-[16px] relative pb-[24px] md:pb-[32px]">
      <div
        className={`w-full h-[120px] rounded-t-[16px] relative 
                ${image?.color}`}
      >
        <ServiceCardBannerOverlay service={service} />
        {image.exists && (
          <img
            src={`https://clientservice-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${service.stripe_product_id}?${new Date().getTime()}`}
            alt="service"
            className="w-full h-full object-cover rounded-t-[16px]"
          />
        )}
      </div>
      <div className="mt-[20px] w-full mx-auto flex flex-col px-[24px]">
        {/* TITLE AND PRICE */}
        <div className="flex items-center w-full justify-between h-[54px]">
          <h3 className="font-bold text-[18px] max-w-[200px]">{title}</h3>
          {/* HIDING PRICE FOR SPACING */}
          {/* <h3 className="font-bold text-[22px] text-primaryBlue">
                        {getPrice()}
                        <span className="text-graySlate text-base font-normal">
                            {getUnits()}
                        </span>
                    </h3> */}
        </div>
        <div className="w-full h-[1px] bg-grayMist my-[16px]"></div>

        {/* PROGRAM DETAILS */}
        <div className="flex items-center justify-between w-full mb-[16px] text-base font-bold">
          <div
            className="w-[142px] h-[32px] bg-grayFlash rounded-[8px]
                        flex items-center justify-center"
          >
            Program Details
          </div>
          <div className="flex items-center gap-[8px]">
            {getSessionTypeImage()}
            {getSessionType()}
          </div>
        </div>
        <div className={`flex flex-col mr-auto gap-[12px] text-base`}>
          <div className="flex items-center gap-[12px]">
            <SvgCheckCircle
            />
            {getSessionCount()}
          </div>
          <div className="flex items-center gap-[12px]">
            <SvgCheckCircle
            />
            {getSessionDuration()}
          </div>
          <div className="flex items-center gap-[12px]">
            <SvgCheckCircle
            />
            {getLengthOrExpiration()}
          </div>
        </div>

        {/* USAGE SUMMARY */}
        <div className="w-full h-[1px] bg-grayMist my-[18px]"></div>
        <div className="mb-[60px] flex flex-col items-center w-full">
          <div className="w-full justify-between flex items-center mb-[24px]">
            <div
              className="rounded-[8px] bg-grayFlash py-[4px] px-[8px] flex
                            items-center justify-center font-bold text-[16px]"
            >
              Usage Summary
            </div>
            <button
              className="text-blurple underline font-bold text-base"
              onClick={() => setServiceUsage({ show: true, service: service })}
            >
              View details
            </button>
          </div>
          <div className="flex justify-evenly items-center w-full">
            {!service.is_group_service ? (
              <>
                <div className="flex flex-col">
                  <span className="font-bold text-[32px] mb-[4px]">
                    {service?.redeemed_session_count || 0}
                  </span>
                  <span className="font-bold text-base text-grayCharcoal">
                    Completed
                  </span>
                </div>
                <div className="w-[1px] h-[50px] bg-graySlate"></div>
                <div className="flex flex-col">
                  <span className="font-bold text-[32px] mb-[4px]">
                    {service?.scheduled_session_count || 0}
                  </span>
                  <span className="font-bold text-base text-grayCharcoal">
                    Scheduled
                  </span>
                </div>
                <div className="w-[1px] h-[50px] bg-graySlate"></div>
                <div className="flex flex-col">
                  <span className="font-bold text-[32px] mb-[4px]">
                    {service?.available_session_count || 0}
                  </span>
                  <span className="font-bold text-base text-grayCharcoal">
                    Unused
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col">
                  <span className="font-bold text-[32px] mb-[4px]">
                    {service?.group_completed_count
                      ? service?.group_completed_count
                      : 0}
                  </span>
                  <span className="font-bold text-base text-grayCharcoal">
                    Completed
                  </span>
                </div>
                <div className="w-[1px] h-[50px] bg-graySlate"></div>
                <div className="flex flex-col">
                  <span className="font-bold text-[32px] mb-[4px]">
                    {service?.group_scheduled_count
                      ? service.group_scheduled_count
                      : 0}
                  </span>
                  <span className="font-bold text-base text-grayCharcoal">
                    Scheduled
                  </span>
                </div>
              </>
            )}
          </div>
        </div>

        {/* SCHEDULE BUTTON*/}
        <div className="flex items-center justify-between relative">
          {service?.is_group_service &&
          moment(service.enroll_by_date) > moment() ? (
            <button
              disabled
              className="btn-primary w-full h-[48px] text-base"
            >
              Meetings will be scheduled on{" "}
              {moment(service.enroll_by_date).format("MMM DD, YYYY")}
            </button>
          ) : (
            <Button
              onClick={() => buttonCallback(service)}
              className="w-full h-[48px] text-md"
              disabled={
                service.service_status === "past" ||
                service.available_session_count < 1
              }
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectServiceCardPreview;
