import React from "react";
import PastSessionPreview from "./PastSessionPreview";
import { SessionsRequests } from "../../../api/app.service";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import DateRangePicker from "../../date-time/DateRangePicker";
import moment from "moment";
import Select from "react-select";
import useScrollToTop from "../../../hooks/useScrollToTop";
import Loader from "../../ui/loader";

interface Props {
    setDetailedPastSession: any;
}

const PastSessions: React.FC<Props> = ({ setDetailedPastSession }) => {
    const { user } = React.useContext(UserCtx);
    const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
    const [pastSessions, setPastSessions] = React.useState<any[]>([]);
    const [activeDates, setActiveDates] = React.useState<Date[] | null>(null);
    const [onlyShowServiceSessions, setOnlyShowServiceSessions] = React.useState<boolean>(false);
    const [activeParticipantOption, setActiveParticipantOption] = React.useState<string | null>(
        null
    );
    const [loadingPastSessions, setLoadingPastSessions] = React.useState<boolean>(false);
    const datePickerDefaultStartDate = moment().subtract(30, "days");
    const datePickerDefaultEndDate = moment().endOf("day");

    const getPastSessions = () => {
        if (user) {
            setLoadingPastSessions(true);
            SessionsRequests.getPastSessions({
                profile_id: parseInt(user.activeProfileId),
            })
                .then((data: any) => {
                    const filteredByDate = filterSessionsByDate(data.session_list);
                    const filteredByDateAndParticipant =
                        filterSessionsByParticipant(filteredByDate);
                    const filteredByDateParticipantAndService = filterSessionsByService(
                        filteredByDateAndParticipant
                    );

                    setPastSessions(filteredByDateParticipantAndService);
                    setLoadingPastSessions(false);
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setTimeout(() => hideAlert(), 5000);
                    setLoadingPastSessions(false);
                });
        }
    };

    const filterSessionsByDate = (sessionList: any) => {
        let startDate = datePickerDefaultStartDate;
        let endDate = datePickerDefaultEndDate;

        if (activeDates) { 
            startDate = moment(activeDates[0]);
            endDate = moment(activeDates[1]);
            if (startDate.isSame(endDate, "date")) {
                endDate.add(1, "day")
            }
        }

        const filteredSessionList = sessionList.filter((session: any) => {
            const sessionStartTime = moment(session.session_start_time);
            return sessionStartTime > startDate && sessionStartTime < endDate;
        });

        return filteredSessionList;
    };

    const filterSessionsByParticipant = (sessionList: any) => {
        if (activeParticipantOption) {
            const filteredSessionList = sessionList.filter((session: any) => {
                const participantUserIds = session.participant_list.map(
                    (participant: any) => participant.user_id
                );
                return participantUserIds.includes(activeParticipantOption);
            });
            return filteredSessionList;
        } else {
            return sessionList;
        }
    };

    const filterSessionsByService = (sessionList: any) => {
        if (onlyShowServiceSessions) {
            const filteredSessionList = sessionList.filter((session: any) => {
                return session.service_usage_id;
            });
            return filteredSessionList;
        } else {
            return sessionList;
        }
    };

    // Participants options logic
    const unsortedParticipantOptions = pastSessions
        ?.map((session) => {
            const participants = session.participant_list;
            const participantData = participants.map((participant: any) => {
                const participantName = `${participant.first_name} ${participant.last_name}`;
                const participantUserId = participant.user_id;

                if (participantUserId?.toString() === user.user_id) {
                    return null;
                }

                return { participantName, participantUserId };
            });
            return participantData;
        })
        .flat()
        .filter((e) => e !== null);
    const uniqueUserIds: Array<number> = [];
    const uniqueParticipantOptions = unsortedParticipantOptions?.filter((participant) => {
        if (!uniqueUserIds.includes(participant.participantUserId)) {
            uniqueUserIds.push(participant.participantUserId);
            return true;
        } else {
            return false;
        }
    });
    const participantOptions = uniqueParticipantOptions?.map((e) => {
        return {
            value: e.participantUserId,
            label: e.participantName,
        };
    });
    const handleParticipantSelectChange = (e: any) => {
        e ? setActiveParticipantOption(e.value) : setActiveParticipantOption(null);
    };

    // Service options logic
    const serviceOptions = [
        {
            value: false,
            label: "Show all",
        },
        {
            value: true,
            label: "Service Attached",
        },
    ];
    const handleServiceSelectChange = (e: any) => {
        setOnlyShowServiceSessions(e.value);
    };

    // use Effects
    React.useEffect(() => {
        getPastSessions();
    }, [activeDates, activeParticipantOption, onlyShowServiceSessions]);

    useScrollToTop();

    return (
        <div className="px-[16px] md:px-0">
            <div className="mb-[24px] flex flex-col md:flex-row items-center justify-between gap-[24px]">
                <DateRangePicker
                    setActiveDates={setActiveDates}
                    defaultStartDate={datePickerDefaultStartDate.toDate()}
                    defaultEndDate={datePickerDefaultEndDate.toDate()}
                />
                <div className="flex items-center gap-[12px]">
                    <Select
                        className="basic-single w-[180px] md:w-[220px] border-grayCloud text-graySlate rounded-[10px]"
                        classNamePrefix="select"
                        options={participantOptions}
                        name="Participant"
                        placeholder="Participant"
                        onChange={handleParticipantSelectChange}
                        isClearable={true}
                    />
                     <Select
                        className="basic-single w-[180px] md:w-[220px] border-grayCloud text-graySlate rounded-[10px]"
                        classNamePrefix="select"
                        options={serviceOptions}
                        name="Service"
                        placeholder="Service"
                        onChange={handleServiceSelectChange}
                    />
                </div>
            </div>
            {loadingPastSessions ? (
                <div className="mt-[32px] flex flex-col items-center gap-[12px]">
                    <Loader />
                    <h3 className="font-bold text-[16px] mb-[4px] text-center">
                        Looking for your past sessions...
                    </h3>
                </div>
            ) : (
                <>
                    {pastSessions.length > 0 ? (
                        <div className="w:[378px] sm:w-[744px] mx-auto flex flex-col">
                            <>
                                {pastSessions &&
                                    pastSessions.reverse().map((session: any) => (
                                        <div
                                            className="border-b last:border-0 border-grayMist last:mb-[80px] md:last:mb-0"
                                            key={session.id}
                                        >
                                            <PastSessionPreview
                                                session={session}
                                                setDetailedPastSession={setDetailedPastSession}
                                            />
                                        </div>
                                    ))}
                            </>
                        </div>
                    ) : (
                        <p className="text-graySlate font-bold mr-auto">
                            No past sessions for the selected period and filters.
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default PastSessions;
