import React, { type FC, useState, useEffect } from "react"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import {
  ContactRequests,
  NotificationRequests,
  SessionsRequests,
} from "../../../api/app.service"
import topBackgroundGradient from "../../../assets/images/background-gradient.png"
import classes from "./MemberDashboardBento.module.css"
import NotificationCenter from "../../../components/notifications/NotificationCenter"
import { useAuthenticatedUser } from "../../../hooks/useAuthenticatedUser"
import { NotificationCtx } from "../../../context/notificationContext"
import Greeting from "../../../components/dashboard/Greeting"
import { Link } from "react-router-dom"
import { Button } from "../../../components/ui/button"
import MemberScheduling from "../../scheduling/MemberScheduling"
import { DashboardContacts } from "../DashboardContacts"
import DashboardNotes from "../DashboardNotes"
import { SvgCalendar, SvgNotebook } from "../../../components/icons/"

const MemberDashboard: FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [userContacts, setUserContacts] = React.useState<any>([])
  const { user } = React.useContext(UserCtx)
  const authenticatedUser = useAuthenticatedUser(user)
  const [sessionList, setSessionList] = React.useState<any[]>([])
  const [activeJoinButton, setActiveJoinButton] = React.useState(false)
  const [notifications, setNotifications] = React.useState<any[]>([])
  const [pickerOpen, setPickerOpen] = useState<boolean>(false)

  const { setInboxNotifications, setContactsNotifications, notificationAlert } =
    React.useContext(NotificationCtx)
  const { renderError, hideAlert } = React.useContext(CommonFunctionCtx)
  const [isNewNotificationAlert] = React.useState<boolean>(
    notificationAlert.message !== ""
  )

  const itemStyle = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 0 20px 5px rgba(150, 150, 150, 0.2)",
  }

  const getNotifications = () => {
    if (authenticatedUser) {
      NotificationRequests.getNotifications({
        profile_id: parseInt(user.activeProfileId),
      })
        .then((data) => {
          const filteredNotifications = data.notifications.filter((n) => {
            return n.notification_category !== "messages"
          })
          setNotifications(filteredNotifications)
          const unreadContactNotifications = data.notifications.filter((n) => {
            return n.slug === "received_connection_request" && n.viewed === 0
          })
          setContactsNotifications(unreadContactNotifications)
          const unreadInboxNotifications = data.notifications.filter((n) => {
            return n.notification_category === "messages" && n.viewed === 0
          })
          setInboxNotifications(unreadInboxNotifications)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setTimeout(() => hideAlert(), 5000)
        })
    }
  }

  const getContacts = () => {
    if (user?.coachProfile) {
      ContactRequests.getContactList({
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setUserContacts(data.contact_list)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setTimeout(() => hideAlert(), 5000)
        })
    }
  }

  const getSessionList = () => {
    if (user) {
      SessionsRequests.get100msSessionList({
        profile_id: user.activeProfileId,
      })
        .then((data) => {
          setSessionList(data.session_list)
          setActiveJoinButton(data?.session_list[0]?.host_joined)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setTimeout(() => hideAlert(), 5000)
        })
        .finally(() => {
        })
    }
  }

  const _ = require("lodash")
  _.mixin(require("lodash-inflection"))

  React.useEffect(() => {
    getNotifications()
    getContacts()
    getSessionList()
  }, [])

  React.useEffect(() => {
    getNotifications()
    getContacts()
    getSessionList()
  }, [notificationAlert.show])

  React.useEffect(() => {
    if (isNewNotificationAlert) {
      getNotifications()
    }
  }, [isNewNotificationAlert])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <div
        className="bg-cover flex flex-col items-center justify-center md:-mt-40 "
        style={{
          backgroundImage: `url(${topBackgroundGradient})`,
        }}
      >
        <div className="flex justify-center items-center z-20 mt-8 md:mt-32 w-full">
          <Greeting />
        </div>
        <div className={classes.container}>
          <div
            style={{ ...itemStyle, gridArea: "meet" }}
            className={`min-w-[350px] h-[423px] lg:w-[820px] lg:h-[531px]`}
          >
            <div className="lg:hidden font-bold text-lg flex justify-between items-center flex-row gap-2 -mt-10 px-2">
              My Calendar
              <Button
                className="-mt-2"
                variant={"link"}
                onClick={() => setPickerOpen(!pickerOpen)}
              >
                <SvgCalendar />
              </Button>
            </div>
            <div className="hidden lg:flex w-full justify-between -mt-12 p-2">
              <h2 className="font-bold text-lg flex justify-ceneter items-center">
                My Calendar
              </h2>
              <Link to="/member/scheduling" className="z-20">
                <Button variant="link">See all</Button>
              </Link>
            </div>
            <MemberScheduling
              isTodayPage
              pickerOpen={pickerOpen}
              setPickerOpen={setPickerOpen}
            />
          </div>
          {windowWidth >= 1280 && (
            <div
              style={{ ...itemStyle, gridArea: "cale" }}
              className="w-[400px] h-[351px]"
            ></div>
          )}
          <div
            style={{ ...itemStyle, gridArea: "noti" }}
            className={`md:w-[400px] min-w-[350px] h-[355px] mt-6`}
          >
            <NotificationCenter
              isTodayPage
              notifications={notifications}
              setNotifications={setNotifications}
            />
          </div>
          <div
            style={{ ...itemStyle, gridArea: "cont" }}
            className={`md:w-[400px] min-w-[350px] h-[355px] mt-6`}
          >
            <DashboardContacts userContacts={userContacts} />
          </div>
          {windowWidth >= 1280 && (
            <div
              style={{ ...itemStyle, gridArea: "sess" }}
              className="w-[396px] h-[538px] flex flex-col justify-center items-center mt-6"
            >
              <div className="flex justify-start items-start w-full">
                <h2 className="flex gap-2 font-bold text-lg items-center -mt-8 mb-1 ml-2">
                  <SvgNotebook />
                  My Notes
                </h2>
              </div>
              <DashboardNotes userContacts={userContacts} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default MemberDashboard
