import React from "react";
import { CoachPrivateProfileContext } from "../../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import DragDropArea from "../../../DragDropArea";
import { ProfileRequests } from "../../../../api/app.service";
import { UserCtx } from "../../../../context/userContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import { Descendant } from "slate";
import BlogEditor from "./blog/BlogEditor";
import Loader from "../../../ui/loader"
import CloseModal from "../../../CloseModal";
import { Button } from "../../../ui/button";
import CharLimit from "../../../ui/charLimit";
import { SvgWarning } from "../../../icons";

interface Props { }

const CreateBlogPost: React.FC<Props> = (props) => {
    const emptyBlogContent: Descendant[] = [
        {
            type: "paragraph",
            children: [{ text: "" }],
        },
    ];

    const { user } = React.useContext(UserCtx);
    const {
        hideAlert, renderError, setPopupNotification, renderSuccess
    } = React.useContext(CommonFunctionCtx);
    const {
        setCreateBlogPost, getCoachPrivateProfile
    } = React.useContext(CoachPrivateProfileContext);
    const [blogPostTitle, setBlogPostTitle] = React.useState<string>("");
    const [blogContent, setBlogContent] = React.useState<any>(emptyBlogContent);
    const [blogImageFile, setBlogImageFile] = React.useState<any>(null);
    const [blogImageProperties, setBlogImageProperties] = React.useState<any>({ base64: "", type: "" });
    const [previewImgUrl, setPreviewImgUrl] = React.useState<string>("");
    const [formErrors, setFormErrors] = React.useState<any>({});
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const [published, setPublished] = React.useState<boolean>(true);
    const [creatingPost, setCreatingPost] = React.useState<boolean>(false)

    const validateForm = () => {
        const errors: any = {};
        (!blogPostTitle) &&
            (errors.blogPostTitle = "Title is required");
        !blogContent[0]?.children[0]?.text && (errors.blogContent = "Content is required");
        return errors;
    };

    const handleSubmit = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        setFormErrors(validateForm());
        setIsSubmit(true);
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBlogPostTitle(e.target.value)
    }

    const saveBlogPost = () => {
        setCreatingPost(true)
        ProfileRequests.createBlogPost({
            coach_profile_id: user?.coachProfile?.profile_id,
            title: blogPostTitle,
            content: blogContent,
            base_64_string: blogImageProperties?.base64,
            file_extension: blogImageProperties?.type,
            published: published
        })
            .then((data: any) => {
                getCoachPrivateProfile();
                setCreateBlogPost(false);
                setCreatingPost(false);
                renderSuccess("Blog posted.");
                setTimeout(() => hideAlert(), 5000);
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
                setCreatingPost(false);
                setTimeout(() => hideAlert(), 5000);
            });
    }

    const validateFile = (imageFile: File) => {
        if ((imageFile.type === "image/png" || imageFile.type === "image/jpg" || imageFile.type === "image/jpeg")
            && imageFile.size <= 5000000 && imageFile.size > 0) {
            return true;
        } else {
            return false;
        }
    }

    const getBlogImageProperties = () => {
        if (blogImageFile) {
            if (validateFile(blogImageFile)) {
                const type = blogImageFile.type.split("/")[1];
                const reader = new FileReader();
                reader.readAsDataURL(blogImageFile);
                reader.onload = function () {
                    const string = (reader.result as string).split('\n').map((data) => {
                        return data.split(',');
                    })
                    const base64 = string[0][1];
                    setBlogImageProperties({ base64: base64, type: type });
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            } else {
                setPopupNotification({
                    show: true,
                    title: "Bummer! Your file is too big.",
                    message: "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
                    callback: null
                });
                setBlogImageFile(undefined);
                setBlogImageProperties({ base64: "", type: "" });
            }
        }
    }

    React.useEffect(() => {
        getBlogImageProperties();
        if (blogImageFile) {
            setPreviewImgUrl(URL.createObjectURL(blogImageFile));
        }
    }, [blogImageFile])

    React.useEffect(() => {
        if (isSubmit && Object.keys(formErrors).length === 0) {
            saveBlogPost();
            setIsSubmit(false);
        } else {
            setIsSubmit(false);
        }
    }, [formErrors])

    const buttonsSection = () => {
        return <>
            <Button
                variant="link"
                onClick={() => setCreateBlogPost(false)}
            >
                Cancel
            </Button>
            <Button
                className="btn-primary bg-blurple enabled:hover:brightness-110"
                type={"submit"}
                value="Submit Post"
            >
                Submit Post
            </Button>
        </>
    }
    
    return (

        <div
            className="w-full h-full py-[80px] bg-white fixed
                left-0 top-0 px-[16px] md:px-[100px] flex flex-col
                overflow-y-auto z-[2001]"
        >
            <CloseModal callback={() => setCreateBlogPost(false)} styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash" />
            <form
                action="#"
                onSubmit={handleSubmit}
                className="flex w-full flex-col"
            >
                <h2 className="font-bold text-[24px] mb-[32px]">Create Blog Post</h2>
                <div className="flex w-full justify-between flex-col md:flex-row pb-[20px] md:pb-0 h-full md:h-[500px]">
                    {/* LEFT COLUMN */}
                    <div className="flex flex-col gap-8 w-full md:w-[48%] h-full mb-[20px] md:mb-0">
                        {/* Title */}
                        <div className="flex flex-col gap-[8px]">
                            <div className="flex justify-between items-center">
                                <h5 className="mb-[8px] font-bold text-[16px]">Blog title</h5>
                                    <CharLimit   charNumber={45} inputText={blogPostTitle}/>
                            </div>
                            <div
                                className={`default-input w-full ${formErrors?.blogPostTitle && "input-error"}`}
                            >
                                <input
                                    type="text"
                                    className='w-full'
                                    onChange={handleTitleChange}
                                    name="title"
                                    value={blogPostTitle}
                                    maxLength={45}
                                />
                                <div className="overlay">Blog Post Title</div>
                            </div>
                            {formErrors?.blogPostTitle && (
                                <div className="field-error">
                                    <SvgWarning />
                                    {formErrors.blogPostTitle}
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <div className="flex items-center justify-between gap-[8px]">
                                <h5 className="font-bold text-[16px]">Blog image</h5>
                                <span className="font-normal text-sm text-graySlate">
                                    Optional
                                </span>
                            </div>
                            <DragDropArea
                                styling={`w-full rounded-[16px] flex-row h-[100px]`}
                                mediaFile={blogImageFile}
                                setMediaFile={setBlogImageFile}
                                mediaType="image"
                            />
                        </div>
                        <div className="flex flex-col justify-center items-center rounded-[16px]">
                    
                            {previewImgUrl ? (
                                <div className="w-[200px] rounded-[16px] h-[200px]">
                                <img
                                    src={previewImgUrl}
                                    alt="preview"
                                    className="w-full h-full rounded-[16px] object-cover"
                                />
                                </div>
                            ):(
                                <p className="text-graySlate text-center text-sm min-w-[300px] mb-5">Max file size is 1MB  Recommended 300 x300 px at 150 DPI. <br/>File types:  .png, .jpg, and .jpeg</p>
                            )}
                            <p className="text-blurple text-center">Don't have an image for your blog?</p> 
                            <p className="text-blurple text-center">Get a free stock photo here <a href="https://pixabay.com">https://pixabay.com</a></p>
                        </div>
                    </div>

                    {/* RIGHT COLUMN */}
                    <div className="w-full md:w-[48%] h-full md:min-h-[500px]">
                        <div className={`w-full h-full flex flex-col gap-[8px]`}>
                            <h5 className="font-bold text-[16px]">Blog content</h5>
                            <BlogEditor
                                blogContent={blogContent}
                                setBlogContent={setBlogContent}
                                customStyles={formErrors?.blogContent ? "border border-[1px] border-vividRed" : ""}
                            />
                            {formErrors?.blogContent && (
                                <div className="field-error">
                                    <SvgWarning />
                                    {formErrors.blogContent}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center gap-[16px] md:ml-auto mt-[32px]">
                    {creatingPost ? <Loader /> : buttonsSection()}
                </div>
            </form>
        </div>
    )
}

export default CreateBlogPost;
