import { type FC, useContext } from "react";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import CloseModal from "../CloseModal";
import { SvgProblem, SvgSuccess } from "../icons";

interface Props {
  message: string;
  show: boolean;
  variant: "error" | "success";
}

export const StandardAlert: FC<Props> = ({ message, show, variant }) => {
  const { hideAlert } = useContext(CommonFunctionCtx);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-x-2 top-24 z-[100000] flex items-center justify-between rounded-xl bg-white px-3 py-1.5 shadow-lg md:inset-x-auto md:left-1/2 md:w-full md:max-w-screen-sm md:-translate-x-1/2">
      <div className="flex items-center">
        {variant === "error" && (
          <SvgProblem className="text-destructive h-20 w-20" />
        )}
        {variant === "success" && (
          <SvgSuccess className="text-green h-20 w-20" />
        )}
        <span>{message}</span>
      </div>
      <CloseModal callback={hideAlert} styling="mr-3" />
    </div>
  );
};
