import React, { useState } from "react"
import EditSocialMedia from "./edit-forms/EditSocialMedia"
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { getLightTextColor } from "../../../utils/getLightTextColor"
import { ProfileRequests } from "../../../api/app.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { SocialMediaLink } from "../../../models/app.interface"
import { Button } from "../../ui/button"
import Loader from "../../ui/loader"
import { SvgEdit, SvgFacebook, SvgInstagram, SvgLinkedin, SvgPlus, SvgWebsite } from "../../icons/"

interface Props {
  isEditable?: boolean
  bgColor: string
  isAboutSection?: boolean
  coachPublicProfileId: number
}

const ListSocialMedia: React.FC<Props> = ({
  isEditable = false,
  isAboutSection = false,
  bgColor,
  coachPublicProfileId,
}) => {
  const { editSocialMedia, setEditSocialMedia } = React.useContext(
    CoachPrivateProfileContext
  )
  const [loading, setLoading] = React.useState<boolean>(false)
  const [socialLinks, setSocialLinks] = useState<SocialMediaLink[]>([])
  const { renderError } = React.useContext(CommonFunctionCtx)

  const getSocialMedia = async () => {
    try {
      setLoading(true)
      const data = await ProfileRequests.getSocialMedia({
        coach_public_profile_id: coachPublicProfileId,
      })
      setSocialLinks(data.links)
      setLoading(false)
    } catch (ex: any) {
      renderError(ex.response?.data?.message || "An error occurred.")
    }
  }

  const socialIcon = () => {
    if (socialLinks) {
      return socialLinks.map(({ link, id }) => {
        let icon = null
        switch (true) {
          case link.toLowerCase().includes("facebook"):
            icon = <SvgFacebook key={id} />
            break
          case link.toLowerCase().includes("instagram"):
            icon = <SvgInstagram key={id} />
            break
          case link.toLowerCase().includes("linkedin"):
            icon = <SvgLinkedin key={id} />
            break
          default:
            icon = <SvgWebsite key={id} />
        }
        return (
          <Button variant="ghost" asChild size="icon" key={id}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {icon}
            </a>
          </Button>
        )
      })
    } else {
      return null
    }
  }

  const backGroundColor = () => {
    const lightColor = getLightTextColor(bgColor)
    if (socialLinks.length === 0 && !isEditable && !isAboutSection) {
      return lightColor
    } else {
      return ""
    }
  }

  React.useEffect(() => {
    getSocialMedia()
  }, [coachPublicProfileId])

  return (
    <>
      <div className="h-[120px] relative flex justify-center items-center items-start group">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div
              className="flex justify-center items-center gap-4 w-full h-full rounded-[20px]"
              style={{
                backgroundColor: backGroundColor(),
              }}
            >
              {socialIcon()}

              {socialLinks.length === 0 && isEditable && (
                <>
                  <div className="flex justify-center items-center h-full w-full text-center">
                    <p className="text-graySlate text-md font-bold">
                      Add social media to get started
                    </p>
                  </div>
                  <Button
                    onClick={() => setEditSocialMedia(true)}
                    variant="utility"
                    size="lg"
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-[4px] px-[20px] bg-white border z-3 border-[1px] shadow-md opacity-100 hidden group-hover:flex"
                  >
                    <SvgPlus />
                    Add
                  </Button>
                </>
              )}

              {socialLinks.length > 0 && isEditable && (
                <Button
                  onClick={() => setEditSocialMedia(true)}
                  variant="utility"
                  size="lg"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-[4px] px-[20px] bg-white border z-3 border-[1px] shadow-md opacity-100 hidden group-hover:flex"
                >
                  <SvgEdit />
                  Edit
                </Button>
              )}
            </div>

            {editSocialMedia && (
              <EditSocialMedia
                links={socialLinks}
                getSocialMedia={getSocialMedia}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ListSocialMedia
