import * as React from "react";
import DragDropArea from "../../../../components/DragDropArea";
import Select from "react-select";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import VerifyPhonePopup from "../../../../components/authentication/VerifyPhonePopup";
import { VerifyPhone } from "../../../../api/app.service";
import { VerifyEmail } from "../../../../api/app.service";
import { UserInfo } from "../../../../api/app.service";
import { UserCtx } from "../../../../context/userContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import { regions } from "../../../../objects/regionObjects";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "../../../../components/ui/loader";
import useLoading from "../../../../hooks/useLoading";
import { Button } from "../../../../components/ui/button";
import { StreamChat } from "stream-chat";
import {
  SvgCheckCircle,
  SvgClose,
  SvgEdit,
  SvgTrash,
  SvgUploadImage,
  SvgVerifyEmail,
  SvgVerifyPhone,
} from "../../../../components/icons/";

const PersonalInfoPage: React.FC = () => {
  const [editNames, setEditNames] = React.useState<boolean>(false);
  const [editEmail, setEditEmail] = React.useState<boolean>(false);
  const [editPhone, setEditPhone] = React.useState<boolean>(false);
  const [editTitle, setEditTitle] = React.useState<boolean>(false);
  const [editQuote, setEditQuote] = React.useState<boolean>(false);
  const [editDescription, setEditDescription] = React.useState<boolean>(false);
  const [editRegion, setEditRegion] = React.useState<boolean>(false);
  const [editTimezone, setEditTimezone] = React.useState<boolean>(false);
  const [editDisplayName, setEditDisplayName] = React.useState<boolean>(false);
  const [emailVerified, setEmailVerified] = React.useState<boolean>(false);
  const [phoneVerified, setPhoneVerified] = React.useState<boolean>(false);
  const [verifyPhoneForm, setVerifyPhoneForm] = React.useState<boolean>(false);
  const [profileImgFile, setProfileImgFile] = React.useState<File>();
  const [awaitNewImage, setAwaitNewImage] = React.useState<boolean>(false);
  const [loadingPersonalInfo, setLoadingPersonalInfo] =
    React.useState<boolean>(false);
  const { user } = React.useContext(UserCtx);
  const {
    getUser,
    renderError,
    renderSuccess,
    setPopupNotification,
    hideAlert,
    previewImgUrl,
    setPreviewImgUrl,
  } = React.useContext(CommonFunctionCtx);

  const { startLoading, stopLoading } = useLoading();
  const [loadingColor, setLoadingColor] = React.useState<boolean>(false);
  const [loadingDeleteAvatar, setLoadingDeleteAvatar] =
    React.useState<boolean>(false);
  const [values, setValues] = React.useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    region: {},
    displayName: "",
    timeZone: {},
    userColor: "",
    hasAvatar: false,
    title: "",
    quote: "",
    description: "",
  });

  const [tempValues, setTempValues] = React.useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    region: {},
    displayName: "",
    timeZone: {},
    hasAvatar: false,
    title: "",
    quote: "",
    description: "",
  });

  const colors = {
    blurple: "bg-blurple",
    oceanBlue: "bg-oceanBlue",
    grassGreen: "bg-grassGreen",
    fallOrange: "bg-fallOrange",
    darkPurple: "bg-darkPurple",
    charcoalBlack: "bg-charcoalBlack",
  };

  const sendVerifyPhone = () => {
    hideAlert();
    startLoading();
    VerifyPhone.sendPhoneCode({ access_token: user.access_token })
      .then((data) => {
        data.message === "complete" && setVerifyPhoneForm(true);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setTimeout(() => hideAlert(), 5000);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const getPersonalInfo = () => {
    if (user) {
      setValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        phone: user.phone || "",
        timeZone: user.timezone || {},
        displayName: user.displayName || "",
        region: user.region || {},
        userColor: user.userColor || "",
        hasAvatar: user.hasAvatar || false,
        title: user.about?.title || "",
        quote: user.about?.quote || "",
        description: user.about?.description || "",
      });
      setTempValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        phone: user.phone || "",
        timeZone: user.timezone || {},
        displayName: user.displayName || "",
        region: user.region || {},
        hasAvatar: user.hasAvatar || false,
        title: user.about?.title || "",
        quote: user.about?.quote || "",
        description: user.about?.description || "",
      });
      setEmailVerified(user.emailVerified);
      setPhoneVerified(user.phoneVerified);
      stopLoading();
    }
  };

  const handleChange = (e: React.ChangeEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    setTempValues({ ...tempValues, [target.name]: target.value });
  };

  const handlePhoneChange = (number: string) => {
    setValues({ ...tempValues, phone: number });
  };

  const handleEditNames = (e: React.MouseEvent<HTMLButtonElement>) => {
    let target = e.target as HTMLImageElement;
    setEditNames(true);
    const first: any = document.getElementById("firstName");
    const last: any = document.getElementById("lastName");
    setTimeout(function () {
      if (window.innerWidth > 768) {
        first.focus();
      } else {
        if (target.alt === "edit-first") {
          first.focus();
        } else if (target.alt === "edit-last") {
          last.focus();
        }
      }
    }, 20);
  };

  const handleEditEmail = () => {
    setEditEmail(true);
    const email: any = document.getElementById("email");
    setTimeout(function () {
      email.focus();
    }, 20);
  };

  const handleEditPhone = () => {
    setEditPhone(true);
    const phone: any = document.getElementById("phone");
    setTimeout(function () {
      phone.focus();
    }, 20);
  };

  const handleEditTitle = () => {
    setEditTitle(true);
    const title: any = document.getElementById("title");
    setTimeout(function () {
      title.focus();
    }, 20);
  };

  const handleEditQuote = () => {
    setEditQuote(true);
    const quote: any = document.getElementById("quote");
    setTimeout(function () {
      quote.focus();
    }, 20);
  };

  const handleEditDescription = () => {
    setEditDescription(true);
    const description: any = document.getElementById("description");
    setTimeout(function () {
      description.focus();
    }, 20);
  };

  const handleEditRegion = () => {
    setEditRegion(true);
    const region: any = document.getElementById("region");
    setTimeout(function () {
      region.focus();
    }, 20);
  };

  const handleEditTimezone = () => {
    setEditTimezone(true);
    const timezone: any = document.getElementById("timezone");
    setTimeout(function () {
      timezone.focus();
    }, 20);
  };

  const handleEditDisplayName = () => {
    setEditDisplayName(true);
    const displayName: any = document.getElementById("displayName");
    setTimeout(function () {
      displayName.focus();
    }, 20);
  };

  const validEmail = () => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    if (tempValues.email && tempValues.email.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };

  const resetTempNames = () => {
    tempValues["firstName"] = values["firstName"];
    tempValues["lastName"] = values["lastName"];
    setEditNames(false);
  };

  const resetTempEmail = () => {
    tempValues["email"] = values["email"];
    setEditEmail(false);
  };

  const resetTempPhone = () => {
    tempValues["phone"] = values["phone"];
    setEditPhone(false);
  };

  const resetTempTitle = () => {
    tempValues["title"] = values["title"];
    setEditTitle(false);
  };

  const resetTempQuote = () => {
    tempValues["quote"] = values["quote"];
    setEditQuote(false);
  };

  const resetTempDescription = () => {
    tempValues["description"] = values["description"];
    setEditDescription(false);
  };

  const resetTempRegion = () => {
    tempValues["region"] = values["region"];
    setEditRegion(false);
  };

  const resetTempTimezone = () => {
    tempValues["timeZone"] = values["timeZone"];
    setEditTimezone(false);
  };

  const resetTempDisplayName = () => {
    tempValues["displayName"] = values["displayName"];
    setEditDisplayName(false);
  };

  const saveNames = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    hideAlert();
    if (user) {
      if (
        values.firstName === tempValues.firstName &&
        values.lastName === tempValues.lastName
      ) {
        setEditNames(false);
      } else {
        startLoading();
        UserInfo.editFullName({
          user_id: parseInt(user.user_id),
          access_token: user.access_token,
          first_name: tempValues.firstName,
          last_name: tempValues.lastName,
        })
          .then(() => {
            getUser();
            setEditNames(false);
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
            stopLoading();
          });
      }
    }

    let activeProfile = user?.activeProfile;
    let inactiveProfile = "";
    if (activeProfile === "coach") {
      inactiveProfile = "member";
    } else {
      activeProfile = "member";
      inactiveProfile = "coach";
    }
    propagateNameToStream(activeProfile);
    propagateNameToStream(inactiveProfile);
  };

  const propagateNameToStream = (profileType: string | null) => {
    let streamToken: string = "";
    const streamClientId = process.env.REACT_APP_STREAM_CLIENT_ID;

    let profileId: string = "";

    if (profileType === "coach") {
      streamToken = user?.coachProfile.stream_token;
      profileId = user?.coachProfile?.profile_id?.toString();
    } else if (profileType === "member") {
      streamToken = user?.memberProfile.stream_token;
      profileId = user?.memberProfile?.profile_id?.toString();
    }

    if (streamClientId && streamToken) {
      const client = new StreamChat(streamClientId);
      client
        .connectUser(
          {
            id: profileId,
          },
          streamToken.toString(),
        )
        .then(() => {
          const updatedInfo = {
            id: profileId,
            set: {
              name: `${tempValues.firstName} ${tempValues.lastName}`,
            },
          };
          return client.partialUpdateUser(updatedInfo);
        })
        .then((updateUser) => {
          client.disconnectUser();
          renderSuccess(
            `Successfully updated name to ${updateUser?.users?.[profileId].name}`,
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const saveEmail = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    hideAlert();
    if (user) {
      if (values.email === tempValues.email) {
        setEditEmail(false);
      } else {
        startLoading();
        UserInfo.editEmail({
          user_id: parseInt(user.user_id),
          access_token: user.access_token,
          email: tempValues.email.toLowerCase(),
        })
          .then((data) => {
            getUser();
            setEmailVerified(data.email_verified);
            setEditEmail(false);
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
            stopLoading();
          });
      }
    }
  };

  const savePhone = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    hideAlert();
    if (user) {
      if (values.phone.length < 10) {
        setEditPhone(false);
      } else {
        startLoading();
        UserInfo.editPhoneNumber({
          user_id: parseInt(user.user_id),
          access_token: user.access_token,
          phone_number: values.phone,
        })
          .then((data) => {
            getUser();
            setPhoneVerified(data.phone_number_verified);
            setEditPhone(false);
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
            stopLoading();
          });
      }
    }
  };

  const saveAbout = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    hideAlert();
    if (user) {
      const aboutObject = {
        title: tempValues.title,
        quote: tempValues.quote,
        description: tempValues.description,
      };
      startLoading();
      UserInfo.editAbout({
        user_id: parseInt(user.user_id),
        about: aboutObject,
      })
        .then(() => {
          getUser();
          setEditTitle(false);
          setEditQuote(false);
          setEditDescription(false);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
          stopLoading();
        });
    }
  };

  const saveRegion = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    hideAlert();
    if (user) {
      if (values.region === tempValues.region) {
        setEditRegion(false);
      } else {
        startLoading();
        UserInfo.editRegion({
          user_id: parseInt(user.user_id),
          access_token: user.access_token,
          region: tempValues.region,
        })
          .then(() => {
            getUser();
            setEditRegion(false);
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
            stopLoading();
          });
      }
    }
  };

  const saveDisplayName = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    hideAlert();
    if (user) {
      if (values.displayName === tempValues.displayName) {
        setEditDisplayName(false);
      } else {
        startLoading();
        UserInfo.editDisplayName({
          user_id: parseInt(user.user_id),
          access_token: user.access_token,
          display_name: tempValues.displayName,
        })
          .then(() => {
            getUser();
            setEditDisplayName(false);
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
            stopLoading();
          });
      }
    }
  };

  const saveUserColor = (color: string) => {
    hideAlert();
    if (user) {
      setLoadingColor(true);
      startLoading();
      UserInfo.editUserColor({
        user_id: parseInt(user.user_id),
        access_token: user.access_token,
        user_color: color,
      })
        .then(() => {
          setLoadingColor(false);
          setValues({ ...values, userColor: color });
          getUser();
        })
        .catch((ex: any) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
          stopLoading();
        });
    }
  };

  const saveUserProfileImage = (base64String: string, type: string) => {
    hideAlert();
    if (user) {
      startLoading();
      UserInfo.editUserAccountImage({
        user_id: parseInt(user.user_id),
        access_token: user.access_token,
        base_64_string: base64String,
        file_extension: type,
      })
        .then(() => {
          getUser().then(() => {
            renderSuccess("Uploaded account image");
            setTimeout(() => hideAlert(), 5000);
            setProfileImgFile(undefined);
            setAwaitNewImage(false);
          });
        })
        .catch((ex: any) => {
          console.log(ex);
          setProfileImgFile(undefined);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
          stopLoading();
        });
    }
  };

  const saveTimezone = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    hideAlert();
    if (user) {
      if (values.timeZone === tempValues.timeZone) {
        setEditTimezone(false);
      } else {
        startLoading();
        UserInfo.editTimezone({
          user_id: parseInt(user.user_id),
          access_token: user.access_token,
          timezone: tempValues.timeZone,
        })
          .then(() => {
            getUser();
            setEditTimezone(false);
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
            stopLoading();
          });
      }
    }
  };

  const sendVerifyEmail = () => {
    hideAlert();
    startLoading();
    if (user) {
      VerifyEmail.sendEmailCode({ user_id: parseInt(user.user_id) })
        .then((data: any) => {
          if (data.message === "complete") {
            setPopupNotification({
              show: true,
              title: "Verification Link Sent!",
              message:
                "A verification email has been sent to the email address associated with your account. Please check your email to verify your email address. 🎉",
              callback: null,
            });
          }
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const deleteProfileImage = () => {
    hideAlert();
    if (user) {
      setLoadingDeleteAvatar(true);
      startLoading();
      UserInfo.deleteUserAccountImage({ user_id: parseInt(user.user_id) })
        .then(() => {
          getUser().then(() => {
            setLoadingDeleteAvatar(false);
            renderSuccess("Deleted account image");
            setTimeout(() => hideAlert(), 5000);
          });
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          stopLoading();
        });
    }
  };

  const validateFile = (imageFile: File) => {
    if (
      (imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg") &&
      imageFile.size <= 5000000 &&
      imageFile.size > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleProfileImageUpload = () => {
    if (profileImgFile) {
      if (validateFile(profileImgFile)) {
        const type = profileImgFile.type.split("/")[1];
        const reader = new FileReader();
        reader.readAsDataURL(profileImgFile);
        reader.onload = function () {
          const string = (reader.result as string).split("\n").map((data) => {
            return data.split(",");
          });
          const base64 = string[0][1];
          saveUserProfileImage(base64, type);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        setPopupNotification({
          show: true,
          title: "Bummer! Your file is too big.",
          message:
            "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
          callback: null,
        });
        setProfileImgFile(undefined);
      }
    }
  };

  React.useEffect(() => {
    handleProfileImageUpload();
    if (profileImgFile) {
      setPreviewImgUrl(URL.createObjectURL(profileImgFile));
    }
  }, [profileImgFile]);

  React.useEffect(() => {
    getPersonalInfo();
  }, [user]);

  useScrollToTop(user);

  React.useEffect(() => {
    setLoadingPersonalInfo(true);
    getUser().then(() => {
      setLoadingPersonalInfo(false);
    });
  }, []);

  return (
    <div
      className={`mx-auto flex flex-col items-center px-[20px] pb-36 md:w-[744px] md:px-0`}
    >
      {verifyPhoneForm && (
        <VerifyPhonePopup
          phone={values?.phone}
          setVerifyPhoneForm={setVerifyPhoneForm}
          setPhoneVerified={setPhoneVerified}
        />
      )}
      {loadingPersonalInfo ? (
        <div className="flex flex-col items-center gap-[12px]">
          <Loader />
          <h3 className="mb-[4px] text-center text-[16px] font-bold">
            Loading your personal info...
          </h3>
        </div>
      ) : (
        <>
          <div className="main-shadow mb-[32px] flex w-full flex-col rounded-[16px] px-[20px] py-[24px] md:w-[744px] md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[28px] md:font-semibold">
              Details
            </h4>
            {values.hasAvatar ? (
              <div
                className="mb-[40px] flex w-full flex-col
                                items-center md:flex-row md:justify-start md:gap-[40px]"
              >
                {awaitNewImage ? (
                  <>
                    <DragDropArea
                      styling={`rounded-full w-[140px] h-[140px]`}
                      mediaFile={profileImgFile}
                      setMediaFile={setProfileImgFile}
                      mediaType="image"
                    />
                    <button
                      className="btn-primary btn-blue"
                      onClick={() => setAwaitNewImage(false)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <div className="relative mb-[20px] h-[140px] w-[140px] rounded-full md:mb-0">
                      {previewImgUrl ? (
                        <img
                          src={previewImgUrl}
                          className="absolute left-0 top-0 flex h-full w-full
                                                    items-center justify-center rounded-full object-cover"
                          alt="avatar preview"
                        />
                      ) : (
                        <img
                          src={user.avatar_url}
                          className="absolute left-0 top-0 flex h-full w-full
                                                    items-center justify-center rounded-full object-cover"
                          alt="avatar"
                        />
                      )}
                    </div>

                    <div className="flex flex-wrap items-center justify-center gap-3">
                      <Button
                        variant="secondary"
                        onClick={() => setAwaitNewImage(true)}
                      >
                        <span className="hidden md:block">
                          Upload New Image
                        </span>
                        <span className="md:hidden">New Image</span>
                        <SvgUploadImage />
                      </Button>
                      {loadingDeleteAvatar ? (
                        <div className="ml-16">
                          <Loader />
                        </div>
                      ) : (
                        <Button
                          variant="destructive"
                          onClick={deleteProfileImage}
                        >
                          <span className="hidden md:block">
                            Delete Profile Image
                          </span>
                          <span className="md:hidden">Delete Image</span>
                          <SvgTrash />
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                className="mb-[40px] flex w-full flex-col
                                items-center md:flex-row md:justify-start md:gap-[129px]"
              >
                <DragDropArea
                  styling={`rounded-full w-[140px] h-[140px]`}
                  mediaFile={profileImgFile}
                  setMediaFile={setProfileImgFile}
                  mediaType="image"
                />
                <div className="flex flex-col items-center">
                  <p className="text-graySlate mb-[12px]">
                    or choose your avatar color
                  </p>
                  <div className="flex items-center gap-[8px]">
                    {loadingColor ? (
                      <Loader />
                    ) : (
                      Object.entries(colors).map(([colorName, bgColor]) => (
                        <div
                          className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${bgColor} cursor-pointer
                                                ${
                                                  colorName ===
                                                    values.userColor &&
                                                  "border border-solid border-black"
                                                }`}
                          onClick={() => saveUserColor(colorName)}
                          key={colorName}
                        ></div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
            <form onSubmit={saveNames}>
              <div
                className={`mb-[16px] flex flex-col gap-[16px] md:flex-row md:gap-[14px] ${editNames && "md:gap-[10px]"}`}
              >
                <div className="flex w-full items-center gap-2">
                  <div className={`default-input w-full`}>
                    <input
                      type="text"
                      placeholder="First Name"
                      value={tempValues.firstName}
                      onChange={handleChange}
                      name="firstName"
                      id="firstName"
                      autoComplete="off"
                      disabled={!editNames}
                    />
                    <div className="overlay">First name</div>
                  </div>
                  {!editNames && (
                    <Button
                      variant="ghost"
                      onClick={handleEditNames}
                      className="md:hidden"
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                <div className="flex w-full items-center gap-2">
                  <div className={`default-input w-full`}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={tempValues.lastName}
                      onChange={handleChange}
                      name="lastName"
                      id="lastName"
                      autoComplete="off"
                      disabled={!editNames}
                    />
                    <div className="overlay">Last name</div>
                  </div>
                  {!editNames && (
                    <Button
                      variant="ghost"
                      onClick={handleEditNames}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editNames && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button
                      disabled={
                        tempValues.firstName === "" ||
                        tempValues.lastName === ""
                      }
                      onClick={saveNames}
                      type="submit"
                    >
                      Save
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempNames}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
            </form>
            <form onSubmit={saveEmail}>
              <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-center gap-2">
                  <div className={`default-input w-full`}>
                    <input
                      type="text"
                      placeholder="Email"
                      value={tempValues.email}
                      onChange={handleChange}
                      name="email"
                      id="email"
                      autoComplete="off"
                      disabled={!editEmail}
                    />
                    <div className="overlay">Email</div>
                  </div>
                  {!editEmail && (
                    <Button
                      variant="ghost"
                      onClick={handleEditEmail}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editEmail && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button disabled={!validEmail()} type="submit">
                      Save
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempEmail}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
            </form>
            {emailVerified ? (
              <div className="mb-[16px] mt-[12px] flex items-center">
                <SvgCheckCircle />
                <p className="text-vividCyanGreen ml-[8px] text-base">
                  Verified Email
                </p>
              </div>
            ) : (
              <button
                className="mb-[16px] mt-[12px] flex items-center"
                onClick={sendVerifyEmail}
              >
                <SvgVerifyEmail fill="#0693E3" />
                <p className="text-cyanBlue ml-[8px] text-base">Verify Email</p>
              </button>
            )}
            <form action="#" onSubmit={savePhone}>
              <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-center gap-2">
                  <div className={`default-input w-full`}>
                    <PhoneInput
                      placeholder="Enter your mobile number"
                      country="us"
                      onChange={handlePhoneChange}
                      value={user.phone}
                      containerClass={`default-input w-full`}
                      inputClass={`w-full h-full rounded-lg focus:py-0 phone-input`}
                      inputProps={{ enableSearch: true, id: `phone` }}
                      disabled={!editPhone}
                    ></PhoneInput>
                  </div>
                  {!editPhone && (
                    <Button
                      variant="ghost"
                      onClick={handleEditPhone}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editPhone && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button type="submit">Save</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempPhone}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
            </form>
            {phoneVerified ? (
              <div className="mb-[16px] mt-[12px] flex cursor-pointer items-center">
                <SvgCheckCircle />
                <p className="text-vividCyanGreen ml-[8px] text-base">
                  Verified Phone
                </p>
              </div>
            ) : (
              <button
                className="mb-[16px] mt-[12px] flex cursor-pointer items-center"
                onClick={sendVerifyPhone}
              >
                <SvgVerifyPhone />
                <p className="text-cyanBlue ml-[8px] text-base">
                  Click to Verify Phone
                </p>
              </button>
            )}
            <form action="#" onSubmit={saveDisplayName}>
              <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-center gap-2">
                  <div className={`default-input w-full`}>
                    <input
                      type="text"
                      placeholder="Display Name"
                      value={tempValues.displayName}
                      onChange={handleChange}
                      name="displayName"
                      id="displayName"
                      autoComplete="off"
                      disabled={!editDisplayName}
                    />
                    <div className="overlay">Display Name</div>
                  </div>
                  {!editDisplayName && (
                    <Button
                      variant="ghost"
                      onClick={handleEditDisplayName}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editDisplayName && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button type="submit">Save</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempDisplayName}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="main-shadow mb-[32px] hidden flex-col rounded-[16px] p-[24px] md:flex md:w-[744px] md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[28px] md:font-semibold">
              Details
            </h4>
            <form action="#" onSubmit={saveAbout}>
              <div className="mb-[10px] flex items-center gap-[16px]">
                <h5 className="text-base font-bold">Tell us who you are</h5>
              </div>
              <div className="mb-[24px] flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-center gap-2">
                  <div className={`default-input w-full`}>
                    <input
                      type="text"
                      placeholder="Title"
                      value={tempValues.title}
                      onChange={handleChange}
                      name="title"
                      id="title"
                      autoComplete="off"
                      disabled={!editTitle}
                    />
                    <div className="overlay">Title</div>
                  </div>
                  {!editTitle && (
                    <Button
                      variant="ghost"
                      onClick={handleEditTitle}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editTitle && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button type="submit">Save</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempTitle}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
              <div className="mb-[10px] flex items-center gap-[16px]">
                <h5 className="text-base font-bold">Quote</h5>
                {/* <p className="text-gray text-base">
                                    144 Characters
                                </p> */}
              </div>
              <div className="mb-[24px] flex flex-col items-start gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-start gap-2">
                  <div className={`w-full`}>
                    <textarea
                      onChange={handleChange}
                      name="quote"
                      id="quote"
                      cols={30}
                      rows={8}
                      className={`text-graySlate focus:border-royalBlue w-full
                                            resize-none rounded-[10px] border-[1px] border-solid
                                            bg-white px-[20px] py-[16px] text-[16px]
                                            focus:border focus:border-solid focus:text-black
                                            focus:outline-0 ${
                                              editQuote
                                                ? "border-royalBlue"
                                                : "border-grayCloud"
                                            }`}
                      placeholder="Here, we recommend you add a quote. This can be something you live by, a bit of inspiration for potential clients, or a sliver of wisdom."
                      disabled={!editQuote}
                      value={tempValues.quote}
                    ></textarea>
                  </div>
                  {!editQuote && (
                    <Button
                      variant="ghost"
                      onClick={handleEditQuote}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editQuote && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button type="submit">Save</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempQuote}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
              <div className="mb-[10px] flex items-center gap-[16px]">
                <h5 className="text-base font-bold">Description</h5>
                {/* <p className="text-gray text-base">
                                    144 Characters
                                </p> */}
              </div>
              <div className="mb-[24px] flex flex-col items-start gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-start gap-2">
                  <div className={`w-full`}>
                    <textarea
                      onChange={handleChange}
                      name="description"
                      id="description"
                      cols={30}
                      rows={8}
                      className={`text-graySlate focus:border-royalBlue w-full
                                            resize-none rounded-[10px] border-[1px] border-solid
                                            bg-white px-[20px] py-[16px] text-[16px]
                                            focus:border focus:border-solid focus:text-black
                                            focus:outline-0 ${
                                              editDescription
                                                ? "border-royalBlue"
                                                : "border-grayCloud"
                                            }`}
                      placeholder="This is where you’ll put a brief bio. We don’t need to know what hospital you were born at, just what makes you a 100% rockstar coach!"
                      disabled={!editDescription}
                      value={tempValues.description}
                    ></textarea>
                  </div>
                  {!editDescription && (
                    <Button
                      variant="ghost"
                      onClick={handleEditDescription}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editDescription && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button type="submit">Save</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempDescription}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="main-shadow mb-[32px] hidden flex-col rounded-[16px] p-[24px] md:flex md:w-[744px] md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[16px] md:text-[28px] md:font-semibold">
              Region
            </h4>
            <p className="text-graySlate mb-[24px] text-base">
              Where are you located?
            </p>
            <form action="#" onSubmit={saveRegion}>
              <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-center gap-2">
                  <Select
                    className="basic-single w-full"
                    classNamePrefix="select"
                    value={tempValues.region}
                    options={regions}
                    menuPlacement="top"
                    name="region"
                    id="region"
                    isDisabled={!editRegion}
                    onChange={(e: any) =>
                      setTempValues({ ...tempValues, region: e })
                    }
                  />
                  {!editRegion && (
                    <Button
                      variant="ghost"
                      onClick={handleEditRegion}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editRegion && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button type="submit">Save</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempRegion}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="main-shadow relative flex w-full flex-col rounded-[16px] px-[20px] py-[24px] md:w-[744px] md:p-[48px]">
            <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[28px] md:font-semibold">
              Timezone
            </h4>
            <p className="text-graySlate mb-[12px] w-[271px] text-base md:mb-[16px] md:w-[405px]">
              Your times, account data, and analytics information will be
              displayed in the timezone you select below.
            </p>
            <form action="#" onSubmit={saveTimezone}>
              <div className="flex flex-col gap-[16px] md:flex-row md:gap-[12px]">
                <div className="flex w-full items-center gap-2">
                  {tempValues.timeZone && (
                    <TimezoneSelect
                      className="basic-single w-full"
                      classNamePrefix="select"
                      value={tempValues.timeZone}
                      menuPlacement="top"
                      name="timezone"
                      id="timezone"
                      isDisabled={!editTimezone}
                      onChange={(e) =>
                        setTempValues({ ...tempValues, timeZone: e })
                      }
                      timezones={{
                        ...allTimezones,
                        [user?.timezone.value]: user?.timezone.value,
                      }}
                    />
                  )}
                  {!editTimezone && (
                    <Button
                      variant="ghost"
                      onClick={handleEditTimezone}
                      size="icon"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </div>
                {editTimezone && (
                  <div className="flex flex-row-reverse items-center justify-between gap-2 md:flex-row">
                    <Button type="submit">Save</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={resetTempTimezone}
                    >
                      <SvgClose />
                    </Button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalInfoPage;
