import React from "react"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ServiceRequests } from "../../api/app.service"
import questionMarkNB from "../../assets/images/questionmarkcircle.png"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import {
  SvgBundle,
  SvgCheckCircle,
  SvgClose,
  SvgConsultation,
  SvgUserCheck,
} from "../icons"

interface Props {
  confirmServiceInvite: any
  setConfirmServiceInvite: any
  selectedContact: any
  // TODO delete if not needed
  setShowContactProfile?: any
  // TODO make necessary if can
  setInviteToService?: any
}

interface ServiceLinkedForm {
  id: number
  title: string
  description: string
  url: string
  coach_profile_id: number
}
const ConfirmServiceInvite: React.FC<Props> = ({
  confirmServiceInvite,
  setConfirmServiceInvite,
  selectedContact,
  setInviteToService,
}) => {
  const {
    billing,
    details,
    image,
    session,
    title,
    type,
    expiration,
    serviceLinkedForms,
  } = confirmServiceInvite.service_details
  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess, hideAlert } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()

  // State for controling program details on hover
  const [isHovering, setIsHovering] = React.useState<boolean>(false)

  // 	State for custom message
  const [customMessage, setCustomMessage] = React.useState<string>("")

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const getUnits = () => {
    const unit = billing.unit
    if (unit === "bulk") {
      return "/program"
    } else if (unit === "session") {
      return "/session"
    } else if (["month", "monthly"].includes(unit)) {
      return "/month"
    } else if (["year", "yearly"].includes(unit)) {
      return "/year"
    } else if (["week", "weekly"].includes(unit)) {
      return "/week"
    }
  }

  const handleSendInvite = () => {
    if (user) {
      startLoading()
      ServiceRequests.createServiceInvite({
        coach_profile_id: parseInt(user.activeProfileId),
        member_profile_id: parseInt(selectedContact.profile_id),
        stripe_product_id: confirmServiceInvite.stripe_product_id,
        stripe_price_id: confirmServiceInvite.stripe_price_id,
        service_name: confirmServiceInvite.service_details.title,
        custom_message: customMessage,
      })
        .then(() => {
          stopLoading()
          setConfirmServiceInvite(null)
          setInviteToService(true)
          renderSuccess(
            `Successfully sent service invite to ${selectedContact.contact_name}`
          )
          setTimeout(() => hideAlert(), 5000)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const getPrice = () => {
    let result = billing?.amount === 0 ? "Free" : `$${billing?.amount}`
    if (type === "recurring") {
      result = `$${parseInt(billing?.amount).toFixed(2)}`
    }
    return result
  }

  const getSessionType = () => {
    if (type === "individual") {
      return "Single Session"
    } else if (type === "consultation") {
      return "Consultation Session"
    } else if (type === "recurring") {
      return "Recurring Sessions"
    } else if (type === "bundle") {
      return "Multiple Sessions"
    }
  }

  const getSessionTypeImage = () => {
    if (type === "individual") {
      return <SvgUserCheck />
    } else if (type === "consultation") {
      return <SvgConsultation />
    } else if (type === "recurring") {
      return <SvgConsultation />
    } else if (type === "bundle") {
      return <SvgBundle />
    }
  }

  const goBackToServiceInvite = () => {
    setConfirmServiceInvite(null)
    setInviteToService(true)
  }

  const _ = require("lodash")
  _.mixin(require("lodash-inflection"))

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(type)) {
      return `${session.count} session`
    } else if (["bundle"].includes(type)) {
      return `${session?.count} ${_("session").pluralize(session.count)}`
    } else if (["recurring"].includes(type)) {
      return (
        <div>
          {`${session?.count} 
                        ${_("session").pluralize(session?.count)}`}
          <span className="text-darkGray"> / {`${billing?.unit}`}</span>
        </div>
      )
    } else {
      return "Not Found"
    }
  }

  const getSessionDuration = () => {
    return (
      <div>
        {parseInt(session?.duration) / 60} minutes
        <span className="text-darkGray"> / session</span>
      </div>
    )
  }

  const getLengthOrExpiration = () => {
    let expirationString = "No expiration"
    if (["consultation", "individual", "bundle"].includes(type)) {
      if (expiration?.expires) {
        expirationString = `Expires after ${expiration?.time} ${expiration?.type}`
      } else {
        expirationString = "No expiration"
      }
    } else if (["recurring"].includes(type)) {
      const units = expiration?.type === "weeks" ? "week" : "month"
      expirationString = `${expiration?.time} ${units} program`
    } else {
      expirationString = "Expiration not found"
    }
    return expirationString
  }

  return (
    <div className="w-[100vw] h-[100vh] zoee-backdrop-filter fixed top-0 left-0 z-[1008]">
      <div className="relative main-shadow top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] flex flex-col shrink mx-[5px] rounded-[16px] max-w-[900px] bg-white z-[1009]  md:pt-[152px] px-[24px] md:px-[48px] pb-[24px] md:pb-[48px] max-h-[770px] ">
        <div
          className="flex items-center justify-center absolute top-[24px] right-[24px]
                    cursor-pointer dark-gray-blurred z-[5] rounded-full w-[32px] h-[32px]"
          onClick={goBackToServiceInvite}
        >
          <SvgClose className="text-white" />
        </div>
        <div
          className={`absolute top-0 left-0 w-full h-[80px] md:h-[120px] rounded-t-[16px] aspect-w-3 aspect-h-1 md:aspect-h-2 ${image?.color}`}
        >
          {image?.exists && (
            <img
              src={`https://clientservice-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${confirmServiceInvite.stripe_product_id}?${new Date().getTime()}`}
              alt="service"
              className="w-full h-full object-cover rounded-t-[16px]"
            />
          )}
        </div>
        <div
          className="flex flex-col md:flex-row w-full md:items-center justify-between 
                    pb-[17px] border-grayMist border-b-[1px] mb-[16px]"
        >
          <h2 className="font-bold text-[18px] md:text-[22px]">{title}</h2>
          <div className="text-graySlate">
            <span className="text-primaryBlue text-[24px] md:text-[28px] font-bold">
              {`${getPrice()}`}
            </span>
            {getUnits()}
          </div>
        </div>
        {/* PROGRAM DETAILS */}
        <div className="flex justify-end w-full mb-[16px] text-base font-bold">
          <div className="flex items-center gap-[8px]">
            {getSessionTypeImage()}
            {getSessionType()}
          </div>
        </div>
        <div className="gap-[12px] flex flex-col mb-[20px] md:mb-[24px] text-base">
          <div className="flex items-center gap-[12px]">
            <SvgCheckCircle />
            {getSessionCount()}
          </div>
          <div className="flex items-center gap-[12px]">
            <SvgCheckCircle />
            {getSessionDuration()}
          </div>
          <div className="flex items-center gap-[12px]">
            <SvgCheckCircle />
            {getLengthOrExpiration()}
          </div>
        </div>
        {serviceLinkedForms && (
          <div className="gap-[12px] flex flex-col mb-[20px] md:mb-[24px] text-base">
            Forms:
            <ul>
              {serviceLinkedForms.map(
                (form: ServiceLinkedForm, index: number) => {
                  return (
                    <li key={index}>
                      <a href={form.url}>{form.title}</a>
                    </li>
                  )
                }
              )}
            </ul>
          </div>
        )}
        {/* Hover Program details */}
        <div className="flex items-center py-[25px] gap-2">
          <div className="bg-grayFlash py-[4px] px-[6px] rounded-[8px] flex-end items-center font-bold justify-center gap-2 ">
            Program Details
          </div>
          <div
            className="relative"
            onFocus={handleMouseOver}
            onMouseOver={handleMouseOver}
            onBlur={handleMouseOut}
            onMouseOut={handleMouseOut}
          >
            <img src={questionMarkNB} alt="service detail icon" />
          </div>

          {isHovering && (
            <div
              className="text-base h-auto w-[40%] mb-[32px] fixed top-[320px] left-[24px] md:top-[440px] md:left-[215px] overflow-y-auto z-10011 p-[20px] inline-block max-h-[330px] min-w-[330px] border border-black bg-white rounded-[8px]"
              data-popover
              id="popover-description"
              role="tooltip"
            >
              <div className="mb-[10px] font-bold">Program Details</div>

              <p>{details.description1}</p>
            </div>
          )}
        </div>
        <label className="font-bold text-[16px]" htmlFor="coach-message">
          Message
        </label>
        <textarea
          id="coach-message"
          name="coach message"
          value={customMessage}
          placeholder="Enter a personalized message to you client (optional)"
          autoFocus
          onChange={(e) => setCustomMessage(e.target.value)}
          className="shrink p-[10px] items-start h-[210px] max-w-[817px] border border-black rounded-[10px] mt-[8px] mb-[12px] text-black"
        ></textarea>
        <Button
          className="px-[24px] py-[12] mr-auto min-h-[48px] gap-[10px] text-md"
          onClick={handleSendInvite}
        >
          Send Invite
        </Button>
      </div>
    </div>
  )
}

export default ConfirmServiceInvite
