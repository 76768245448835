import React from "react";
import CardSelector from "../subscription/checkout-process/CardSelector";
import { SubscriptionContext } from "../../pages/subscription/context/subscriptionContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { SubscriptionRequests } from "../../api/app.service";
import Loader from "../ui/loader";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import { SvgInformationCircle, SvgPlus } from "../icons";

const CurrentPaymentMethod: React.FC = () => {
  const {
    paymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    createSetupIntent,
    subscription,
    getSubscription,
    delayedGetPaymentMethods,
    setPaymentMethodModal,
  } = React.useContext(SubscriptionContext);
  const { setPopupNotification, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const { startLoading } = useLoading();

  const updateSubscriptionPaymentMethod = () => {
    if (
      subscription?.stripe_payment_method_id !==
      selectedPaymentMethod?.stripe_payment_method_id
    ) {
      startLoading();
      SubscriptionRequests.updateSubscriptionPaymentMethod({
        stripe_payment_method_id:
          selectedPaymentMethod?.stripe_payment_method_id,
        subscription_id: subscription?.subscription_id,
      })
        .then(() => {
          getSubscription().then(() => {
            delayedGetPaymentMethods();
            setTimeout(() => {
              renderSuccess(
                "Successfully updated subscription payment method!",
              );
            });
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className="main-shadow mb-[80px] w-full rounded-[16px] p-[16px] sm:mb-0 sm:min-w-[360px] md:h-[178px] md:p-[32px]">
        <div className="mb-[32px] flex w-full items-center justify-between">
          <h2 className="text-[22px] font-bold">Payment Method</h2>
          <button
            onClick={() => {
              setPopupNotification({
                show: true,
                title: "Payment Methods",
                message:
                  "Here you can select the payment method that you want to be used for your next and subsequent subscription payments.",
              });
            }}
          >
            <SvgInformationCircle />
          </button>
        </div>
        {paymentMethods !== null ? (
          <>
            {paymentMethods?.length > 0 ? (
              <div className="mb-[24px] flex items-center">
                <CardSelector
                  paymentMethods={paymentMethods}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  callback={() => {
                    createSetupIntent().then(() => {
                      setPaymentMethodModal(true);
                    });
                  }}
                />
                <Button
                  disabled={
                    subscription?.stripe_payment_method_id ===
                    selectedPaymentMethod?.stripe_payment_method_id
                  }
                  className="text-md ml-[16px]"
                  onClick={updateSubscriptionPaymentMethod}
                >
                  Save
                </Button>
              </div>
            ) : (
              <button
                className="text-primaryBlue mb-[24px] flex w-full cursor-pointer
                                items-center justify-start gap-[8px]"
                onClick={createSetupIntent}
              >
                <SvgPlus fill="#4750F5" />
                Add New Payment Method
              </button>
            )}
          </>
        ) : (
          <div className="flex w-full items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentPaymentMethod;
