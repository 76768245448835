import React from "react";
import PaymentsDashboard from "./PaymentsDashboard";
import { ClientPaymentsItem } from "../../types/clientPaymentTypes";

type Props = {
    connectAccountData?: any;
    clientPaymentsData: Array<ClientPaymentsItem> | null;
    setActiveDates: React.Dispatch<React.SetStateAction<Date[] | null>>;
};

const Reports: React.FC<Props> = ({ connectAccountData, setActiveDates, clientPaymentsData }) => {
    return (
        <section>
            <PaymentsDashboard
                setActiveDates={setActiveDates}
                clientPaymentsData={clientPaymentsData}
                connectAccountData={connectAccountData}
            />
        </section>
    );
};

export default Reports;
