import React, { useContext } from "react";
import ContactAvatar from "../../cards/ContactAvatar";
import { Button } from "../../ui/button";
import { UserCtx } from "../../../context/userContext";

type Props = {
    contacts: any;
    setStep: any;
};

const SelectedContactsOverlay: React.FC<Props> = ({ contacts, setStep }) => {
    const {user} = useContext(UserCtx)
    
    return (
        <div className="fixed z-[700] bottom-[32px] left-1/2 ml-[-400px] w-[800px] h-[80px] p-[16px] flex items-center justify-between bg-white rounded-[16px] modal-shadow-lg">
            <div className="flex items-center gap-[16px]">
                <p>
                    {contacts.length}{" "}
                    {contacts.length === 1
                        ? user?.activeProfile === "member"
                            ? "coach"
                            : "client"
                        : user?.activeProfile === "member"
                        ? "coaches"
                        : "clients"}{" "}
                    selected
                </p>
                <div className="flex items-center gap-[8px]">
                    {contacts?.map((c: any, idx: number) => {
                        return (
                            <ContactAvatar
                                key={idx}
                                width={24}
                                height={24}
                                fontSize="text-base"
                                contact={c}
                                bold={false}
                            />
                        );
                    })}
                </div>
            </div>
            <Button
                onClick={() =>
                    setStep((previousStep: number) => previousStep + 1)
                }
                className="text-md"
                size={"lg"}
            >
                Confirm
            </Button>
        </div>
    );
};

export default SelectedContactsOverlay;
