import * as React from "react";
import { ContactAvatar } from "./ContactAvatar";
import { Button } from "../../ui/button";
import { ContactStatusTag } from "./ContactStatusTag";
import { Link } from "react-router-dom";
import { UserCtx } from "../../../context/userContext";

export const ContactCard: React.FC<{ contact: any }> = ({ contact }) => {
  const { user } = React.useContext(UserCtx);

  return (
    <div className="flex flex-col rounded-lg bg-white p-1 shadow">
      <div className="bg-grayFlash relative mb-12 h-20 rounded-lg p-2">
        <ContactStatusTag contact={contact} />
        <ContactAvatar
          contact={contact}
          className="absolute bottom-0 left-1/2 h-20 w-20 -translate-x-1/2 translate-y-2/3"
        />
      </div>

      <div className="flex flex-1 flex-col items-center px-3 py-8 text-center">
        <h2 className="max-w-full truncate text-lg font-bold">
          {contact.contact_name}
        </h2>
        <p className="text-muted-foreground max-w-full truncate text-sm">
          {contact.email}
        </p>
      </div>

      <pre className="overflow-x-auto">{JSON.stringify(contact, null, 2)}</pre>

      <Button variant="link" className="mx-auto flex" asChild>
        <Link
          to={`/v2/${user?.activeProfile}/contacts/${contact.contact_type}/${contact?.profile_id}`}
        >
          View Details
        </Link>
      </Button>
    </div>
  );
};
