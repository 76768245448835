import React from "react";
import CardListItem from "./CardListItem";
import { Badge } from "../../ui/badge";
import {
  SvgAmericanExpress,
  SvgAsterisk,
  SvgChevronDown,
  SvgDinersClub,
  SvgDiscover,
  SvgJcb,
  SvgMastercard,
  SvgPlus,
  SvgVisa,
} from "../../icons";

interface Props {
  paymentMethods: any;
  selectedPaymentMethod: any;
  setSelectedPaymentMethod: any;
  callback: any;
  setPaymentMethodModal?: any;
}

const CardSelector: React.FC<Props> = ({
  paymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  callback,
  setPaymentMethodModal,
}) => {
  const [listExpanded, setListExpanded] = React.useState<boolean>(false);

  const getCardLogo = () => {
    switch (selectedPaymentMethod?.card_brand) {
      case "visa":
        return <SvgVisa className="mr-[8px]" />;
      case "mastercard":
        return <SvgMastercard className="mr-[8px]" />;
      case "discover":
        return <SvgDiscover className="mr-[8px]" />;
      case "amex":
        return <SvgAmericanExpress className="mr-[8px]" />;
      case "jcb":
        return <SvgJcb className="mr-[8px]" />;
      case "diners_club":
        return <SvgDinersClub className="mr-[8px]" />;
      default:
        break;
    }
  };
  return (
    <>
      <div className="relative h-[56px] w-full">
        <div
          className="border-1 border-grayMist flex h-full w-full cursor-pointer flex-row items-center justify-between gap-[20px] rounded-xl border p-[24px] sm:px-[24px] sm:py-0"
          onClick={() => setListExpanded(!listExpanded)}
        >
          <div className="jusfify-between flex w-full items-center">
            <div className="flex items-center">
              {getCardLogo()}
              <div className="mr-[16px] flex items-center gap-[4px]">
                <SvgAsterisk className="h-3 w-3" />
                <SvgAsterisk className="h-3 w-3" />
                <SvgAsterisk className="h-3 w-3" />
                <SvgAsterisk className="h-3 w-3" />
              </div>
              <span className="text-base font-bold">
                {selectedPaymentMethod?.card_last4}
              </span>
            </div>
          </div>
          <div className={`flex items-center justify-between gap-[24px]`}>
            {Boolean(selectedPaymentMethod?.is_default) && (
              <Badge variant="success" className="hidden xl:flex">
                Default
              </Badge>
            )}
            <div className="flex h-[20px] w-[20px] cursor-pointer items-center justify-center">
              <SvgChevronDown
                className={`cursor-pointer text-black ${
                  listExpanded ? "rotate-[180deg]" : ""
                }`}
              />
            </div>
          </div>
          {listExpanded && (
            <div
              className="main-shadow absolute -bottom-[4px] left-0 z-[20] w-full translate-y-[100%]
                            rounded-[16px] bg-white px-[32px] py-[24px]"
            >
              <div className="flex max-h-[104px] w-full flex-col gap-[16px] overflow-y-auto">
                {paymentMethods?.map((card: any, index: number) => (
                  <CardListItem
                    key={index}
                    card={card}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setListExpanded={setListExpanded}
                  />
                ))}
                <button
                  className="text-blurple flex w-full cursor-pointer
                                    items-center justify-start gap-[8px]"
                  onClick={() => callback()}
                >
                  <SvgPlus />
                  Add New Payment Method
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardSelector;
