import { FC } from "react";
import { Post } from "../../../types/postTypes";
import { Link } from "react-router-dom";
import { Button } from "../../ui/button";

type Props = {
  posts: Array<Post>;
};

const PostsGrid: FC<Props> = ({ posts }) => {
  return (
    <section className="grid grid-cols-2 gap-[12px] lg:grid-cols-3 lg:gap-[36px] md:gap-[12px]">
      {posts.slice(1).map((post, index) => {
        return (
          <div className="flex flex-col gap-[12px] mb-[56px] items-start" key={index}>
            <div className="relative">
              <img
                src={post.metadata.previewImg}
                alt={post.metadata.title}
                className="object-cover sm:h-[244px] h-[144px] w-[166px] sm:w-auto max-w-[378px] bg-gray rounded-[24px]"
              >
              </img>
              {post.metadata.imgTag && (
                <div className="hidden sm:block absolute bottom-[16px] left-[16px] rounded-lg bg-white font-bold text-base px-[6px] py-[3px] bg-opacity-80">
                  {post.metadata.imgTag}
                </div>
              )}
            </div>
            <h4 className="mt-[12px] text-grayCharcoal font-semibold text-[18px] text-balance">
              {post.metadata.title}
            </h4>
            <p>{post.metadata.description}</p>
            <Button variant="link" asChild>
              <Link
                to={`/blog/${post.metadata.urlSlug}`}
              >
                Read more
              </Link>
            </Button>
          </div>
        );
      })}
    </section>
  );
};

export default PostsGrid;
