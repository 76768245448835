import React from "react";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { SvgPlus } from "../../icons/";
import { Button } from "../../ui/button";

interface Props {
    numOfVideos: number
}

const AddPortraitVideoButton: React.FC<Props> = ({ numOfVideos }) => {
    const { setEditPortraitVideo } = React.useContext(CoachPrivateProfileContext);

    return (
        <Button
            onClick={() => setEditPortraitVideo(true)}
            className="bg-white px-[20px] hover:bg-grayFlash gap-1"   
            variant={'utility'}
            size={"lg"}
        >     
        <SvgPlus />
        Add
        </Button>
    )
}

export default AddPortraitVideoButton;
