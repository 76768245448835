import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import Element from "./blog/Element";
import Leaf from "./blog/Leaf";
import useDate from "../../hooks/useDate";
import { ProfileRequests } from "../../api/app.service";
import { Link } from "react-router-dom";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { PublicProfileRequests } from "../../api/public.service";
import BackToButton from "../BackToButton";
import { Button } from "../ui/button";
import { parseInt } from "lodash";
import useLoading from "../../hooks/useLoading";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  DropdownMenuGroup,
  DropdownMenuLabel,
} from "@radix-ui/react-dropdown-menu";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { SvgShare } from "../icons/";
import useScrollToTop from "../../hooks/useScrollToTop";

const FullBlogPost: FC = () => {
  const { renderError } = useContext(CommonFunctionCtx);
  const { post_id, endpoint_slug } = useParams();
  const { startLoading, stopLoading } = useLoading();
  const [, copy] = useCopyToClipboard();
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const [editor] = useState(() => withReact(createEditor()));
  const { lgDate } = useDate();

  const [coachPublicProfileData, setCoachPublicProfileData] = useState<any>(
    null,
  );
  const [post, setPost] = useState<any>();

  const getBlogPost = async () => {
    startLoading();
    try {
      if (!endpoint_slug || !post_id) {
        throw new Error("Coach slug or post id not given");
      }

      const data = await PublicProfileRequests.getCoachPublicProfile({
        endpoint_slug: endpoint_slug,
      });

      if (data.coach_public_profile) {
        setCoachPublicProfileData(data.coach_public_profile);

        const coach_profile_id = data.coach_public_profile.coach_profile_id;
        const blogPost = await ProfileRequests.getCoachBlogPosts({
          coach_profile_id: parseInt(coach_profile_id),
        });

        setPost(blogPost?.blog_posts?.find((post: any) => post.id == post_id));
      } else {
        setCoachPublicProfileData(null);
      }
    } catch (err: any) {
      renderError(err.response.data.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    getBlogPost();
  }, []);

  useScrollToTop();

  const blogURL = document.URL;

  const handleCopyToClipboard = () => {
    copy(blogURL);
  };

  if (!post) {
    return (
      <div className="flex flex-col items-center gap-4 min-h-screen justify-center fixed inset-0">
        <h1 className="font-bold text-xl">Blog post not found</h1>
        <Button className="mt-8" size="lg" asChild>
          <Link to={`/find-a-coach/${endpoint_slug}`}>Back to coach</Link>
        </Button>
      </div>
    );
  }

  return (
    <div className="container max-w-prose pb-24">
      <div className="flex mt-14">
        <div className="w-full flex items-center justify-between pb-[15px] md:pb-0">
          <BackToButton
            to={`/find-a-coach/${endpoint_slug}`}
            prev="Coach Profile"
            current="Coach Profile"
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="utility">
                <SvgShare />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel className="text-graySlate text-xs p-2">
                Share via
              </DropdownMenuLabel>
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={handleCopyToClipboard}>
                  Copy Link
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className="p-8 rounded-xl shadow-[0_0_20px_5px_rgba(150,_150,_150,_0.1)]">
        <div className="flex flex-col">
          <div className="lg:flex lg:justify-between pb-2">
            <div className="w-full lg:w-1/2 flex items-center justify-center lg:items-end justify-end lg:order-2">
              {Boolean(post?.has_image) &&
                (
                  <img
                    src={`https://blog-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${post?.id}?`}
                    alt="post"
                    className="aspect-square max-w-xs w-full rounded-xl main-shadow object-cover mb-[20px]"
                  />
                )}
            </div>
            <div className="w-full lg:w-1/2 lg:order-1">
              <h1 className="font-bold text-[20px] md:text-[32px] mb-[4px]">
                {post?.title}
              </h1>
              <h5 className="text-grayCharcoal text-[14px] md:text-[16px] mb-[20px]">
                {`Posted by ${coachPublicProfileData?.first_name} ${coachPublicProfileData?.last_name} on ${
                  lgDate(post?.created_at)
                }`}
              </h5>
            </div>
          </div>
          <Slate editor={editor} value={post?.content}>
            <Editable
              readOnly={true}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
            />
          </Slate>
        </div>
      </div>
    </div>
  );
};

export default FullBlogPost;
