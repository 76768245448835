import React from "react";
import AvatarImage from "./AvatarImage";
import AvatarVideo from "./AvatarVideo";
import { Button } from "../../ui/button";
import { UserCtx } from "../../../context/userContext";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import ResponseTimeModal from "./ResponseTimeModal";
import { SvgEdit, SvgSettings } from "../../icons/";

interface Props {}

const ProfileInformation: React.FC<Props> = (props: any) => {
  const { user } = React.useContext(UserCtx);
  const { coachPublicProfileData, setEditProfileInformation } =
    React.useContext(CoachPrivateProfileContext);
  const [openMessagingModal, setOpenMessagingModal] =
    React.useState<boolean>(false);

  return (
    <>
      {/* DESKTOP */}
      <div className="hidden md:flex items-center w-full gap-[20px] relative px-12">
        {/* PROFILE IMAGE OR VIDEO */}
        <div className="min-w-[185px] max-w-[185px]">
          {coachPublicProfileData?.has_profile_video ? (
            <div className="w-full h-[258px]">
              <AvatarVideo showHoverEffect={true} />
            </div>
          ) : (
            <div className="w-full h-[185px] md:-mt-44">
              <AvatarImage />
            </div>
          )}
        </div>

        {/* INFORMATION */}
        <div className="flex flex-col items-start ml-8 -mt-4">
          <div className="flex items-center gap-[15px]">
            <h3 className="font-bold text-[28px]">{`${user.firstName} ${user.lastName}`}</h3>
          </div>
          <p className="text-[16px] mb-[17px] md:max-w-[80%]">
            {coachPublicProfileData?.profile_information?.title || "Coach"}
          </p>
          <div className="md:max-w-[80%]">
            <p className="text-[16px] mb-[17px]">
              {coachPublicProfileData?.profile_information?.description}
            </p>
          </div>
          <div className="flex gap-[48px]">
            {coachPublicProfileData?.profile_information?.yearsExperience >
              0 && (
              <div className="flex flex-col items-start gap-[8px]">
                <p className="text-base text-graySlate">Experience</p>
                <h5 className="font-bold text-[16px]">
                  {coachPublicProfileData?.profile_information?.yearsExperience}{" "}
                  Years
                </h5>
              </div>
            )}
            {coachPublicProfileData?.profile_information?.region?.label && (
              <div className="flex flex-col items-start gap-[8px]">
                <p className="text-base text-graySlate">Location</p>
                <h5 className="font-bold text-[16px]">
                  {coachPublicProfileData?.profile_information?.region?.label}
                </h5>
              </div>
            )}
            {coachPublicProfileData?.credentials?.credentials.length > 0 ? (
              <div className="flex flex-col items-start gap-[8px]">
                <p className="text-base text-graySlate">Credentials</p>
                <h5 className="font-bold text-[16px]">
                  <h5 className="font-bold text-[16px]">
                    {coachPublicProfileData.credentials.credentials[0].name}
                  </h5>
                </h5>
              </div>
            ) : null}
          </div>
        </div>

        {/* EDIT AND MESSAGE SETTINGS BUTTONS */}
        <Button
          onClick={() => setEditProfileInformation(true)}
          className="absolute top-0 right-0 ml-auto w-[120px] h-[50px] gap-[8px] font-bold"
          variant={"utility"}
        >
          <SvgEdit />
          Edit Info
        </Button>

        <Button
          onClick={() => setOpenMessagingModal(true)}
          className="absolute top-16 right-0 ml-auto w-[185px] h-[50px] gap-[8px] font-bold"
          variant={"utility"}
        >
          <SvgSettings
            fill="black"
            className="text-2xl"
          />
          Message Settings
        </Button>
      </div>

      {/* MESSAGING SETTINGS MODAL */}
      {openMessagingModal && (
        <ResponseTimeModal
          setOpen={setOpenMessagingModal}
          open={openMessagingModal}
        />
      )}

      {/* MOBILE */}
      <div className="flex md:hidden flex-col items-start w-full gap-[20px] relative -mt-12 ">
        {/* PROFILE IMAGE OR VIDEO */}
        <div className="flex items-center w-full mt-4 gap-2">
          <div className="">
            {coachPublicProfileData?.has_profile_video ? (
              <div className="min-w-full max-w-full h-[258px]">
                <AvatarVideo showHoverEffect={true} />
              </div>
            ) : (
              <div className="flex flex-row items-start min-w-full max-w-full mt-4 z-10">
                <div className="h-[130px] w-[130px]">
                  <AvatarImage />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-end gap-2 ml-auto">
            <Button
              onClick={() => setEditProfileInformation(true)}
              variant={"utility"}
              className="flex justify-center items-center h-[50px] bg-grayFlash w-[120px] rounded-md shadow-md text-sm font-bold mt-8 ml-auto"
            >
              Edit Info
              <SvgEdit />
            </Button>

            <Button
              onClick={() => setOpenMessagingModal(true)}
              variant={"utility"}
              className="h-[50px]"
            >
              Message Settings
              <SvgSettings fill="black" />
            </Button>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="flex items-center gap-[8px]">
            <h3 className="font-bold text-[22px]">{`${coachPublicProfileData?.first_name} ${coachPublicProfileData?.last_name}`}</h3>
          </div>
          <p className="text-[16px]">
            {coachPublicProfileData?.profile_information?.title || "Coach"}
          </p>
        </div>
        <div className="flex flex-col items-start">
          <p className="text-[16px] mb-[17px]">
            {coachPublicProfileData?.profile_information?.description}
          </p>
        </div>
      </div>
    </>
  );
};

export default ProfileInformation;
