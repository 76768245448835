export type ServiceType =
  | "consultation"
  | "individual"
  | "bundle"
  | "recurring";

export default function useManageServices() {
  const getRecurringFrequency = (serviceValues: any) => {
    let unit = "";
    if (serviceValues?.recurring.recurringFrequency === "monthly") {
      unit = "month";
    } else if (serviceValues?.recurring.recurringFrequency === "weekly") {
      unit = "week";
    }
    return unit;
  };

  const formatBundleBulkObject = (
    serviceValues: any,
    serviceType: ServiceType,
  ) => {
    return {
      "availability_rule_id": serviceValues?.availability_rule_id,
      "allow_promo": serviceValues.allowPromo,
      "type": serviceType,
      "isGroupService": serviceValues?.isGroupService,
      "status": serviceValues.enabled ? "active" : "inactive",
      "title": serviceValues.title,
      "details": {
        "description1": serviceValues.description,
        "description2": serviceValues.description,
        "features": {
          "feature1": serviceValues.feature1,
          "feature2": serviceValues.feature2,
          "feature3": serviceValues.feature3,
        },
      },
      "session": {
        "duration": (serviceValues.sessionLength.minutes * 60).toString(),
        "count": serviceValues.package.packageSessions,
      },
      "billing": {
        "amount": serviceValues?.package?.packageCost,
        "unit": "bulk",
      },
      "expiration": {
        "expires": serviceValues?.expiration?.expires,
        "type": serviceValues?.expiration?.expireType,
        "time": serviceValues?.expiration?.expireTime,
      },
      "image": {
        "exists": serviceValues.image.exists,
        "base64": serviceValues.image.base64,
        "base64extension": serviceValues.image.base64extension,
        "color": serviceValues.color,
      },
      "visibility": {
        "profile": {
          "service": serviceValues.profileServiceVisible,
          "cost": serviceValues.profileCostVisible,
        },
        "marketplace": {
          "service": serviceValues.marketplaceServiceVisible,
          "cost": serviceValues.marketplaceCostVisible,
        },
      },
      "group": {
        "isFree": serviceValues?.group?.isFree,
        "meetingDates": serviceValues?.group?.meetingDates,
        "weeklyDayTimes": serviceValues?.group?.weeklyDayTimes,
        "enrollByDate": new Date(" "),
        "maximumClients": serviceValues?.group?.maximumClients,
        "groupChat": serviceValues?.group?.groupChat,
      },
      "serviceLinkedForms": serviceValues?.serviceLinkedForms,
    };
  };

  const formatbundleInstallmentObject = (
    serviceValues: any,
    serviceType: ServiceType,
  ) => {
    return {
      "availability_rule_id": serviceValues?.availability_rule_id,
      "allow_promo": serviceValues.allowPromo,
      "type": serviceType,
      "isGroupService": serviceValues?.isGroupService,
      "status": serviceValues?.enabled ? "active" : "inactive",
      "title": serviceValues?.title,
      "details": {
        "description1": serviceValues?.description,
        "description2": serviceValues?.description,
        "features": {
          "feature1": serviceValues?.feature1,
          "feature2": serviceValues?.feature2,
          "feature3": serviceValues?.feature3,
        },
      },
      "session": {
        "duration": (serviceValues?.sessionLength?.minutes * 60).toString(),
        "count": serviceValues?.package?.packageSessions,
      },
      "billing": {
        "amount": serviceValues?.package?.packageCost,
        "unit": serviceValues?.package?.packageBillingType,
        "installmentIncrements": serviceValues?.package?.installmentIncrements,
        "upFrontPercentage": serviceValues?.package?.upFrontPercentage,
        "packageBillingType": serviceValues?.package?.packageBillingType,
      },
      "expiration": {
        "expires": serviceValues?.expiration?.expires,
        "type": serviceValues?.expiration?.expireType,
        "time": serviceValues?.expiration?.expireTime,
      },
      "image": {
        "exists": serviceValues?.image?.exists,
        "base64": serviceValues?.image?.base64,
        "base64extension": serviceValues?.image?.base64extension,
        "color": serviceValues?.color,
      },
      "visibility": {
        "profile": {
          "service": serviceValues?.profileServiceVisible,
          "cost": serviceValues?.profileCostVisible,
        },
        "marketplace": {
          "service": serviceValues?.marketplaceServiceVisible,
          "cost": serviceValues?.marketplaceCostVisible,
        },
      },
      "group": {
        "isFree": serviceValues?.group?.isFree,
        "meetingDates": serviceValues?.group?.meetingDates,
        "weeklyDayTimes": serviceValues?.group?.weeklyDayTimes,
        "enrollByDate": new Date(" "),
        "maximumClients": serviceValues?.group?.maximumClients,
        "groupChat": serviceValues?.group?.groupChat,
      },
      "serviceLinkedForms": serviceValues?.serviceLinkedForms,
    };
  };

  const formatRecurringObject = (
    serviceValues: any,
    serviceType: ServiceType,
  ) => {
    return {
      "availability_rule_id": serviceValues?.availability_rule_id,
      "allow_promo": serviceValues.allowPromo,
      "type": serviceType,
      "isGroupService": serviceValues?.isGroupService,
      "status": serviceValues.enabled ? "active" : "inactive",
      "title": serviceValues.title,
      "details": {
        "description1": serviceValues.description,
        "description2": serviceValues.description,
        "features": {
          "feature1": serviceValues.feature1,
          "feature2": serviceValues.feature2,
          "feature3": serviceValues.feature3,
        },
      },
      "session": {
        "duration": (serviceValues.sessionLength.minutes * 60).toString(),
        "count": serviceValues?.recurring?.sessionsPerPeriod,
      },
      "billing": {
        "amount": serviceValues?.recurring?.periodCost.toString(),
        "unit": getRecurringFrequency(serviceValues),
      },
      "expiration": {
        "expires": serviceValues?.expiration?.expires,
        "type": serviceValues?.expiration?.expireType,
        "time": serviceValues?.expiration?.expireTime,
      },
      "image": {
        "exists": serviceValues.image.exists,
        "base64": serviceValues.image.base64,
        "base64extension": serviceValues.image.base64extension,
        "color": serviceValues.color,
      },
      "visibility": {
        "profile": {
          "service": serviceValues.profileServiceVisible,
          "cost": serviceValues.profileCostVisible,
        },
        "marketplace": {
          "service": serviceValues.marketplaceServiceVisible,
          "cost": serviceValues.marketplaceCostVisible,
        },
      },
      "group": {
        "isFree": serviceValues?.group?.isFree,
        "meetingDates": serviceValues?.group?.meetingDates,
        "weeklyDayTimes": serviceValues?.group?.weeklyDayTimes,
        "enrollByDate": new Date(" "),
        "maximumClients": serviceValues?.group?.maximumClients,
        "groupChat": serviceValues?.group?.groupChat,
      },
      "serviceLinkedForms": serviceValues?.serviceLinkedForms,
    };
  };

  const formatConsultationOrIndividualObject = (
    serviceValues: any,
    serviceType: ServiceType,
  ) => {
    return {
      "availability_rule_id": serviceValues?.availability_rule_id,
      "type": serviceType,
      "isGroupService": serviceValues?.isGroupService,
      "status": serviceValues.enabled ? "active" : "inactive",
      "title": serviceValues.title,
      "allow_promo": serviceValues.allowPromo,
      "details": {
        "description1": serviceValues.description,
        "description2": serviceValues.description,
        "features": {
          "feature1": serviceValues.feature1,
          "feature2": serviceValues.feature2,
          "feature3": serviceValues.feature3,
        },
      },
      "session": {
        "duration": (serviceValues.sessionLength.minutes * 60).toString(),
        "count": "1",
      },
      "billing": {
        "amount": serviceValues.isFree
          ? "0"
          : serviceValues.sessionCost.toString(),
        "unit": "session",
      },
      "expiration": {
        "expires": serviceValues?.expiration?.expires,
        "type": serviceValues?.expiration?.expireType,
        "time": serviceValues?.expiration?.expireTime,
      },
      "image": {
        "exists": serviceValues.image.exists,
        "base64": serviceValues.image.base64,
        "base64extension": serviceValues.image.base64extension,
        "color": serviceValues.color,
      },
      "visibility": {
        "profile": {
          "service": serviceValues.profileServiceVisible,
          "cost": serviceValues.profileCostVisible,
        },
        "marketplace": {
          "service": serviceValues.marketplaceServiceVisible,
          "cost": serviceValues.marketplaceCostVisible,
        },
      },
      "group": {
        "isFree": serviceValues?.group?.isFree,
        "meetingDates": serviceValues?.group?.meetingDates,
        "weeklyDayTimes": serviceValues?.group?.weeklyDayTimes,
        "enrollByDate": new Date(" "),
        "maximumClients": serviceValues?.group?.maximumClients,
        "groupChat": serviceValues?.group?.groupChat,
      },
      "serviceLinkedForms": serviceValues?.serviceLinkedForms,
    };
  };

  const validateConsultationOrIndividual = (serviceValues: any) => {
    const errors: any = {};
    !serviceValues.title && (errors.title = "Title is required");
    !serviceValues.description &&
      (errors.description = "Description is required");
    serviceValues.sessionLength.minutes < 1 &&
      (errors.sessionLength =
        "Session must have a length greater than 0 minutes");
    !serviceValues.sessionCost &&
      (serviceValues?.isGroupService
        ? !serviceValues?.group?.isFree
        : !serviceValues?.isFree) &&
      (errors.sessionCost = "Session must have a cost greater than $0");
    (serviceValues?.expiration?.expireTime === 0 &&
      serviceValues?.expiration?.expires) &&
      (errors.expires = "Session expiration units can't be 0");
    return errors;
  };

  const validatePackage = (serviceValues: any) => {
    const errors: any = {};
    !serviceValues.title && (errors.title = "Title is required");
    !serviceValues.description &&
      (errors.description = "Description is required");
    !serviceValues.package.packageSessions &&
      (errors.packageSessions = "At least one session is required");
    (!serviceValues.group.isFree && !serviceValues.package.upFrontPercentage) &&
      (errors.upFrontPercentage = "Up front percentage is required");
    serviceValues.sessionLength.minutes < 1 &&
      (errors.sessionLength =
        "Session must have a length greater than 0 minutes");
    !serviceValues.package.packageCost &&
      (serviceValues?.isGroupService
        ? !serviceValues?.group?.isFree
        : !serviceValues?.isFree) &&
      (errors.sessionCost = "Session must have a cost greater than $0");
    (serviceValues?.expiration?.expireTime === 0 &&
      serviceValues?.expiration?.expires) &&
      (errors.expires = "Session expiration units can't be 0");
    return errors;
  };

  const validateRecurring = (serviceValues: any) => {
    const errors: any = {};
    !serviceValues.title && (errors.title = "Title is required");
    !serviceValues.description &&
      (errors.description = "Description is required");
    // !serviceValues.feature1 && (errors.feature1 = "At least one feature is required");
    serviceValues.sessionLength.minutes < 1 &&
      (errors.sessionLength =
        "Session must have a length greater than 0 minutes");
    (!serviceValues.sessionCost && !serviceValues.isFree) &&
      (errors.sessionCost = "Session must have a cost greater than $0");
    (!serviceValues?.recurring.periodCost && !serviceValues.isFree) &&
      (errors.periodCost = `${
        serviceValues?.recurring.recurringFrequency === "monthly"
          ? "Monthly"
          : "Weekly"
      } price must be greater than $0`);
    (serviceValues?.expiration?.expireTime === 0 &&
      serviceValues?.expiration?.expires) &&
      (errors.expires = "Session expiration units can't be 0");
    !serviceValues?.recurring?.sessionsPerPeriod &&
      (errors.sessionsPerPeriod = "Sessions per period must be at least one");
    return errors;
  };

  const validateFile = (imageFile: File) => {
    if (
      (imageFile.type === "image/png" || imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg") &&
      imageFile.size <= 5000000 && imageFile.size > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getAvailableTimeTypes = (
    serviceType: ServiceType,
    timeTypes: any,
    serviceValues: any,
  ) => {
    let availableTimeTypes = timeTypes;
    if (serviceType === "recurring") {
      if (serviceValues?.recurring.recurringFrequency === "weekly") {
        availableTimeTypes = [{ value: "weeks", label: "Weeks" }];
      } else if (serviceValues?.recurring.recurringFrequency === "monthly") {
        availableTimeTypes = [{ value: "months", label: "Months" }];
      }
    }
    return availableTimeTypes;
  };

  const setServiceImageDetails = (
    serviceImgFile: any,
    setServiceValues: any,
    serviceValues: any,
    setPopupNotification: any,
    setServiceImgFile: any,
    setPreviewImgUrl: any,
  ) => {
    if (serviceImgFile) {
      if (validateFile(serviceImgFile)) {
        setPreviewImgUrl(URL.createObjectURL(serviceImgFile));
        let base64 = "";
        const type = serviceImgFile.type.split("/")[1];
        const reader = new FileReader();
        reader.readAsDataURL(serviceImgFile);
        reader.onload = function () {
          const string = (reader.result as string).split("\n").map((data) => {
            return data.split(",");
          });
          base64 = string[0][1];
          setServiceValues({
            ...serviceValues,
            image: {
              "exists": true,
              "base64": base64,
              "base64extension": type,
            },
          });
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        setPopupNotification({
          show: true,
          title: "Bummer! Your file is too big.",
          message:
            "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
          callback: null,
        });
        setServiceImgFile(undefined);
      }
    }
  };

  const getServiceObject = (serviceType: ServiceType, serviceValues: any) => {
    let serviceObject = {};
    console.log(serviceValues)
    if (["consultation", "individual"].includes(serviceType)) {
      serviceObject = formatConsultationOrIndividualObject(
        serviceValues,
        serviceType,
      );
    } else if (["recurring"].includes(serviceType)) {
      serviceObject = formatRecurringObject(serviceValues, serviceType);
    } else if (["bundle"].includes(serviceType)) {
      if (serviceValues.package.packageBillingType === "bulk") {
        serviceObject = formatBundleBulkObject(serviceValues, serviceType);
      } else if (serviceValues.package.packageBillingType === "installment") {
        serviceObject = formatbundleInstallmentObject(
          serviceValues,
          serviceType,
        );
      }
    }
    return serviceObject;
  };

  return {
    formatConsultationOrIndividualObject,
    formatRecurringObject,
    formatBundleBulkObject,
    validateConsultationOrIndividual,
    validatePackage,
    validateRecurring,
    setServiceImageDetails,
    getAvailableTimeTypes,
    formatbundleInstallmentObject,
    getServiceObject,
  };
}
