import * as React from "react";
import { useContacts } from "./ContactsLayout";
import { ListContacts } from "../../components/contacts/v2/ListContacts";
import { UserCtx } from "../../context/userContext";

export const SentInvites: React.FC = () => {
  const { contacts, view } = useContacts();
  const sentInvites = contacts.filter(
    (contact) => contact.contact_type === "invited",
  );
  const { user } = React.useContext(UserCtx);

  return (
    <div>
      <ListContacts
        contacts={sentInvites}
        view={view}
        emptyState={`Looks like you don't have any sent invitations at the moment. Let's get started by inviting your ${user.activeProfile === "coach" ? "clients" : "coaches"} to Zoee.`}
      />
    </div>
  );
};
