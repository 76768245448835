import React, { useState } from "react";
import ContactAvatar from "./ContactAvatar";
import ContactStatusTag from "./ContactStatusTag";
import { UserCtx } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import useDate from "../../hooks/useDate";
import { Button } from "../ui/button";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ContactRequests } from "../../api/app.service";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { DropdownMenuGroup } from "@radix-ui/react-dropdown-menu";
import useLoading from "../../hooks/useLoading";
import { SvgClose, SvgEllipses, SvgInvite, SvgTrash } from "../icons/";

interface Props {
  setInvites: any;
  setShowConfirmInviteModal: any;
  contact: any;
  getContacts: any;
  setSelectedContact: any;
  setShowContactProfile: any;
  setContactRequestFull: any;
  acceptContactRequest: any;
  denyContactRequest: any;
  revokeContactRequest: any;
  resendContactRequest: any;
  shouldApplyMargin: any;
}

const ContactCard: React.FC<Props> = ({
  setInvites,
  setShowConfirmInviteModal,
  getContacts,
  contact,
  setSelectedContact,
  setShowContactProfile,
  resendContactRequest,
  setContactRequestFull,
  acceptContactRequest,
  denyContactRequest,
  revokeContactRequest,
  shouldApplyMargin,
}) => {
  const { hideAlert, renderError, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const {
    contact_type,
    contact_name,
    tagline,
    contact_date,
    status,
    profile_connection_id,
    email,
  } = contact || {};
  const { user } = React.useContext(UserCtx);
  const navigate = useNavigate();
  const { smDate } = useDate();

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const loadContactProfile = () => {
    navigate(`/${user.activeProfile}/contacts/${contact.profile_id}`, {
      state: {
        contact: contact,
      },
    });
  };

  const showFullRequest = () => {
    setContactRequestFull({ show: true, contact: contact });
  };

  const handleDeleteContact = () => {
    startLoading();
    if (status === "prospect") {
      ContactRequests.deleteContactConnection({ profile_connection_id })
        .then(() => {
          getContacts();
          renderSuccess("Contact deleted!");
          setTimeout(() => hideAlert(), 5000);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        })
        .finally(() => {
          stopLoading();
        });
    } else if (contact_type === "prospect") {
      ContactRequests.deleteProspect({ prospect_id: contact.prospect_id })
        .then(() => {
          getContacts();
          renderSuccess("Contact deleted!");
          setTimeout(() => hideAlert(), 5000);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const getTagline = () => {
    let value = "";
    if (tagline) {
      value = tagline;
    } else if (contact_type === "invited") {
      value = `Invited ${smDate(contact_date)}`;
    } else if (contact_type === "prospect") {
      value = email;
    } else {
      if (user.activeProfile === "coach") {
        value = "Zoee Member";
      } else {
        value = "Professional Coach";
      }
    }
    return value;
  };

  const getStatus = () => {
    let profileStatus = "Unknown";
    if (contact_type === "prospect") {
      return "prospect";
    }
    if (status === "connected") {
      profileStatus = "active";
    } else if (status === "lead") {
      profileStatus = "lead";
    } else if (status === "zoee lead") {
      profileStatus = "zoee lead";
    } else if (status === "prospect") {
      profileStatus = "prospect";
    } else if (contact_type === "invited") {
      profileStatus = "invited";
    } else if (contact_type === "requested") {
      profileStatus = "requested";
    }
    return profileStatus;
  };

  return (
    <div
      className={`md:w-[258px] w-[184px] md:h-[302px] h-[213px] promotion-shadow flex flex-col 
        items-center rounded-[16px] relative cursor-pointer hover:transform hover:scale-105 transition-transform duration-300 shadow-custom ${
          shouldApplyMargin ? "mb-[80px]" : ""
        } ${isHovered ? "bg-royalBlue" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={contact.profile_id ? loadContactProfile : () => null}
    >
      <div
        className="absolute top-[4px] left-[50%] -translate-x-[50%]
                md:w-[250px] w-[178px] md:h-[78px] h-[69px] bg-grayFlash rounded-[12px]"
      >
        {contact_type === "requested" && (
          <button
            className="absolute w-[32px] h-[32px] bg-white rounded-full
                        top-[8px] right-[8px] flex items-center justify-center"
            onClick={(e) => {
              e.stopPropagation();
              denyContactRequest(contact);
            }}
          >
            <SvgClose className="w-[20px] h-[20px]" />
          </button>
        )}
        <ContactStatusTag
          contactStatus={getStatus()}
          origin=""
          contactDate={contact_date}
        />
        <div className="absolute top-[52px] left-[50%] -translate-x-[50%]">
          <div className="hidden md:block">
            <ContactAvatar
              contact={contact}
              width={80}
              height={80}
              border="border border-[4px] border-white"
              // isNotBold={true}
            />
          </div>
          <div className="md:hidden">
            <ContactAvatar
              contact={contact}
              width={48}
              height={48}
              border="border border-[4px] border-white"
              isNotBold={true}
            />
          </div>
        </div>
      </div>
      <h3
        className={`font-bold md:mt-[160px] mt-[112px] w-full md:w-auto text-center px-[2px] truncate ${
          contact_type === "invited" ? "text-base" : "text-[18px]"
        } ${isHovered ? "text-white" : "text-graySlate"}`}
      >
        {`${contact_name?.substring(0, 18)}${
          contact_name?.length > 18 ? "..." : ""
        }`}
      </h3>
      <p
        className={`${
          isHovered ? "text-white" : "text-graySlate"
        } text-base md:mt-[2px]`}
      >
        {getTagline()}
      </p>{" "}
      {contact_type === "invited" && (
        <div className="flex items-center gap-[8px] mt-[12px] md:mt-[48px]">
          <Button
            className={`h-[32px] ${isHovered ? "border border-white" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              resendContactRequest(contact);
            }}
          >
            Resend
          </Button>
          <button
            className="btn-primary btn-secondary h-[32px]"
            onClick={(e) => {
              e.stopPropagation();
              revokeContactRequest(contact);
            }}
          >
            Revoke
          </button>
        </div>
      )}
      {contact_type === "requested" && (
        <div className="flex items-center gap-[8px] mt-[12px] md:mt-[48px]">
          <Button
            className="h-[32px] text-md"
            onClick={(e) => {
              e.stopPropagation();
              acceptContactRequest(contact);
            }}
          >
            Accept
          </Button>
          <button
            className="btn-primary btn-secondary h-[32px]"
            onClick={showFullRequest}
          >
            View
          </button>
        </div>
      )}
      {Boolean(status === "prospect" || contact_type === "prospect") && (
        <>
          <div className="ml-auto mr-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant={"ghost"}
                  size={"icon"}
                  className="absolute top-2 right-2"
                >
                  <SvgEllipses />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className="text-vividRed"
                    onClick={() => {
                      handleDeleteContact();
                    }}
                  >
                    <SvgTrash className="w-4 h-4 mr-2" />
                    <span>delete</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="grayFlash"
                    onClick={() => {
                      setInvites([{ email: contact.email }]);
                      setShowConfirmInviteModal(true);
                    }}
                  >
                    <SvgInvite className="w-4 h-4 mr-2" />
                    <span>Send email Invite</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactCard;
