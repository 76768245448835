import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Button } from "../../ui/button"
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext"
import Loader from "../../ui/loader"
import { getLightTextColor } from "../../../utils/getLightTextColor"
import { SvgBundle, SvgChevronRight, SvgConsultation, SvgPlus, SvgRecurring, SvgUserCheck } from "../../icons/"

interface Props {
  renderServices: () => void
  isEditable?: boolean
  services: any
  coachColor?:any
}

const OverviewServices: React.FC<Props> = ({
  renderServices,
  isEditable = true,
  services,
  coachColor
}) => {
  const { loadingServices, coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )
  const [showEditButtonHover, setShowEditButtonHover] = useState(false)
  const { endpoint_slug } = useParams()

  const serviceIcons: { [key: string]: React.ReactNode } = {
    individual: <SvgUserCheck fill={`${coachColor}`} />,
    consultation: <SvgConsultation fill={`${coachColor}`} />,
    bundle: <SvgBundle fill={`${coachColor}`} />,
    recurring: <SvgRecurring fill={`${coachColor}`} />,
  }

  const [hoveredCard, setHoveredCard] = React.useState(null);

  const handleMouseEnter = (serviceId: any) => {
    setHoveredCard(serviceId);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  const renderEditButton = () => {
    if (isEditable) {
      return (
        <div className="group relative">
          {showEditButtonHover && (
            <Link to="/coach/services">
              <Button
                variant="utility"
                size="lg"
                className="py-[4px] px-[20px] bg-white border z-10 border-[1px] shadow-md opacity-100 absolute top-[-50%] left-1/2 transform -translate-x-1/2"
              >
                <SvgPlus />
                Add
              </Button>
            </Link>
          )}
        </div>
      )
    }
    return null
  }

  return (
    <>
      <div className="items-center justify-center">
        {loadingServices ? (
          <Loader />
        ) : (
          <>
            {services.length > 0 && (
              <div className="h-16 w-full text-center p-4 -mt-2 flex justify-between items-center">
                <span className="font-bold mt-5">Services</span>
                <Button onClick={renderServices}
                  variant={"ghost"}
                  size={"sm"}
                  className="mt-5"
                >
                  See All
                </Button>
              </div>
            )}
            {services.length > 0 ? (
              services.slice(0, 2).map((service: any) => (
                <Link to={Object.keys(coachPublicProfileData).length !== 0 ? '' : `/find-a-coach/${endpoint_slug}/services/${service.service_id}`} onClick={renderServices} key={service.service_id}>
                  <div
                    className="flex border hover:bg-grayMist rounded-[10px] mx-4 mb-3 p-2 flex-col h-[90px] transform transition-transform hover:scale-102 group"
                    key={service.service_id}
                    style={{ borderColor: hoveredCard === service.service_id ? `${coachColor}` : '' }}
                    onMouseEnter={() => handleMouseEnter(service.service_id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="flex justify-between -mt-[10px]">
                      <div className="flex items-center">
                        <span
                          className="mr-2"
                          style={{
                            color: `${coachColor}`,
                          }}
                        >
                          {serviceIcons[service?.service_details?.type]}
                        </span>
                        <h2 className="hidden md:flex font-bold"
                            style={{ color: hoveredCard === service.service_id ? `${coachColor}` : '' }}
                        >
                          {service?.service_details?.title}
                        </h2>
                        <h2 className="md:hidden font-bold"
                            style={{ color: hoveredCard === service.service_id ? `${coachColor}` : '' }}
                        >
                          {service?.service_details?.title.slice(0,11)}
                        </h2>
                      </div>
                      <div className="flex items-center ml-auto">
                      <Button
                        variant={"link"}
                        className="text-sm text-black hover:no-underline"
                      >
                        <span style={{ color: hoveredCard === service.service_id ? `${coachColor}` : '' }}>
                            See Details
                        </span>
                      </Button>
                        <SvgChevronRight className='w-5 h-5'
                            style={{ color: hoveredCard === service.service_id ? `${coachColor}` : '' }}
                        />
                      </div>
                      <div></div>
                    </div>
                    <div>
                      <p className="hidden md:text-sm md:line-clamp-2">
                        {service?.service_details?.details?.description1}
                      </p>
                    </div>
                  </div>
                </Link>
              ))
            ) : isEditable ? (
              <div
                className="flex flex-col items-center justify-center gap-[12px] h-[300px] w-[300px] md:h-[280px] md:w-[624px]"
                onMouseEnter={() => setShowEditButtonHover(true)}
                onMouseLeave={() => setShowEditButtonHover(false)}
              >
                {renderEditButton()}
                <div className="text-graySlate font-bold text-center z-0">
                  <p>Add a service to get started</p>
                </div>
              </div>
            ) : (
              <div
                className="h-[280px] rounded-[20px]"
                style={{
                  backgroundColor: getLightTextColor(coachColor),
                }}
              ></div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default OverviewServices
