import React from "react";
import { CalendarRequests } from "../../../api/app.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { UserCtx } from "../../../context/userContext";
import Loader from "../../ui/loader";
import { Switch } from "../../ui/switch";
import { Button } from "../../ui/button";
import {
  SvgApple,
  SvgClose,
  SvgEdit,
  SvgGoogleCalendar,
  SvgOffice365Calendar,
  SvgOutlookCalendar,
  SvgQuestionCircle,
} from "../../icons";
import { Badge } from "../../ui/badge";

interface Props {
  setEditCalendarSettings: any;
  calendar: any;
  profile: any;
  fetchUserCalendars: any;
}

const EditCalendarSettings: React.FC<Props> = ({
  setEditCalendarSettings,
  calendar,
  profile,
  fetchUserCalendars,
}) => {
  const {
    renderError,
    renderSuccess,
    hideAlert,
    updateNextStepsData,
    setPopupNotification,
  } = React.useContext(CommonFunctionCtx);
  const { user } = React.useContext(UserCtx);
  const [isPrimary, setIsPrimary] = React.useState<boolean>(
    calendar?.is_primary
  );
  const [loadingSettings, setLoadingSettings] = React.useState<boolean>(false);
  const [loadingPrimary, setLoadingPrimary] = React.useState<boolean>(false);
  const [loadingCalendarName, setLoadingCalendarName] =
    React.useState<boolean>(false);
  const [calendarName, setCalendarName] = React.useState<string>(
    calendar?.calendar_name
  );
  const [changesMade, setChangesMade] = React.useState<boolean>(false);
  const [submitChange, setSubmitChange] = React.useState<boolean>(false);
  const [calendarSettings, setCalendarSettings] = React.useState<any>(null);
  const [editCalendarName, setEditCalendarName] =
    React.useState<boolean>(false);
  const typeCapitalized = () => {
    let service = profile.provider_service;
    if (service === "outlook_com") {
      service = "outlook";
    }
    if (service === "gsuite") {
      service = "google";
    }
    const first = service.slice(0, 1).toUpperCase();
    const rest = service.slice(1);
    return `${first}${rest}`;
  };

  const getCalendarLogo = () => {
    let service = profile.provider_service;
    if (service === "gsuite") {
      return <SvgGoogleCalendar />;
    } else if (service === "office365") {
      return <SvgOffice365Calendar />;
    } else if (service === "outlook_com") {
      return <SvgOutlookCalendar />;
    } else if (service === "apple") {
      return <SvgApple />;
    }
  };

  const saveCalendarName = () => {
    setLoadingCalendarName(true);
    CalendarRequests.setCalendarName({
      calendar_id: calendar?.calendar_id,
      name: calendarName,
    })
      .then((data: any) => {
        renderSuccess("Updated calendar name");
        setTimeout(() => hideAlert(), 5000);
        setLoadingCalendarName(false);
        setEditCalendarName(false);
      })
      .catch((ex: any) => {
        console.log(ex);
        renderError(ex?.response?.data?.message);
        setTimeout(() => hideAlert(), 5000);
        setLoadingCalendarName(false);
      });
  };

  const toggleCalendarSetting = (checked: boolean, name: string) => {
    setSubmitChange(true);
    setCalendarSettings({
      ...calendarSettings,
      [name]: checked,
    });
  };

  const updateCalendarSettings = () => {
    setSubmitChange(false);
    setLoadingSettings(true);
    CalendarRequests.setCalendarSettings({
      calendar_id: calendar?.calendar_id,
      settings_object: {
        sync_for_availability: calendarSettings?.syncForAvailability,
        add_zoee_meetings: calendarSettings?.addZoeeMeetings,
        hide_this_calendar: calendarSettings?.hideThisCalendar,
      },
    })
      .then(() => {
        setChangesMade(true);
        renderSuccess("Updated calendar settings");
        setTimeout(() => hideAlert(), 5000);
        setLoadingSettings(false);
      })
      .catch((ex: any) => {
        console.log(ex);
        renderError(ex?.response?.data?.message);
        setTimeout(() => hideAlert(), 5000);
        setLoadingSettings(false);
      });
  };

  const setPrimaryCalendar = () => {
    setIsPrimary(true);
    setLoadingPrimary(true);
    CalendarRequests.setPrimaryCalendar({
      user_id: parseInt(user.user_id),
      calendar_id: calendar.calendar_id,
    })
      .then(() => {
        setChangesMade(true);
        renderSuccess("Updated primary calendar!");
        setTimeout(() => hideAlert(), 5000);
        updateNextStepsData(false);
        setLoadingPrimary(false);
      })
      .catch((ex: any) => {
        console.log(ex);
        renderError(ex?.response?.data?.message);
        setTimeout(() => hideAlert(), 5000);
        setLoadingPrimary(false);
      });
  };

  React.useEffect(() => {
    if (submitChange) {
      updateCalendarSettings();
    }
  }, [submitChange]);

  React.useEffect(() => {
    setCalendarSettings({
      syncForAvailability: calendar?.sync_for_availability,
      addZoeeMeetings: calendar?.add_zoee_meetings,
      hideThisCalendar: calendar?.hide_this_calendar,
    });
  }, []);

  const closeEditSettings = () => {
    setEditCalendarSettings({ show: false, calendar: {} });
    if (changesMade) {
      fetchUserCalendars();
    }
  };

  return (
    <div
      className="fixed sm:fixed top-0 left-0 w-screen min-h-screen
        zoee-backdrop-filter z-[601] flex sm:items-center justify-center"
    >
      <div
        className="py-[24px] md:py-[40px] top-[40%] sm:top-[50%] left-[50%] -translate-x-[50%]
                -translate-y-[50%] px-[24px] md:px-[60px] w-[90%] md:w-[720px] bg-white 
                main-shadow rounded-[16px] flex flex-col absolute max-h-[70%] overflow-y-auto"
      >
        <div
          onClick={closeEditSettings}
          className="absolute h-[32px] w-[32px] top-[16px]
          md:top-[24px] md:right-[24px] right-[16px] rounded-full
          cursor-pointer bg-grayFlash flex justify-center items-center"
        >
          <SvgClose className="h-[16px] w-[16px]" />
        </div>
        <h3 className="text-[18px] md:text-[22px] font-bold mb-[32px] mx-auto">
          Calendar Settings
        </h3>
        <div className="flex flex-col sm:flex-row items-center md:gap-[24px] gap-[16px]">
          {getCalendarLogo()}
          <div className="flex flex-col items-center sm:items-start gap-[4px] md:gap-[6px]">
            <div className="flex items-center gap-[12px]">
              <h5 className="font-bold text-[16px]">{`${typeCapitalized()} Calendar`}</h5>
              {loadingPrimary ? (
                <Loader />
              ) : (
                <>
                  {isPrimary ? (
                    <Badge>Primary</Badge>
                  ) : (
                    <>
                      {!calendar.read_only && (
                        <div className="flex gap-1 md:gap-2 items-center">
                          <Button variant="link" onClick={setPrimaryCalendar}>
                            Make Primary
                          </Button>
                          <Button
                            size="icon"
                            variant="ghost"
                            onClick={() => {
                              setPopupNotification({
                                show: true,
                                title: "Primary Calendar",
                                message:
                                  "When you create a meeting with your contact(s), they will receive the invite from the email address associated with your primary calendar.",
                              });
                            }}
                          >
                            <SvgQuestionCircle />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <p className="text-graySlate md:text-[16px] text-base">
              <span className="font-bold">Account:</span>&nbsp;
              {profile?.profile_name}
            </p>
            <div
              className="flex items-center text-graySlate md:text-[16px]
                text-base gap-[8px]"
            >
              <div className="flex-col flex sm:flex-row items-center">
                <span className="font-bold whitespace-nowrap mr-1">
                  Calendar Name:
                </span>

                {editCalendarName ? (
                  <div className="default-input w-[150px] sm:w-[220px] ml-[37px] sm:ml-[12px]">
                    <input
                      type="text"
                      name="calendarName"
                      value={calendarName}
                      onChange={(e: any) => {
                        setCalendarName(e.target.value);
                      }}
                    />
                    <div className="overlay">Calendar Name</div>
                  </div>
                ) : (
                  <div>{calendarName}</div>
                )}
              </div>
              {loadingCalendarName ? (
                <Loader />
              ) : (
                <>
                  {editCalendarName ? (
                    <div className="flex items-center gap-[8px] ml-[8px] -mb-[20px] sm:-mb-0">
                      <Button onClick={saveCalendarName}>Save</Button>
                      <Button
                        onClick={() => setEditCalendarName(false)}
                        variant="ghost"
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      onClick={() => setEditCalendarName(true)}
                      variant="ghost"
                      size="icon"
                      className="ml-[8px]"
                      aria-label="Edit Calendar Name"
                    >
                      <SvgEdit />
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-[16px] mt-[40px]">
          <div className="w-full flex items-center justify-between gap-[20px]">
            <div className="flex flex-col">
              <h4 className="font-bold">Sync for Availability</h4>
              <p className="text-graySlate w-[85%] text-base">
                Check this calendar for conflicts when scheduling Zoee meetings
              </p>
            </div>
            {loadingSettings ? (
              <Loader />
            ) : (
              <>
                <Switch
                  onCheckedChange={(checked) =>
                    toggleCalendarSetting(checked, "syncForAvailability")
                  }
                  checked={calendarSettings?.syncForAvailability}
                  name="syncForAvailability"
                />
              </>
            )}
          </div>

          {/* FOR NOW, HIDING THE 'ADD ZOEE MEETINGS' SETTING */}

          {/* <div className="w-full flex items-center justify-between gap-[20px]">
            <div className="flex flex-col">
              <h4 className="font-bold">Add Zoee Meetings</h4>
              <p className="text-graySlate w-[85%] text-base">
                Meetings created on Zoee will be automatically synced to this
                calendar
              </p>
            </div>
            {loadingSettings ? (
              <Loader />
            ) : (
              <Toggle
                icons={false}
                onChange={toggleCalendarSetting}
                checked={calendarSettings?.addZoeeMeetings}
                name="addZoeeMeetings"
              />
            )}
          </div> */}
          <div className="w-full flex items-center justify-between gap-[20px]">
            <div className="flex flex-col">
              <h4 className="font-bold">Hide this Calendar</h4>
              <p className="text-graySlate w-[85%] text-base">
                Meetings from this calendar will be hidden from your schedule on
                Zoee
              </p>
            </div>
            {loadingSettings ? (
              <Loader />
            ) : (
              <Switch
                onCheckedChange={(checked) =>
                  toggleCalendarSetting(checked, "hideThisCalendar")
                }
                checked={calendarSettings?.hideThisCalendar}
                name="hideThisCalendar"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCalendarSettings;
