import React from "react";
import StartProfileCreation from "./StartProfileCreation";

interface Props {}

const ProfileCreateFlow: React.FC<Props> = (props: any) => {
    return (
        <>
            <StartProfileCreation />
        </>
    )
}

export default ProfileCreateFlow;
