import React, { useCallback } from "react";
import ContactAvatar from "../contacts/ContactAvatar";
import NotesPreviewCard from "./NotePreviewCard";
import NoteModal from "./NoteModal";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { NoteRequests } from "../../api/app.service";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import CloseModal from "../CloseModal";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import { SvgCheckbox, SvgCheckboxOff, SvgChevronDown, SvgSearch } from "../icons/";
import { Note } from "../../models/app.interface";

interface Props {
    isNewNote: boolean;
    setIsNewNote: any;
    setShowNotesPopover: any;
    contact?: any;
    setCallGetMemberNotes: any;
    showNoteModal: boolean;
    setShowNoteModal: any;
}

const NotesPopover: React.FC<Props> = ({
    setShowNotesPopover,
    contact,
    isNewNote,
    setIsNewNote,
    showNoteModal,
    setShowNoteModal
}) => {
    const [activeNoteId, setActiveNoteId] = React.useState<number | null>(null);
    const [showSortModal, setShowSortModal] = React.useState<boolean>(false);
    const [activeSortByDateMode, setActiveSortByDateMode] = React.useState<string>("newest");
    const [activeSortByUpdatedDateMode, setActiveSortByUpdatedDateMode] =
        React.useState<string>("");
    const [activeSortByTypeMode, setActiveSortByTypeMode] = React.useState<string>("all");
    const [isShowUpdatedDate, setIsShowUpdatedDate] = React.useState<boolean>(false);
    const { user } = React.useContext(UserCtx);
    const { renderError } = React.useContext(CommonFunctionCtx);
    const { stopLoading } = useLoading();
    const [notes, setNotes] = React.useState<Note[] | undefined>(undefined);
    const [loadingNotes, setLoadingNotes] = React.useState<boolean>(false);
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [originalNotes, setOriginalNotes] = React.useState<Note[] | undefined>(undefined);
    const navigate = useNavigate();

    const getMemberNotes = () => {
        if (user?.coachProfile) {
            setLoadingNotes(true);
            NoteRequests.getMemberNotes({
                writer_profile_id: parseInt(user.activeProfileId),
                relates_to_profile_id: contact.profile_id,
            })
                .then((data) => {
                    stopLoading();
                    setNotes(data.notes);
                    setOriginalNotes(data.notes);
                    setLoadingNotes(false);
                })
                .catch((ex) => {
                    renderError(ex.response.data.message);
                    setLoadingNotes(false);
                });
        }
    };

    let sortNotes = useCallback(() => {
        let temp = notes;

        if (!temp) {
            return [];
        }

        let filtered;

        if (activeSortByTypeMode === "session") {
            filtered = temp.filter((note) => note.session_id);
        } else if (activeSortByTypeMode === "program") {
            filtered = temp.filter((note) => note.service_details);
        } else if (activeSortByTypeMode === "general") {
            filtered = temp.filter((note) => !note.session_id && !note.service_details);
        } else {
            filtered = temp;
        }

        // TODO refactor this with moment
        let sorted = filtered.sort((a, b) => {
            if (activeSortByDateMode === "oldest") {
                return parseISO(a.created_at).getTime() - parseISO(b.created_at).getTime();
            } else if (activeSortByDateMode === "newest") {
                return parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime();
            } else if (activeSortByUpdatedDateMode === "oldest") {
                return parseISO(a.updated_at).getTime() - parseISO(b.updated_at).getTime();
            } else if (activeSortByUpdatedDateMode === "newest") {
                return parseISO(b.updated_at).getTime() - parseISO(a.updated_at).getTime();
            } else {
                return parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime();
            }
        });

        return sorted;
    }, [notes, activeSortByDateMode, activeSortByUpdatedDateMode, activeSortByTypeMode]);

    // SEARCH LOGIC
    // TODO may want to come back to these search functions to develop more robust search in future; could also benefit from more in depth testing, especially with sort options also selected
    const updateSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const updateNotesOnSearch = () => {
        const keyword = searchValue;
        if (keyword !== "") {
            const results = notes?.filter((note) => {
                return note.title.toLowerCase().includes(keyword.toLowerCase());
            });

            if (results) {
                setNotes(results);
            } else {
                setNotes(originalNotes);
            }
        } else {
            setNotes(originalNotes);
        }
    };

    React.useEffect(() => {
        if (!notes) {
            setNotes([]);
        }
        updateNotesOnSearch();
    }, [searchValue]);

    const navigateToPDFPreview = () => {
        navigate(`/${user.activeProfile}/contacts/${contact.profile_id}/notes`, {
            state: { contact: contact, notes },
        });
    };

    const handleCloseNotesPopover = () => {
        setShowNotesPopover(false)
        setShowNoteModal(false)
    }

    React.useEffect(() => {
        getMemberNotes();
      }, [])

    return (
        <div className="w-full shrink">
            <div
                className="fixed top-0 right-0 h-[100vh] w-full sm:w-[460px] 
                    bg-white z-[600] sm:card-hover-shadow pt-[32px] px-[20px] sm:px-[40px] pb-[16px]
                    overflow-y-auto flex flex-col"
            >
                <div className="flex flex-col mb-[32px] w-full">
                    <div className="flex justify-between items-center w-full mb-[12px]">
                        <div className="flex items-center gap-[16px]">
                            <ContactAvatar
                                contact={contact}
                                width={32}
                                height={32}
                                border={""}
                                isNotBold={false}
                                fontSize="text-[16px]"
                            />
                            <h3 className="font-bold text-[22px]">
                                {contact.contact_name}'s Notes
                            </h3>
                        </div>
                        <CloseModal
                            callback={handleCloseNotesPopover}
                            styling="w-[32px] h-[32px] mr-[16px]"
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between w-full mb-[12px]">
                    <div className="default-input w-full mr-[12px]">
                        <input
                            type="text"
                            className="rounded-[100px] pt-0 indent-[48px]"
                            placeholder="Search"
                            onChange={updateSearchValue}
                        />
                        <SvgSearch
                            className="absolute top-[50%] -translate-y-[50%] left-[12px]"
                        />
                    </div>

                    {showNoteModal ? (
                        <button
                            onClick={() => {
                                setIsNewNote(true);
                                setShowNoteModal(true);
                            }}
                            className="btn-primary min-w-[116px] h-[48px] text-base"
                            disabled
                        >
                            New Note
                        </button>
                    ) : (
                        <Button
                            onClick={() => {
                                setIsNewNote(true);
                                setShowNoteModal(true);
                            }}
                            className="min-w-[116px] h-[48px] text-base"
                        >
                            New Note
                        </Button>
                    )}
                </div>
                <div className="flex flex-col w-full overflow-y-auto min-h-[30px] mb-[16px]">
                    <button
                        onClick={() => setShowSortModal(() => !showSortModal)}
                        className="flex items-center w-full"
                    >
                        <p className="font-bold text-base mr-[8px]">Sort</p>
                        <SvgChevronDown stroke="black"/>
                    </button>
                </div>

                {/* TODO: Move sort modal out into a component */}
                {/* SORT MODAL */}
                {showSortModal ? (
                    <div className="absolute bg-white box-shadow-2 top-[208px] px-[32px] py-[24px] rounded-[12px]">
                        <div className="flex flex-col mb-[32px]">
                            <h6 className="text-base text-graySlate mb-[16px]">
                                by created date
                            </h6>
                            <div className="w-[184px] flex flex-col gap-[10px] text-base">
                                <div className="flex justify-between items-center">
                                    <p>Newest</p>
                                    <button
                                        onClick={() => {
                                            setActiveSortByDateMode("newest");
                                            setActiveSortByUpdatedDateMode("");
                                            setIsShowUpdatedDate(false);
                                        }}
                                    >
                                        {activeSortByDateMode === "newest" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p>Oldest</p>
                                    <button
                                        onClick={() => {
                                            setActiveSortByDateMode("oldest");
                                            setActiveSortByUpdatedDateMode("");
                                            setIsShowUpdatedDate(false);
                                        }}
                                    >
                                        {activeSortByDateMode === "oldest" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col mb-[32px]">
                            <h6 className="text-base text-graySlate mb-[16px]">
                                by updated date
                            </h6>
                            <div className="w-[184px] flex flex-col gap-[10px] text-base">
                                <div className="flex justify-between items-center">
                                    <p>Newest</p>
                                    <button
                                        onClick={() => {
                                            setActiveSortByUpdatedDateMode("newest");
                                            setActiveSortByDateMode("");
                                            setIsShowUpdatedDate(true);
                                        }}
                                    >
                                        {activeSortByUpdatedDateMode === "newest" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p>Oldest</p>
                                    <button
                                        onClick={() => {
                                            setActiveSortByUpdatedDateMode("oldest");
                                            setActiveSortByDateMode("");
                                            setIsShowUpdatedDate(true);
                                        }}
                                    >
                                        {activeSortByUpdatedDateMode === "oldest" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <h6 className="text-base text-graySlate mb-[16px]">by type</h6>
                            <div className="w-[184px] flex flex-col gap-[10px] text-base">
                                <div className="flex justify-between items-center">
                                    <p>All</p>
                                    <button onClick={() => setActiveSortByTypeMode("all")}>
                                        {activeSortByTypeMode === "all" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p>Session</p>
                                    <button onClick={() => setActiveSortByTypeMode("session")}>
                                        {activeSortByTypeMode === "session" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p>Program</p>
                                    <button onClick={() => setActiveSortByTypeMode("program")}>
                                        {activeSortByTypeMode === "program" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p>General</p>
                                    <button onClick={() => setActiveSortByTypeMode("general")}>
                                        {activeSortByTypeMode === "general" ? (
                                            <SvgCheckbox />
                                        ) : (
                                            <SvgCheckboxOff />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {/* SORT MODAL END */}

                {/* NOTES HISTORY */}
                {loadingNotes ? (
                    <>
                        <div className="mx-auto h-full flex justify-center items-center">
                            <h3 className="font-bold">Loading Notes...</h3>
                        </div>
                    </>
                ) : (
                    <>
                        {sortNotes().length > 0 ? (
                            <>
                                <div className="mx-auto">
                                    {sortNotes()?.map((note, idx) => {
                                        return (
                                            <NotesPreviewCard
                                                key={idx}
                                                note={note}
                                                setActiveNoteId={setActiveNoteId}
                                                activeNoteId={activeNoteId}
                                                isShowUpdatedDate={isShowUpdatedDate}
                                                setShowNoteModal={setShowNoteModal}
                                            />
                                        );
                                    })}

                                    {/* TODO UIUX update for this button placement */}
                                    <button
                                        className="hidden sm:block mt-[20px] mx-auto btn-primary btn-secondary"
                                        onClick={navigateToPDFPreview}
                                    >
                                        Notes PDF Preview
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="mx-auto h-full flex justify-center items-center">
                                <h3 className="font-bold">Let's get started!</h3>
                            </div>
                        )}
                    </>
                )}
            </div>
            {/* NOTES HISTORY END */}

            {/* NOTES EDITOR MODAL */}
            {(activeNoteId || isNewNote) && showNoteModal && (
                <NoteModal
                    note={notes?.find((n) => n.id === activeNoteId)}
                    setActiveNoteId={setActiveNoteId}
                    setShowNoteModal={setShowNoteModal}
                    isNewNote={isNewNote}
                    setIsNewNote={setIsNewNote}
                    user={user}
                    contact={contact}
                    getMemberNotes={getMemberNotes}
                />
            )}
            {/* NOTES EDITOR MODAL END */}
        </div>
    );
};

export default NotesPopover;
