import React from "react"
import ListSocialMedia from "./ListSocialMedia"
import PublicCredentials from "./ProfileCredentials"

interface Props {
  post?: any
  service?: any
  coachPublicProfileData?: any
}

const ProfileAboutTab: React.FC<Props> = ({ coachPublicProfileData }) => {
  React.useEffect(() => {}, [coachPublicProfileData])
  
  return (
    <>
      <div
        className="flex flex-col lg:flex-row rounded-[20px] p-6 lg:p-12"
        style={{
          boxShadow: "0 0 20px 5px rgba(150, 150, 150, 0.1)",
        }}
      >
        <div className="flex flex-col justify-start items-start w-full lg:w-[60%]">
          <div>
            <h1 className="font-bold text-xl mb-8">About</h1>
            <p className="whitespace-pre-line mb-8">
              {coachPublicProfileData?.profile_information?.about}
            </p>
          </div>
          <div>
            <PublicCredentials
              credentials={coachPublicProfileData?.credentials?.credentials}
              educationList={coachPublicProfileData?.credentials?.education}
            />
          </div>
        </div>
        <div className="lg:w-[40%] lg:order-2 lg:pl-20">
          <div className="h-[469px] rounded-[20px] lg:-mt-0">
            {coachPublicProfileData?.tags.length !== 0 ? 
            <div className="flex flex-col items-start">
              <p className="text-16 font-bold mb-4">Areas of Expertise</p>
              <div className="flex flex-wrap h-full justify-start max-w-[278px]">
                {coachPublicProfileData?.tags?.map(
                  (tag: any, index: number) => (
                    <div
                      className="px-2 border border-2 rounded-lg mr-1 mt-1 text-sm flex items-center font-bold text-[11px] sm:text-[14px] h-[30px] bg-grayFlash line-clamp-1"
                      key={index}
                    >
                      <p className="ml-1">{tag}</p>
                    </div>
                  )
                )}
              </div>
            </div>
            : 
              <></> 
            }
            <div className="max-w-[278px] -ml-10">
              <ListSocialMedia
                coachPublicProfileId={
                  coachPublicProfileData?.coach_public_profile_id
                }
                isAboutSection
                bgColor={
                  coachPublicProfileData?.profile_information?.profileColor
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden mt-6"></div>
    </>
  )
}

export default ProfileAboutTab
