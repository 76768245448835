import * as React from "react";
import { CalendarRequests } from "../../../../api/app.service";
import { UserCtx } from "../../../../context/userContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import { defaultAvailability } from "../../../../objects/availabilityObjects";
import useScrollToTop from "../../../../hooks/useScrollToTop";
import Loader from "../../../../components/ui/loader";
import Availability from "../../../../components/scheduling/availability/Availability";
import { ConnectCalendarCTA } from "../../../../components/account-settings/calendars/ConnectCalendarCTA";
import ListCalendars from "../../../../components/account-settings/calendars/ListCalendars";

const CalendarSettingsPage: React.FC = () => {
  const [showCalendarTab, setShowCalendarTab] = React.useState<any>(true);
  const [showAvailability, setShowAvailability] =
    React.useState<boolean>(false);
  const [showSocialTab, setShowSocialTab] = React.useState<any>(false);
  const [completeCalendarConnect, setCompleteCalendarConnect] =
    React.useState<any>(false);
  const [userCalendars, setUserCalendars] = React.useState<any>({});
  const [userCalendarsLoading, setUserCalendarsLoading] =
    React.useState<boolean>(false);
  const [availabilities, setAvailabilities] = React.useState<any>([]);
  const { user } = React.useContext(UserCtx);
  const { renderError, renderSuccess, hideAlert, updateNextStepsData } =
    React.useContext(CommonFunctionCtx);

  const showComponent = (callback: any) => {
    setShowCalendarTab(false);
    setShowSocialTab(false);
    setShowAvailability(false);
    callback(true);
  };

  const getAvailabilityRules = () => {
    if (user) {
      CalendarRequests.getAvailabilityRules({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          setAvailabilities(data.availability_rules);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
        });
    }
  };

  const userDefaultAvailability = (name: string, is_default: boolean) => {
    const newDefaultAvailability = {
      ...defaultAvailability,
      is_default: is_default,
      name: name,
      timezone: user.timezone,
    };
    return newDefaultAvailability;
  };

  const setDefaultAvailability = async () => {
    if (user && availabilities.length === 0) {
      // const consultAvailability = userDefaultAvailability("Default Consultation Availability", false)
      const defAvailability = userDefaultAvailability(
        "Default Availability",
        true,
      );

      CalendarRequests.setAvailabilityRules({
        user_id: parseInt(user.user_id),
        availability_rules: [defAvailability],
      }).catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setTimeout(() => hideAlert(), 5000);
      });
    }
  };

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const completeRegisterCalendar = (code: string, data: any) => {
    const redirectUrl = `${process.env.REACT_APP_BASE_URL}/account-settings/calendars`;
    if (code && user) {
      CalendarRequests.registerCalendar({
        user_id: parseInt(user.user_id),
        profile_count: data.profile_count,
        calendar_connect_code: code,
        redirect_url: redirectUrl,
      })
        .then(() => {
          renderSuccess("Calendar connected!");
          setTimeout(() => hideAlert(), 5000);
          fetchUserCalendars().then(() => {
            scrollToBottom();
          });
          setCompleteCalendarConnect(true);
          updateNextStepsData(false);
        })
        .catch((ex: any) => {
          console.log(ex);
          renderError(ex.response.data.error_detail);
          setTimeout(() => hideAlert(), 5000);
          setUserCalendarsLoading(false);
        });
      window.history.replaceState(null, "", window.location.pathname);
    }
  };

  const checkUrlParams = (data: any) => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code && user) {
      completeRegisterCalendar(code, data);
    } else {
      window.history.replaceState(null, "", window.location.pathname);
      setUserCalendarsLoading(false);
    }
  };

  const fetchUserCalendars = async () => {
    setUserCalendarsLoading(true);
    if (user) {
      await CalendarRequests.getCalendarList({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setUserCalendars({
            profileCount: data.profile_count,
            profiles: data.profiles,
          });
          checkUrlParams(data);
        })
        .catch((ex: any) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
          setUserCalendarsLoading(false);
        });
    } else {
      setUserCalendarsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchUserCalendars();
    getAvailabilityRules();
  }, [user]);

  React.useEffect(() => {
    if (completeCalendarConnect) {
      setDefaultAvailability();
    }
  }, [completeCalendarConnect]);

  useScrollToTop(showCalendarTab, showSocialTab);

  return (
    <>
      <div className="mx-auto flex max-w-[424px] flex-col px-[20px] md:w-[1080px] md:max-w-none md:pt-0">
        <h1
          className="mb-[40px] hidden text-[28px] font-semibold text-black
                    sm:text-[36px] sm:font-bold md:block"
        >
          Calendar Settings
        </h1>

        {/* SECONDARY NAV HIDDEN */}
        <div className="text-gray mb-[24px] flex items-center justify-center gap-[12px] text-base md:mb-[40px] md:justify-start">
          <button
            className={`${
              showCalendarTab
                ? "btn-primary btn-secondary-nav"
                : "px-[16px] py-[12px] font-bold"
            }`}
            onClick={() => showComponent(setShowCalendarTab)}
          >
            Calendar
          </button>
          <button
            className={`${
              showAvailability
                ? "btn-primary btn-secondary bg-grayMist"
                : "px-[16px] py-[12px] font-bold"
            }`}
            onClick={() => showComponent(setShowAvailability)}
            disabled={userCalendars.profileCount === 0}
          >
            Availability
          </button>
        </div>

        {!userCalendarsLoading ? (
          <>
            {showCalendarTab && (
              <div className="flex flex-col gap-6">
                <ListCalendars
                  userCalendars={userCalendars}
                  fetchUserCalendars={fetchUserCalendars}
                />

                <ConnectCalendarCTA
                  isCalendarConnected={userCalendars.profileCount > 0}
                />
              </div>
            )}
            {showAvailability && <Availability />}
          </>
        ) : (
          <div className="flex w-full flex-col items-center gap-[12px]">
            <Loader />
            <h3 className="mb-[4px] text-center text-[16px] font-bold">
              Checking for calendars...
            </h3>
          </div>
        )}
      </div>
    </>
  );
};

export default CalendarSettingsPage;
