import * as React from "react";
import { Post } from "../../../types/postTypes";

type Props = {
  posts: Array<Post>;
};

const ResourceSlider: React.FC<Props> = ({ posts }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const post2 = posts[2];
  const post9 = posts[9];
  const post18 = posts[18];

  const displayedPosts = [post2, post9, post18].filter(Boolean);

  const goToPost = (postIndex: any) => {
    setCurrentIndex(postIndex);
  };

  return (
    <div className="lg:hidden flex justify-center w-[100%] pt-8 ">
      <div className="h-[600px] w-[400px] relative flex justify-center ">
        {displayedPosts.map((post, index) => (
          <div
            key={index}
            className={`h-[475px] w-[80%] rounded-[20px] shadow-lg absolute ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            } transition-opacity duration-500`}
          >
            <div className="h-44">
              <img
                src={post.metadata.previewImg}
                alt={`post ${index + 1}`}
                className="w-full h-full object-cover rounded-t-[20px]"
              />
            </div>
            <div className="pl-4 pt-2 pr-4">
              <h1 className="font-bold text-xl">{post.metadata.title}</h1>
            </div>
            <div className="pl-4 pt-2 pb-2 text-xs">
              <h1>{post.metadata.date}</h1>
            </div>
            <div className="pl-4 pb-2 text-sm pr-4">
              <h1>{post.metadata.description}</h1>
            </div>
          </div>
        ))}

        <div className="absolute bottom-4 w-full flex justify-center">
          {displayedPosts.map((_, index) => (
            <div
              key={index}
              className={`block mx-2 cursor-pointer rounded-full w-3 h-3 -mt-20 ${
                index === currentIndex ? "bg-blurple" : "bg-mediumGray"
              }`}
              onClick={() => goToPost(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default ResourceSlider;
