import React, { useState } from "react";
import { UserCtx } from "../../../context/userContext";
import { ProfileRequests } from "../../../api/app.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { Link } from "react-router-dom";
import { Button } from "../../ui/button";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import CloseModal from "../../CloseModal";
import Loader from "../../ui/loader";
import { getLightTextColor } from "../../../utils/getLightTextColor";
import background from "../../../assets/images/background-gradient-bottom.png";
interface Props {
  selectedTestimonialId: number | undefined;
}
const PrivateOverviewTestimonials: React.FC<Props> = ({
  selectedTestimonialId,
}: Props) => {
  const { renderError } = React.useContext(CommonFunctionCtx);
  const { coachPublicProfileData, setCreateBlogPost } = React.useContext(
    CoachPrivateProfileContext,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [testimonial, setTestimonial] = useState<any>();
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor,
  );
  const getCoachTestimonials = async () => {
    setIsLoading(true);
    ProfileRequests.getTestimonials({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data: any) => {
        const allTestimonials = data.testimonials;
        let firstTestimonial = allTestimonials[7];
        const indexOfSelectedTestimonial = allTestimonials.findIndex(
          (testimonial: any) => testimonial.id === selectedTestimonialId,
        );
        if (indexOfSelectedTestimonial !== -1) {
          firstTestimonial = allTestimonials[indexOfSelectedTestimonial];
        }
        setTestimonial(firstTestimonial);
      })
      .catch((ex: any) => {
        console.log(ex);
        renderError("Error fetching testimonials");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getCoachTestimonials();
  }, [coachPublicProfileData]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="h-full w-full">
          {testimonial ? (
            <div
              className="flex h-full w-full flex-col justify-between rounded-[20px] p-4"
              style={{
                /*backgroundImage: `url(${background})`,*/ backgroundColor: `${textColor}`,
              }}
            >
              <h1 className="font-bold">Testimonials</h1>
              <p className="line-clamp-[8] max-w-[250px] self-center italic">
                {testimonial?.testimonial}
              </p>
              <p className="pl-4 text-xs font-medium">
                {testimonial?.client_name
                  ? testimonial.client_name
                  : "Anonymous Member"}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
export default PrivateOverviewTestimonials;
