import React from "react";
import successKit from "../../assets/images/affiliate-program-success-kit.webp";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ReferralRequests } from "../../api/app.service";
import ContactInvite from "../../components/contacts/ContactInvite";
import ConfirmReferralInvite from "../../components/referrals/ConfirmReferralInvite";
import ReferralItem from "../../components/referrals/ReferralItem";
import PersonalizeYourPagePreview from "../../components/referrals/PersonalizeYourPagePreview";
import DragDropArea from "../../components/DragDropArea";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import useScrollToTop from "../../hooks/useScrollToTop";
import Loader from "../../components/ui/loader";
import useLoading from "../../hooks/useLoading";
import { Button } from "../../components/ui/button";
import { Switch } from "../../components/ui/switch";
import { Label } from "../../components/ui/label";
import { SvgCopy, SvgFacebook, SvgLink, SvgLinkedin, SvgNewWindow, SvgTwitter } from "../../components/icons/";

interface Props {}

const ReferACoach: React.FC<Props> = () => {
    const { user } = React.useContext(UserCtx);
    const {
        renderError,
        getUser,
        renderSuccess,
        setPopupNotification,
        hideAlert,
    } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();
    const [promocode, setPromocode] = React.useState<string>("");
    const [showInviteModal, setShowInviteModal] = React.useState<boolean>(false);
    const [showConfirmInviteModal, setShowConfirmInviteModal] = React.useState<boolean>(false);
    const [showPypPreview, setShowPypPreview] = React.useState<boolean>(false);
    const [invites, setInvites] = React.useState<any>([{ email: "" }]);
    const [referrals, setReferrals] = React.useState<any[]>([]);
    const [isPypPublished, setIsPypPublished] = React.useState<boolean>(false);
    const [personalizeYourPageValues, setPersonalizeYourPageValues] = React.useState<any>({
        name: "",
        title: "",
        description: "",
    });
    const [, setHasUnsavedDetails] = React.useState<boolean>(false);
    const [profileImgFile, setProfileImgFile] = React.useState<File>();
    const [awaitNewImage, setAwaitNewImage] = React.useState<boolean>(false);
    const [hasPypImage, setHasPypImage] = React.useState<boolean>(false);
    const [imageId, setImageId] = React.useState<string | null>(null);
    const [previewImgUrl, setPreviewImgUrl] = React.useState<string>("");
    const [loadingReferrals, setLoadingReferrals] = React.useState<boolean>(false);
    const [loadingPypDetails, setLoadingPypDetails] = React.useState<boolean>(false);

    const numSubscribedReferrals = referrals?.filter((r) => r.status === "subscribed").length;
    const earnings = referrals
        .map((r) => r.earnings_amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const createUserPromoCode = () => {
        if (user) {
            ReferralRequests.createUserPromoCode({
                user_id: parseInt(user.user_id),
                first_name: user.firstName,
            })
                .then((data) => {
                    setPromocode(data.promo_code);
                })
                .then(() => {
                    getUser();
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setTimeout(() => hideAlert(), 5000);
                });
        }
    };

    const getReferrals = () => {
        if (user) {
            setLoadingReferrals(true);
            ReferralRequests.getReferrals({
                user_id: parseInt(user.user_id),
            })
                .then((data) => {
                    setReferrals(data.referrals);
                    setLoadingReferrals(false);
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setTimeout(() => hideAlert(), 5000);
                    setLoadingReferrals(false);
                });
        }
    };

    const getPersonalizeYourPageDetails = () => {
        if (user?.promoCode) {
            setLoadingPypDetails(true);
            ReferralRequests.getPersonalizeYourPageDetails({
                promo_code: user.promoCode,
            })
                .then((data) => {
                    if (Object.keys(data.pyp_data).length === 0) {
                        setLoadingPypDetails(false);
                        editPersonalizeYourPageDetails(
                            {
                                name: "",
                                title: "",
                                description: "",
                            },
                            false,
                            { "hideSuccessAlert": true }
                        );
                    } else {
                        setLoadingPypDetails(false);
                        setIsPypPublished(data.pyp_data.is_published || false);
                        setPersonalizeYourPageValues({
                            name: data.pyp_data.pyp_details.name || "",
                            title: data.pyp_data.pyp_details.title || "",
                            description: data.pyp_data.pyp_details.description || "",
                        });
                        setHasPypImage(data.pyp_data.has_pyp_image);
                        setImageId(data.pyp_data.img_id);
                    }
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setTimeout(() => hideAlert(), 5000);
                    setLoadingPypDetails(false);
                });
        }
    };

    const editPersonalizeYourPageDetails = (
        pypData: any,
        isPypPublished: boolean,
        options?: any
    ) => {
        let hideSuccessAlert = false
        if (options && options.hideSuccessAlert) {
            hideSuccessAlert = true
        }

        if (user && user.promoCode) {
            startLoading();
            ReferralRequests.editPersonalizeYourPageDetails({
                user_id: parseInt(user.user_id),
                pyp_details: pypData,
                pyp_is_published: isPypPublished,
            })
                .then(() => {
                    if (!hideSuccessAlert) {
                        renderSuccess("Saved your personalize your page details!");
                        setTimeout(() => hideAlert(), 5000);
                    }
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                    setTimeout(() => hideAlert(), 5000);
                })
                .finally(() => {
                    stopLoading();
                });
        }
    };

    const handlePromoCodeCopyClick = () => {
        navigator.clipboard.writeText(promocode);
        renderSuccess("Success! Referral code copied.");
        setTimeout(() => hideAlert(), 3000);
    };

    const handlePromoCodeUniqueLinkCopyClick = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/welcome?ref=${promocode}`);
        renderSuccess("Success! Referral link copied.");
        setTimeout(() => hideAlert(), 3000);
    };

    const handlePersonalizeYourPageChange = (e: React.ChangeEvent<EventTarget>) => {
        setHasUnsavedDetails(true);
        let target = e.target as HTMLInputElement;

        if (target.value.length > 1000) {
            return;
        }

        setPersonalizeYourPageValues({ ...personalizeYourPageValues, [target.name]: target.value });
    };

    const handleEnablePersonalizeYourPageToggleChange = () => {
        setIsPypPublished((prev) => !prev);
        editPersonalizeYourPageDetails(personalizeYourPageValues, !isPypPublished);
    };

    const savePersonalizeYourPageDetails = () => {
        setHasUnsavedDetails(false);
        editPersonalizeYourPageDetails(personalizeYourPageValues, isPypPublished);
    };

    const validateFile = (imageFile: File) => {
        if (
            (imageFile.type === "image/png" ||
                imageFile.type === "image/jpg" ||
                imageFile.type === "image/jpeg") &&
            imageFile.size <= 5000000 &&
            imageFile.size > 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handlePersonalizeYourPageImageUpload = () => {
        if (profileImgFile) {
            if (validateFile(profileImgFile)) {
                const type = profileImgFile.type.split("/")[1];
                const reader = new FileReader();
                reader.readAsDataURL(profileImgFile);
                reader.onload = function () {
                    const string = (reader.result as string).split("\n").map((data) => {
                        return data.split(",");
                    });
                    const base64 = string[0][1];
                    saveUserPersonalizeYourPageImage(base64, type);
                };
                reader.onerror = function (error) {
                    console.log("Error: ", error);
                };
            } else {
                setPopupNotification({
                    show: true,
                    title: "Bummer! Your file is too big.",
                    message:
                        "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
                    callback: null,
                });
                setProfileImgFile(undefined);
            }
        }
    };

    const saveUserPersonalizeYourPageImage = (base64String: string, type: string) => {
        hideAlert();
        if (user) {
            startLoading();
            ReferralRequests.editPersonalizeYourPageImage({
                user_id: parseInt(user.user_id),
                access_token: user.access_token,
                base_64_string: base64String,
                file_extension: type,
            })
                .then(() => {
                    getUser().then(() => {
                        renderSuccess("Successfully uploaded personalize your page image");
                        setTimeout(() => hideAlert(), 5000);
                        setProfileImgFile(undefined);
                        setAwaitNewImage(false);
                    });

                    setTimeout(() => {
                        hideAlert();
                    }, 5000);
                })
                .catch((ex: any) => {
                    console.log(ex);
                    setProfileImgFile(undefined);
                    renderError(ex.response.data.message);
                    setTimeout(() => hideAlert(), 5000);
                    stopLoading();
                });
        }
    };

    const handlePreviewSectionClick = () => {
        setShowPypPreview(true);
    };

    const handlePreviewPageClick = () => {
        window
            .open(`${process.env.REACT_APP_BASE_URL}/welcome?ref=${promocode}`, "_blank")
            ?.focus();
    };

    const downloadSuccessKit = () => {
        const environment = process.env.REACT_APP_USER_ENVIRONMENT;
        const url = `https://zoee-downloads-${environment}.s3.amazonaws.com/Zoee+Affiliate+Program+Success+Kit.pdf`;
        window.open(url, "_blank");
    };

    const shareToFacebook = () => {
        const promoLink = `http%3A%2F%2Fzoee.com%2Fwelcome%3Fref%3D${promocode}`;
        const url = `https://www.facebook.com/sharer.php?u=${promoLink}`;
        window.open(url, "_blank");
    };

    const shareToTwitter = () => {
        // https://twitter.com/intent/tweet?url={url}&text={title}&via={user_id}&hashtags={hash_tags}
        const promoLink = `http%3A%2F%2Fzoee.com%2Fwelcome%3Fref%3D${promocode}`;
        const url = `https://twitter.com/intent/tweet?url=${promoLink}`;
        window.open(url, "_blank");
    };

    const shareToLinkedin = () => {
        const promoLink = `http%3A%2F%2Fzoee.com%2Fwelcome%3Fref%3D${promocode}`;
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${promoLink}`;
        window.open(url, "_blank");
    };

    // const shareToInstagram = () => {
    //     const promoLink = `http%3A%2F%2Fzoee.com%2Fwelcome%3Fref%3D${promocode}`;
    //     const url = `https://www.linkedin.com/sharing/share-offsite/?url=${promoLink}`;
    //     window.open(url, "_blank");
    // };
    //
    // const shareToTikTok = () => {
    //     const promoLink = `http%3A%2F%2Fzoee.com%2Fwelcome%3Fref%3D${promocode}`;
    //     const url = `https://www.linkedin.com/sharing/share-offsite/?url=${promoLink}`;
    //     window.open(url, "_blank");
    // };

    React.useEffect(() => {
        handlePersonalizeYourPageImageUpload();
        if (profileImgFile) {
            setPreviewImgUrl(URL.createObjectURL(profileImgFile));
        }
    }, [profileImgFile]);

    React.useEffect(() => {
        if (user) {
            if (!user.promoCode) {
                try {
                    createUserPromoCode();
                } catch (error) {
                    console.error(error);
                }
            } else {
                setPromocode(user.promoCode);
                getReferrals();
                getPersonalizeYourPageDetails();
            }
        }
    }, [user]);

    useScrollToTop()

    return (
        <>
            <div className="md:pt-0 mx-auto flex flex-col items-center w-full gap-[40px]">
                <div className="max-w-[824px] md:w-[800px] mx-[12px] bg-white main-shadow pt-[40px] md:px-[38px] px-[16px] rounded-[16px]">

                    <h2 className="md:text-[32px] text-[28px] font-semibold md:ml-[29px] mb-[36px] md:mb-[40px]">
                        Give $50,{" "}
                        <span className="text-blurple">Get $50</span>
                    </h2>

                    <div className="max-w-[530px] md:ml-[29px] mb-[28px] md:mb-[32px]">
                        <div className="flex items-center gap-[10px] mb-[8px]">
                            <h4 className="font-bold text-[20px]">
                                Earn money sharing Zoee with other coaches
                            </h4>
                        </div>
                    </div>

                    {promocode === "" ? (
                        <div className="flex flex-col items-center gap-[12px] mt-[160px]">
                            <Loader />
                            <h3 className="font-bold text-[16px] mb-[4px] text-center">
                                Getting your promo code...
                            </h3>
                        </div>
                    ) : (
                        <>
                            {/* FOUNDING COACH PERIOD VERSION */}
                            <div className="mb-[32px] md:px-auto px-[12px] flex flex-col items-center bg-blurple rounded-[16px] py-[24px] text-white text-center">
                                <p className="text-white md:max-w-[550px] max-w-[280px] mb-[16px]">
                                    Grow your business and earn extra income with us. Spread the word about Zoee and receive{" "}
                                    <span className="font-bold">$50</span>{" "}
                                    for every coach referral who signs up for an annual subscription using your code.
                                </p>
                                <div className="flex flex-col md:flex-row items-center gap-[6px] md:gap-[12px]">
                                    <p>Use code</p>
                                    <Button
                                        onClick={handlePromoCodeCopyClick}
                                        variant="secondary"
                                        size="sm"
                                    >
                                        {promocode}
                                        <SvgCopy />
                                    </Button>
                                    <Link
                                        to="/affiliate-program-terms"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-base underline"
                                    >
                                        See terms
                                    </Link>
                                </div>
                            </div>

                            <div className="flex flex-col gap-[30px] mb-[30px]">
                                <div className="flex flex-col gap-[2px]">
                                    <h5 className="text-base font-bold">
                                        Copy and share your unique link
                                    </h5>
                                    <div className="pl-[20px] pr-[3px] border border-grayCloud rounded-[10px] h-[53px] flex justify-between items-center">
                                        <p className="text-graySlate">zoee.com/welcome?ref={promocode}</p>
                                        <Button
                                            onClick={handlePromoCodeUniqueLinkCopyClick}
                                            className="h-[45px] md:w-[180px] w-[100px] active:scale-95"
                                        >
                                            Copy
                                            <SvgLink />
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-[2px]">
                                    <h5 className="text-base font-bold">Invite via email</h5>
                                    <div
                                        onClick={() => setShowInviteModal(true)}
                                        className="pl-[20px] pr-[3px] border border-grayCloud rounded-[10px] h-[53px] flex justify-between items-center"
                                    >
                                        <p className="text-graySlate">
                                            Invite your coach friends via email
                                        </p>
                                        <Button
                                            onClick={() => setShowInviteModal(true)}
                                            className="h-[45px] md:w-[180px] w-[100px] active:scale-95"
                                        >
                                            Send Invite
                                        </Button>
                                    </div>
                                </div>
                                <div className="flex items-center gap-[28px]">
                                    <h5 className="text-base font-bold">Or share via...</h5>
                                    <div className="flex items-center gap-8">
                                        <Button variant="ghost" size="icon" onClick={shareToTwitter}>
                                            <SvgTwitter />
                                        </Button>
                                        <Button variant="ghost" size="icon" onClick={shareToFacebook}>
                                            <SvgFacebook />
                                        </Button>
                                        <Button variant="ghost" size="icon" onClick={shareToLinkedin}>
                                            <SvgLinkedin />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="w-full h-px bg-grayCloud" />
                    {loadingReferrals ? (
                        <div className="flex flex-col items-center gap-[12px] mt-[160px]">
                            <Loader />
                            <h3 className="font-bold text-[16px] mb-[4px] text-center">
                                Checking for referrals...
                            </h3>
                        </div>
                    ) : (
                        <>
                            <div className="my-[40px] flex md:flex-row flex-col items-center gap-[8px]">
                                <div className="md:w-1/3 w-full h-[122px] flex flex-col gap-[7px] items-center justify-center rounded-[16px] border border-grayCloud">
                                    <p className="text-[36px] font-semibold">{referrals.length}</p>
                                    <p className="text-base text-center font-bold text-graySlate">
                                        {referrals.length === 1 ? "Invite sent" : "Invites sent"}
                                    </p>
                                </div>
                                <div className="md:w-1/3 w-full h-[122px] flex flex-col gap-[7px] items-center justify-center rounded-[16px] border border-grayCloud">
                                    <p className="text-[36px] font-semibold">{numSubscribedReferrals}</p>
                                    <p className="text-base text-center font-bold text-graySlate">
                                        {numSubscribedReferrals === 1 ? "Referral has" : "Referrals have"}
                                        <br />
                                        subscribed
                                    </p>
                                </div>
                                <div className="md:w-1/3 w-full h-[122px] flex flex-col gap-[7px] items-center justify-center rounded-[16px] border border-grayCloud">
                                    <p className="text-[36px] font-semibold">${earnings}</p>
                                    <p className="text-base text-center font-bold text-graySlate">
                                        Earnings
                                    </p>
                                </div>
                            </div>
                            <div className="w-full h-[1px] bg-grayCloud" />
                            <div className="mt-[30px] flex flex-col gap-[24px]">
                                {referrals?.length > 0 ? (
                                    <>
                                        {referrals?.map((r) => {
                                            return (
                                                <div
                                                    key={r?.id}
                                                    className="md:p-0 pb-[24px] last:pb-0 md:border-b-0 border-b border-grayCloud last:border-0"
                                                >
                                                    <ReferralItem
                                                        nameOrEmail={
                                                            r.recipient_first_name
                                                                ? `${r.recipient_first_name} ${r.recipient_last_name}`
                                                                : r.recipient_email
                                                        }
                                                        recipientId={r.recipient_user_id}
                                                        status={r.status}
                                                        expiresAt={r.expires_at}
                                                        hasAvatar={r.recipient_has_avatar || null}
                                                        userColor={r.recipient_user_color || null}
                                                        earningsAmount={r.earnings_amount || null}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <p>
                                        No referrals yet! Send referral invites to your coach friends using
                                        the link or buttons above.
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <div className="max-w-[824px] px-[12px] flex flex-col w-full">
                    <div className="flex flex-col gap-[12px] items-center">
                        <div className="flex flex-col main-shadow rounded-[16px] bg-white w-full px-[24px] pt-[24px] pb-[32px] md:pb-[42px]">
                            <div className="flex flex-col md:flex-row gap-[19px] items-center justify-between mb-[24px]">
                                <div className="flex items-center gap-[10px]">
                                    <h3 className="font-semibold md:text-[32px] text-[24px] mr-auto">
                                        Personalize your page
                                    </h3>
                                    <p className="text-base font-semibold">*Optional</p>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Label htmlFor="publish">Publish</Label>
                                    <Switch id="publish" checked={isPypPublished} onCheckedChange={handleEnablePersonalizeYourPageToggleChange} />
                                </div>
                            </div>
                            {loadingPypDetails ? (
                                <div className="flex flex-col items-center gap-[12px] mb-[20px]">
                                    <Loader />
                                    <h3 className="font-bold text-[16px] mb-[4px] text-center">
                                        Getting personalize your page details...
                                    </h3>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className={`mx-auto lg:max-w-[540px] w-full flex flex-col gap-[20px] mb-[20px]`}
                                    >
                                        {hasPypImage ? (
                                            <div className="flex flex-row w-full md:justify-start gap-[24px] items-center mb-[40px]">
                                                {awaitNewImage ? (
                                                    <>
                                                        <DragDropArea
                                                            styling={`rounded-full w-[140px] h-[140px]`}
                                                            mediaFile={profileImgFile}
                                                            setMediaFile={setProfileImgFile}
                                                            mediaType="image"
                                                        />
                                                        <button
                                                            className="btn-primary btn-blue h-[32px]"
                                                            onClick={() => setAwaitNewImage(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="w-[140px] h-[140px] relative rounded-full">
                                                            {previewImgUrl ? (
                                                                <img
                                                                    src={previewImgUrl}
                                                                    className="absolute top-0 left-0 w-full h-full object-cover
                                                                rounded-full flex items-center justify-center"
                                                                    alt="avatar preview"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`https://pyp-avatar-${
                                                                        process.env
                                                                            .REACT_APP_USER_ENVIRONMENT
                                                                    }.s3.amazonaws.com/${
                                                                        user?.user_id
                                                                    }`}
                                                                    className="absolute top-0 left-0 w-full h-full object-cover rounded-full flex items-center justify-center"
                                                                    alt="avatar"
                                                                />
                                                            )}
                                                        </div>

                                                        <div className="flex items-center gap-[10px]">
                                                            <button
                                                                className="btn-primary btn-secondary h-[32px] bg-white border border-grayCloud"
                                                                onClick={() => setAwaitNewImage(true)}
                                                            >
                                                                Upload New Image
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                <div className="flex flex-col md:flex-row w-full md:justify-start md:gap-[129px] items-center mb-[40px]">
                                                    <DragDropArea
                                                        styling={`rounded-full w-[140px] h-[140px]`}
                                                        mediaFile={profileImgFile}
                                                        setMediaFile={setProfileImgFile}
                                                        mediaType="image"
                                                    />
                                                </div>
                                            </>
                                        )}   
                                        <div className="flex flex-col gap-[16px]">
                                            <div className="default-input w-full">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    className="w-full"
                                                    onChange={handlePersonalizeYourPageChange}
                                                    value={personalizeYourPageValues.name}
                                                />
                                                <div className="overlay">Name</div>
                                            </div>
                                            <div className="default-input w-full">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    placeholder="Title"
                                                    className="w-full"
                                                    onChange={handlePersonalizeYourPageChange}
                                                    value={personalizeYourPageValues.title}
                                                />
                                                <div className="overlay">Title</div>
                                            </div>
                                            <div className="w-full">
                                                <textarea
                                                    cols={30}
                                                    rows={6}
                                                    name="description"
                                                    placeholder="Share the love for Zoee (1000 characters)"
                                                    className={`resize-none border-[1px] border-solid border-grayCloud
                                                    text-graySlate text-[16px] bg-white w-full
                                                    py-[16px] px-[20px] rounded-[10px] focus:outline-0
                                                    focus:border focus:border-solid focus:border-royalBlue
                                                    focus:text-black 
                                                `}
                                                    onChange={handlePersonalizeYourPageChange}
                                                    value={personalizeYourPageValues.description}
                                                />
                                            </div>
                                        </div> 
                                    </div>
                                        
                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
                                        <Button
                                            onClick={handlePreviewSectionClick}
                                            variant="secondary"
                                        >
                                            Preview Section
                                        </Button>
                                        <Button
                                            onClick={handlePreviewPageClick}
                                            variant="secondary"
                                        >
                                            View Page
                                            <SvgNewWindow />
                                        </Button>
                                        <Button
                                            onClick={savePersonalizeYourPageDetails}
                                            // disabled={!hasUnsavedDetails}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="max-w-[824px] px-[12px] flex flex-col w-full">
                    <div className="flex flex-col gap-[12px] items-center">
                        <div className="flex flex-col main-shadow rounded-[16px] bg-white w-full px-[24px] pt-[24px] pb-[32px] md:pb-[42px]">
                            <h3 className="font-semibold md:text-[32px] text-[28px] mr-auto mb-[24px]">
                                Success Kit
                            </h3>
                            <div className="flex flex-col md:flex-row md:gap-[56px] gap-[24px] items-center">
                                <img
                                    src={successKit}
                                    alt="referral program success kit"
                                    className="md:ml-[9px] w-[216px] h-[293px] object-fill card-hover-shadow"
                                />
                                <div className="flex flex-col gap-[20px]">
                                    <h3 className="font-bold text-[22px]">Let us help you.</h3>
                                    <p className="text-graySlate text-[16px] max-w-[410px]">
                                        The Ultimate Guide to Marketing Zoee Referrals! This kit
                                        will give you everything you need to create successful and
                                        effective referral marketing campaigns.
                                    </p>
                                    <Button
                                        onClick={downloadSuccessKit}
                                    >
                                        Download Success Kit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showInviteModal && (
                <ContactInvite
                    setShowInviteModal={setShowInviteModal}
                    invites={invites}
                    setInvites={setInvites}
                    setShowConfirmInviteModal={setShowConfirmInviteModal}
                    isReferralInviteVariant={true}
                />
            )}

            {showConfirmInviteModal && (
                <ConfirmReferralInvite
                    setShowInviteModal={setShowInviteModal}
                    setShowConfirmInviteModal={setShowConfirmInviteModal}
                    invites={invites}
                    setInvites={setInvites}
                    getReferrals={getReferrals}
                />
            )}

            {showPypPreview && (
                <AnimatePresence>
                    <PersonalizeYourPagePreview
                        personalizeYourPageValues={personalizeYourPageValues}
                        hasPypImage={hasPypImage}
                        imageId={imageId}
                        setShowPypPreview={setShowPypPreview}
                    />
                </AnimatePresence>
            )}
        </>
    );
};

export default ReferACoach;
