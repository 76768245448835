import React from 'react';
import Select from "react-select";
import { AvailabilityRulesData } from "../../types/availabilityTypes";
import { defaultAvailabilityOption } from "../../objects/availabilityObjects";

interface Props {
  availabilities: AvailabilityRulesData[];
  handleAvailabilityRulesChange: (selectedOption: AvailabilityRulesData) => void;
  serviceType: string;
  service: any;
}

const AvailabilityRulesSelect: React.FC<Props> = ({availabilities, serviceType, service, handleAvailabilityRulesChange}) => {
    const [availabilityOptions, setAvailabilityOptions] = React.useState(defaultAvailabilityOption);
    const [activeAvailability, setActiveAvailability] = React.useState<AvailabilityRulesData | null>(null);

    React.useEffect(() => {
      const defaultAvailability = availabilities?.find((a) => a?.is_default);
      const existingServiceAvailability = availabilities?.find((a) => service?.availability_rule_id === a.id);
      const options = availabilities?.map((a) => ({ value: a.name, label: a.name }));
      const currentAvailability = existingServiceAvailability || defaultAvailability || availabilities[0]

      setAvailabilityOptions(options || defaultAvailabilityOption);
      setActiveAvailability(currentAvailability);
      handleAvailabilityRulesChange(currentAvailability)
    }, [availabilities, service?.availability_rule_id, service?.group, serviceType]);
     
    const handleAvailabilityOptionSelect = (e: any) => {
      const selectedOption = availabilities.find((a) => a.name === e.value);
      if (selectedOption) {
        setActiveAvailability(selectedOption);
        handleAvailabilityRulesChange(selectedOption);
      }
    };
    
    return (
      <div className="flex flex-col">
        <h4 className="font-bold text-base mb-[8px]">Availability</h4>
        <Select
          className="basic-single w-full"
          classNamePrefix="select"
          value={availabilityOptions.filter((a) => { return a.value === activeAvailability?.name })}
          options={availabilityOptions}
          name="availability"
          onChange={handleAvailabilityOptionSelect}
          isDisabled={false}
        />
      </div>
    );
}

export default AvailabilityRulesSelect;