import * as React from "react";
import PricingToggleSquare from "../../../components/marketing/pricing/PricingToggleSquare";
import FAQ from "../../../components/marketing/pricing/FAQ";
import { useLocation } from "react-router-dom";

const PricingPage: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === "#faq") {
      window.scrollTo({
        top: 650,
        left: 0,
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [location.hash]);

  return (
    <div className="relative mx-auto mt-[88px] max-w-screen-2xl overflow-hidden">
      <div
        id="pricing"
        className="bg-grayFlash flex max-h-[910px] min-h-[904px] flex-col items-center px-[48px] pt-[36px] md:min-h-[800px] md:flex-row md:items-start md:justify-around md:pt-[86px]"
      >
        <h2 className="mb-[96px] text-[58px] font-bold leading-[103%] md:mb-0 md:text-[64px] xl:text-[88px]">
          <div className="text-blurple">
            UNLIMITED<span className="text-black">:</span>
          </div>
          <div className="flex translate-y-[8px] flex-col xl:translate-y-[12px]">
            <div className="">CLIENTS,</div>
            <div className="">SESSIONS,</div>
            <div className="">PROGRAMS,</div>
            <div className="">INCOME.</div>
          </div>
        </h2>
        <div className="xl:pt-[40px]">
          <PricingToggleSquare />
        </div>
      </div>
      <div className="mx-[24px] flex -translate-y-[120px] flex-col items-center rounded-t-xl bg-white px-[12px] pb-[28px] pt-[42px] text-center sm:mx-[80px] sm:px-[40px] md:mx-[60px] md:-translate-y-[220px] md:px-[40px] lg:mx-[120px] lg:px-[100px] lg:pt-[80px] xl:-translate-y-[180px]">
        <h3 className="mb-[24px] max-w-[666px] text-[48px] font-bold leading-[104%]">
          Frequently Asked Questions
        </h3>
        <h4 className="text-grayCharcoal mb-[24px] text-[36px] font-bold leading-[38px]">
          Got questions? We&apos;ve got answers.
        </h4>
        <p className="max-w-[486px]">
          Not seeing your question here, or need further assistance? The Zoee
          team would love to help! Email us at{" "}
          <a
            href="mailto:hello@zoee.com"
            className="hover:text-blurple font-bold transition-colors"
          >
            hello@zoee.com.
          </a>
        </p>
      </div>
      <div id="faq" className="translate-y-[-80px] md:translate-y-[-120px]">
        <FAQ />
      </div>
    </div>
  );
};

export default PricingPage;
