import React from "react";
import SubscriptionTag from "./SubscriptionTag";
import { SvgCheck } from "../icons";

type Props = {};

const TrialSubscriptionCard: React.FC<Props> = () => {    
  return (
    <div className="flex md:flex-col items-center md:items-start justify-between">
      <div className="md:hidden">
        <SubscriptionTag subscriptionName="trial" />
      </div>
      <h2 className="hidden md:block mb-[8px] font-semibold text-[28px]">
        Free Trial
      </h2>
      <p className="hidden md:block mb-[40px] text-graySlate">
        For users who want to do more
      </p>
      <div className="md:mb-[80px] flex font-bold md:items-start items-center gap-[4px] leading-none">
        <h4 className="text-[36px] text-grayCharcoal -translate-y-[6px] md:translate-y-0">
          $
        </h4>
        <h4 className="md:text-[80px] text-[64px] md:-translate-y-[10px]">0</h4>
        <h4 className="md:text-[18px] text-base text-graySlate -translate-y-[10px] md:translate-y-0">
          /month
        </h4>
      </div>
      <div className="hidden md:flex flex-col gap-[12px]">
        <div className="flex items-center gap-[16px]">
          <SvgCheck stroke="#00D084" />
          <p className="text-base font-bold">Zoee 14-day trial</p>
        </div>
      </div>
    </div>
  );
};

export default TrialSubscriptionCard;