import React from "react";
import { Link } from "react-router-dom";
import { UserCtx } from "../../context/userContext";
import CloseModal from "../CloseModal";
import { SvgSyncNeeded } from "../icons";

interface Props {
    setSyncPrompt: any;
    service: any;
    connectAccountData: any;
}

const SyncPrompt: React.FC<Props> = ({ setSyncPrompt, connectAccountData, service }) => {
    const { user } = React.useContext(UserCtx);
    const closeAndReset = () => {
        
        setSyncPrompt(false);
    };
    const syncBankAccountHeaderText = `Before you can enable a Service, please sync your bank account`
    const verifyIdentityHeaderText = `To enable a Free Service, please verify your identity`
    const syncBankAccountBodyText = `You can save a Service as a draft until your bank account setup is complete`
    const verifyIdentityBodyText = `We take the safety of our coaches and clients very seriously. We use Stripe to verify your identity to prevent fraud, streamline risk operations, and increase trust and safety.`
    const shouldVerifyIdentity = connectAccountData.identity_status === 'incomplete' && service.isFree

    return (
        <div className="fixed top-0 left-0 w-screen h-screen pb-[100px] md:pb-0 zoee-backdrop-filter z-[801] flex items-center justify-center">
            <div
                className="mx-[20px] md:w-[600px] w-full py-[48px] px-[28px] md:px-[72px] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative"
            >
                <CloseModal callback={closeAndReset} styling="absolute top-[24px] right-[24px]" />
                <SvgSyncNeeded className="mb-[16px] md:mb-[32px]" />
                <h3 className="font-bold md:font-semibold text-[22px] md:text-[28px] mb-[12px] mx-auto text-center">
                  {shouldVerifyIdentity ? verifyIdentityHeaderText : syncBankAccountHeaderText}
                </h3>
                <p className="text-graySlate text-[16px] mb-[16px] md:mb-[32px] text-center mx-auto">
                {shouldVerifyIdentity ? verifyIdentityBodyText : syncBankAccountBodyText}
                </p>
                <Link to={`/${user.activeProfile}/client-payments`} className="btn-primary btn-secondary bg-grayMist mb-[32px] min-w-[260px] md:min-w-0">
                  { shouldVerifyIdentity ?  `Verify Your Identity` : `Sync Bank Account`}
                </Link>
            </div>
        </div>
    );
};

export default SyncPrompt;
