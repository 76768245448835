import React from "react";
import Draggable from "react-draggable";
import arrow from "../../assets/images/white-back-arrow.webp";
import { Link } from "react-router-dom";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import {
  useVideo,
  selectPeers,
  useHMSStore,
  selectDominantSpeaker,
  selectIsPeerVideoEnabled,
  selectIsPeerAudioEnabled,
  selectIsConnectedToRoom,
  selectPermissions,
  useHMSActions,
  useAVToggle,
  selectPeerScreenSharing,
  selectIsLocalScreenShared,
  selectIsSomeoneScreenSharing,
  selectScreenShareByPeerID,
} from "@100mslive/react-sdk";
import useLoading from "../../hooks/useLoading";
import {
  SvgDisconnect,
  SvgMic,
  SvgMicOff,
  SvgShareScreen,
  SvgVideoOffSolid,
  SvgVideoSolid,
} from "../icons";

const SessionDraggable: React.FC = () => {
  const { user } = React.useContext(UserCtx);
  const { setPopupNotification, hideAlert, renderError } =
    React.useContext(CommonFunctionCtx);
  const { startLoading } = useLoading();
  const peers = useHMSStore(selectPeers);
  const [currentSpeaker, setCurrentSpeaker] = React.useState<any>(null);
  const dominantSpeaker = useHMSStore(selectDominantSpeaker);
  const videoIsOn = useHMSStore(selectIsPeerVideoEnabled(currentSpeaker?.id));
  const audioIsOn = useHMSStore(selectIsPeerAudioEnabled(currentSpeaker?.id));
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const permissions = useHMSStore(selectPermissions);
  const presenter = useHMSStore(selectPeerScreenSharing);
  const amIScreenSharing = useHMSStore(selectIsLocalScreenShared);
  const screenshareOn = useHMSStore(selectIsSomeoneScreenSharing);
  const hmsActions = useHMSActions();
  const screenshareVideoTrack = useHMSStore(
    selectScreenShareByPeerID(presenter?.id)
  );
  const [trackId, setTrackId] = React.useState<any>(null);
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  const { videoRef } = useVideo({
    trackId: trackId,
  });

  const waitAndRedirectToSessions = () => {
    setPopupNotification({
      show: false,
      callback: null,
      title: "",
      message: "",
    });
    startLoading();
    setTimeout(() => {
      window.location.href = `/${user.activeProfile}/sessions`;
    }, 2500);
  };

  const leaveMeeting = async () => {
    startLoading();
    if (isConnected) {
      if (permissions?.endRoom) {
        const lock = true;
        const reason = "Host ended the session";
        await hmsActions
          .endRoom(lock, reason)
          .then(() => {
            setPopupNotification({
              show: true,
              callback: waitAndRedirectToSessions,
              title: "You ended the session",
              message:
                "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await hmsActions
          .leave()
          .then(() => {
            setPopupNotification({
              show: true,
              callback: waitAndRedirectToSessions,
              title: "You left the session",
              message:
                "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const toggleScreenShare = async () => {
    if (amIScreenSharing) {
      await hmsActions
        .setScreenShareEnabled(false, {
          videoOnly: true,
        })
        .then(() => {
          console.log("disabled screenshare");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (screenshareOn) {
        renderError(`${presenter?.name || "Someone"} is already sharing.`);
        setTimeout(() => {
          hideAlert();
        }, 3000);
      } else {
        await hmsActions
          .setScreenShareEnabled(true, {
            videoOnly: true,
          })
          .then(() => {
            console.log("enabled screenshare");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  React.useEffect(() => {
    if (dominantSpeaker) {
      setCurrentSpeaker(dominantSpeaker);
    } else {
      const peerIds = peers.map((peer) => peer.id);
      if (currentSpeaker && !peerIds.includes(currentSpeaker?.id)) {
        setCurrentSpeaker(peers.filter((peer) => !peer.isLocal)[0]);
      }
    }
  }, [dominantSpeaker]);

  React.useEffect(() => {
    const speaker =
      peers.length < 2
        ? peers.filter((peer) => peer.isLocal)[0]
        : peers.filter((peer) => !peer.isLocal)[0];
    setCurrentSpeaker(speaker);
  }, [peers]);

  React.useEffect(() => {
    console.log(screenshareOn);

    setTrackId(
      screenshareOn ? screenshareVideoTrack.id : currentSpeaker?.videoTrack
    );
  }, [screenshareOn, currentSpeaker, screenshareVideoTrack]);

  return (
    <>
      <Draggable>
        <div
          className="fixed bottom-[100px] right-[100px] flex flex-col py-[20px] px-[10px] items-center justify-between
                    main-shadow bg-white rounded-[16px] w-[320px] z-[2999] gap-[12px] cursor-pointer"
        >
          <h5 className="font-bold">Your session is still in progress</h5>
          {amIScreenSharing && (
            <p className="text-graySlate">You are sharing your screen</p>
          )}
          {!amIScreenSharing && screenshareOn && (
            <p className="text-graySlate">
              {presenter?.name + " is sharing their screen"}
            </p>
          )}
          <div
            className={`bg-black rounded-[10px] w-[300px] h-[168.75px] relative`}
          >
            {videoIsOn || screenshareOn ? (
              <>
                <video
                  className="rounded-[10px] w-full h-full"
                  ref={videoRef}
                  autoPlay
                  muted
                  playsInline
                />
                <div
                  className="absolute bottom-[4px] left-[4px] bg-black/[0.64]
                                    text-white px-[4px] py-[2px] rounded-[6px] font-bold bg-blur-md"
                >
                  {currentSpeaker?.name}
                </div>
              </>
            ) : (
              <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white">
                {currentSpeaker?.name}
              </div>
            )}
            {!audioIsOn && (
              <div className="absolute bottom-[4px] right-[4px] bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full">
                <SvgMicOff
                  className="w-[16px] h-[16px]"
                  fill="#CF2E2E"
                />
              </div>
            )}
          </div>
          <div className="flex items-center justify-center w-full gap-[4px]">
            <button
              className="bg-primaryBlue rounded-full w-[40px] h-[40px] flex items-center justify-center"
              onClick={toggleAudio}
            >
              {isLocalAudioEnabled ? (
                <SvgMic fill="white" />
              ) : (
                <SvgMicOff fill="white" />
              )}
            </button>
            <button
              className="bg-primaryBlue rounded-full w-[40px] h-[40px] flex items-center justify-center"
              onClick={toggleVideo}
            >
              {isLocalVideoEnabled ? (
                <SvgVideoSolid fill="white" />
              ) : (
                <SvgVideoOffSolid fill="white" />
              )}
            </button>
            <button
              className="bg-primaryBlue rounded-full w-[40px] h-[40px] flex items-center justify-center"
              onClick={toggleScreenShare}
            >
              <SvgShareScreen
                fill="white"
              />
            </button>
            <Link
              to={`/${user.activeProfile}/sessions`}
              className="w-[40px] h-[40px] rounded-full flex items-center justify-center bg-primaryBlue"
            >
              <img
                src={arrow}
                alt="back to sessions"
                className="w-[50%] h-[60%]"
              />
            </Link>
            <button
              className="bg-vividRed rounded-full w-[40px] h-[40px] flex items-center justify-center"
              onClick={leaveMeeting}
            >
              <SvgDisconnect fill="white" />
            </button>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default SessionDraggable;
