// import React from "react"
// import PrivateProfileBlogPost from "./PrivateProfileBlogPost"
// import charcoalPlus from "../../../assets/images/charcoal-plus.svg"
// import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext"
// import { ProfileRequests } from "../../../api/app.service"
// import { UserCtx } from "../../../context/userContext"
// import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
// import Loader from "../../ui/loader"
// import { Icons } from "../../Icons"
// import { Button } from "../../ui/button"

import React, { useState } from "react"
import { UserCtx } from "../../../context/userContext"
import { ProfileRequests } from "../../../api/app.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { Link } from "react-router-dom"
import { Button } from "../../ui/button"
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext"
import CloseModal from "../../CloseModal"
// import { Icons } from "../../Icons"
import Loader from "../../ui/loader"
import { getLightTextColor } from "../../../utils/getLightTextColor"
import { SvgPlus } from "../../icons"

interface Props {
  selectedBlogPostId: number | undefined
}

const PrivateBentoBlogCard: React.FC<Props> = ({ selectedBlogPostId }: Props) => {
  const { user } = React.useContext(UserCtx)
  const [loadingPosts, setLoadingPosts] = React.useState<boolean>(false)
  const [firstPost, setFirstPost] = React.useState<any | null>(null)
  const [blogs, setBlogs] = React.useState<any[] | null>(null)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { coachPublicProfileData, setCreateBlogPost } = React.useContext(
    CoachPrivateProfileContext
  )
  const [editBlogOpen, setEditBlogOpen] = React.useState<boolean>(false)
  const [selectBlogOpen, setSelectBlogOpen] = React.useState<boolean>(false)
  const [selectedBlog, setSelectedBlog] = React.useState<any | null>(null)
  const [showEditButtonHover, setShowEditButtonHover] = useState(false)
  const [isEditable, setIsEditable] = React.useState<boolean>(false)
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor
  )

  const getCoachBlogPosts = async () => {
    if (user) {
      await ProfileRequests.getCoachBlogPosts({
        coach_profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          const allBlogs = data.blog_posts
          let firstPost = data.blog_posts[0]
          const indexOfBlogPostId = allBlogs.findIndex(
            (blog: any) => blog.id === selectedBlogPostId
          )
          if (indexOfBlogPostId !== -1) {
            firstPost = allBlogs[indexOfBlogPostId]
          }
          if (allBlogs.length === 0) {
            setIsEditable(true)
          }
          setFirstPost(firstPost)
          setBlogs(allBlogs)
          setLoadingPosts(false)
        })
        .catch((ex) => {
          renderError("An error occured while fetching blog posts")
          console.log(ex)
        })
    }
  }

  const renderEditButton = () => {
    if (isEditable && showEditButtonHover) {
      return (
        <Button
          onClick={() => {
            setCreateBlogPost(true)
          }}
          variant={"utility"}
          size={"lg"}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-[4px] px-[20px] bg-white z-3 border-[1px] shadow-md opacity-100"
        >
          <SvgPlus />
          Add
        </Button>
      )
    }
    return null
  }

  const readTime = (post: any) => {
    const fullString = post.content
      .flatMap(({ type, children }: any) => {
        if (type !== "bulleted-list" && type !== "numbered-list") {
          return children.flatMap((item: any) => item.text)
        }

        return children.flatMap((listItem: any) =>
          listItem.children.map((thing: any) => thing.text)
        )
      })
      .join(" ")

    const wpm = 225
    const words = fullString?.trim().split(/\s+/).length
    const time = Math.ceil(words / wpm)
    return time
  }

  React.useEffect(() => {
    getCoachBlogPosts()
  }, [coachPublicProfileData])
  return (
    <>
      <div className="h-full">
        {loadingPosts ? (
          <Loader />
        ) : firstPost ? (
          <Link
            to={`/find-a-coach/${coachPublicProfileData.endpoint_slug}/blog/${firstPost.id}`}
          >
            <div
              className={`h-[100%] rounded-[20px] flex flex-col p-2 justify-between transform transition-transform hover:scale-102${isEditable ? "" : ""}`}
              style={{
                backgroundImage: `url(https://blog-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${firstPost?.id})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="w-full text-center p-2 mb-10 flex justify-between items-center">
                <span className="font-bold bg-white bg-opacity-80 rounded-[15px] p-1 px-4">
                  Blog
                </span>
              </div>
              <div className="flex flex-col gap-[12px] text-left bg-white/[0.8] p-[12px] rounded-[15px] min-w-[95%]">
                <h2 className="font-bold text-[16px] max-w-[208px] text-black line-clamp-2">
                  {firstPost?.title}
                </h2>
                <div className="flex items-center gap-[8px] font-bold text-base">
                  <span className="text-black">
                    {readTime(firstPost)} min read
                  </span>
                </div>
              </div>
              {isEditable && renderEditButton()}
            </div>
          </Link>
        ) : isEditable ? (
          <div
            className={`h-[100%] rounded-[20px] flex flex-col p-2 relative ${isEditable ? "" : ""
              }`}
            onMouseEnter={() => setShowEditButtonHover(true)}
            onMouseLeave={() => setShowEditButtonHover(false)}
          >
            <div className="flex justify-center items-center h-full w-full text-center">
              <p className="text-graySlate text-md font-bold max-w-[65%]">
                Add a blog to get started
              </p>
            </div>
            {renderEditButton()}
          </div>
        ) : (
          <div
            className="h-full rounded-[20px]"
            style={{ backgroundColor: textColor }}
          ></div>
        )}
      </div>
    </>
  )
}

export default PrivateBentoBlogCard
