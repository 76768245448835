import * as React from "react";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";
import { Input } from "../../components/ui/input";
import { Button, buttonVariants } from "../../components/ui/button";
import { SvgGrid, SvgList, SvgUserPlus } from "../../components/icons";
import { UserCtx } from "../../context/userContext";
import useLoading from "../../hooks/useLoading";
import { ContactRequests } from "../../api/app.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";

type View = "list" | "grid";

type ContextType = { contacts: any[]; view: View };

export const ContactsLayout: React.FC = () => {
  const [search, setSearch] = React.useState<string>("");
  const [view, setView] = React.useState<View>("grid");
  const [contacts, setContacts] = React.useState<any[]>([]);

  const { user } = React.useContext(UserCtx);
  const { renderError } = React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();

  const filterContacts = contacts.filter((contact) => {
    return (
      contact?.contact_name?.toLowerCase().includes(search.toLowerCase()) ||
      contact?.email?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const [filteredContacts, setFilteredContacts] =
    React.useState<any[]>(filterContacts);

  const getContacts = () => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading();
      ContactRequests.getContactList({
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setContacts(data.contact_list);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  React.useEffect(() => {
    getContacts();
  }, []);

  return (
    <>
      <div className="border-grayMist mb-6 flex flex-col md:flex-row items-center border-b pb-6">
        <h1 className="flex-1 text-4xl font-bold">Contacts</h1>
        <div className="flex items-center gap-2 flex-wrap">
          <Button
            variant="ghost"
            size="icon"
            onClick={() =>
              setView((prev) => (prev === "list" ? "grid" : "list"))
            }
          >
            {view === "list" ? <SvgGrid /> : <SvgList />}
          </Button>
          <Input
            label="Search"
            type="search"
            placeholder="Search Contacts"
            className="w-64"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setFilteredContacts(filterContacts);
            }}
          />
          <Button>
            <SvgUserPlus />
            Add Contact
          </Button>
        </div>
      </div>

      <div className="mb-6 flex items-center gap-3 flex-wrap">
        <NavLink
          to=""
          end
          className={({ isActive }) =>
            buttonVariants({ variant: isActive ? "utility" : "ghost" })
          }
        >
          All Contacts
        </NavLink>
        <NavLink
          to="leads"
          className={({ isActive }) =>
            buttonVariants({ variant: isActive ? "utility" : "ghost" })
          }
        >
          Leads
        </NavLink>
        <NavLink
          to="prospects"
          className={({ isActive }) =>
            buttonVariants({ variant: isActive ? "utility" : "ghost" })
          }
        >
          Prospects
        </NavLink>
        <NavLink
          to="received-invites"
          className={({ isActive }) =>
            buttonVariants({ variant: isActive ? "utility" : "ghost" })
          }
        >
          Received Invites
        </NavLink>
        <NavLink
          to="sent-invites"
          className={({ isActive }) =>
            buttonVariants({ variant: isActive ? "utility" : "ghost" })
          }
        >
          Sent Invites
        </NavLink>
      </div>

      <Outlet context={{ contacts: filterContacts, view }} />
      <div className="h-12" />
    </>
  );
};

export function useContacts() {
  return useOutletContext<ContextType>();
}
