import { cn } from "../../../utils/cn";
import { SvgUsers } from "../../icons";
import { ContactCard } from "./ContactCard";
import { ContactListItem } from "./ContactListItem";

export const ListContacts: React.FC<{
  contacts: any[];
  view: "list" | "grid";
  emptyState: string;
}> = ({ contacts, view, emptyState }) => {
  if (contacts.length <= 0) {
    return (
      <div className="mx-auto flex h-96 w-full max-w-sm flex-col items-center justify-center gap-6 text-balance">
        <SvgUsers className="text-grayCloud h-12 w-12" />
        <p className="text-muted-foreground text-center">{emptyState}</p>
      </div>
    );
  }

  return (
    <div
      className={cn("", {
        "grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4":
          view === "grid",
        "flex flex-col divide-y": view === "list",
      })}
    >
      {contacts.map((contact) => (
        <>
          {view === "grid" ? (
            <ContactCard contact={contact} />
          ) : (
            <ContactListItem contact={contact} />
          )}
        </>
      ))}
    </div>
  );
};
