import React from "react";
import { EmailLogin } from "../../api/auth.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import useLoading from "../../hooks/useLoading";
import CloseModal from "../CloseModal";
import { SvgWarning } from "../icons";

interface Props {
  setShowForgotPasswordModal: any;
}

const ForgotPasswordModal: React.FC<Props> = (props: any) => {
  const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();

  const [email, setEmail] = React.useState<string>("");
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateForm = () => {
    const errors: any = {};
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    const match = email.match(mailformat);
    (!email || !match) && (errors.email = "Valid email is required");
    return errors;
  };

  React.useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      startLoading();
      setIsSubmit(false);
      EmailLogin.resetPasswordSendLink({ email: email })
        .then((data) => {
          if (data.message === "complete") {
            // show modal telling user to check their email
            stopLoading();
            window.location.href = "/home?reset=true";
          }
          stopLoading();
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          setTimeout(() => hideAlert(), 5000);
          stopLoading();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateForm());
    setIsSubmit(true);
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen zoee-backdrop-filter z-[801] flex items-center justify-center">
      <div
        className="py-[32px] px-[24px] w-[351px] sm:w-[400px] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative"
      >
        <CloseModal
          callback={() => props.setShowForgotPasswordModal(false)}
          styling="absolute top-[24px] right-[24px]"
        />
        <h2 className="text-[22px] font-bold mb-[24px]">Forgot Password?</h2>
        <p className="text-graySlate text-base text-center w-[260px] mb-[40px]">
          To reset your password, enter your login email and we`ll send you
          instructions
        </p>
        <form action="#" onSubmit={handleSubmit} className="w-full">
          <div className="mb-[16px] w-full">
            <div className="default-input w-full">
              <input
                type="text"
                placeholder="Email Address"
                className="w-full"
                onChange={handleChange}
              />
              <div className="overlay">Email Address</div>
            </div>
            {formErrors.email && (
              <div className="field-error">
                <SvgWarning />
                {formErrors.email}
              </div>
            )}
          </div>
          <input
            type="submit"
            className="btn-primary bg-blurple enabled:hover:brightness-110 mx-auto"
            value="Send Instructions"
          />
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
