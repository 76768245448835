import React from "react";

import { ClientPaymentsItem } from "../../types/clientPaymentTypes";
import ContactAvatar from "../cards/ContactAvatar";
import moment from "moment-timezone";
import { UserCtx } from "../../context/userContext";
import { Button } from "../ui/button";
import { SvgChevronDown } from "../icons";
import { cn } from "../../utils/cn";
import { Badge } from "../ui/badge";

type Props = {
  invoiceData: ClientPaymentsItem;
};

const InvoiceCard: React.FC<Props> = ({ invoiceData }) => {
  const { user } = React.useContext(UserCtx);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const grossAmount = invoiceData.stripe_amount_gross / 100;
  const netAmount = invoiceData.stripe_amount_net / 100;
  const internationalCharge = invoiceData?.stripe_international_fee_amount;
  const stripeTransactionFee = invoiceData?.stripe_transaction_fee_amount / 100;

  const zoeeMarketplaceFee = Math.max(
    invoiceData?.zoee_merchant_fee_amount / 100 - 0.029 * grossAmount,
    0,
  );
  const zoeeMarketplacePercentage = Math.max(
    (zoeeMarketplaceFee / grossAmount) * 100,
    0,
  );

  const merchantFee =
    invoiceData?.zoee_merchant_fee_amount / 100 - zoeeMarketplaceFee;
  const merchantPercentage = (merchantFee / grossAmount) * 100;

  const parsedTransactionDate = moment
    .utc(invoiceData.transaction_date)
    .tz(user.timezone.value)
    .toDate();

  const invoiceContactObj = {
    first_name: invoiceData.first_name,
    last_name: invoiceData.last_name,
    has_avatar: invoiceData.has_avatar,
    user_color: invoiceData.user_color,
    user_id: invoiceData.user_id,
  };

  const parseMonth = (monthNum: number): string => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return months[monthNum] || "";
  };

  const handleViewInvoiceClick = () => {
    window.open(invoiceData.invoice_url, "_blank");
  };

  const lineItems: { label: string; value: string; hightlight?: boolean }[] = [
    {
      label: "Client Revenue",
      value: `$${grossAmount.toFixed(2)}`,
    },
    {
      label: `Merchant Processing Fee (${merchantPercentage.toFixed(1)}%)`,
      value: `$${merchantFee.toFixed(2)}`,
    },
    {
      label: "Stripe Service Fee",
      value: `$${stripeTransactionFee.toFixed(2)}`,
    },
    {
      label: "International Surcharge",
      value: `$${internationalCharge.toFixed(2)}`,
    },
    {
      label: `Zoee Marketplace Fee (${zoeeMarketplacePercentage.toFixed(1)}%)`,
      value: `$${zoeeMarketplaceFee.toFixed(2)}`,
    },
    {
      label: "Total Payout",
      value: `$${netAmount.toFixed(2)}`,
      hightlight: true,
    },
  ];

  return (
    <div className="box-shadow-1 relative grid w-full grid-cols-12 items-center gap-2 rounded-lg bg-white px-6 py-4">
      <div className="text-muted-foreground col-span-2 flex w-fit flex-col items-center font-bold md:col-span-1">
        <p className="text-base">
          {parseMonth(parsedTransactionDate.getMonth())}
        </p>
        <p className="text-lg">
          {parsedTransactionDate.getDate() < 10 ? "0" : ""}
          {parsedTransactionDate.getDate()}
        </p>
      </div>
      <div className="col-span-10 flex items-center gap-[16px] md:col-span-4">
        <ContactAvatar
          contact={invoiceContactObj}
          width={40}
          height={40}
          bold={false}
        ></ContactAvatar>
        <p className="col-span-4 text-base font-bold">
          {invoiceData.first_name} {invoiceData.last_name}
        </p>
      </div>
      <p className="col-span-12 text-base font-bold md:col-span-3">
        {invoiceData.service_name}
      </p>
      <div className="col-span-6 md:col-span-2">
        <Badge
          className="capitalize"
          variant={
            invoiceData.stripe_type.toLowerCase() === "charge"
              ? "success"
              : invoiceData.stripe_type.toLowerCase() === "refund"
                ? "alert"
                : invoiceData.stripe_type.toLowerCase() === "failed"
                  ? "destructive"
                  : "secondary"
          }
        >
          {invoiceData.stripe_type}
        </Badge>
      </div>
      <p className="col-span-6 text-right font-bold md:col-span-2 md:text-left">
        ${netAmount.toFixed(2)}
      </p>
      <Button
        size="icon"
        variant="ghost"
        className="absolute right-6 top-5"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SvgChevronDown
          className={cn("transform transition-transform", {
            "-scale-y-100": isOpen,
          })}
        />
      </Button>
      {isOpen && (
        <>
          <div className="border-grayMist col-span-full mb-[20px] ml-auto mt-[32px] flex w-full gap-[12%] rounded-xl border px-[24px] py-[20px] text-base md:w-3/4">
            <div className="flex w-full flex-col gap-3">
              {lineItems.map(({ label, value, hightlight }) => (
                <div
                  className={cn(
                    "text-grayCharcoal flex items-center justify-between",
                    {
                      "text-lg font-bold text-black": hightlight,
                    },
                  )}
                >
                  <p className="whitespace-nowrap">{label}</p>
                  <div className="border-grayCloud mx-2 h-2 w-full -translate-y-1 transform border-b border-dashed"></div>
                  <p>{value}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-span-full mb-3 flex justify-end">
            <Button onClick={handleViewInvoiceClick}>View Invoice</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default InvoiceCard;
