import React from "react"
import InputMask from "react-input-mask"
import { Link } from "react-router-dom"
import { VerifyPhone, UserInfo } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import CloseModal from "../CloseModal"
import WomanOnPhone from "../../assets/images/woman-on-phone.webp"
import useLoading from "../../hooks/useLoading"

interface Props {
	phone: string
	setVerifyPhoneForm: any
	setShowMemberVerifyPhonePrompt: any
}

const MemberVerifyPhonePopup: React.FC<Props> = ({
	phone,
	setVerifyPhoneForm,
	setShowMemberVerifyPhonePrompt,
}) => {
	const [values, setValues] = React.useState<any>({
		confirmation_code: "",
	})
	const { user } = React.useContext(UserCtx)
	const { renderError, hideAlert, getUser } =
		React.useContext(CommonFunctionCtx)

  const { startLoading, stopLoading } = useLoading();

	const mask = "(999) 999-9999"

	const resendCode = () => {
		VerifyPhone.sendPhoneCode({ access_token: user.access_token })
			.then(() => {
				stopLoading()
				setVerifyPhoneForm(true)
			})
			.catch((ex) => {
				console.log(ex)
				renderError(ex.response.data.message)
				stopLoading()
				setTimeout(() => {
					hideAlert()
				}, 5000)
			})
		setTimeout(() => {
			hideAlert()
		}, 5000)
	}

	const handleChange = (e: React.ChangeEvent<EventTarget>) => {
		let target = e.target as HTMLInputElement
		setValues({ ...values, [target.name]: target.value })
	}

	const handleVerifyLater = () => {
		setShowMemberVerifyPhonePrompt(false)
	}

	const savePhoneNumber = () => {
		UserInfo.editPhoneNumber({
			user_id: parseInt(user.user_id),
			access_token: user.access_token,
			phone_number: values.phone_number,
		})
			.then(() => {
				getUser().then(() => {
					resendCode()
				})
			})
			.catch((ex) => {
				console.log(ex)
				renderError(ex.response.data.message)
				stopLoading()
				setTimeout(() => {
					hideAlert()
				}, 5000)
			})
	}

	return (
		<>
			{phone ? (
				<div className='fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-[10px] flex items-center mx-auto flex-col w-[335px] md:w-[600px] py-[30px] px-[24px] md:pb-[100px phone-confirm-popup-shadow z-[10000]'>
					<CloseModal
						styling='absolute top-[20px] right-[20px]'
						callback={setShowMemberVerifyPhonePrompt}
					/>
					<div className='flex-col md:flex-row absolute ml-[10px] mt-[4px] md:bottom-0 md:right-0'>
						<img
							className='invisible md:visible max-h-[250px]'
							src={WomanOnPhone}
							alt='woman using phone'
						/>
					</div>
					<div className='text-center text-[28px] text-black font-semibold mb-[10px] mt-[35px] md:mt-[20px] leading-[33.6px]'>
						Stay connected with your coach!
					</div>
					<div className='base2 text-center w-[100%] md:max-w-[500px] mb-[18px] px-[10px]'>
						Never miss out on important updates again! Enter your phone
						number to stay informed and up to date with convenient SMS
						reminders and notifications!
					</div>
          <div className="mb-[10px]">{`+1 (${phone.slice(1, 4)}) ${phone.slice(4, 7)}-${phone.slice(7)}`}</div>
					<Link
						className='my-[15px] items-center btn-primary font-[16px] btn-blue h-[48px]'
						to='/member'
						onClick={(e) => {
							e.preventDefault()
							startLoading()
							setShowMemberVerifyPhonePrompt(false)
							if (phone) {
								resendCode()
							} else {
								savePhoneNumber()
							}
						}}>
						Verify My Phone
					</Link>
					<div className='text-primaryBlue  items-center '>
						<button
							className='items-center font-bold mt-[12px] pb-[8px] hover:underline'
							onClick={handleVerifyLater}>
							Not Right Now
						</button>
          </div>
					<div className='text-primaryBlue  items-center '>
						<Link
							className='items-center font-bold mt-[12px] pb-[43px] hover:underline'
							to="/account-settings/personal-info">
							Edit Phone
						</Link>
					</div>
				</div>
			) : (
				<div className='fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-[10px] flex items-center mx-auto flex-col w-[335px] md:w-[600px] py-[30px] px-[24px] md:pb-[100px phone-confirm-popup-shadow z-[10000]'>
					<CloseModal
						styling='absolute top-[20px] right-[20px]'
						callback={setShowMemberVerifyPhonePrompt}
					/>
					<div className='flex-col md:flex-row absolute ml-[10px] mt-[4px] md:bottom-0 md:right-0'>
						<img
							className='invisible md:visible max-h-[250px]'
							src={WomanOnPhone}
							alt='woman using phone'
						/>
					</div>
					<div className='text-center text-[28px] text-black font-semibold mb-[10px] mt-[35px] md:mt-[20px] leading-[33.6px]'>
						Stay connected with your coach!
					</div>
					<div className='base2 text-center w-[100%] md:max-w-[500px] mb-[25px] px-[10px]'>
						Never miss out on important updates again! Enter your phone
						number to stay informed and up to date with convenient SMS
						reminders and notifications!
					</div>
					<div className='default-input items-center w-[225px] mb-[15px]'>
						<InputMask
							className='items-center'
							mask={mask}
							placeholder='Enter your mobile number'
							name='phone_number'
							onChange={handleChange}
							autoComplete='off'
							value={values.phone_number}
							data-cy='phoneInput'></InputMask>
					</div>

					<Link
						className='items-center btn-primary btn-blue h-[48px]'
						to='/member'
						onClick={(e) => {
							e.preventDefault()
							startLoading()
							setShowMemberVerifyPhonePrompt(false)
							if (phone) {
								resendCode()
							} else {
								savePhoneNumber()
							}
						}}>
						Verify My Phone
					</Link>
					<div className='text-primaryBlue  items-center '>
						<button
							className='items-center font-bold mt-[20px] hover:underline'
							onClick={handleVerifyLater}>
							Not Right Now
						</button>
					</div>
				</div>
			)}
		</>
	)
}

export default MemberVerifyPhonePopup
