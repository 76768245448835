import React from "react";
import { SvgPromoCode } from "../icons";

interface Props {
  addPromoCode: any
  appliedDiscount: any
  setAddPromoCode: any
  promoCode: string
  validatePromoCode: any
  handlePromoCodeChange: any
}

const PromoCodeInput: React.FC<Props> = ({
  addPromoCode,
  appliedDiscount,
  setAddPromoCode,
  promoCode,
  validatePromoCode,
  handlePromoCodeChange
}) => {
  return (
    <>
      <div className="relative">
        <div className="flex items-center w-full justify-between mb-[16px]">
          <h5 className={`text-base text-graySlate`}>
            SUMMARY
          </h5>   
            <>
              {addPromoCode ? (
                <button
                  onClick={() => setAddPromoCode(() => !addPromoCode)}
                  className="text-primaryBlue font-bold text-base"
                >
                  Cancel
                </button>
                ) : (
                <button
                  onClick={() => setAddPromoCode(() => !addPromoCode)}
                  className="flex items-center gap-[8px] text-blurple font-bold text-base"
                >
                 <SvgPromoCode />
                 Add Promocode
                </button>
              )}
            </>
        </div>
        {addPromoCode && (
          <div className="mb-[16px] flex gap-[16px] items-center">
            <div className="default-input w-[222px]">
              <input
                type="text"
                placeholder="Enter Promocode"
                onChange={handlePromoCodeChange}
                value={promoCode}
              />
              <div className="overlay">Promocode</div>
            </div>
            <button
              className="btn-primary btn-blue w-[98px] h-[56px]"
              onClick={validatePromoCode}
            >
                    Save
                </button>
            </div>
        )}
        <div className="flex items-center mb-[8px]">
            <p className="text-base min-w-fit">
              {`Promotions ${appliedDiscount?.promoCode && `(${appliedDiscount.promoCode})`}`}
            </p>
            <div className="w-full h-[2px] mx-[24px] border-b border-dashed border-grayCloud" />
            <p className="text-base min-w-fit">
              (${appliedDiscount.amount})
            </p>
        </div>
    </div>
    </>)
}

export default PromoCodeInput;