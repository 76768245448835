import React from "react";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { SvgEdit, SvgPause, SvgPlay } from "../../icons";

interface Props {
  showHoverEffect: boolean;
}

const AvatarVideo: React.FC<Props> = ({ showHoverEffect }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [profileVideoPlaying, setProfileVideoPlaying] =
    React.useState<boolean>(false);
  const {
    setEditPrimaryProfileVideo,
    coachPublicProfileData,
    editprimaryProfileVideo,
  } = React.useContext(CoachPrivateProfileContext);

  const videoHandler = (control: any) => {
    if (control === "play") {
      videoRef?.current?.play();
      setProfileVideoPlaying(true);
    } else if (control === "pause") {
      videoRef?.current?.pause();
      setProfileVideoPlaying(false);
    }
  };

  const getVideoType = (extension: any) => {
    if (extension === "mov") {
      return "mp4";
    } else {
      return extension;
    }
  };

  return (
    <div className="w-full h-full rounded-[16px] flex items-center relative group">
      <video
        ref={videoRef}
        className="w-full h-full object-cover rounded-[16px]"
      >
        <source
          src={`https://public-profile-videos-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileData?.coach_public_profile_id}_main_${coachPublicProfileData?.main_video_id}.${coachPublicProfileData?.main_video_extension}`}
          type={`video/${getVideoType(
            coachPublicProfileData?.main_video_extension
          )}`}
        />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div
        className="absolute bottom-[8px] left-[50%] -translate-x-[50%] flex sm:hidden sm:group-hover:flex z-[20]
                items-center w-full sm:justify-evenly gap-[8px] justify-center sm:gap-0"
      >
        <button
          className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-gray"
          onClick={() => {
            if (profileVideoPlaying) {
              videoHandler("pause");
            } else {
              videoHandler("play");
            }
          }}
        >
          {profileVideoPlaying ? (
            <SvgPause className="w-[32px]" />
          ) : (
            <SvgPlay />
          )}
        </button>
        {!editprimaryProfileVideo && (
          <button
            onClick={() => setEditPrimaryProfileVideo(true)}
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-gray"
          >
            <SvgEdit />
          </button>
        )}
      </div>
    </div>
  );
};

export default AvatarVideo;
