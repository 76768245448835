import React, { useContext, useEffect, useState } from "react"
import { UserCtx } from "../../context/userContext"
import { NoteRequests } from "../../api/app.service"
import { Button } from "../../components/ui/button"
import NoteModal from "../../components/notes/NoteModal"
import CloseModal from "../../components/CloseModal"
import ContactAvatar from "../../components/contacts/ContactAvatar"
import Loader from "../../components/ui/loader"
import classes from "./CoachDashboard/CoachDashboardBento.module.css"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { SvgCheckbox, SvgCheckboxOff, SvgChevronDown, SvgPlus } from "../../components/icons/"

interface UserContact {
  contact_name: string
  profile_id: number
}

interface Props {
  userContacts: UserContact[]
}

// interface MatchingContact {
//   contact_name: string
//   profile_id: number
// }

const DashboardNotes: React.FC<Props> = ({ userContacts }) => {
  const [notes, setNotes] = React.useState<any>(null)
  const [selectedNote, setSelectedNote] = React.useState<any>(null)
  //   const { renderError } = useContext(CommonFunctionCtx)
  const { user } = useContext(UserCtx)
  const [selectedContact, setSelectedContact] = useState<UserContact | undefined>(undefined);
  const [selectedContactName, setSelectedContactName] = useState<any>(null);
  const [activeNoteId, setActiveNoteId] = React.useState<number | null>(null)
  const [showNoteModal, setShowNoteModal] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [matchingIds, setMatchingIds] = React.useState<any[]>([])
  const [isCreatingNewNote, setIsCreatingNewNote] =
    React.useState<boolean>(false)
  const [contactSelectModal, setContactSelectModal] =
    React.useState<boolean>(false)

  const fetchNotesForAllContacts = async () => {
    setLoading(true)
    try {
      const coachNotes = await NoteRequests.getCoachNotes({
          writer_profile_id: parseInt(user.activeProfileId),
      });
      setNotes(coachNotes.notes)
      setLoading(false)
    } catch (ex) {
      console.log(ex)
      // renderError(ex.response.data.message);
    }
  }

  const getNoteInfo = (note: any) => {
    const matchingContact = userContacts.find(
      (contact: any) => contact.profile_id === note.relates_to_profile_id
    )
    if (matchingContact) {
      return (
        <div 
        onClick={() => setSelectedContact(matchingContact)}
        className="w-full flex flex-row">
          <div className="w-1/6">
            <div>
              <ContactAvatar
                contact={matchingContact}
                width={40}
                height={40}
                border="border-none"
              />
            </div>
          </div>
          <div className="w-5/6 flex flex-col">
            <div className="flex justify-start w-full">
              <p className="w-full flex justify-start font-bold mb-1">
                {matchingContact.contact_name
                  .split(" ")
                  .map((part: any, index: number) =>
                    index === 1 ? `${part.charAt(0)}` : part
                  )
                  .join(" ")}{" "}
                : {note.title ? note.title.slice(0, 15) : ''}
              </p>
            </div>
            <div className="w-full mb-1">
              <p className="w-full flex justify-start line-clamp-1">
                {note.content && 
                note.content[0] && 
                note.content[0].children && 
                note.content[0].children[0] && 
                note.content[0].children[0].text
                  ? note.content[0].children[0].text.slice(0, 35)
                  : ''}
              </p>
            </div>
            <div className="flex justify-start w-full">
              <p className="text-xs text-graySlate w-full flex justify-start">
                Created {note.created_at.slice(5, 7)}-
                {note.created_at.slice(8, 10)}-{note.created_at.slice(0, 4)}
              </p>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  const dropDownContact = () => {
    if (!notes) {
      console.error("Notes is null or undefined")
      return null
    }

    const uniqueProfileIds = Array.from(
      new Set(notes.map((note: any) => note.relates_to_profile_id))
    )
    const contactsWithNotes = userContacts.filter((contact) =>
      uniqueProfileIds.includes(contact.profile_id)
    )

    return contactsWithNotes.map((contact: any, index: number) => (
      <DropdownMenuItem key={index}>
        <Button
          variant={"ghost"}
          className="flex justify-start text-black"
          onClick={() => {
            setSelectedContactName(contact.contact_name.slice(0, 10))

            // Reorganize the notes array
            const selectedContactNotes = notes.filter(
              (note: any) => contact.profile_id === note.relates_to_profile_id
            )
            const otherNotes = notes.filter(
              (note: any) => contact.profile_id !== note.relates_to_profile_id
            )
            const reorderedNotes = [...selectedContactNotes, ...otherNotes]

            setNotes(reorderedNotes)
          }}
        >
          <p>{contact.contact_name}</p>
        </Button>
      </DropdownMenuItem>
    ))
  }

  useEffect(() => {
    if (userContacts.length > 0 && user) {
      fetchNotesForAllContacts()
    }
  }, [user, userContacts, showNoteModal])

  return loading ? (
    <div className="h-full w-full flex justify-center items-center">
      <Loader />
    </div>
  ) : (
    <>
      <div className="h-[538px] w-[400px]">
        <div className="flex flex-row w-full justify-between py-8 px-8">
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant={"outline"}
                  className="border border-gray text-gray pr-1 "
                >
                  {selectedContactName ? selectedContactName : "Filter by Name"}
                  <div className="border-r border-gray h-[95%] w-[1px] -mr-1"></div>
                  <SvgChevronDown />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuGroup>
                  <DropdownMenuItem>
                    <Button
                      onClick={() => {
                        setSelectedContactName("")
                        fetchNotesForAllContacts()}}
                      variant={"ghost"}
                      className="flex justify-start text-black"
                    >
                      None
                    </Button>
                  </DropdownMenuItem>
                  {dropDownContact()}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <Button
            onClick={() => {
              setContactSelectModal(true)
            }}
            className="flex"
          >
            New Note
            <SvgPlus />
          </Button>
        </div>
        <div className={`flex flex-col h-[420px] w-full overflow-y-auto pl-6 pr-4 ${classes.scrollBar}`}>

          {notes?.map((note: any, index: number) => (
            <button
              onClick={() => {
                setSelectedNote(note)
                setContactSelectModal(false)
                setIsCreatingNewNote(false)
                setShowNoteModal(true)
              }}
              key={index}
              className={`py-[14px] cursor-pointer border-b last:border-0 border-grayMist`}
            >
              {getNoteInfo(note)}
            </button>
          ))}
          
        </div>
      </div>
      {contactSelectModal ? (
        <>
          <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
            <div className="w-[450px] max-w-[90%] main-shadow rounded-[16px] pt-[10px] pb-[40px] mt-[10px] px-[16px] md:px-[55px] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto">
              <CloseModal
                callback={() => setContactSelectModal(false)}
                styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
              />
              <h2 className="font-bold mt-6">Create A New Note</h2>
              <div className="gap-4 h-[375px] overflow-y-auto w-full mt-12">
                {userContacts?.map((contact: any, index: number) => (
                  <div key={index} className="flex gap-4 mb-6">
                    <ContactAvatar
                      contact={contact}
                      width={40}
                      height={40}
                      border="border-none"
                    />
                    <div className="flex flex-col">
                      <p>{contact.contact_name.slice(0, 15)}</p>
                      <p>{contact.profile_type}</p>
                    </div>
                    <Button
                      onClick={() => {
                        setSelectedContact(contact)
                      }}
                      variant="link"
                      className="rounded-md ml-auto"
                    >
                      {selectedContact === contact ? (
                        <SvgCheckbox />
                      ) : (
                        <SvgCheckboxOff />
                      )}
                    </Button>
                  </div>
                ))}
              </div>
              {selectedContact ? (
                <Button
                  onClick={() => {
                    setContactSelectModal(false)
                    setIsCreatingNewNote(true)
                    setShowNoteModal(true)
                  }}
                  className="mt-6"
                  size="lg"
                >
                  Create Note
                </Button>
              ) : (
                <Button variant="ghost" disabled className="mt-6" size="lg">
                  Create Note
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {isCreatingNewNote && showNoteModal && (
        <NoteModal
          setActiveNoteId={setActiveNoteId}
          setShowNoteModal={setShowNoteModal}
          isNewNote={isCreatingNewNote}
          setIsNewNote={setIsCreatingNewNote}
          user={user}
          getMemberNotes={fetchNotesForAllContacts}
          contact={selectedContact}
        />
      )}
      {selectedNote && showNoteModal && (
        <NoteModal
          setActiveNoteId={setActiveNoteId}
          setShowNoteModal={setShowNoteModal}
          isNewNote={isCreatingNewNote}
          setIsNewNote={setIsCreatingNewNote}
          user={user}
          note={selectedNote}
          getMemberNotes={fetchNotesForAllContacts}
          contact={selectedContact}
        />
      )}
    </>
  )
}

export default DashboardNotes
