export const faqs = [
    {
        id: 1,
        title: "What is Zoee",
        content: (
            <div>
                <p>
                    Zoee is a gateway to connection through a powerful online experience. We take
                    coaches into the future of coaching with a fully integrated virtual office
                    including video chat, client management, communication tools (including live
                    chat), custom programs, and seamless scheduling and billing. We aren&apos;t
                    stopping there—keep an eye out for Zoee's upcoming features, including a
                    marketplace (where we&apos;ll help you grow your business), group coaching, and
                    more!
                </p>
            </div>
        ),
    },
    {
        id: 2,
        title: "Why should I choose Zoee?",
        content: (
            <div>
                <p>
                    Zoee is on a mission to transform the way we do life. There&apos;s a reason why
                    coaching is the second fastest growing profession in the world, according to
                    PwC—people are broken, mental health issues are on the rise, and we are more
                    stressed out, disconnected, and full of dis-ease than ever before. If the
                    pandemic taught us anything, it&apos;s that we&apos;re all aching for human
                    connection.
                </p>
                <div className="h-[16px]" />
                <p>
                    Our vision is to transform how we live by cultivating community during every
                    season of life. Though we once connected in person, our world has grown to
                    foster digital connections, allowing us to be together even when we&apos;re far
                    apart. Zoee provides just that: the opportunity to do business—and truly
                    connect—from anywhere, with anyone.
                </p>
                <div className="h-[16px]" />
                <p>
                    Yes, we are leading the coaching industry into a future oriented virtual office.
                    But we&apos;re also aiming to change lives on a global level. Through
                    transformative-based experiences, human connection, an inclusive community where
                    all are welcome, we know coaches are going to save the world. What&apos;s more,
                    our goal is to make coaches accessible to everyone. By growing the accessibility
                    of coaches that can ease the ache for human connection, there&apos;s no end to
                    the human transformation that can happen.
                </p>
            </div>
        ),
    },
    {
        id: 3,
        title: "What does a '14 day free trial' really mean?",
        content: (
            <div>
                <p>It means you can enjoy 100% of what Zoee has to offer—unlimited—for 14 days!</p>
            </div>
        ),
    },
    {
        id: 5,
        title: "What does my Zoee Business Membership include?",
        content: (
            <div>
                <p>
                    Unlimited access to everything the Zoee Business membership has to offer, no
                    upgrades needed. You&apos;ll get our one stop shop tech, and…
                </p>
                <div className="h-[16px]" />
                <ul className="list-disc list-inside">
                    <li>A network of coaches and community members</li>
                    <li>Member only events and perks</li>
                    <li>Zoee ambassador program (coming soon!)</li>
                </ul>
            </div>
        ),
    },
    {
        id: 6,
        title: "I already have sessions booked with my current clients. Can I switch those over to the Zoee platform?",
        content: (
            <div>
                <p>
                    You bet! With Zoee, you can sync your calendar (we interface with Google,
                    Outlook, Office 365, Apple iCalendar) and easily update scheduled sessions. You
                    can also invite your current clients to the Zoee platform and schedule new
                    sessions with them directly in your Zoee account. Easy peasy!
                </p>
            </div>
        ),
    },
    {
        id: 7,
        title: "Can I get help setting up my account?",
        content: (
            <div>
                <p>
                    Of course you can—our team is here to help every step of the way. Once you start
                    your free trial and create your account, you&apos;ll have the opportunity to do
                    a personal onboarding call with a member of the Zoee team. You can also email
                    <span className="font-bold">&nbsp;hello@zoee.com</span>, or chat with us to get
                    the help you&apos;re looking for.
                </p>
            </div>
        ),
    },
    {
        id: 9,
        title: "Is Zoee available to international subscribers?",
        content: (
            <div>
                <p>
                    We love our citizens of the world! At this time, Zoee is only available to
                    United States and Canada residents, with a U.S. currency based credit card
                    (American Express, Visa, Mastercard, Discover). We plan to expand our team of
                    coaches globally in the future. To join our our wait list, please email<span className="font-bold">&nbsp;hello@zoee.com</span>.
                </p>
            </div>
        ),
    },
    {
        id: 10,
        title: "I’m a new coach and currently don’t have any active clients. Can I still join Zoee to set up my coaching business?",
        content: (
            <div>
                <p>
                    We are so excited for you! At Zoee, all coaches are welcome, whether you have a
                    long list of loyal clients or are just getting your feet wet. In fact, our
                    Founder &amp; CEO is a certified coach, which means when you join Zoee
                    you&apos;ll get access to insider tips, access to webinars, and resources from
                    other coaches to help you get started. Before you know it, you&apos;ll be
                    kickin&apos; it with the best of &apos;em!
                </p>
            </div>
        ),
    },
    {
        id: 11,
        title: "Can I cancel at any time?",
        content: (
            <div>
                <p>
                    We'd hate to see you go, but understand that life happens. Yes, you can cancel
                    at any time. If you do, your current plan will last until the end of your
                    billing cycle.
                </p>
            </div>
        ),
    },
    {
        id: 12,
        title: "Tell me about your refund policy…",
        content: (
            <div>
                <p>
                    We want you to be 100% satisfied. You can always cancel your Zoee membership at
                    any time! If there are any extenuating circumstances, you may be entitled to a
                    full refund. Please contact the concierge at
                    <span className="font-bold">&nbsp;support@zoee.com</span>.
                </p>
            </div>
        ),
    },
    {
        id: 13,
        title: "How can I contact you?",
        content: (
            <div>
                <p>
                    We would love to answer any questions or inquiries. Please feel free to send us
                    an email at<span className="font-bold">&nbsp;support@zoee.com&nbsp;</span> to
                    get started.
                </p>
            </div>
        ),
    },
];
