import React from "react";
import { UserExists } from "../../api/auth.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { Button } from "../ui/button";
import { type View } from "./AuthModal";
import { SvgWarning, SvgZoeeLogo } from "../icons";

interface Props {
    setShowGetStarted: any;
    setEmailLoginObject: any;
    isModalVariant?: boolean;
    setModalLoading?: any;
    setView: React.Dispatch<React.SetStateAction<View>>
}

const LoginEmail: React.FC<Props> = ({ setView, setEmailLoginObject, setShowGetStarted, setModalLoading, isModalVariant }) => {
    const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
    const [values, setValues] = React.useState<any>({
        email: "",
    });
    const [formErrors, setFormErrors] = React.useState<any>({});
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);

    const handleChange = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        let target = e.target as HTMLInputElement;
        setValues({ ...values, [target.name]: target.value });
    };

    const validateForm = (values: any) => {
        const errors: any = {};
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        const match = values.email.match(mailformat);
        (!values.email || !match) && (errors.email = "Valid email is required");
        return errors;
    };

    React.useEffect(() => {
        if (isSubmit && Object.keys(formErrors).length === 0) {
            setModalLoading("Finding your account");
            hideAlert();
            UserExists.verifyUserExists({
                attributes_to_verify: {
                    email: values.email.toLowerCase(),
                },
            })
                .then((data) => {
                    if (data.user_found) {
                        setEmailLoginObject({
                            email: values.email,
                            first_name: data.first_name,
                        });
                        
                        setView("password");
                    } else {
                        setEmailLoginObject({ email: values.email });
                        setShowGetStarted(true);
                        setView("email")
                    }
                })
                .catch((ex) => {
                    renderError(ex.response.data);
                })
                .finally(() => {
                    setModalLoading(false);
                });
                setTimeout(() => hideAlert(), 5000);
        } else {
            setIsSubmit(false);
            setModalLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    const handleSubmit = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        setFormErrors(validateForm(values));
        setIsSubmit(true);
    };

    return (
        <div
            className={`flex items-center flex-col gap-6 justify-start ${
                isModalVariant ? "mt-[32px]" : "mt-[190px] pb-[200px]"
            }`}
        >
            <SvgZoeeLogo className="w-[121px]" />
            <div className="font-normal">Welcome to Zoee!</div>
            <form onSubmit={handleSubmit}>
                <div className="mb-[24px]">
                    <div className={`default-input ${formErrors?.email && "input-error"}`}>
                        <input
                            type="text"
                            placeholder="Enter your email to get started"
                            onChange={handleChange}
                            name="email"
                            autoComplete="off"
                            autoFocus={true}
                        />
                        <div className="overlay">Email</div>
                    </div>
                    {formErrors.email && (
                        <div className="field-error">
                            <SvgWarning />
                            {formErrors.email}
                        </div>
                    )}
                </div>
                <div className="flex justify-center">
                    <Button size="lg" type="submit">Continue</Button>
                </div>
            </form>
        </div>
    );
};

export default LoginEmail;
