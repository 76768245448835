import React from "react";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

interface Props {
  popupNotification: {
    message?: string;
    title: string;
    show: boolean;
    callback?: any;
  };
}

const PopupNotification: React.FC<Props> = ({ popupNotification }) => {
  const { setPopupNotification } = React.useContext(CommonFunctionCtx);
  const handleClosePopup = () => {
    setPopupNotification({
      ...popupNotification,
      show: false,
      title: "",
      message: "",
    });
    if (popupNotification?.callback) {
      popupNotification.callback();
    }
  };

  return (
    <Dialog open={popupNotification.show} onOpenChange={handleClosePopup}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl md:text-2xl text-center">
            {popupNotification.title}
          </DialogTitle>
        </DialogHeader>
        <p className="text-base md:text-[16px] text-graySlate text-center my-6">
          {popupNotification.message}
        </p>
        <DialogFooter className="sm:justify-center">
          <Button onClick={handleClosePopup}>OK</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PopupNotification;
