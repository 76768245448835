import React, { useEffect } from "react"
import moment from "moment"
import { UserCtx } from "../../../context/userContext"
import { NoteRequests } from "../../../api/app.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import DeleteDialogueModal from "../../DialogueModal"
import useLoading from "../../../hooks/useLoading"

interface Props {
	note: any
	setActiveNoteId: any
  	activeNoteId: number | null
	setIsNewNote: any
	setShowNoteModal: any
	setTriggerPopoverUpdate: any
	getMemberNotes: any
}

const NotePreview: React.FC<Props> = ({
	note,
	setActiveNoteId,
  	activeNoteId,
	setIsNewNote,
	setShowNoteModal,
	setTriggerPopoverUpdate,
	getMemberNotes,
}) => {
	const {
		renderError,
		renderSuccess,
		hideAlert,
	} = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading();
	const { user } = React.useContext(UserCtx)
	const { title, content, updated_at } = note
	const [showDialogueModal, setShowDialogueModal] =
		React.useState<boolean>(false)
    const [isActive, setIsActive] = React.useState<boolean>(false)
    const [borderStyle, setBorderStyle] = React.useState<string>(
        "border border-grayMist"
    );
  
// Click on card to open NoteModal for edit
  const handleClick = () => {
        window.scrollTo(0, 0);
        setIsActive(() => !isActive);
        if (note.id === activeNoteId) {
            setActiveNoteId(null);
            setShowNoteModal(false);
        } else {
            setActiveNoteId(note.id);
            setShowNoteModal(false)
            setTimeout(() => {
                setShowNoteModal(true);
            }, 1);
        }
        isActive
            ? setBorderStyle("border border-grayMist")
            : setBorderStyle("border-primaryBlue border-2");
    };

    useEffect(() => {
        if (note.id === activeNoteId) {
            setIsActive(true);
            setBorderStyle("border-primaryBlue border-2");
        } else {
            setIsActive(false);
            setBorderStyle("border border-grayMist");
        }
    }, [activeNoteId, note.id]);

	const parsedUpdatedAtTime = moment
		.utc(updated_at)
		.tz(user.timezone.value)
		.toDate()

	const handleConfirmDelete = () => {
		if (note) {
			startLoading()
			NoteRequests.deleteNote({ note_id: note?.id })
				.then(() => {
					renderSuccess("Deleted note!")
					setTimeout(() => {hideAlert()}, 3000)
					setActiveNoteId(null)
					setIsNewNote(false)
					setShowNoteModal(false)
					setTriggerPopoverUpdate((num: number) => num + 1)
					setShowDialogueModal(false)
					getMemberNotes()
          stopLoading();
				})
				.catch((ex) => {
					console.log(ex);
					renderError(ex.response.data.message);
          stopLoading();
					setTimeout(() => hideAlert(), 5000);
				});
		}
	}

	return (
		<>
			{showDialogueModal && (
				<DeleteDialogueModal
					setShowDialogueModal={setShowDialogueModal}
					confirmCallbackFn={handleConfirmDelete}
					confirmButtonText={"Delete Note"}
					header={"Caution! You are about to permanently delete this note."}
					helpText={"Are you sure you want to deleted this note?"}
					isWarningVariant={true}
				/>
			)}
			<div
				className={`cursor-pointer md:w-[196px] h-[176px] md:h-[300px] p-[15px] relative
            sm:py-[10px] sm:px-[16px] border border-white hover:border-primaryBlue rounded-[16px] bg-white shadow-custom`}
            onClick={handleClick}>
				<h3 className='mb-[8px] sm:mb-[2px] md:mb-[3px] font-bold text-[16px]'>
					{`${title.substring(0, 15)}...`}
				</h3>
				<p className='h-[72px] md:h-[192px] overflow-ellipsis overflow-hidden  break-normal md:break-all sm:break-all text-grayCharcoal mb-[6px]'>
					{content[0].children[0].text}
				</p>
				<div className='text-gray text-base'>
					{`Updated: ${moment(parsedUpdatedAtTime).format("M/D/YY h:mmA")}`}
				</div>
			</div>
		</>
	)
}

export default NotePreview
