import * as React from "react";
import { useContacts } from "./ContactsLayout";
import { ListContacts } from "../../components/contacts/v2/ListContacts";

export const Leads: React.FC = () => {
  const { contacts, view } = useContacts();
  const leads = contacts.filter((contact) =>
    ["lead", "zoee lead"].includes(contact.status),
  );

  return (
    <div>
      <ListContacts
        contacts={leads}
        view={view}
        emptyState="Looks like you don't have any Zoee leads yet."
      />
    </div>
  );
};
