import React from "react";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import CloseModal from "../CloseModal";
import { Button } from "../ui/button";
import { UserCtx } from "../../context/userContext";
import { ContactRequests } from "../../api/app.service";
import useLoading from "../../hooks/useLoading";

interface Props {
  setShowProspectModal: any;
  prospect: any;
  setProspect: any;
  getContacts: any;
  contacts: any;
}

const ContactAdd: React.FC<Props> = ({
  setShowProspectModal,
  prospect,
  setProspect,
  getContacts,
  contacts,
}) => {
  const { renderError, hideAlert } = React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const { user } = React.useContext(UserCtx);

  const validateEmail = (email: string) => {
    let error: string = "";
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    const valid = mailformat.test(email);
    if (!email || !valid) {
      error = "Valid email is required";
    }
    return { error, valid };
  };

  const saveProspect = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const existingContacts = contacts.map((contact: any) =>
      contact?.email?.toLowerCase(),
    );
    if (existingContacts.includes(prospect?.email?.toLowerCase())) {
      renderError("The email you entered is already in your contacts.");
      setTimeout(() => {
        hideAlert();
      }, 5000);
    } else {
      const { error, valid } = validateEmail(prospect.email);
      if (valid) {
        startLoading();
        ContactRequests.createProspect({
          coach_profile_id: parseInt(user.activeProfileId),
          first_name: prospect.firstName,
          last_name: prospect.lastName,
          email: prospect.email,
        })
          .then((data) => {
            setShowProspectModal(false);
            getContacts();
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
            setTimeout(() => hideAlert(), 5000);
          })
          .finally(() => stopLoading());
      } else {
        renderError("Please add at least one valid email address");
        setTimeout(() => {
          hideAlert();
        }, 5000);
      }
    }
  };

  const updateProspectArray = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = prospect;
    newState[e.target.name] = e.target.value;

    setProspect(newState);
  };

  const closeAndReset = () => {
    setProspect({ firstName: "", LastName: "", email: "" });
    setShowProspectModal(false);
  };

  const focusInput = () => {
    const input = document.getElementById("email0");
    input?.focus();
  };

  React.useEffect(() => {
    focusInput();
  }, []);

  return (
    <>
      <div className="zoee-backdrop-filter fixed left-0 top-0 z-[801] flex h-screen w-screen items-center justify-center pb-[220px] md:pb-[30px]">
        <div className="main-shadow relative flex w-[351px] flex-col items-center rounded-[16px] bg-white px-[24px] pb-[30px] pt-[22px] sm:w-auto sm:px-[48px] md:w-[551px] md:py-[32px]">
          <CloseModal
            callback={closeAndReset}
            styling="absolute top-[24px] right-[24px]"
          />
          <h2 className="mb-[24px] flex items-center gap-[8px] text-[18px] font-bold md:text-[22px]">
            Add Prospect
          </h2>
          <form onSubmit={saveProspect}>
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col gap-2 md:w-[404px]">
                <div className="flex flex-col items-center gap-2 sm:flex-col">
                  <div className="default-input mb-2 w-full">
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      onChange={(e) => updateProspectArray(e)}
                      id="firstName"
                      className="w-full"
                    />
                    <div className="overlay">First</div>
                  </div>
                  <div className="default-input mb-2 w-full">
                    <input
                      type="text"
                      placeholder="Last name"
                      name="lastName"
                      onChange={(e) => updateProspectArray(e)}
                      id="LastName"
                      className="w-full"
                    />
                    <div className="overlay">Last Name</div>
                  </div>
                  <div className="default-input mb-4 w-full">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={(e) => updateProspectArray(e)}
                      id="email"
                      className="w-full"
                    />
                    <div className="overlay">Email</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 flex flex-col items-center gap-2 sm:mt-4 md:flex-row">
              <Button className="mx-auto" size="lg">
                Add
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactAdd;
