import React, { useContext } from 'react'
import CloseModal from '../../CloseModal'
import { Button } from '../../ui/button'
import DragDropArea from '../../DragDropArea'
import uploadImage from "../../../assets/images/upload-image.svg"
import CharLimit from '../../ui/charLimit'
import { CoachPrivateProfileContext } from '../../../pages/manage-business/profile/context/coachPrivateProfileContext'
import useLoading from '../../../hooks/useLoading'
import { UserCtx } from '../../../context/userContext'
import { CommonFunctionCtx } from '../../../context/commonFunctionContext'
import { UserInfo } from '../../../api/app.service'
import { SvgCrop, SvgImage, SvgQuoteRight2, SvgTrash } from '../../icons'
import { SvgArrowLeftRight } from '../../icons/arrows/arrow-left-right'

interface Props {
  handleClick: any;
  bentoSelection: number;
  updateBento: (bentoSpot: number | null, selection: string, contentId?: number, content?: string, url?: string) => void;
}

const BentoImageFlow: React.FC<Props> = ({
  handleClick,
  updateBento,
  bentoSelection,

}) => {
  const [imageFile, setImageFile] = React.useState<any>(null)
  const [urlToImageFile, setUrlToImageFile] = React.useState<string>(`https://blog-images-development.s3.amazonaws.com/1000003`)
  const { startLoading, stopLoading } = useLoading()
  const { user } = useContext(UserCtx)
  const { getUser, renderSuccess, renderError, hideAlert } = useContext(CommonFunctionCtx)
  const { coachPublicProfileData } = useContext(CoachPrivateProfileContext)
  const [awaitNewImage, setAwaitNewImage] = React.useState<boolean>(false)
  const [quoteText, setQuoteText] = React.useState<string>(() => {
    if (bentoSelection === 3) {
      return coachPublicProfileData?.bento_box_three?.data?.quote
    } else if (bentoSelection === 4) {
      return coachPublicProfileData?.bento_box_four?.data?.quote
    } else if (bentoSelection === 5) {
      return coachPublicProfileData?.bento_box_five?.data?.quote
    }
  })

  const saveUploadedImage = (base64String: string, type: string) => {
    if (user) {
      startLoading()
      //skeleton -- lambda not created yet
      UserInfo.editUserAccountImage({
        user_id: parseInt(user.user_id),
        access_token: user.access_token,
        base_64_string: base64String,
        file_extension: type,
      })
        .then(() => {
          getUser().then(() => {
            renderSuccess("Succesfully uploaded image")
            setTimeout(() => hideAlert(), 5000)
            setImageFile(undefined)
            setAwaitNewImage(false)
          })
        })
        .catch((ex: any) => {
          console.error(ex)
          setImageFile(undefined)
          renderError("Error uploading image")
          stopLoading()
        })
    }
  }

  const deleteUploadedImage = () => {
    hideAlert()
    if (user) {
      startLoading()
      //skeleton -- lambda not created yet
      UserInfo.deleteUserAccountImage({ user_id: parseInt(user.user_id) })
        .then(() => {
          getUser().then(() => {
            renderSuccess("Successfully deleted image")
            setTimeout(() => hideAlert(), 5000)
          })
        })
        .catch((ex) => {
          console.log(ex)
          renderError("Error deleting image")
          stopLoading()
          setTimeout(() => hideAlert(), 5000)
        })
    }
  }

  const fileSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    console.log(e)
    if (e.target.files) {
      let selectedFile = e?.target?.files[0];
      if (selectedFile.type.split("/")[0] !== "image") {
        return renderError("Please provide an image file to upload!");
      }
      setImageFile(selectedFile);
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
        <div className="w-[775px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto">
          <CloseModal
            callback={() => {
              handleClick("cancel")
            }}
            styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          />
          <h2 className="text-center top-[10px] left-[10px] sm:left-[30px] sm:top-[30px] md:left-[50px] text-[16px] md:text-[28px] lg:text-[16px] sm:mt-0">
            Featured Content
          </h2>
          <div className="flex gap-x-2 justify-center items-center">
            <SvgQuoteRight2 height="40" width="40" />
            <p className="font-bold text-[20px] sm:mt-4 md:-translate-y-2">
              Quote
            </p>
          </div>
          <Button
            variant={"link"}
            onClick={() => {
              handleClick("switch content")
            }}
          >
            <SvgArrowLeftRight />
            Switch content type
          </Button>

          <div className="flex flex-col justify-center items-center mt-4 w-full">
            {/* <p className='w-full'><b>Choose Image</b> Optional</p>
            <p className="w-[70%] text-graySlate text-center mb-[8px] font-bold text-sm mt-8">
              Max file size is 5MB wtih 1:1 Ratio
              <br />
              File types: .png, .jpg, and .jpeg
            </p> */}
            {/* UNCOMMENT WHEN S3 UPLOAD IS IMPLEMENTED */}
            {/* <DragDropArea
              styling={`w-[200px] h-[200px] rounded-[14px]`}
              mediaFile={imageFile}
              setMediaFile={setImageFile}
              mediaType="image"
            /> */}
            {/* <div className="flex gap-8 mt-4 ml-2 mr-2">
              <Button
                variant="destructive"
                onClick={() => setImageFile(null)}
                disabled={!imageFile}
                className='w-[100px]'
              >
                <SvgTrash />
                Delete
              </Button>

              <Button
                variant="secondary"
                onClick={() => { }}
                disabled
                className='w-[100px]'
              >
                <SvgCrop />
                Crop
              </Button> */}
              {/* UPLOAD Redirecting for some reason - set to disabled for now */}
              {/* <form >
                <Button
                  variant="utility"
                  className='w-[100px] bg-white'
                  disabled
                >
                  <img src={uploadImage} alt="upload" />
                  Upload
                </Button>
                <input
                  type="file"
                  name="newImageFile"
                  id="newImageFile"
                  className='hidden'
                  onChange={fileSelectImage}
                />
              </form> */}
            {/* </div> */}

          </div>
          <div className='flex flex-col justify-center items-center w-full mt-8'>
            <span className='w-full flex justify-between'>
              <p><b>Quote</b></p>
              <CharLimit
                inputText={quoteText}
                charNumber={250}
              />
            </span>
            <textarea
              name="quote"
              value={quoteText}
              maxLength={250}
              onChange={(e) => setQuoteText(e.target.value)}
              className={`whitespace-pre-line resize-none border-[1px] border-solid text-graySlate text-[16px] bg-white w-full h-[150px] py-[16px] px-[20px] rounded-[10px] focus:outline-0 focus:border focus:border-solid focus:border-black focus:text-black border-grayCloud mb-6`}
              placeholder={quoteText || "Enter your quote here..."}
            ></textarea>
          </div>
          <div className="flex gap-x-2">
            <Button size="lg"
              variant={"ghost"}
              onClick={() => {
                handleClick("cancel")

              }}
            >
              Cancel
            </Button>
            <Button
              size="lg"
              variant="secondary"
              onClick={() => {
                updateBento(bentoSelection, "image", undefined, quoteText, urlToImageFile);
                handleClick("cancel");
              }}
              disabled={!quoteText?.trim()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BentoImageFlow