import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import * as z from "zod"

import { ContactRequests } from "../../../api/app.service"
import { UserExists } from "../../../api/auth.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { UserCtx } from "../../../context/userContext"
import useLoading from "../../../hooks/useLoading"
import useScrollToTop from "../../../hooks/useScrollToTop"
import { CoachPublicProfileContext } from "../../../pages/manage-business/profile/context/coachPublicProfileContext"
import { isCoachInCommissionTracking } from "../../../utils/commissionTracking"
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable"
import PasswordRequirements from "../../account-settings/PasswordRequirements"
import {
  SvgCalendar,
  SvgClock,
  SvgVideoOutline,
  SvgWebsite,
} from "../../icons/"
import { Button } from "../../ui/button"
import { Dialog, DialogContent, DialogFooter } from "../../ui/dialog"
import { Input } from "../../ui/input"
import Loader from "../../ui/loader"
import { RegisterFormContext } from "./context"
import { validateFirstAndLastName } from "./name-form"
import Recaptcha from "./Recaptcha"
import SuccessModal from "./sucess-modal"
import ForceVerifyEmail from "../../../pages/ForceVerifyEmail"

interface Props {
  showConfirm: boolean
  setShowConfirm: Dispatch<SetStateAction<boolean>>
  consultation: any
  startTime: Date
  endTime: Date
  initialSize?: string
}

const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        email,
      },
    })
    return { found: data.user_found }
  } catch (error) {
    console.error(error)
  }
}

export const CombinedConsultationForm: FC<Props> = ({
  showConfirm,
  setShowConfirm,
  consultation,
  startTime,
  endTime,
  initialSize,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { startLoading, stopLoading } = useLoading()
  const { user, setUser } = useContext(UserCtx)
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const { renderError } = useContext(CommonFunctionCtx)
  const [showForceVerifyModal, setShowForceVerifyModal] = useState(false)

  const startDate = new Date(startTime)
  const endDate = new Date(endTime)
  const formattedStartTime = formatTime(startDate)
  const formattedEndTime = formatTime(endDate)
  const navigate = useNavigate()
  const { coachPublicProfileData, isOwner } = useContext(
    CoachPublicProfileContext
  )

  const [showPaswReqs, setShowPassReq] = useState<boolean>(false)
  const [isEntreeValid, setIsEntryValid] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  )
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  )
  const [isNotRobot, setIsNotRobot] = useState<boolean>(false)

  const coachName = `${coachPublicProfileData.first_name} ${coachPublicProfileData.last_name}`
  const formattedDayOfWeek = startDate.toLocaleDateString(undefined, {
    weekday: "long",
  })
  const formattedMonth = startDate.toLocaleDateString(undefined, {
    month: "long",
  })
  const formattedDay = startDate.toLocaleDateString(undefined, {
    day: "2-digit",
  })
  const formattedYear = startDate.toLocaleDateString(undefined, {
    year: "numeric",
  })
  const durationInMinutes = Math.floor(
    (endDate.getTime() - startDate.getTime()) / (1000 * 60)
  )

  const {
    email,
    setEmail,
    firstName,
    lastName,
    password,
    setFirstName,
    setLastName,
    setPassword,
    createAccount,
  } = useContext(RegisterFormContext)

  const lengthError = !firstName.length || !lastName.length || !email.length
  const inputError =
    firstNameError !== undefined ||
    lastNameError !== undefined ||
    emailError !== undefined ||
    !isEntreeValid
  const isDisabled = lengthError || inputError || !isNotRobot

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const inputValue = e.target.value
    const inputSuccess = validateFirstAndLastName(inputValue).success
    const errorMsg = "Invalid Input"

    if (inputName === "email") {
      setEmailError(undefined)
      setEmail(inputValue)
    }

    if (inputName === "first") {
      setFirstNameError(undefined)
      setFirstName(inputValue)
      if (!inputSuccess) {
        setFirstNameError(errorMsg)
      }
    }

    if (inputName === "last") {
      setLastNameError(undefined)
      setLastName(inputValue)
      if (!inputSuccess) {
        setLastNameError(errorMsg)
      }
    }

    if (inputName === "password") {
      setPassword(inputValue)
    }
  }

  function formatTime(date: Date): string {
    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  }

  useScrollToTop()

  const getUserTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const longTimezoneName = new Date().toLocaleTimeString("en", {
      timeZoneName: "long",
    })
    const extractedTimezone = longTimezoneName.split(" ")[2]
    const timezoneWithTime = `${extractedTimezone} Time`
    let timezoneDisplay = timezoneWithTime.replace("_", " ")
    timezoneDisplay = timezoneDisplay.replace("/", " & ")
    timezoneDisplay = `${timezoneDisplay} - ${timezone}`
    return timezoneDisplay
  }

  const userTimezone = getUserTimezone()

  const handleClientSwitch = async (user: any) => {
    startLoading()
    const activeProfile = "member"
    const activeProfileId = user?.memberProfile?.profile_id
    const userObject = {
      ...user,
      activeProfile: activeProfile,
      activeProfileId: activeProfileId,
    }
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject))
    }
    setUser(userObject)
  }

  const connectAndRedirect = async (user: any) => {
    localStorage.setItem(
      "meetingToSchedule",
      JSON.stringify({ startTime: startTime, endTime: endTime })
    )

    if (isOwner) {
      renderError(
        "Can't take action on profile page when logged into this account"
      )
      return
    }

    if (user && user.memberProfile) {
      handleClientSwitch(user)
        .then(() => {
          navigate(
            `/member/contacts/${coachPublicProfileData?.coach_profile_id}/services/${consultation?.service_id}`
          )
        })
        .then(() => {
          ContactRequests.createContactConnection({
            coach_profile_id: coachPublicProfileData?.coach_profile_id,
            member_profile_id: user?.memberProfile?.profile_id,
            user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
            status: isCoachInCommissionTracking(
              coachPublicProfileData?.endpoint_slug
            )
              ? "zoee lead"
              : "connected",
          }).catch((ex) => {
            renderError(ex.response.data.message)
            stopLoading()
          })
        })
        .then(() => {
          if (!user.emailVerified) {
            setShowForceVerifyModal(true)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
        })
    } else {
      renderError("User or user's member profile is undefined")
      stopLoading()
    }
  }

  const handleAuthentication = async () => {
    setLoading(true)

    const emailSchema = z.string().email().safeParse(email)
    if (!emailSchema.success) {
      setEmailError(emailSchema.error.issues[0].message)
      setLoading(false)
      return
    }

    const emailExists = await doesEmailExist(email)
    if (emailExists?.found) {
      setEmailError("Email is already in use")
      setLoading(false)
      return
    }

    try {
      await createAccount()
      const userJSON = localStorage.getItem("user")
      if (userJSON !== null) {
        const user = JSON.parse(userJSON)
        await connectAndRedirect(user)
      } else {
        renderError("Failed to retrieve user after account creation.")
      }
    } catch (error) {
      console.error(error)
      renderError("An error occurred. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={showConfirm} onOpenChange={setShowConfirm} modal={false}>
      <DialogContent
        className="p-[24px]"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        {!successModal ? (
          <>
            <div className="flex gap-2 flex-col">
              <h3 className="font-bold text-lg text-center">{coachName}</h3>
              <div className="text-muted-foreground grid grid-cols-[auto_1fr] gap-2 my-3 text-sm text-[#45474a]">
                <SvgClock /> {durationInMinutes} minutes
                <SvgVideoOutline />
                <p className="capitalize">
                  Web conferencing details provided upon confirmation.
                </p>
                <SvgCalendar />
                {formattedStartTime} - {formattedEndTime}, {formattedDayOfWeek},{" "}
                {formattedMonth} {formattedDay}, {formattedYear}
                <SvgWebsite /> {userTimezone}
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <Input
                label="Email"
                placeholder="Email"
                value={email}
                onChange={(e) => handleChange(e, "email")}
                error={emailError}
                disabled={loading}
                autoFocus
              />
              <Input
                label="First Name"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => handleChange(e, "first")}
                error={firstNameError}
                disabled={loading}
              />
              <Input
                label="Last Name"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => handleChange(e, "last")}
                error={lastNameError}
                disabled={loading}
              />
              {showPaswReqs && (
                <PasswordRequirements
                  validEntree={password}
                  setIsEntryValid={setIsEntryValid}
                />
              )}
              <Input
                label="Password"
                placeholder="Enter Your Password"
                value={password}
                type="password"
                onChange={(e) => handleChange(e, "password")}
                onFocus={() => setShowPassReq(true)}
                onBlur={() => setShowPassReq(false)}
              />
              <Recaptcha setIsNotRobot={setIsNotRobot} />
            </div>

            <p className="text-muted-foreground text-xs text-pretty w-full text-center mx-auto items-baseline text-[#45474a]">
              By proceeding, you confirm that you have read and agree to{" "}
              <Link
                to="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Zoee's Terms of Use
              </Link>
              ,{" "}
              <Link
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Privacy Policy
              </Link>{" "}
              &{" "}
              <Link
                to="/cookie-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                Cookie Policy
              </Link>
            </p>
            <DialogFooter className="grid grid-cols-2 gap-3">
              <Button
                variant="outline"
                size="lg"
                onClick={() => setShowConfirm(false)}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="default"
                size="lg"
                onClick={handleAuthentication}
                disabled={isDisabled}
              >
                {loading ? <Loader /> : "Next"}
              </Button>
            </DialogFooter>
          </>
        ) : (
          <SuccessModal
            setShowConfirm={setShowConfirm}
            consultation={consultation}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </DialogContent>
      {showForceVerifyModal && <ForceVerifyEmail />}
    </Dialog>
  )
}
