import React from "react";
import {
  useHMSStore,
  useHMSActions,
  selectIsConnectedToRoom,
  selectPermissions,
} from "@100mslive/react-sdk";
import { SessionsRequests } from "../../api/app.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { SessionContext } from "../../pages/sessions/context/sessionContext";
import CloseModal from "../CloseModal";
import { Button } from "../ui/button";

interface Props {
  setShowEmptyRoomPrompt: any;
  sendReview: boolean;
}

const EmptyRoomPrompt: React.FC<Props> = ({ setShowEmptyRoomPrompt, sendReview }) => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const hmsActions = useHMSActions();
  const permissions = useHMSStore(selectPermissions);
  const { roomId } = React.useContext(SessionContext);
  const { renderError, setPopupNotification, hideAlert } =
    React.useContext(CommonFunctionCtx);
  const closeEmptyRoomPrompt = () => {
    setShowEmptyRoomPrompt(false);
  };
  const closePopup = () => {
    setPopupNotification({
      show: false,
      callback: null,
      title: "",
      message: "",
    });
  };
  const updateSendReview = () => {
    SessionsRequests.updateSendReview({
      send_review: sendReview,
      room_id: roomId,
    })
      .then(() => {
        // renderSuccess("");
        setTimeout(() => hideAlert(), 5000);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setTimeout(() => hideAlert(), 5000);
      });
  };

  const leaveMeeting = async () => {
    if (isConnected) {
      if (permissions?.endRoom) {
        const lock = true;
        const reason = "Host ended the session";
        await hmsActions
          .endRoom(lock, reason)
          .then(() => {
            setPopupNotification({
              show: true,
              callback: closePopup,
              title: "You ended the session",
              message:
                "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
            });
          })
          .then(() => {
            updateSendReview()
            SessionsRequests.sessionRemoveParticipant({
              room_id: roomId,
            })
              .then((data) => {
                // console.log(data);
              })
              .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
                setTimeout(() => hideAlert(), 5000);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        await hmsActions
          .leave()
          .then(() => {
            setPopupNotification({
              show: true,
              callback: closePopup,
              title: "You left the session",
              message:
                "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
            });
          })
          .then(() => {
            SessionsRequests.sessionRemoveParticipant({
              room_id: roomId,
            })
              .then((data) => {
                // console.log(data);
              })
              .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <div className="px-[20px] fixed box-shadow-2 top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] flex rounded-[16px] items-center flex-col justify-center w-[335px] md:w-[600px] h-[380px] md:h-[418px] bg-white z-[10000]">
		<CloseModal callback={closeEmptyRoomPrompt} styling="absolute top-[24px] right-[24px]" />
      <h1 className="text-black text-[22px] md:text-[28px] font-semibold text-center mb-[12px]">
        Everyone left!
      </h1>
      <p className="text-base md:text-[16px] text-graySlate w-[259px] md:w-[470px] text-center mb-[32px]">
        You can stay here and wait for members to re-join, or you can end the
        session.
      </p>
      <div className="flex items-center gap-[10px]">
        <Button onClick={closeEmptyRoomPrompt}>
          Stay Here
        </Button>
        <Button onClick={leaveMeeting} variant="destructive">
          End Session
        </Button>
      </div>
    </div>
  );
};

export default EmptyRoomPrompt;
