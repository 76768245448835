import { selectPeers, useHMSStore } from "@100mslive/react-sdk"
import * as React from "react"
import { Link } from "react-router-dom"
import { UserInfo } from "../../api/app.service"
import { Button } from "../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { UserCtx } from "../../context/userContext"
import useName from "../../hooks/useName"
import { getTextColor } from "../../utils/getTextColor"
import { getUserColor } from "../../utils/getUserColor"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import InfoModal from "../InfoModal"
import {
  SvgHelp,
  SvgInvite,
  SvgLogout,
  SvgSettings,
  SvgStar,
  SvgUser,
  SvgEducation,
} from "../icons/"
import { useAuthModal } from "../auth/use-auth-modal"
import { useIntercom } from "react-use-intercom"

const UserButton: React.FC = () => {
  // Context
  const { user } = React.useContext(UserCtx)
  const { renderError, previewImgUrl } = React.useContext(CommonFunctionCtx)

  // Hooks
  const { initial, username } = useName()
  const { logout } = useAuthModal()
  const peers = useHMSStore(selectPeers)
  const { show } = useIntercom()

  // State
  const [nextStepsData, setNextStepsData] = React.useState<any>({})
  const [, setDisabledSwitchAccount] = React.useState<boolean>(false)

  const [showReferCoachComingSoon, setShowReferCoachComingSoon] =
    React.useState<boolean>(false)

  // Functions
  const userInActiveSession =
    peers.filter((peer) => {
      return peer.customerUserId === user.activeProfileId.toString()
    }).length > 0

  const getNextStepsValues = async () => {
    await UserInfo.getNextStepsValues({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        // GET RID OF `OR` condition on master push
        setNextStepsData(data.next_steps_data)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  // useEffects
  React.useEffect(() => {
    if (userInActiveSession) {
      setDisabledSwitchAccount(true)
    } else {
      setDisabledSwitchAccount(false)
    }
  }, [peers])

  React.useEffect(() => {
    getNextStepsValues()
  }, [])

  return (
    <>
      {showReferCoachComingSoon && (
        <InfoModal
          setShowModal={setShowReferCoachComingSoon}
          header={`Exciting News! 🎉`}
          helpText={`This feature is currently in the works and will be coming soon.`}
          confirmButtonText="OK"
          isWarningVariant={false}
        />
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="rounded-full w-10 h-10">
            <Avatar size="xs">
              {Boolean(user.hasAvatar) && (
                <AvatarImage
                  alt="avatar"
                  src={previewImgUrl || user.avatar_url}
                />
              )}
              <AvatarFallback className={getUserColor(user.userColor)}>
                <div
                  style={{
                    color: getTextColor(user.userColor),
                  }}
                >
                  {initial}
                </div>
              </AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-64 p-4 rounded-lg"
          align="end"
          sideOffset={20}
        >
          <DropdownMenuLabel className="flex items-center gap-4">
            <Avatar size="sm">
              {Boolean(user.hasAvatar) && (
                <AvatarImage
                  alt="avatar"
                  src={previewImgUrl || user.avatar_url}
                />
              )}
              <AvatarFallback className={getUserColor(user.userColor)}>
                <div
                  style={{
                    color: getTextColor(user.userColor),
                  }}
                >
                  {initial}
                </div>
              </AvatarFallback>
            </Avatar>

            <div className="flex flex-col">
              <span className="capitalize text-muted-foreground">
                {user.activeProfile}
              </span>
              <span className="text-lg text-ellipsis overflow-hidden whitespace-nowrap w-36">
                {username}
              </span>
            </div>
          </DropdownMenuLabel>

          {user.activeProfile === "coach" && (
            <>
              <DropdownMenuSeparator />
              {nextStepsData?.profile_access_status === "approved" && (
                <DropdownMenuItem asChild>
                  <Link to="/coach/profile" className="text-blurple">
                    <SvgUser className="mr-4 h-6 w-6" />
                    View my Profile
                  </Link>
                </DropdownMenuItem>
              )}

              <DropdownMenuItem asChild>
                <Link to="/coach/subscription">
                  <SvgStar className="mr-4 w-6 h-6" />
                  My Subscription
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link
                  to="/affiliate"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowReferCoachComingSoon(!showReferCoachComingSoon)
                  }}
                >
                  <SvgInvite className="mr-4 h-6 w-6" />
                  Refer a coach
                </Link>
              </DropdownMenuItem>
            </>
          )}

          <DropdownMenuSeparator />

          <DropdownMenuItem asChild>
            <Link to="/account-settings">
              <SvgSettings fill="black" className="mr-4 w-6 h-6" />
              Account settings
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild>
            <a
              href="https://intercom.help/zoee/en"
              target="_blank"
              rel="noreferrer"
            >
              <SvgEducation className="mr-4 h-6 w-6" />
              Zoee University
            </a>
          </DropdownMenuItem>

          <DropdownMenuItem asChild>
            <button onClick={() => show()} className="flex items-center w-full">
              <SvgHelp className="mr-4 h-6 w-6" />
              Help
            </button>
          </DropdownMenuItem>

          <DropdownMenuItem onClick={logout} className="text-vividRed">
            <SvgLogout className="mr-4 h-6 w-6" />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default UserButton
